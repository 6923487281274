import React from 'react';
import {
    ListItem,
    ListItemText,
    Typography,
    List,
    ListItemSecondaryAction,
} from '@material-ui/core';
import { Done } from '@material-ui/icons';

const PickerField: React.FC<PickerFieldProps> = (props) => {
    const { label, options, stagedOption, setStagedOption, valueToOption } = props;

    const renderOptions = () => (
        <List>
            {options.map((value, index) => {
                const optionIsSelected = stagedOption === value;

                return (
                    <ListItem
                        key={index}
                        onClick={() => setStagedOption(value)}
                        selected={optionIsSelected}
                    >
                        <ListItemText
                            id={index.toString()}
                            primary={valueToOption ? valueToOption[value] : value}
                            primaryTypographyProps={{
                                color: optionIsSelected ? 'primary' : 'secondary',
                            }}
                        />
                        {optionIsSelected && (
                            <ListItemSecondaryAction>
                                <Done color={'primary'} />
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                );
            })}
        </List>
    );

    return (
        <>
            <Typography variant={'h6'}>{label}</Typography>
            {renderOptions()}
        </>
    );
};

interface PickerFieldProps {
    label: string;
    stagedOption: string;
    options: string[];
    setStagedOption: (value: string) => void;
    valueToOption?: Record<string, string>;
}

export default PickerField;
