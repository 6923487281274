import React, { MouseEventHandler } from 'react';
import {
    EditablePost,
    FeaturedPostPosition,
    Post,
    PostCategories,
    PostStatus,
} from 'types/post';
import { FormikHelpers } from 'formik';
import { Media, User } from '@elevatormedia/duffel-bag';

export interface PostEditorFormContextType {
    initialPostStatus?: PostStatus;
    categories: PostCategories;
    currentUser: User;
    isNewPost: boolean;
    loading?: boolean;

    onSubmit: (values: any, formikActions: FormikHelpers<any>) => void;

    togglePostDetailsVisibility: MouseEventHandler;
    showPostDetails: boolean;

    enqueueSnackbar: Function;

    uploadImageCallBack: Function;
    convertToRawHTML: Function;
    getMissingPublishingRequirements: Function;
    setIsPublishingPost: Function;
    isPublishingPost: boolean;
    handleTouchedField: (field: string, value: any) => void;

    onSaveImageTransformations: Function;
    isSavingImageTransformations: boolean;

    dateTimePickerDialogOpen: boolean;
    toggleDateTimePickerDialog: () => void;

    image: Media | null;
    featured: FeaturedPostPosition;
    postId: string;
    publishedAt: string;
    postCategories?: PostCategories;
    handleTogglePostVisibility: MouseEventHandler;
    handleMoveToTrash: () => void;

    post?: Post;
    initialValues: EditablePost;

    hasSubmission: boolean;
}

const PostEditorFormContext = React.createContext<PostEditorFormContextType>({} as any);
export const PostEditorProvider = PostEditorFormContext.Provider;
export const PostEditorConsumer = PostEditorFormContext.Consumer;

export default PostEditorFormContext;
