import React, { useEffect } from 'react';
import useStyles from './styles';
import { Chip } from '@material-ui/core';
import { Check } from 'mdi-material-ui';
import { CreditType } from 'types/credits';

const CreditTypeSelector = (props: CreditTypeSelectorProps) => {
    const classes = useStyles();
    const { creditTypes, currentType, handleChange } = props;

    useEffect(() => {
        if (!currentType && creditTypes.length > 0) handleChange(creditTypes[0].typeName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.chipListContainer}>
            {creditTypes.map((creditType, index: number) => {
                const { typeName } = creditType;
                const isActive = currentType === typeName;

                const icon = isActive ? <Check className={classes.checkmark} /> : null;

                return (
                    <div key={index} className={classes.chipContainer}>
                        <Chip
                            className={classes.chip}
                            id={'type'}
                            icon={icon}
                            label={typeName}
                            size={'medium'}
                            color={isActive ? 'primary' : 'default'}
                            onClick={() => handleChange(typeName)}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export type CreditTypeSelectorProps = {
    currentType: string;
    creditTypes: CreditType[];
    handleChange: (type: string) => void;
};

export default CreditTypeSelector;
