import React from 'react';
import {
    Button,
    Divider,
    Paper,
    Switch,
    DialogContentText,
    TextField,
} from '@material-ui/core';
import { GridItem, useAuth, User } from '@elevatormedia/duffel-bag';
import useStyles from './styles';
import { FormikProps } from 'formik';
import { EditUserProfileValues } from 'types/user';
import UserActionRow from 'atoms/UserActionRow';
import SectionHeader from 'atoms/SectionHeader';
import ConfirmationDialogue from 'molecules/ConfirmationDialogue';
import EnhancedCardHeader from 'atoms/EnhancedCardHeader';
import { useMutation } from '@apollo/client';
import { DISABLE_REFERRALS, ENABLE_REFERRALS } from 'lib/graphql/mutations/users';
import { useSnackbar } from 'notistack';
import { reportError } from 'lib/errors';

/**
 * User Action Descriptions:
 *
 * MAKE PREFERRED USER:
 * Marking a user preferred allows them to make submissions free of charge to the ELEVATOR platform.
 *
 * RESET USER ACCOUNT:
 * This will disable login capabilities and set a new temporary password code for the user,
 * requiring them to use the account activation flow to re-authenticate and continue using their account.
 * NOTE: This should only be used to recover accounts for users who have forgotten their password and did NOT verify their email.
 *
 * DISABLE/ENABLE USER ACCOUNT:
 * This is will prevent this user from re-authenticating (logging in, refreshing sessions) &
 * will block all requests to the ELEVATOR API.
 * NOTE: THIS VERSION OF THE ADMIN DASHBOARD WILL NOT NOTIFY THIS USER (COMING SOON).
 *
 * DELETE USER:
 * This is a permanent deletion and will remove all
 * account and profile information. Orders, Submissions,
 * Media, Posts, and other content created by this user
 * will not be affected.
 *
 */
const DangerZoneCard: React.FC<DangerZoneCardProps> = (props) => {
    const classes = useStyles();
    const { currentUser } = useAuth();
    const {
        values,
        handleChange,
        setFieldTouched,
        readOnly,
        handleDisableUser,
        handleEnableUser,
        user,
        handleDelete,
        handleReset,
        canDeleteUser,
        canDisableUser,
        canBanUser,
        handleBanUser,
        canResetUser,
        renderDangerOptions,
        handleTouchedFields,
        refetch,
    } = props;

    /**
     * Handler func for field changes in the formik form. Will set the new state value
     * as well a mark the field as "touched" for validation purposes.
     */
    const handleSwitchChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ) => {
        handleChange(event);
        setFieldTouched(event.target.id, true);
        handleTouchedFields('preferred', `${checked}`);
    };

    const { enqueueSnackbar } = useSnackbar();

    const [confirmationDialogeOpen, setConfirmationDialogeOpen] = React.useState(false);
    const [dialogeTitle, setDialogeTitle] = React.useState('');
    const [dialogBody, setDialogBody] = React.useState<JSX.Element | string>(null);
    const [onConfirmCb, setOnConfirmCb] = React.useState(null);
    const [banReason, setBanReason] = React.useState('');

    const [banUserConfirmationOpen, setBanUserConfirmationOpen] = React.useState(false);

    const [enableReferrals] = useMutation(ENABLE_REFERRALS);
    const [disableReferrals] = useMutation(DISABLE_REFERRALS);

    const toggleConfirmationOpen = (
        title: string,
        body: JSX.Element | string,
        cb: Function,
    ) => {
        setDialogeTitle(title);
        setDialogBody(body);
        /**
         * Setting an additional anonymous function wrapper around your callback is necessary
         * when using state hooks. Otherwise your function get called immediately.
         * Read more here: https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
         */
        setOnConfirmCb(() => cb);
        setConfirmationDialogeOpen(!confirmationDialogeOpen);
    };

    const userAccountStatusText = user.disabled ? 'Enable' : 'Disable';

    const userAccountStatusHandler = user.disabled ? handleEnableUser : handleDisableUser;

    const referralsText = user.referToken ? 'Disable' : 'Enable';
    const referralsDesc = user.referToken
        ? `User can refer others using https://${process.env.cookieDomain}/submit?referId=${user.referToken}`
        : 'User can not refer others';

    const handleEnableReferrals = async () => {
        try {
            await enableReferrals({
                variables: {
                    userId: user.userId,
                },
            });

            enqueueSnackbar(`${user.preferredUsername}'s referrals have been enabled`, {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                preventDuplicate: true,
            });

            await refetch();
        } catch (err) {
            reportError(err, {
                metaData: {
                    operation: 'mutation enableUserReferrals',
                },
                user: {
                    username: currentUser.username,
                },
            });
            enqueueSnackbar('Error enabling referrals', {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                preventDuplicate: true,
                variant: 'error',
            });
        }
    };

    const handleDisableReferrals = async () => {
        try {
            await disableReferrals({
                variables: {
                    userId: user.userId,
                },
            });

            enqueueSnackbar(`${user.preferredUsername}'s referrals have been disabled`, {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                preventDuplicate: true,
            });

            await refetch();
        } catch (err) {
            reportError(err, {
                metaData: {
                    operation: 'mutation disableUserReferrals',
                },
                user: {
                    username: currentUser.username,
                },
            });
            enqueueSnackbar('Error disabling referrals', {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                preventDuplicate: true,
                variant: 'error',
            });
        }
    };

    const renderCardActions = () => {
        return (
            <>
                <Divider />
                <div className={classes.dangerCard}>
                    <UserActionRow
                        actionTitle={'Make this user preferred'}
                        actionDescription={``}
                    >
                        <Switch
                            checked={values.preferred}
                            onChange={handleSwitchChange}
                            id={'preferred'}
                            name={'preferred'}
                            color="primary"
                            size={'small'}
                            disabled={readOnly}
                        />
                    </UserActionRow>

                    <UserActionRow
                        actionTitle={`${referralsText} referrals for this user`}
                        actionDescription={referralsDesc}
                    >
                        <Button
                            onClick={() =>
                                toggleConfirmationOpen(
                                    `${referralsText} Referrals`,
                                    <DialogContentText>
                                        {`${referralsText} referrals for ${user.preferredUsername}?`}
                                    </DialogContentText>,
                                    user.referToken
                                        ? handleDisableReferrals
                                        : handleEnableReferrals,
                                )
                            }
                        >
                            {referralsText}
                        </Button>
                    </UserActionRow>

                    {renderDangerOptions && (
                        <>
                            {canResetUser && (
                                <UserActionRow
                                    actionTitle={`Reset this user's account`}
                                    actionDescription={`
                                `}
                                >
                                    <Button
                                        onClick={() =>
                                            toggleConfirmationOpen(
                                                'Reset User',
                                                <DialogContentText>
                                                    {`Reset ${user.preferredUsername}'s account?`}
                                                </DialogContentText>,
                                                handleReset,
                                            )
                                        }
                                        disabled={!canResetUser}
                                        variant={'text'}
                                        size={'small'}
                                        color={'default'}
                                        className={classes.masterPasswordReset}
                                    >
                                        {'Master Password Reset'}
                                    </Button>
                                </UserActionRow>
                            )}

                            {canDisableUser && (
                                <UserActionRow
                                    actionTitle={`${userAccountStatusText} this user's account`}
                                    actionDescription={``}
                                >
                                    <Button
                                        onClick={() => {
                                            toggleConfirmationOpen(
                                                `${userAccountStatusText} Account`,
                                                <DialogContentText>
                                                    {`${userAccountStatusText} ${user.preferredUsername}'s account?`}
                                                </DialogContentText>,
                                                userAccountStatusHandler,
                                            );
                                        }}
                                        disabled={!canDeleteUser}
                                        variant={'text'}
                                        size={'small'}
                                        className={
                                            user.disabled
                                                ? classes.activateAction
                                                : classes.deactivateAction
                                        }
                                    >
                                        {user.disabled
                                            ? 'Enable Account'
                                            : 'Disable Account'}
                                    </Button>
                                </UserActionRow>
                            )}

                            <SectionHeader textColor={'red'}>danger zone</SectionHeader>
                            {canBanUser && !user.disabled && (
                                <UserActionRow
                                    actionTitle={'Ban this user'}
                                    actionDescription={``}
                                >
                                    <Button
                                        onClick={() => setBanUserConfirmationOpen(true)}
                                        disabled={!canBanUser}
                                        variant={'text'}
                                        className={classes.deleteAction}
                                        size={'small'}
                                    >
                                        {'Ban User'}
                                    </Button>
                                </UserActionRow>
                            )}
                            {canDeleteUser && (
                                <UserActionRow
                                    actionTitle={'Delete this user'}
                                    actionDescription={``}
                                >
                                    <Button
                                        onClick={() => {
                                            toggleConfirmationOpen(
                                                'Delete user',
                                                `Delete ${user.preferredUsername}'s account?`,
                                                handleDelete,
                                            );
                                        }}
                                        disabled={!canDeleteUser}
                                        variant={'text'}
                                        className={classes.deleteAction}
                                        size={'small'}
                                    >
                                        {'Delete User'}
                                    </Button>
                                </UserActionRow>
                            )}
                        </>
                    )}
                </div>
            </>
        );
    };

    return (
        <GridItem>
            <Paper className={classes.largeCard}>
                <EnhancedCardHeader title={'User Actions'} />

                {renderCardActions()}

                <ConfirmationDialogue
                    confirmationDialogueOpen={confirmationDialogeOpen}
                    closeConfirmationDialogue={() => setConfirmationDialogeOpen(false)}
                    title={dialogeTitle}
                    confirmButtonText={'Confirm'}
                    onConfirm={onConfirmCb}
                >
                    {dialogBody}
                </ConfirmationDialogue>

                <ConfirmationDialogue
                    confirmationDialogueOpen={banUserConfirmationOpen}
                    closeConfirmationDialogue={() => {
                        setBanReason('');
                        setBanUserConfirmationOpen(false);
                    }}
                    title={`Ban ${user.preferredUsername}'s account`}
                    confirmButtonText={'Confirm'}
                    onConfirm={() => {
                        handleBanUser(user.email, banReason);
                    }}
                    classes={{
                        paper: classes.banDialog,
                    }}
                >
                    <TextField
                        id={'reason'}
                        label={'Reason for ban'}
                        value={banReason}
                        margin={'normal'}
                        variant={'outlined'}
                        fullWidth
                        onChange={(event) => setBanReason(event.target.value)}
                    />
                </ConfirmationDialogue>
            </Paper>
        </GridItem>
    );
};

interface DangerZoneCardProps extends FormikProps<EditUserProfileValues> {
    isValid: boolean;
    touched: object;
    readOnly: boolean;
    handleTouchedFields: (key: string, value: string) => void;
    handleDisableUser?: () => void;
    handleEnableUser?: () => void;
    user: User & { referToken?: string };
    handleDelete?: () => void;
    canDeleteUser: boolean;
    canDisableUser: boolean;
    canBanUser: boolean;
    handleBanUser?: (email: string, reason: string) => void;
    canResetUser: boolean;
    renderDangerOptions: boolean;
    refetch: () => Promise<any>;
}

export default DangerZoneCard;
