import React from 'react';
import { Toolbar, Typography } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';

const EnhancedTableToolbar: React.FC<EnhancedTableToolbarProps> = ({
    tableTitle,
    numSelected = 0,
    toolbarComponent,
}) => {
    const classes = useStyles();

    return (
        <Toolbar
            className={clsx(classes.toolbarRoot, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography
                        color={'inherit'}
                        variant={'subtitle1'}
                        className={classes.selectedText}
                    >
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography variant={'h6'} id={tableTitle}>
                        {tableTitle}
                    </Typography>
                )}
            </div>
            <div className={classes.spacer} />
            {toolbarComponent && (
                <div className={classes.actions}>{toolbarComponent}</div>
            )}
        </Toolbar>
    );
};

interface EnhancedTableToolbarProps {
    tableTitle: string;
    numSelected?: number;
    toolbarComponent?: React.ReactNode;
}

export default EnhancedTableToolbar;
