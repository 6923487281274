import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContainer: {
            minHeight: '350px',
            minWidth: '550px',
            [theme.breakpoints.down('sm')]: {
                minHeight: '70px',
                minWidth: '50px',
            },
        },
        dialogContent: {
            minHeight: '350px',
            minWidth: '530px',
            height: '30vh',
            [theme.breakpoints.down('sm')]: {
                minHeight: '70px',
                minWidth: '50px',
            },
        },
        currentAuthorsContainer: {
            paddingBottom: theme.spacing(3),
        },
        currentAuthorChip: {
            marginRight: theme.spacing(1),
        },
        noAuthorsText: {
            textAlign: 'center',
            paddingTop: theme.spacing(1),
        },
        searchResultAvatar: {
            height: '30px',
            width: '30px',
        },
    }),
);

export default useStyles;
