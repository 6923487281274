import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // Media Library
        paper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(4),
            width: '100%',
        },
        modalMargin: {
            margin: theme.spacing(4),
        },
        headerContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: theme.spacing(2),
        },
        imageContainer: {
            width: '100%',
            height: '100%',
        },
        mediaContents: {
            width: '100%',
            height: '100%',
            maxHeight: '72vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: theme.spacing(2),
        },
        toolBar: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginBottom: theme.spacing(2),
        },
        searchBarContainer: {
            width: '100%',
            paddingRight: theme.spacing(3),
        },
        imageItemBar: {
            background:
                'linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, ' +
                'rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%)',
        },
        infoIcon: {
            marginRight: theme.spacing(1),
            color: theme.palette.custom.grey.g200 + 'BF',
        },

        // Modal
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
);

export default useStyles;
