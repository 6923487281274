import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        secondaryTextLabel: {
            fontSize: theme.typography.pxToRem(12),
        },
        labelContainer: {
            paddingTop: theme.spacing(1.5),
        },
        legendText: {
            fontSize: theme.typography.pxToRem(18),
            paddingRight: theme.spacing(1),
        },
        formLabel: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        chevron: {
            color: 'black',
        },
    }),
);

export default useStyles;
