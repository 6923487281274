import paletteConfig from './palette';
import { reduceOpacity } from '@elevatormedia/duffel-bag';
import { SimplePaletteColorOptions } from '@material-ui/core';

/**
 * Create an instance of default theme so we can use the default theme variables
 * See More:
 * https://stackoverflow.com/questions/47977618/accessing-previous-theme-variables-in-createmuitheme
 */
const opaqueMain = reduceOpacity(
    (paletteConfig.primary as SimplePaletteColorOptions).main,
    0.12,
);

/**
 * Global component style configuration for entire application. This JS object allows us to provide default
 * styling across the application
 *
 * More Info: https://material-ui.com/customization/globals/#css
 */
const overridesConfig = {
    overrides: {
        MuiTableRow: {
            root: {
                cursor: 'pointer',
                '&$selected': {
                    backgroundColor: opaqueMain,
                },
                '&$hover:hover': {
                    backgroundColor: opaqueMain,
                },
            },
        },
        MuiPaper: {
            root: {
                border: `1px solid ${paletteConfig.divider}`,
            },
        },
        MuiListItem: {
            root: {
                '&$selected': {
                    backgroundColor: opaqueMain,
                },
            },
        },
    },
};

export default overridesConfig;
