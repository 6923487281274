import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridContainerWrapper: {
            width: '100%',
        },
        borderedBox: {
            minHeight: '260px',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            outline: 'none',
            '&:hover': {
                cursor: 'pointer',
                border: `3px dashed ${theme.palette.divider}`,
            },
            transition: 'border .10s ease-in-out',
        },
        addDefaultBorder: {
            border: `2px dashed ${theme.palette.divider}`,
        },
        addBlueBorder: {
            border: `2px dashed ${theme.palette.primary.main}`,
        },
        paperHeader: {
            padding: theme.spacing(1),
        },
        instructionContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        icon: {
            fontSize: theme.typography.pxToRem(100),
        },
        uploadInstr: {
            maxWidth: '250px',
        },
        fileUploadCardContainer: {
            padding: theme.spacing(1, 0),
        },
        fileNameContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: theme.spacing(1),
        },
        fileName: {
            paddingLeft: theme.spacing(1),
        },
        errorBorder: {
            border: `2px dashed red`,
        },
        iconInactive: {
            color: theme.palette.custom.black50,
        },
        image: {
            margin: theme.spacing(0, 0, 2, 2),
            height: 'auto',
            width: 'auto',
            maxHeight: '300px',
        },
    }),
);

export default useStyles;
