import React from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';

const EnhancedCardHeader: React.FC<EnhancedCardHeaderProps> = ({
    title,
    color = 'textSecondary',
    children,
}) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.cardContentContainer, classes.cardSectionContainer)}>
            {title ? (
                <Typography variant={'overline'} color={color}>
                    {title}
                </Typography>
            ) : null}
            {children}
        </div>
    );
};

interface EnhancedCardHeaderProps {
    title?: string;
    color?: 'textPrimary' | 'textSecondary';
}

export default EnhancedCardHeader;
