import React from 'react';
import {
    ButtonGroup,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    Button,
    CircularProgress,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import useStyles from './styles';

const SplitButton: React.FC<SplitButtonProps> = (props) => {
    const classes = useStyles();

    const { options, loading, disabled, size, ...restButtonProps } = props;

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClick = () => {
        options[selectedIndex].onClick();
    };

    const handleMenuItemClick = (index: number) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <ButtonGroup
                variant="contained"
                color="primary"
                size={size}
                ref={anchorRef}
                aria-label="split button"
                classes={{
                    groupedContained: classes.groupedButton,
                }}
                disabled={disabled}
                {...restButtonProps}
            >
                <Button className={classes.leftButton} onClick={handleClick}>
                    {options[selectedIndex].text}
                    {loading ? (
                        <div className={classes.progressContainer}>
                            <CircularProgress color={'inherit'} size={14} />
                        </div>
                    ) : null}
                </Button>
                {options.length >= 2 ? (
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="other action options"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                ) : null}
            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {options.map((option, index) => {
                                        const handleClick = () =>
                                            handleMenuItemClick(index);
                                        return (
                                            <MenuItem
                                                key={option.text}
                                                selected={index === selectedIndex}
                                                onClick={handleClick}
                                            >
                                                {option.text}
                                            </MenuItem>
                                        );
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

interface SplitButtonProps {
    loading?: boolean;
    options: Array<{
        text: string;
        onClick: () => void;
    }>;
    disabled?: boolean;
    size?: 'medium' | 'large' | 'small';
}

export default SplitButton;
