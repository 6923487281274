import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        eventIcon: {
            fontSize: theme.typography.pxToRem(14),
        },
        historyTimelineContainer: {
            position: 'relative',
            left: theme.spacing(5),
            width: '80%',
            borderLeft: `3px solid ${theme.palette.divider}`,
        },
        historyListHeader: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            minHeight: '50px',
        },
        historyItemContainer: {
            position: 'relative',
            right: '17px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            paddingBottom: theme.spacing(5),
        },
        eventDescription: {
            paddingBottom: theme.spacing(1),
        },
        historyItemContentContainer: {
            paddingLeft: theme.spacing(2),
        },
        historyItemIcon: {
            minWidth: '30px',
            minHeight: '30px',
            borderRadius: '15px',
            border: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.paper,
        },
        userCommentCard: {
            margin: theme.spacing(1),
            minHeight: '75px',
            maxWidth: '400px',
        },
        alignTop: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            margin: 0,
            paddingLeft: theme.spacing(1),
            maxWidth: '80%',
        },
        userCommentCartAvatar: {
            width: '35px',
            height: '35px',
        },
        userCommentCardHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
        },
        historyListFooter: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '70px',
            borderTop: `4px solid ${theme.palette.divider}`,
        },
        commentCardText: {
            fontSize: theme.typography.pxToRem(15),
        },
        boldedText: {
            fontWeight: 'bold',
        },
    }),
);

export default useStyles;
