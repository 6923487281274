import { Divider, InputBase, InputBaseProps, Paper, withStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import useStyles from './styles';

/**
 * Searchbar: Adds Custom Searchbar component which accepts Inputbase
 * props. Additionally allows rendering custom children as an end-adornment of the
 * searchbar
 */
const Searchbar: React.FC<SearchbarProps> = (props) => {
    const { removeBorder, onSubmit, value: valueProp, ...rest } = props;
    const classes = useStyles();
    const [value, setValue] = useState(valueProp || '');

    useEffect(() => {
        if (valueProp !== undefined) {
            setValue(valueProp);
        }
    }, [valueProp]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        if (props.onChange) {
            props.onChange(event);
        }
    };
    const preventDefault: React.FormEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        if (onSubmit) onSubmit(e as React.FormEvent<HTMLDivElement>);
    };

    return (
        <Paper className={clsx(classes.root, removeBorder && classes.removeBorder)}>
            <SearchIcon className={classes.searchIcon} />
            <div role={'search'} className={classes.form} onSubmit={preventDefault}>
                <InputBase
                    type={'search'}
                    name={'q'}
                    className={classes.input}
                    onChange={handleChange}
                    value={value}
                    {...rest}
                />
            </div>
            {props.children && (
                <Divider orientation={'vertical'} className={classes.divider} />
            )}
            {props.children}
        </Paper>
    );
};

interface SearchbarProps extends InputBaseProps {
    removeBorder?: boolean;
}

export default Searchbar;
