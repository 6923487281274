import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            padding: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '4px',
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2),
                borderRadius: '2px',
            },
        },
        titleText: {
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.custom.grey.g600,
            marginBottom: theme.spacing(1),
        },
        itemContainer: {
            marginLeft: theme.spacing(5),
            [theme.breakpoints.down('xs')]: {
                marginLeft: theme.spacing(1),
            },
        },
        rightContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        stackedContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        valueText: {
            fontSize: theme.typography.pxToRem(25),
            fontWeight: 500,
            [theme.breakpoints.down('xs')]: {
                fontSize: theme.typography.pxToRem(20),
            },
        },
        labelText: {
            fontSize: theme.typography.pxToRem(12),
            letterSpacing: '0.005px',
            wordSpacing: '0.25px',
            fontWeight: 300,
            color: theme.palette.custom.grey.g600,
        },
    }),
);

export default useStyles;
