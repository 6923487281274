import React from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Typography,
    withStyles,
    Collapse,
} from '@material-ui/core';
import { camelCaseToRegular } from '@elevatormedia/duffel-bag';
import { ChevronUp, ChevronDown } from 'mdi-material-ui';
import clsx from 'clsx';
import useStyles from './styles';

const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
    const classes = useStyles();
    const {
        handleChange,
        legendText,
        checkboxLabels,
        secondaryTextKey,
        primaryTextKey,
        checkedKey,
        readOnly,
    } = props;

    const [expanded, setExpanded] = React.useState(false);

    const toggleCollapse = () => {
        setExpanded(!expanded);
    };

    return (
        <FormControl component="fieldset">
            <FormLabel
                onClick={toggleCollapse}
                component="label"
                className={classes.formLabel}
            >
                <Typography
                    variant={'subtitle2'}
                    color={'textPrimary'}
                    className={classes.legendText}
                >
                    {legendText.replace('_', ' ')}
                </Typography>

                {expanded ? <ChevronUp /> : <ChevronDown />}
            </FormLabel>
            <FormGroup>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {checkboxLabels.map((labelConfig, index) => {
                        // Use Object.prototype.hasOwnProperty.call
                        const hasSecondaryText = Object.prototype.hasOwnProperty.call(
                            labelConfig,
                            secondaryTextKey,
                        );

                        const value = labelConfig[primaryTextKey];
                        // Create a one-to-one mapping of keys to proper context lables
                        const primaryText = camelCaseToRegular(
                            labelConfig[primaryTextKey],
                            false,
                        );
                        const secondaryText = labelConfig[secondaryTextKey];
                        const isChecked = labelConfig[checkedKey];

                        return (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        color={'primary'}
                                        checked={isChecked}
                                        onChange={(event) => handleChange(value, event)}
                                        value={primaryText}
                                        disabled={readOnly}
                                    />
                                }
                                label={
                                    <div
                                        className={clsx(
                                            hasSecondaryText && classes.labelContainer,
                                        )}
                                    >
                                        <Typography variant={'body2'}>
                                            {primaryText}
                                        </Typography>
                                        {hasSecondaryText && (
                                            <Typography
                                                variant={'body2'}
                                                color={'textSecondary'}
                                                className={classes.secondaryTextLabel}
                                            >
                                                {secondaryText}
                                            </Typography>
                                        )}
                                    </div>
                                }
                            />
                        );
                    })}
                </Collapse>
            </FormGroup>
        </FormControl>
    );
};

export interface CheckboxGroupProps {
    checkboxLabels: any[];
    legendText?: string;
    handleChange: (value: string, event: any) => void;
    readOnly?: boolean;
    secondaryTextKey?: string;
    primaryTextKey?: string;
    checkedKey?: string;
}

export default CheckboxGroup;
