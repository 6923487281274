import { Theme, makeStyles } from '@material-ui/core';

const styles: any = (theme: Theme | any) => ({
    /*****************
     * AUTO COMPLETE *
     *****************/
    editDistanceText: {
        fontWeight: 'bold',
    },
    container: {
        flexGrow: 1,
        position: 'relative',
        width: '100%',
    },
    input: {
        padding: theme.spacing(1, 0, 1, 0),
        // backgroundColor: theme.palette.custom.grey.light,
        borderBottomWidth: 0,
        border: 'none',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
        left: 0,
        right: 0,
        zIndex: 1,
        borderRadius: '3px',
        display: 'block',
        backgroundColor: theme.palette.background.paper,
    },
    suggestionsContainer: {
        position: 'absolute',
        width: '100%',
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        borderTopWidth: 0,
        border: 'none',
    },

    noResultsFoundContainer: {
        zIndex: 10,
    },

    searchIcon: {
        paddingLeft: theme.spacing(2),
        paddingTop: '2px',
    },
});

export default makeStyles(styles);
