import React from 'react';
import { AppProps } from 'next/app';
import theme from '../src/theme/elevatorTheme';
import bugsnagClient from '../src/lib/errors/bugsnag';
import Error from './_error';
import { ElevatorProvider } from '@elevatormedia/duffel-bag';
import { reportError } from '../src/lib/errors';
import { MY_USER_AND_PERMISSIONS } from '../src/lib/graphql/queries/users';
import NavWrapper from '../src/organisms/NavWrapper';

const ErrorBoundary = bugsnagClient.getPlugin('react');

function App({ Component, pageProps }: AppProps) {
    return (
        <ErrorBoundary FallbackComponent={Error}>
            <ElevatorProvider
                theme={theme}
                reportError={reportError}
                apiHost={new URL(process.env.apiUri).origin}
                userQuery={MY_USER_AND_PERMISSIONS}
                defaultSeoProps={{
                    title: 'Admin | ELEVATOR',
                }}
            >
                <NavWrapper>
                    <Component {...pageProps} />
                </NavWrapper>
            </ElevatorProvider>
        </ErrorBoundary>
    );
}

export default App;
