import isURL from 'validator/lib/isURL';
import { reportError } from './errors';

export const encodeFromURL = (fromURL: string) => {
    try {
        if (!fromURL) {
            throw new Error('from URL must not be null or empty');
        }

        if (!window) {
            throw new Error('window must be defined to B64 encode fromURL');
        }

        // determine if string is a valid url
        const isValidURL = isURL(fromURL, {
            require_host: true,
            require_protocol: true,
            host_whitelist: ['admin.elevatormag.com', 'localhost', 'admin.efflux.media'],
            require_tld: process.env.cookieDomain !== 'localhost',
        });

        // Invalidate redirect if not valid
        if (!isValidURL) {
            throw new Error(
                'invalid redirect URL. Must be of origin ELEVATOR, but got ' + fromURL,
            );
        }

        // Parse redirect to b64 string
        const b64EncodedFromURL = window && window.btoa(fromURL);

        // Otherwise return the requested redirect
        return b64EncodedFromURL;
    } catch (err) {
        reportError(err, {
            metaData: {
                operation: 'b64 encode redirect URL',
            },
        });
        return null;
    }
};
