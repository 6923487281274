import { reduceOpacity } from '@elevatormedia/duffel-bag';

import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        /*****************
         * IMAGE OPTIONS *
         *****************/

        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: theme.spacing(0, 0, 2, 2),

            [theme.breakpoints.up(1670)]: {
                flexDirection: 'row',
            },
        },
        imageContainer: {
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(4),
        },
        imagePreview: {
            height: '170px',
            width: '280px',
            objectFit: 'cover',
        },
        loadingContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.background.default,
        },
        imageResDownloaderContainer: {
            paddingTop: theme.spacing(4),
            marginRight: theme.spacing(1.5),
        },
        formControlLabel: {
            margin: 0,
            position: 'relative',
            bottom: '12px',
        },

        imageOptionsContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },

        /*********************
         * IMAGE CROP OPTION *
         *********************/
        imageCropOptionItemRoot: {
            '&:not(:last-child)': {
                paddingRight: theme.spacing(2),
            },
            [theme.breakpoints.down('sm')]: {
                paddingBottom: theme.spacing(2),
            },
        },
        imageCropOptionContainer: {
            minHeight: '150px',
            minWidth: '120px',
            borderRadius: '4px',
            border: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.custom.black50,
            '&:hover': {
                backgroundColor: reduceOpacity(theme.palette.primary.main, 0.15),
                cursor: 'pointer',
                border: `2px solid ${theme.palette.primary.main}`,
                color: theme.palette.primary.main,
            },
        },
        activeCropOption: {
            border: `1px solid ${theme.palette.primary.main}`,
            // color: theme.palette.text.textSecondary,
            backgroundColor: reduceOpacity(theme.palette.primary.main, 0.05),
        },
        icon: {
            color: 'inherit',
            fontSize: theme.typography.pxToRem(40),
        },
        imageCropOptionText: {
            paddingTop: theme.spacing(1),
            color: 'inherit',
        },
        activeOption: {
            color: theme.palette.primary.main,
        },
        saveButtonContainer: {
            padding: theme.spacing(2, 0),
        },
    }),
);

export default useStyles;
