import React from 'react';
import useStyles from './styles';
import {
    centsToDollars,
    formatPrice,
    GridContainer,
    GridItem,
} from '@elevatormedia/duffel-bag';
import { Button, Divider, Paper, Typography } from '@material-ui/core';
import { Order } from 'types/order';
import { Service } from 'types/service';
import { REFUND_ORDER } from '../../lib/graphql/mutations/orders';
import { SUBMISSION_BY_ID } from '../../lib/graphql/queries/submissions';
import { reportError } from '../../lib/errors';
import { useMutation } from '@apollo/client';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

/**
 * Transaction Card: Stores order details and refund functionality.
 */
const TransactionCard: React.FC<TransactionCardProps> = (props) => {
    const classes = useStyles();

    const { services, serviceObjects, order, submissionId, canCreateRefund } = props;
    const { orderId, amountPaid, ccLast4, status, paymentType } = order;

    const total = formatPrice(amountPaid);

    const { enqueueSnackbar } = useSnackbar();
    const [refundOrder] = useMutation(REFUND_ORDER);

    const canRefundOrder =
        canCreateRefund && status === 'paid' && amountPaid > 0 && paymentType !== 'Free';

    const refundAttachedOrder = async () => {
        try {
            await refundOrder({
                variables: { orderId },
                refetchQueries: [
                    {
                        query: SUBMISSION_BY_ID,
                        variables: { submissionId },
                    },
                ],
            });
            enqueueSnackbar('Refund created', {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                variant: 'success',
            });
        } catch (err) {
            reportError(err);
            enqueueSnackbar('Failed to create refund', {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                variant: 'error',
            });
        }
    };

    /**
     * Renders one service per line, including its description, price, and quantity
     */
    const renderServiceLines = () => {
        if (services)
            return [...services]
                .sort((a, b) => {
                    if (a.parent) {
                        return 1;
                    } else if (b.parent) {
                        return -1;
                    } else {
                        return 0;
                    }
                })
                .map((item, index) => (
                    <GridItem xs key={index}>
                        <GridContainer direction={'row'}>
                            <GridItem xs={8}>
                                <Typography
                                    className={item.parent ? classes.addOn : ''}
                                    align={'left'}
                                    variant={'body2'}
                                >
                                    {item.name}
                                </Typography>
                            </GridItem>
                            <GridItem xs={4}>
                                <Typography
                                    className={item.parent ? classes.addOnCost : ''}
                                    align={'right'}
                                    variant={'body2'}
                                >
                                    ${centsToDollars(item.cost)}
                                </Typography>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                ));
        // NOTE: see note related to serviceObjects prop below; this can all be removed then as well
        else if (serviceObjects) {
            return serviceObjects.map((item, index) => (
                <GridItem xs key={index}>
                    <GridContainer direction={'row'}>
                        <GridItem xs={8}>
                            <Typography align={'left'} variant={'body2'}>
                                {item.serviceDescription}
                            </Typography>
                        </GridItem>
                        <GridItem xs={4}>
                            <Typography align={'right'} variant={'body2'}>
                                ${item.servicePrice}
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            ));
        }
    };

    return (
        <GridItem>
            <Typography
                variant={'body1'}
                color={'textPrimary'}
                className={clsx(classes.dataFieldValue, classes.transactionCardField)}
            >
                Payment Type: {paymentType}
            </Typography>
            <Typography
                variant={'body1'}
                color={'textPrimary'}
                className={clsx(classes.dataFieldValue, classes.transactionCardField)}
            >
                Status: {status}
            </Typography>
            {ccLast4 && (
                <Typography
                    variant={'body1'}
                    color={'textPrimary'}
                    className={clsx(classes.dataFieldValue, classes.transactionCardField)}
                >
                    Last 4: {`XXXX XXXX XXXX ${ccLast4}`}
                </Typography>
            )}
            <Paper className={classes.transactionCard}>
                <GridContainer
                    direction={'column'}
                    className={classes.transactionCardContent}
                >
                    {renderServiceLines()}
                    <Divider variant={'fullWidth'} />
                    <GridItem className={classes.grandTotalContainer}>
                        <Typography
                            display={'inline'}
                            component={'span'}
                            align={'right'}
                            variant={'body2'}
                        >
                            Grand Total:
                            <Typography
                                display={'inline'}
                                variant={'body2'}
                                className={classes.boldedTextEmphasis}
                            >
                                ${total}
                            </Typography>
                        </Typography>
                    </GridItem>
                </GridContainer>
            </Paper>
            {canRefundOrder && (
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={refundAttachedOrder}
                    className={classes.createRefundButton}
                >
                    Create Refund
                </Button>
            )}
        </GridItem>
    );
};

interface TransactionCardProps {
    services?: Service[];
    // NOTE: the following prop can be removed and replaced with the above once
    // submissions are used to make campaigns; submission.services should then
    // be used instead
    serviceObjects?: {
        serviceDescription: string;
        servicePrice: string;
    }[];
    order: Order;
    submissionId?: string;
    canCreateRefund: boolean;
}

export default TransactionCard;
