import { SocialMedia, SocialMediaType } from '@elevatormedia/duffel-bag';
import { buildUrl, extractUser } from '@elevatormedia/socialite';
import { Credit, Credits } from 'types/credits';

export const copyContentLink = (contentUrl: string, onCopyCredits: Function) => {
    const placeholder = document.createElement('INPUT') as HTMLInputElement;
    document.body.appendChild(placeholder);

    placeholder.setAttribute('value', contentUrl);
    placeholder.select();
    document.execCommand('copy');
    document.body.removeChild(placeholder);

    onCopyCredits('Streaming link copied to clipboard');
};

export const getSocialMediaLink = (
    socialMediaArr: SocialMedia,
    type: SocialMediaType,
) => {
    const res = socialMediaArr.findIndex(
        (socialMediaEntry) => socialMediaEntry.type === type,
    );

    if (res === -1) return null;

    // Use Socialite to generate fully-qualified links
    if (type === 'appleMusic') {
        return socialMediaArr[res].link;
    } else if (type === 'spotify') {
        return buildUrl(extractUser(socialMediaArr[res].link), type, 'artist');
    } else {
        return buildUrl(extractUser(socialMediaArr[res].link), type);
    }
};

export const formatCredits = (credit: Credit) => {
    if (!credit.socialMedia.length) {
        return null;
    }

    // Adds a Start * followed by the Artist name
    const artist = `${String.fromCharCode(9733)} ${credit.name}`;

    const twitter = getSocialMediaLink(credit.socialMedia, 'twitter');
    const instagram = getSocialMediaLink(credit.socialMedia, 'instagram');
    const facebook = getSocialMediaLink(credit.socialMedia, 'facebook');

    const soundcloud = getSocialMediaLink(credit.socialMedia, 'soundcloud');
    const spotify = getSocialMediaLink(credit.socialMedia, 'spotify');
    const appleMusic = getSocialMediaLink(credit.socialMedia, 'appleMusic');

    const youtube = getSocialMediaLink(credit.socialMedia, 'youtube');

    // ordered social media list by filtering out null values
    let orderedStringList = [
        artist,
        twitter,
        instagram,
        facebook,
        soundcloud,
        spotify,
        appleMusic,
        youtube,
    ];

    orderedStringList = orderedStringList.filter((stringValue) => stringValue);

    return orderedStringList.join('\n');
};

export const copyCreditsAsFormatted = (credits: Credits, onCopyCredits: Function) => {
    const copyValue = credits
        .map((credit) => formatCredits(credit))
        .filter((credit) => credit)
        .join('\n\n');
    const placeholder = document.createElement('textarea');
    placeholder.value = copyValue;

    document.body.appendChild(placeholder);

    placeholder.select();
    document.execCommand('copy');
    document.body.removeChild(placeholder);

    onCopyCredits('Submission credits copied to clipboard');
};
