import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        menu: {
            minWidth: '250px',
        },
        menuItem: {
            padding: theme.spacing(1, 1.5),
        },
        menuIcon: {
            color: theme.palette.text.secondary,
        },
    });

export default makeStyles(styles);
