import React, { useState } from 'react';
import useStyles from '../styles';
import { Button, MenuItem, TextField, Typography } from '@material-ui/core';
import { StateUpdateFunction } from '../../../types/editor';
import { POST_TYPES, GridItem } from '@elevatormedia/duffel-bag';
import { PostEditorFormValues } from '..';
import { useFormikContext } from 'formik';
import { PostCategories } from 'types/post';
import { useQuery } from '@apollo/client';
import { SEARCH_TAGS } from 'lib/graphql/queries/tags';
import { WriteupQueueItem } from 'layouts/Posts/WriteupQueue/WriteupQueue';
import { SubmissionSummary } from 'types/submission';
import { AutoSuggestChipInput, WriteupQueueModal } from 'molecules';

const PostMetadataForm: React.FC<PostMetadataFormProps> = ({
    categories,
    hasSubmission,
    handleTouchedField,
    handleInputChange,
}) => {
    const classes = useStyles();
    const [modalOpen, setModalOpen] = useState(false);

    const { values, errors, handleChange, setFieldTouched, setFieldValue, touched } =
        useFormikContext<PostEditorFormValues>();

    // State Tracking
    const [tagSearchValue, setTagSeachValue] = React.useState('');

    // Queries
    const { data } = useQuery(SEARCH_TAGS, {
        variables: { search: tagSearchValue },
    });
    const tagSuggestions = data && data.tags ? data.tags : [];

    /**
     * Handles adding a new chip to a chip input field
     */
    const handleAddChip = (chip: string) => {
        const newTags = [...values.tags, chip];

        setFieldValue('tags', newTags);
        setChipInputTouched('tags');
    };

    /**
     * Sets the chip input field name as touched for the formik state handler
     */
    const setChipInputTouched = (fieldName: string) => {
        setFieldTouched(fieldName, true);
    };

    /**
     * Handles deleting a chip from a chip input field
     */
    const handleDeleteChip = (deletedChip: string) => {
        const newTags = values.tags.filter((chip) => chip !== deletedChip);

        setFieldValue('tags', newTags);
    };

    const toggleModalOpen = () => setModalOpen(!modalOpen);

    const handleQueueItemSelection = (item: WriteupQueueItem) => {
        const submission: SubmissionSummary = {
            submissionId: item.submissionId,
            contentTitle: item.contentTitle,
            contentUrl: item.contentUrl,
            credits: item.credits,
            order: item.order,
            preferredAuthor: item.preferredAuthor,
            service: item.service,
            services: item.services,
        };
        toggleModalOpen();
        handleTouchedField('submission', submission);
        setFieldValue('submission', submission);
    };

    return (
        <>
            <GridItem className={classes.textFieldContainer}>
                <Typography variant={'subtitle2'} className={classes.sectionHeader}>
                    Tags
                </Typography>
                <AutoSuggestChipInput
                    placeholder={'Post Tags'}
                    onTextInputChangeCb={setTagSeachValue as StateUpdateFunction}
                    id={'tags'}
                    chips={values.tags}
                    handleAddChip={(chip: string) => {
                        handleAddChip(chip);
                    }}
                    handleDeleteChip={handleDeleteChip}
                    label={'Post Tags'}
                    suggestionSrc={tagSuggestions}
                    error={Boolean(errors.tags)}
                    helperText={
                        errors.tags || "Press 'Enter' after each value to add a new entry"
                    }
                    onBlur={() => setChipInputTouched('tags')}
                    renderCopyContentBtn
                />
            </GridItem>

            <GridItem className={classes.textFieldContainer}>
                <Typography variant={'subtitle2'} className={classes.sectionHeader}>
                    Category
                </Typography>
                <TextField
                    id={'selectedCategory'}
                    name={'selectedCategory'}
                    select
                    variant={'outlined'}
                    value={values.selectedCategory}
                    onChange={(event) => {
                        handleInputChange(
                            'selectedCategory',
                            event,
                            handleChange,
                            setFieldTouched,
                            event.target.value,
                        );
                        handleTouchedField('selectedCategory', event.target.type);
                    }}
                    className={classes.dropdown}
                    SelectProps={{
                        variant: 'filled',
                        classes: {
                            icon: classes.dropdownarrow,
                        },
                    }}
                >
                    {categories.map(({ categoryId, name }, index) => (
                        <MenuItem key={index} value={categoryId}>
                            {name}
                        </MenuItem>
                    ))}
                </TextField>
            </GridItem>

            <GridItem className={classes.textFieldContainer}>
                <Typography variant={'subtitle2'} className={classes.sectionHeader}>
                    Post Type
                </Typography>
                <TextField
                    id="type"
                    select
                    value={values.type}
                    onChange={(event) => {
                        handleInputChange(
                            'type',
                            event,
                            handleChange,
                            setFieldTouched,
                            event.target.value,
                        );
                        handleTouchedField('type', event.target.type);
                    }}
                    error={touched.type && Boolean(errors.type)}
                    helperText={touched.type ? errors.type : ''}
                    margin="normal"
                    variant="outlined"
                    className={classes.dropdown}
                    SelectProps={{
                        variant: 'filled',
                        classes: {
                            icon: classes.dropdownarrow,
                        },
                    }}
                >
                    {Object.values(POST_TYPES).map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </GridItem>

            <GridItem className={classes.textFieldContainer}>
                <Button disabled={hasSubmission} onClick={toggleModalOpen} variant="text">
                    Link Post to Submission
                </Button>

                {!hasSubmission && values.submission && (
                    <Typography
                        className={classes.linkSubmissionTitle}
                        gutterBottom
                        variant={'body2'}
                        color={'textSecondary'}
                    >
                        Title: {values.submission.contentTitle}
                    </Typography>
                )}
            </GridItem>
            <WriteupQueueModal
                open={modalOpen}
                handleClose={toggleModalOpen}
                handleRowClick={handleQueueItemSelection}
            />
        </>
    );
};
interface PostMetadataFormProps {
    categories: PostCategories;
    hasSubmission: boolean;
    handleTouchedField: (field: string, value: any) => void;
    handleInputChange: (
        fieldName: string,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        handleChange: Function,
        setTouched: Function,
        imperativeValue?: any,
    ) => void;
}

export default PostMetadataForm;
