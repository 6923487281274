import { createStyles, makeStyles } from '@material-ui/core';
import { reduceOpacity } from '@elevatormedia/duffel-bag';

const styles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        quickViewChips: {
            paddingBottom: theme.spacing(2),
        },
        editButton: {
            color: theme.palette.primary.main,
            border: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: reduceOpacity(theme.palette.primary.main, 0.08),
            },
        },
        approveAction: {
            color: theme.palette.success.main,
            border: theme.palette.success.main,
            '&:hover': {
                backgroundColor: reduceOpacity(theme.palette.success.main, 0.08),
            },
        },
        denyAction: {
            color: theme.palette.error.main,
            border: theme.palette.error.main,
            '&:hover': {
                backgroundColor: reduceOpacity(theme.palette.error.main, 0.08),
            },
        },
        desktopSeeRelatedPostContainer: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        mobileSeeRelatedPostContainer: {
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        inlineButtonIcon: {
            marginRight: theme.spacing(0.5),
        },
        submissionTypeChipContainer: {
            paddingLeft: theme.spacing(2),
        },
        typeChip: {
            borderRadius: '3px',
            backgroundColor: theme.palette.background.default,
        },
        submissionActionsContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        contentDescription: {
            maxWidth: '45vw',
            [theme.breakpoints.down('md')]: {
                maxWidth: '60vw',
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
        },
        submitterInfoContainer: {
            width: '100%',
        },
        submitterInfo: {
            paddingBottom: theme.spacing(2),
        },
        submissionTitle: {
            fontSize: theme.typography.pxToRem(18),
            paddingTop: theme.spacing(2),
            fontWeight: 400,
            display: 'flex',
            flexDirection: 'row',
        },
        marginContainer: {
            margin: theme.spacing(2, 0),
        },
    }),
);

export default styles;
