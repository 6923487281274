import React from 'react';
import useStyles from './styles';
import { GridContainer, GridItem, SocialMediaType } from '@elevatormedia/duffel-bag';
import { Typography } from '@material-ui/core';
import {
    Account,
    Email,
    Instagram,
    Soundcloud,
    Twitter,
    Youtube,
    Facebook,
    Spotify,
} from 'mdi-material-ui';
import { Credit } from 'types/credits';

const CreditInfo = (props: CreditInfoProps) => {
    const classes = useStyles();

    const { credit } = props;

    const getStartAdornment = (social: SocialMediaType) => {
        switch (social) {
            case 'twitter':
                return <Twitter className={classes.fontColor} />;
            case 'instagram':
                return <Instagram className={classes.fontColor} />;
            case 'soundcloud':
                return <Soundcloud className={classes.fontColor} />;
            case 'facebook':
                return <Facebook className={classes.fontColor} />;
            case 'spotify':
                return <Spotify className={classes.fontColor} />;
            case 'youtube':
                return <Youtube className={classes.fontColor} />;

            default:
                return null;
        }
    };

    return (
        <div style={{ width: '100%' }}>
            <GridContainer
                direction={'row'}
                spacing={1}
                alignItems={'flex-start'}
                className={classes.text}
            >
                <GridItem>{<Account className={classes.fontColor} />}</GridItem>
                <GridItem className={classes.infoLink}>
                    <Typography variant={'body2'} className={classes.fontColor}>
                        {credit.name}
                    </Typography>
                </GridItem>
            </GridContainer>
            {credit.email && (
                <GridContainer
                    direction={'row'}
                    spacing={1}
                    alignItems={'flex-start'}
                    className={classes.text}
                >
                    <GridItem>{<Email className={classes.fontColor} />}</GridItem>
                    <GridItem className={classes.infoLink}>
                        <Typography variant={'body2'} className={classes.fontColor}>
                            {credit.email}
                        </Typography>
                    </GridItem>
                </GridContainer>
            )}
            {credit.socialMedia.map((item, index) => {
                const startAdornment = getStartAdornment(item.type);
                return (
                    <GridContainer
                        key={item.link}
                        direction={'row'}
                        spacing={1}
                        alignItems={'flex-start'}
                        className={classes.text}
                    >
                        <GridItem>{startAdornment}</GridItem>
                        <GridItem className={classes.infoLink}>
                            <Typography variant={'body2'} className={classes.fontColor}>
                                {item.link}
                            </Typography>
                        </GridItem>
                    </GridContainer>
                );
            })}
        </div>
    );
};

export interface CreditInfoProps {
    credit: Credit;
}

export default CreditInfo;
