import React, { MouseEventHandler } from 'react';
import { Link as MuiLink, Paper, Tooltip, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import clsx from 'clsx';
import { Post, PostAuthor } from 'types/post';
import EnhancedCardHeader from 'atoms/EnhancedCardHeader';
import { REDIRECTS } from '../../config/Nav';
import { formatDate } from '@elevatormedia/duffel-bag';
import ScheduledPostInfo from '../../molecules/ScheduledPostInfo/ScheduledPostInfo';
import CachedIcon from '@material-ui/icons/Cached';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import useStyles from './styles';

const PostDetailsCard = (props: PostDetailsCardProps) => {
    const {
        toggleDateTimePickerDialog,
        showPostDetails,
        post,
        authors,
        setEditAuthorsOpen,
        isNewPost,
        loading,
        isSubmitting,
    } = props;

    /**
     * Post may be null (meaning it is either loading, or this is a new post)
     * Thus we allow these values to be undefined until post can populate them if
     * it becomes defined.
     */
    let createdAt: string;
    let slug: string;
    let publishedAt: string;
    let modifiedAt: string;
    let updatedAt: string;
    let views: number;

    if (!isNewPost && !loading) {
        ({ createdAt, slug, publishedAt, modifiedAt, updatedAt, views } = post as Post);
    }

    const classes = useStyles();

    const didMount = React.useRef(false);
    const [addFadeOut, setAddFadeOut] = React.useState(false);

    React.useEffect(() => {
        /**
         * Credit to: https://stackoverflow.com/a/53180013
         * Allows storing ref to the current mounted component
         * so that we may "skip" the initial run of useEffect, but
         * not any subsequent runs
         */
        if (didMount.current) {
            if (!isNewPost) {
                setAddFadeOut(true);
                setTimeout(() => {
                    setAddFadeOut(false);
                }, 1500);
            }
        } else {
            didMount.current = true;
        }
    }, [updatedAt, isNewPost]);

    // ref: https://blog.logrocket.com/implementing-copy-clipboard-react-clipboard-api/
    const copyToClipboard = (value: string) => {
        if ('clipboard' in navigator) return navigator.clipboard.writeText(value);
        else return document.execCommand('copy', true, value);
    };

    const renderStatusContent = () => {
        if (post.status === 'published') {
            return (
                <React.Fragment>
                    <Tooltip title={'See live post'}>
                        <MuiLink
                            href={REDIRECTS.website.uri + slug}
                            target={'_blank'}
                            rel="noopener noreferrer"
                        >
                            {post.status}
                        </MuiLink>
                    </Tooltip>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Tooltip title={'See preview post'}>
                        <MuiLink
                            href={REDIRECTS.preview.uri + slug}
                            target={'_blank'}
                            rel="noopener noreferrer"
                        >
                            {post.status}
                        </MuiLink>
                    </Tooltip>
                </React.Fragment>
            );
        }
    };

    const renderPostDetailRow = (key: string, value: any, capitalize = true) => {
        return (
            <div className={classes.detailRowContainer}>
                <Typography
                    variant={'body2'}
                    className={capitalize ? classes.titleCase : ''}
                >
                    {key} — {value}
                </Typography>
            </div>
        );
    };

    const renderAuthors = () => {
        if (!authors) return null;

        return (
            <div className={clsx(classes.detailRowContainer, classes.authorsContainer)}>
                <Typography variant={'body2'} color={'textSecondary'}>
                    {authors.map((author: PostAuthor, index: number) => {
                        let name = author.preferredUsername;

                        if (index > 0) name = `, ${name}`;

                        return name;
                    })}
                </Typography>
                <Edit
                    color={'primary'}
                    className={classes.editAuthorsIconButton}
                    onClick={() => setEditAuthorsOpen(true)}
                />
            </div>
        );
    };

    const SectionHeader = ({ children }: { children: any }) => {
        return (
            <Typography variant={'subtitle2'} gutterBottom>
                {children}
            </Typography>
        );
    };

    const interpolateAutoSaveFeedbackComponent = () => {
        if (isSubmitting) {
            return (
                <div className={classes.inLine}>
                    <Tooltip title={'Auto-saving document to the cloud'}>
                        <CachedIcon
                            className={clsx(classes.rotate, classes.autoSaveIcon)}
                        />
                    </Tooltip>
                    <div className={classes.savingStatus}>
                        <Typography variant={'body2'} color={'textSecondary'}>
                            Saving
                        </Typography>
                    </div>
                </div>
            );
        } else {
            if (isNewPost) {
                return null;
            } else {
                return (
                    <div className={classes.inLine}>
                        <Tooltip
                            title={'This post has been successfully saved to the cloud'}
                        >
                            <CloudDoneIcon className={classes.autoSaveIcon} />
                        </Tooltip>
                        <div className={classes.savingStatus}>
                            <Typography variant={'body2'} color={'textSecondary'}>
                                Saved
                            </Typography>
                        </div>
                    </div>
                );
            }
        }
    };

    const viewsDefined = views !== null && views > 0;

    const renderContent = () => {
        return (
            <React.Fragment>
                <EnhancedCardHeader title={'Post Details'} color={'textPrimary'}>
                    {interpolateAutoSaveFeedbackComponent()}
                </EnhancedCardHeader>

                <div className={classes.cardContent}>
                    {post.status === 'scheduled' && (
                        <ScheduledPostInfo
                            scheduledDate={formatDate(parseInt(publishedAt))}
                            onEditScheduledDatePress={toggleDateTimePickerDialog}
                        />
                    )}
                    <SectionHeader>Post</SectionHeader>

                    <div className={classes.inLine}>
                        <div
                            className={clsx({
                                [classes.fadingUpdateContainer]: addFadeOut,
                            })}
                        >
                            {renderPostDetailRow(
                                'UpdatedAt',
                                updatedAt
                                    ? formatDate(parseInt(updatedAt))
                                    : 'Not Published',
                            )}
                        </div>
                    </div>

                    {renderPostDetailRow('Status', renderStatusContent())}

                    {post.status === 'published' &&
                        !!post.shortLink &&
                        renderPostDetailRow(
                            'Short URL',
                            <Tooltip title={'Copy'}>
                                <MuiLink
                                    className={classes.shortLinkHover}
                                    onClick={() => copyToClipboard(post.shortLink)}
                                >
                                    {post.shortLink.replace(/^https?:\/\//, '')}
                                </MuiLink>
                            </Tooltip>,
                            false,
                        )}

                    {/**
                     * If no modified date is available, it should be set equal to the updated date
                     */}
                    {renderPostDetailRow(
                        'ModifiedAt',
                        modifiedAt ? formatDate(parseInt(modifiedAt)) : 'Not Published',
                    )}
                    {renderPostDetailRow(
                        'CreatedAt',
                        createdAt ? formatDate(parseInt(createdAt)) : 'Not Saved',
                    )}
                </div>

                <div className={classes.cardContent}>
                    <SectionHeader>Author(s)</SectionHeader>
                    {renderAuthors()}
                </div>

                <div className={classes.cardContent}>
                    <React.Fragment>
                        <Tooltip title={'See preview post'}>
                            <MuiLink
                                href={REDIRECTS.preview.uri + slug}
                                target={'_blank'}
                                rel="noopener noreferrer"
                            >
                                Preview Post
                            </MuiLink>
                        </Tooltip>
                    </React.Fragment>
                </div>

                {viewsDefined && (
                    <div className={classes.cardContent}>
                        <SectionHeader>Stats</SectionHeader>
                        {renderPostDetailRow(
                            'Views last 30 days',
                            views.toLocaleString(),
                        )}
                    </div>
                )}
            </React.Fragment>
        );
    };

    const renderPaperCard = () => {
        return (
            <div className={classes.root}>
                <Paper>{renderContent()}</Paper>
            </div>
        );
    };

    return showPostDetails ? renderPaperCard() : null;
};

export type PostDetailsCardProps = {
    showPostDetails: boolean;
    post: Post;
    toggleDateTimePickerDialog: MouseEventHandler;
    isSubmitting: boolean;
    setEditAuthorsOpen: Function;
    authors: Array<PostAuthor>;
    isNewPost: boolean;
    loading: boolean;
};

export default PostDetailsCard;
