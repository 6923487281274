import React, { MouseEvent } from 'react';
import { ListItemAvatar, ListItemText, MenuItem } from '@material-ui/core';
import { Plus } from 'mdi-material-ui';

const AddNewMenuItem = (props: AddNewMenuItemPropTypes) => {
    const { isAddCreditOption, isHighlighted, handleManualAddNew } = props;

    return (
        <>
            {isAddCreditOption ? (
                <MenuItem
                    selected={isHighlighted}
                    // onClick will open the `CreatorInfo`
                    onClick={() => {
                        handleManualAddNew();
                    }}
                    // prevent the click causing the input to be blurred
                    onMouseDown={(e: MouseEvent) => e.preventDefault()}
                    component={'div'}
                >
                    <ListItemAvatar>
                        <Plus />
                    </ListItemAvatar>
                    <ListItemText> Add New </ListItemText>
                </MenuItem>
            ) : null}
        </>
    );
};

export interface AddNewMenuItemPropTypes {
    isAddCreditOption?: boolean;
    isHighlighted: boolean;
    handleManualAddNew: Function;
}

export default AddNewMenuItem;
