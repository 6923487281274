import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backgroundContainer: {
            height: '24px',
            width: '24px',
            borderRadius: '3px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.custom.orange.huntingVest,
        },
        chipIcon: {
            fontSize: theme.typography.pxToRem(14),

            color: 'white',
        },
    }),
);

export default useStyles;
