import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        drawer: {
            minHeight: '22vh',
        },

        menuIcon: {
            color: 'inherit',
        },

        listItem: {
            padding: theme.spacing(2, 1.5),
            cursor: 'pointer',
            fontWeight: 'bold',
            textDecoration: 'none',
            color: theme.palette.text.secondary,
            '&:hover': {
                backgroundColor: theme.palette.custom.grey.g200,
                color: theme.palette.primary.main,
            },
        },
        listItemText: {
            fontWeight: 'bold',
            textDecoration: 'none',
        },
    });

export default makeStyles(styles);
