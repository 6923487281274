import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';

const ConfirmationDialogue: React.FC<ConfirmationDialogueProps> = (props) => {
    const {
        confirmationDialogueOpen,
        title,
        children,
        confirmButtonText,
        onConfirm,
        closeConfirmationDialogue,
        classes,
    } = props;

    const handleConfirmAction = () => {
        onConfirm();
        closeConfirmationDialogue();
    };

    return (
        <Dialog
            open={confirmationDialogueOpen}
            keepMounted
            onClose={closeConfirmationDialogue}
            aria-labelledby={`alert-dialog-slide-${title}`}
            aria-describedby={`alert-dialog-slide-description-${title}`}
            classes={classes}
        >
            <DialogTitle id={`alert-dialog-slide-${title}`}>{title}</DialogTitle>
            <DialogContent id={`alert-dialog-slide-description-${title}`}>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirmAction} color="primary">
                    {confirmButtonText}
                </Button>
                <Button onClick={closeConfirmationDialogue} color="default">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

interface ConfirmationDialogueProps {
    classes?: any;
    confirmationDialogueOpen: boolean;
    title: string;
    children: React.ReactNode;
    confirmButtonText: string;
    onConfirm: () => void;
    closeConfirmationDialogue: () => void;
}

export default ConfirmationDialogue;
