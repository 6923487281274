import gql from 'graphql-tag';

const ALL_POSTS = gql`
    query posts(
        $where: PostWhereInput
        $search: PostSearchInput
        $limit: Int
        $skip: Int
        $sort: SortInput
    ) {
        posts(where: $where, search: $search, limit: $limit, skip: $skip, sort: $sort) {
            status
            postId
            type
            title
            image {
                mediaId
                sourceUrl
                sourceSet
            }
            authors {
                userId
                preferredUsername
            }
            categories {
                categoryId
                name
            }
            featured {
                position
            }
            slug
            createdAt
            updatedAt
            publishedAt
            modifiedAt
            count
        }
    }
`;

const POST = gql`
    query post($postId: ID, $slug: String, $submissionId: ID) {
        post(postId: $postId, slug: $slug, submissionId: $submissionId) {
            postId
            title
            slug
            shortLink
            status
            type
            description
            content
            credits {
                creditId
                creditType {
                    typeName
                }
                name
                user {
                    userId
                    preferredUsername
                    avatar {
                        sourceUrl
                        sourceSet
                    }
                }
                socialMedia {
                    type
                    link
                }
            }
            image {
                sourceUrl
                sourceSet
                metadata {
                    filename
                    crop
                }
            }
            featured {
                position
            }
            authors {
                userId
                preferredUsername
                avatar {
                    sourceUrl
                    sourceSet
                }
            }
            categories {
                categoryId
                name
            }
            tags {
                name
                displayName
            }
            createdAt
            updatedAt
            publishedAt
            modifiedAt
            views
        }
    }
`;

const POST_AND_CATEGORIES = gql`
    query post($postId: ID, $slug: String) {
        post(postId: $postId, slug: $slug) {
            postId
            title
            slug
            shortLink
            status
            type
            description
            content
            credits {
                creditId
                creditType {
                    typeName
                }
                name
                user {
                    userId
                    preferredUsername
                    avatar {
                        sourceUrl
                        sourceSet
                    }
                }
                socialMedia {
                    type
                    link
                }
            }
            image {
                path
                sourceUrl
                sourceSet
                metadata {
                    filename
                    crop
                    mimetype
                }
            }
            featured {
                position
            }
            authors {
                userId
                preferredUsername
                avatar {
                    sourceUrl
                    sourceSet
                }
            }
            categories {
                categoryId
                name
            }
            tags {
                name
                displayName
            }
            submission {
                submissionId
                service {
                    serviceId
                    name
                }
                services {
                    serviceId
                    name
                }
                credits {
                    creditId
                    creditType {
                        typeName
                    }
                    name
                    user {
                        userId
                        preferredUsername
                    }
                    socialMedia {
                        type
                        link
                    }
                }
                contentTitle
                contentUrl
                order {
                    user {
                        preferredUsername
                        firstName
                        lastName
                    }
                }
                preferredAuthorId
                preferredAuthor {
                    userId
                    username
                    preferredUsername
                    displayName
                    firstName
                    lastName
                }
            }
            createdAt
            updatedAt
            publishedAt
            modifiedAt
            views
        }
        categories {
            categoryId
            name
            description
        }
    }
`;

const FEATURED_POSTS = gql`
    query featuredPosts {
        featuredPosts {
            _0 {
                postId
                title
                slug
                image {
                    sourceUrl
                    sourceSet
                }
                type
                modifiedAt
                publishedAt
                authors {
                    displayName
                    preferredUsername
                }
                categories {
                    name
                    slug
                }
            }
            _1 {
                postId
                title
                slug
                image {
                    sourceUrl
                    sourceSet
                }
                type
                modifiedAt
                publishedAt
                authors {
                    displayName
                    preferredUsername
                }
                categories {
                    name
                    slug
                }
            }
            _2 {
                postId
                title
                slug
                image {
                    sourceUrl
                    sourceSet
                }
                type
                modifiedAt
                publishedAt
                authors {
                    displayName
                    preferredUsername
                }
                categories {
                    name
                    slug
                }
            }
            _3 {
                postId
                title
                slug
                image {
                    sourceUrl
                    sourceSet
                }
                type
                modifiedAt
                publishedAt
                authors {
                    displayName
                    preferredUsername
                }
                categories {
                    name
                    slug
                }
            }
            _4 {
                postId
                title
                slug
                image {
                    sourceUrl
                    sourceSet
                }
                type
                modifiedAt
                publishedAt
                authors {
                    displayName
                    preferredUsername
                }
                categories {
                    name
                    slug
                }
            }
        }
    }
`;

const POST_TOTALS_BY_STATUS = gql`
    query {
        postTotalsByStatus
    }
`;

export { ALL_POSTS, POST, POST_AND_CATEGORIES, FEATURED_POSTS, POST_TOTALS_BY_STATUS };
