import {
    Done as ApproveIcon,
    Edit as DraftIcon,
    DoneAll as CompletedIcon,
    Close as CancelledIcon,
    HowToVote as ReviewIcon,
    HelpOutline as UnknownStatusIcon,
} from '@material-ui/icons';
import {
    FileFind as ReviseIcon,
    MinusCircle as HoldIcon,
    CalendarClock as ScheduledIcon,
    Newspaper as RunningIcon,
    AccountClock as PendingIcon,
} from 'mdi-material-ui';
import { CAMPAIGN_STATUSES_MAP, CampaignStatus } from '@elevatormedia/duffel-bag';

const getStatusIcon = (status: CampaignStatus, props: any) => {
    switch (status) {
        case CAMPAIGN_STATUSES_MAP.REVIEW:
            return <ReviewIcon {...props} />;
        case CAMPAIGN_STATUSES_MAP.APPROVE:
            return <ApproveIcon {...props} />;
        case CAMPAIGN_STATUSES_MAP.DRAFT:
            return <DraftIcon {...props} />;
        case CAMPAIGN_STATUSES_MAP.HOLD:
            return <HoldIcon {...props} />;
        case CAMPAIGN_STATUSES_MAP.PENDING:
            return <PendingIcon {...props} />;
        case CAMPAIGN_STATUSES_MAP.REVISE:
            return <ReviseIcon {...props} />;
        case CAMPAIGN_STATUSES_MAP.SCHEDULED:
            return <ScheduledIcon {...props} />;
        case CAMPAIGN_STATUSES_MAP.RUNNING:
            return <RunningIcon {...props} />;
        case CAMPAIGN_STATUSES_MAP.COMPLETED:
            return <CompletedIcon {...props} />;
        case CAMPAIGN_STATUSES_MAP.CANCELLED:
            return <CancelledIcon {...props} />;
        default:
            return <UnknownStatusIcon {...props} />;
    }
};

export default getStatusIcon;
