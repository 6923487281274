import React, { useMemo } from 'react';
import CheckboxGroup from '../CheckboxGroup';
import useStyles from './styles';
import { CheckboxGroupProps } from 'molecules/CheckboxGroup/CheckboxGroup';
import { Permission } from '@elevatormedia/duffel-bag';

const PermissionsReviewer: React.FC<PermissionsReviewerProps> = (props) => {
    const classes = useStyles();
    const { handlePermissionChange, readOnly = true, userPermissions = [] } = props;

    const splitArray = (collection: any[]) => {
        if (!collection.length) return {};

        const pivot = collection.length / 2;
        const firstHalf = collection.slice(0, pivot);
        const secondHalf = collection.slice(pivot);

        return {
            firstHalf,
            secondHalf,
        };
    };

    const { firstHalf, secondHalf } = useMemo(() => {
        // iterate through permissions and group them by category
        const permissionTypes = userPermissions.reduce((prev, permission) => {
            if (!prev[permission.type]) prev[permission.type] = [];

            prev[permission.type].push(permission);

            return prev;
        }, {} as Record<string, Permission[]>);

        const list = Object.keys(permissionTypes).map((type, index) => {
            const permissions = permissionTypes[type];

            return (
                <div key={index} className={classes.checkboxGroupContainer}>
                    <CheckboxGroup
                        readOnly={readOnly}
                        checkboxLabels={permissions}
                        handleChange={handlePermissionChange}
                        legendText={type}
                        primaryTextKey={'operationName'}
                        secondaryTextKey={'description'}
                        checkedKey={'access'}
                    />
                </div>
            );
        });

        return splitArray(list);
    }, [userPermissions, readOnly, handlePermissionChange, classes]);

    return (
        <div className={classes.permissionsContainer}>
            <div className={classes.columnContainer}>{firstHalf}</div>
            <div className={classes.columnContainer}>{secondHalf}</div>
        </div>
    );
};

interface PermissionsReviewerProps {
    handlePermissionChange: CheckboxGroupProps['handleChange'];
    readOnly?: boolean;
    userPermissions?: Permission[];
}

export default PermissionsReviewer;
