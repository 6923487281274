import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        thumbnailEmbed: {
            position: 'relative',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.custom.grey.g750,
        },
        noEmbedIcon: {
            fontSize: 100,
            paddingBottom: theme.spacing(2),
            color: theme.palette.custom.opaqueWhite,
        },
        noEmbedDisclaimer: {
            color: theme.palette.custom.opaqueWhite,
        },
        noImageContainer: {
            maxWidth: '50%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        fabContainer: {
            position: 'absolute',
        },
        container: {
            backgroundColor: theme.palette.background.default,
        },
        embedSmall: {
            height: '350px',
        },
        embedLarge: {
            [theme.breakpoints.up('md')]: {
                height: '500px',
            },
            [theme.breakpoints.up('lg')]: {
                height: '600px',
            },
            [theme.breakpoints.up('xl')]: {
                height: '700px',
            },
        },
        centered: {
            right: theme.spacing(2),
            bottom: '30px',
        },
    }),
);

export default useStyles;
