import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dataFieldValue: {
            fontSize: '0.90rem',
            fontStyle: 'normal',
        },
        dataFieldValueBolded: {
            fontWeight: 'bold',
        },
        dataFieldItalicized: {
            fontStyle: 'italic',
        },
        creditSocialMediaIndent: {
            marginLeft: theme.spacing(2),
        },
        socialMediaCreditField: {
            paddingBottom: theme.spacing(1),
        },
    }),
);

export default useStyles;
