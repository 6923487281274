export interface StaticComponentSizesConfig {
    mobile_appbar_height?: number;
    desktop_appbar_height?: number;
    notificationBoxHeight?: number;
    content_max_width?: number;
}

const staticComponentSizesConfig: StaticComponentSizesConfig = {
    mobile_appbar_height: 50,
    desktop_appbar_height: 80,
    notificationBoxHeight: 40,
    content_max_width: 1100,
};

export default staticComponentSizesConfig;
