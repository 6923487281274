import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        screenToolbar: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: theme.palette.custom.blueGrey,
        },
        borderBottom: {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
    }),
);

export default useStyles;
