export type EventType =
    | 'PAYMENT_RECEIVED'
    | 'SUBMISSION_APPROVAL'
    | 'SUBMISSION_APPROVED'
    | 'SUBMISSION_REJECTED'
    | 'SUBMISSION_FLAGGED'
    | 'USER_COMMENT'
    | 'POST_DRAFTED'
    | 'POST_PUBLISHED'
    | 'SUBMISSION_RESTARTED'
    | 'SUBMISSION_UPDATED';

const EVENT_TYPES: Record<EventType, EventType> = {
    PAYMENT_RECEIVED: 'PAYMENT_RECEIVED',
    SUBMISSION_APPROVAL: 'SUBMISSION_APPROVAL',
    SUBMISSION_APPROVED: 'SUBMISSION_APPROVED',
    SUBMISSION_REJECTED: 'SUBMISSION_REJECTED',
    SUBMISSION_FLAGGED: 'SUBMISSION_FLAGGED',
    USER_COMMENT: 'USER_COMMENT',
    POST_DRAFTED: 'POST_DRAFTED',
    POST_PUBLISHED: 'POST_PUBLISHED',
    SUBMISSION_RESTARTED: 'SUBMISSION_RESTARTED',
    SUBMISSION_UPDATED: 'SUBMISSION_UPDATED',
};

Object.freeze(EVENT_TYPES);

export { EVENT_TYPES };
