import React from 'react';
import { Typography } from '@material-ui/core';
import {
    Edit as DraftIcon,
    ArtTrack as PublishedIcon,
    VisibilityOff as PrivateIcon,
    HelpOutline as UnknownStatusIcon,
    Schedule as ScheduledStatusIcon,
} from '@material-ui/icons';
import { Lightbulb as PitchIcon, FileClockOutline as PendingIcon } from 'mdi-material-ui';
import useStyles from './styles';
import { PostStatusType, POST_STATUSES } from '@elevatormedia/duffel-bag';
import clsx from 'clsx';

/**
 * Convenience Component: Renders a special icon assigned to a Post status
 * NOTE This logic can be abstracted to a StatusIcon HOC
 */
const PostStatus: React.FC<PostStatusProps> = ({
    status,
    customText,
    iconOnly = false,
}) => {
    const classes = useStyles();

    const getStatusIcon = () => {
        const classType = clsx(classes.icon, !iconOnly && classes.withTextSibling);
        switch (status) {
            case POST_STATUSES.DRAFT:
                return <DraftIcon className={classType} />;
            case POST_STATUSES.PUBLISHED:
                return <PublishedIcon className={classType} />;
            case POST_STATUSES.PRIVATE:
                return <PrivateIcon className={classType} />;
            case POST_STATUSES.SCHEDULED:
                return <ScheduledStatusIcon className={classType} />;
            case POST_STATUSES.PITCH:
                return <PitchIcon className={classType} />;
            case POST_STATUSES.PENDING:
                return <PendingIcon className={classType} />;
            default:
                return <UnknownStatusIcon className={classType} />;
        }
    };

    return (
        <div className={classes.statusContainer}>
            {getStatusIcon()}
            {!iconOnly && (
                <Typography className={classes.statusText}>
                    {customText ? customText : status}
                </Typography>
            )}
        </div>
    );
};

interface PostStatusProps {
    status: PostStatusType;
    customText?: string;
    iconOnly?: boolean;
}

export default PostStatus;
