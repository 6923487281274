import React from 'react';
import PostEditorFormContext from './PostEditorFormContext';
import { convertToRaw } from 'draft-js';
import { Formik } from 'formik';
import { EditablePost } from 'types/post';
import {
    array,
    boolean,
    mixed,
    number,
    object as yupObject,
    string as yupString,
} from 'yup';
import PostEditorForm from './PostEditorForm';

const MAX_FILE_SIZE = 20971520; // ~ 20 Megabytes
export const SUPPORTED_IMAGE_FORMATS = ['image/jpeg', 'image/png'];

export const Index = () => {
    const { onSubmit, initialValues } = React.useContext(PostEditorFormContext);

    const validationSchema = yupObject({
        title: yupString().required('A post title is required to create a post'),
        // This field is 'description' field on db.
        description: yupString().required('An excerpt is required to create a post'),
        // This field is 'slug' field in db.
        slug: yupString().required('A permalink is required to create a post'),
        content: mixed().test(
            'RTENotEmpty',
            'Content is required to create a post',
            (value) => {
                // Here we would validate the Rich text editor content.
                const rawContentBlocks = convertToRaw(value.getCurrentContent());
                const onlyOneBlock = rawContentBlocks.blocks.length === 1;
                const hasEmptyTextBlock = rawContentBlocks.blocks[0].text === '';

                const isInvalid = onlyOneBlock && hasEmptyTextBlock;

                return !isInvalid;
            },
        ),
        image: mixed()
            .test(
                'SupportedImageTypes',
                'This file type is not supported',
                // A func returning true means it is a valid input
                (value) => {
                    // when image instances are empty, its fine as they aren't required all the time
                    if (!value) return true;
                    const imageType =
                        value instanceof File ? value.type : value.metadata.mimetype;
                    // For when images are local and we need to check for valid formats
                    return SUPPORTED_IMAGE_FORMATS.includes(imageType);
                },
            )
            .test(
                'MaxfileSize',
                'Image must be less than 20MB',
                // A func returning true means it is a valid input
                (value) => {
                    if (!value) return true;
                    if (!(value instanceof File)) return true;
                    return value.size < MAX_FILE_SIZE;
                },
            ),
        tags: array().of(yupString()).min(0),
        selectedCategory: yupString(),
        submission: yupObject().nullable(),
        featuredPos: number(),
        isAutoSaving: boolean(),
    });

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize
        >
            <PostEditorForm />
        </Formik>
    );
};

export interface PostEditorFormValues extends EditablePost {
    publishedAt?: Date;
}

export type PostEditorFormValuesKeys = keyof PostEditorFormValues;

export default Index;
