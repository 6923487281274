import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        largeCard: {
            width: '100%',
            maxWidth: '750px',
        },
        cardSectionContainer: {
            minHeight: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        dangerCard: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: theme.spacing(0, 2),
        },
        deleteAction: {
            color: theme.palette.error.main,
        },
        deactivateAction: {
            color: theme.palette.custom.darkYellow,
        },
        activateAction: {
            color: theme.palette.success.main,
        },
        banDialog: {
            minWidth: '600px',
            [theme.breakpoints.down('sm')]: {
                minWidth: '300px',
            },
        },
        masterPasswordReset: {
            whiteSpace: 'nowrap',
        },
    }),
);

export default useStyles;
