import { gql } from '@apollo/client';

export const MEDIA_LIST = gql`
    query media(
        $where: MediaWhereInput
        $limit: Int
        $skip: Int
        $sort: SortInput
        $search: String
    ) {
        media(where: $where, limit: $limit, skip: $skip, sort: $sort, search: $search) {
            mediaId
            path
            sourceUrl
            sourceSet
            metadata {
                filename
                bucket
                mimetype
            }
            count
        }
    }
`;
