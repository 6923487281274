import { reduceOpacity } from '@elevatormedia/duffel-bag';

import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textFieldGroupContainer: {
            paddingBottom: theme.spacing(5),
        },
        buttonContainer: {
            paddingTop: theme.spacing(1),
        },
        errorButton: {
            color: theme.palette.submissionStatuses.rejected,
            '&:hover': {
                backgroundColor: reduceOpacity(
                    theme.palette.submissionStatuses.rejected,
                    0.08,
                ),
            },
        },
        dateTimePicker: {
            margin: theme.spacing(1, 0, 1, 0),
        },
    }),
);

export default useStyles;
