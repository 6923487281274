import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        statusContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        icon: {
            color: theme.palette.text.secondary,
        },
        withTextSibling: {
            paddingRight: theme.spacing(1),
        },
        statusText: {
            fontSize: '0.9rem',
        },
    }),
);

export default useStyles;
