import React from 'react';
import useStyles from './styles';
import { StatsCardIndexProps } from '.';
import { GridContainer, GridItem } from '@elevatormedia/duffel-bag/dist/atoms/Grid';
import Typography from '@material-ui/core/Typography';

const StackedStatsCard = (props: StackedStatsCardProps) => {
    const classes = useStyles();

    const { title, labels, values, valueColors } = props;

    const renderItem = (label: string, value: any, index: number) => {
        return (
            <GridContainer
                key={`item-${index}`}
                direction={'row'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                spacing={1}
            >
                <GridItem>
                    <Typography className={classes.labelText} variant={'overline'}>
                        {label}
                    </Typography>
                </GridItem>
                <GridItem>
                    <Typography
                        className={classes.valueText}
                        variant={'h5'}
                        style={{ color: valueColors ? valueColors[index + 1] : 'black' }}
                    >
                        {value}
                    </Typography>
                </GridItem>
            </GridContainer>
        );
    };

    return (
        <GridContainer
            direction={'row'}
            spacing={1}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <GridItem>
                <div className={classes.titleText}>{title.toLocaleUpperCase()}</div>
                <Typography
                    className={classes.valueText}
                    variant={'h5'}
                    style={{ color: valueColors ? valueColors[0] : 'black' }}
                >
                    {values[0]}
                </Typography>
            </GridItem>
            <GridItem>
                <div className={classes.stackedContainer}>
                    {values.slice(1).map((value: any, index: number) => {
                        {
                            renderItem(labels[index], value, index);
                        }
                    })}
                </div>
            </GridItem>
        </GridContainer>
    );
};

export type StackedStatsCardProps = Omit<StatsCardIndexProps<'stacked'>, 'variant'>;

export default StackedStatsCard;
