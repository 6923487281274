import { Theme, makeStyles, createStyles } from '@material-ui/core';

export const styles = makeStyles((theme) =>
    createStyles({
        userChip: {
            margin: theme.spacing(0.5),
        },
        deletedChip: {
            border: `2px solid ${theme.palette.secondary.main}`,
        },
    }),
);

export default styles;
