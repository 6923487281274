import React from 'react';
import useStyles from './styles';

const ContentSpacer: React.FC = (props) => {
    const classes = useStyles();

    return <div className={classes.root}>{props.children}</div>;
};

export default ContentSpacer;
