import React from 'react';
import { GridContainer, GridItem } from '@elevatormedia/duffel-bag';
import { Typography, Card, CardContent, CardActionArea } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';
import Router from 'next/router';
import { DashboardMetricBase } from 'types/dashboard';

/**
 * Dashboard Metrics Card: A card layout component meant to display prominent business metrics. OnClick
 * functionality here is optional Please see propTypes for configuration JS Object.
 */
const DashboardMetricsCard: React.FC<DashboardMetricBase> = ({
    cardTitle,
    cardMetricText,
    highContrast = false,
    navigateTo,
    queryParams = {},
    endAdornment,
}) => {
    const classes = useStyles();

    // Interpolate card touch properties based off of prop values.
    const shouldDisableTouchProperties = !navigateTo || navigateTo.length < 1;

    const handleCardOnClick = () => {
        if (shouldDisableTouchProperties) {
            return;
        } else {
            Router.push({
                pathname: navigateTo,
                query: queryParams,
            });
        }
    };

    return (
        <Card
            className={clsx(classes.root, highContrast && classes.highContrastBackground)}
        >
            <CardActionArea
                onClick={handleCardOnClick}
                disabled={shouldDisableTouchProperties}
                className={classes.cardActionArea}
            >
                <CardContent>
                    <GridContainer justifyContent={'space-between'} alignItems={'center'}>
                        <GridItem>
                            <Typography
                                className={classes.title}
                                color={'textSecondary'}
                                gutterBottom
                                variant={'body2'}
                            >
                                {cardTitle.toUpperCase()}
                            </Typography>
                            <Typography variant={'h5'} className={classes.cardMetricText}>
                                {cardMetricText}
                            </Typography>
                        </GridItem>
                        <GridItem>{endAdornment}</GridItem>
                    </GridContainer>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default DashboardMetricsCard;
