import React from 'react';
import {
    Table,
    TableBody,
    TablePagination,
    Paper,
    Typography,
    CircularProgress,
} from '@material-ui/core';
import EnhancedToolbar from './EnhancedToolbar';
import EnhancedTableHeader, { EnhancedTableHeaderProps } from './EnhancedTableHeader';
import { Table as TableIcon } from 'mdi-material-ui';
import useStyles from './styles';

const EnhancedTable: React.FC<EnhancedTableProps> = (props) => {
    const classes = useStyles();
    const {
        // General Props for Table
        tableName,
        onSelectAllClick,
        onRequestSort,
        rowsPerPageOptions = [25, 50, 75, 100],
        orderBy,
        order,
        ignoreColumns = [],
        ignoreColumnsSort,
        disableSelectAll,
        loading,

        // Data related rendering props
        rows = [],
        rowCount = 0,
        renderRow,
        columnHeaders,
        toolbarComponent,
        page = 0,
        handleChangePage,
        rowsPerPage = 25,
        handleChangeRowsPerPage = () => {},
        numSelected,
    } = props;

    const onChangePage = (_e: any, newPage: number) => {
        handleChangePage(newPage);
    };

    const handleRowsPerPageChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        if (handleChangeRowsPerPage) {
            handleChangeRowsPerPage(event);
        }
    };

    const renderTableHeader = () => {
        return (
            <EnhancedTableHeader
                columnHeaders={columnHeaders}
                ignoreColumns={ignoreColumns}
                ignoreColumnsSort={ignoreColumnsSort}
                numSelected={numSelected}
                orderBy={orderBy}
                order={order}
                onSelectAllClick={onSelectAllClick}
                onRequestSort={onRequestSort}
                rowsPerPage={rowsPerPage}
                disableSelectAll={disableSelectAll}
            />
        );
    };

    /**
     * Returns the index of a row relative to the master collection,
     * To calculate the relative index of a row in a master collection, we use the formula:
     *
     * Ri = (Rows Per Page x Current_Page) + Local Index
     *
     * where local index is the index of the row relative to its local collection (its page collection)
     */
    const getRelativeIndex = (index: number) => {
        return rowsPerPage * page + index;
    };

    const renderTableContent = () => {
        if (loading) {
            return (
                <div className={classes.nonContentStateContainer}>
                    <CircularProgress />
                </div>
            );
        } else if (rows.length < 1) {
            return (
                <div className={classes.nonContentStateContainer}>
                    <TableIcon className={classes.emptyStateIcon} />
                    <Typography
                        variant={'h5'}
                        color={'textSecondary'}
                        className={classes.emptyStateText}
                    >
                        No rows returned
                    </Typography>
                </div>
            );
        } else {
            return (
                <>
                    <EnhancedToolbar
                        toolbarComponent={toolbarComponent}
                        numSelected={numSelected}
                        tableTitle={tableName}
                    />

                    <div className={classes.tableWrapper}>
                        <Table className={classes.table} aria-labelledby={tableName}>
                            {renderTableHeader()}
                            <TableBody>
                                {rows
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage,
                                    )
                                    .map((row, index) => {
                                        return renderRow(row, getRelativeIndex(index));
                                    })}
                            </TableBody>
                        </Table>
                    </div>
                </>
            );
        }
    };

    return (
        <>
            <Paper className={classes.root}>{renderTableContent()}</Paper>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions || [25, 50, 75, 100]}
                component={'div'}
                count={rowCount || 0}
                rowsPerPage={rowsPerPage || 25}
                page={page || 0}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onPageChange={onChangePage}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </>
    );
};

interface EnhancedTableProps<T = any> extends EnhancedTableHeaderProps {
    tableName: string;
    rowsPerPageOptions?: number[];

    // Data related rendering props
    rows: T[];
    renderRow: (row: T, index: number) => void;
    toolbarComponent?: React.ReactNode;
    loading: boolean;
    page: number;
    handleChangePage: (page: number) => void;
    rowCount: number;
    handleChangeRowsPerPage?: React.ChangeEventHandler<
        HTMLTextAreaElement | HTMLInputElement
    >;
}

EnhancedTable.defaultProps = {
    ignoreColumns: [],
    rowsPerPageOptions: [25, 50, 75, 100],
    loading: true,
};

export default EnhancedTable;
