import React from 'react';
import useStyles from './styles';
import { PostEditorFormValues } from '..';
import PostEditorFormContext from '../PostEditorFormContext';
import { SplitButton } from 'atoms';
import { ConfirmationDialogue } from 'molecules';
import { PostStatus } from 'types/post';
import { GridContainer, GridItem } from '@elevatormedia/duffel-bag/dist/atoms/Grid';
import { useAuth } from '@elevatormedia/duffel-bag/dist/hooks/useAuth';
import { Button, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { FormikErrors } from 'formik/dist/types';

const PostActions = (props: PostActionsProps) => {
    const classes = useStyles();

    const {
        values,
        submitForm,
        isSubmitting,
        setValues,
        handleCancelScheduledPostClick,
        handleSubmitClick,
        errors,
    } = props;

    const {
        postId,
        isNewPost,
        initialPostStatus,

        isPublishingPost,

        handleMoveToTrash,
        toggleDateTimePickerDialog,
    } = React.useContext(PostEditorFormContext);

    const [confirmationDialogueOpen, setConfirmationDialogueOpen] = React.useState(false);

    // Permissions
    const { permissionsMap } = useAuth();
    const canPublishPosts = permissionsMap.posts.publishPost;

    return (
        <div className={clsx(classes.cardContent, classes.spaceContent)}>
            {!isNewPost && handleMoveToTrash && permissionsMap.posts.deletePost && (
                <GridContainer
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                    spacing={1}
                >
                    <GridItem>
                        <ConfirmationDialogue
                            confirmationDialogueOpen={confirmationDialogueOpen}
                            closeConfirmationDialogue={() =>
                                setConfirmationDialogueOpen(false)
                            }
                            title={'Confirm Delete'}
                            confirmButtonText={'Confirm'}
                            onConfirm={handleMoveToTrash}
                            classes={{
                                paper: classes.dialogue,
                            }}
                        >
                            Are you sure you want to delete this post?
                        </ConfirmationDialogue>
                    </GridItem>
                    <GridItem className={classes.deleteButtonContainer}>
                        <Button
                            onClick={() => setConfirmationDialogueOpen(true)}
                            disabled={isSubmitting}
                            color={'secondary'}
                            variant={'contained'}
                            className={classes.deleteButton}
                        >
                            Trash
                        </Button>
                    </GridItem>
                </GridContainer>
            )}
            {initialPostStatus === PostStatus.Published && (
                <GridContainer
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                    className={classes.gridContainer}
                >
                    <GridItem>
                        <Button
                            className={classes.button}
                            onClick={async () => {
                                await setValues({
                                    ...values,
                                    status: PostStatus.Private,
                                });
                                submitForm();
                            }}
                            disabled={isSubmitting}
                            color={'default'}
                            variant={'contained'}
                        >
                            Make Private
                        </Button>
                    </GridItem>
                </GridContainer>
            )}
            {initialPostStatus !== PostStatus.Published && !isSubmitting && (
                <GridContainer
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                    className={classes.gridContainer}
                >
                    <GridItem>
                        <Button
                            className={
                                values.status === 'pitch'
                                    ? classes.button
                                    : classes.buttonMargin
                            }
                            onClick={() => submitForm()}
                            disabled={isSubmitting}
                            color={'default'}
                            variant={'contained'}
                        >
                            {values.status === 'pitch' ? 'Save Pitch' : 'Save'}
                        </Button>
                    </GridItem>
                </GridContainer>
            )}
            {initialPostStatus !== PostStatus.Published && isSubmitting && (
                <GridContainer
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                    className={classes.gridContainer}
                >
                    <GridItem className={classes.savingContainer}>
                        <Button
                            onClick={() => {}}
                            disabled={true}
                            color={'primary'}
                            variant={'text'}
                            className={classes.button}
                        >
                            Saving...
                            <CircularProgress
                                size={15}
                                color={'primary'}
                                className={classes.saveProgress}
                            />
                        </Button>
                    </GridItem>
                </GridContainer>
            )}
            {values.status === 'draft' && !canPublishPosts && (
                <GridItem>
                    <Button
                        className={clsx(classes.button, classes.saveSubmitButton)}
                        onClick={async () => {
                            await setValues({
                                ...values,
                                status: PostStatus.Pending,
                            });
                            submitForm();
                        }}
                        disabled={isSubmitting}
                        color={'primary'}
                        variant={'contained'}
                    >
                        {isSubmitting ? 'Submitting' : 'Save & Submit'}
                    </Button>
                </GridItem>
            )}
            {values.status === 'pitch' && postId && canPublishPosts && (
                <GridItem className={classes.stretchContainer}>
                    <Button
                        className={classes.button}
                        onClick={async () => {
                            await setValues({
                                ...values,
                                status: PostStatus.Draft,
                            });
                            submitForm();
                        }}
                        disabled={isSubmitting}
                        color={'primary'}
                        variant={'contained'}
                    >
                        {isSubmitting ? 'Saving' : 'Approve Pitch'}
                    </Button>
                </GridItem>
            )}
            {values.status === 'scheduled' && canPublishPosts && (
                <GridItem className={classes.stretchContainer}>
                    <Button
                        onClick={handleCancelScheduledPostClick}
                        disabled={isSubmitting}
                        className={classes.errorButton}
                        variant={'text'}
                    >
                        Cancel Scheduled Post
                    </Button>
                </GridItem>
            )}
            {['draft', 'pending', 'published'].includes(initialPostStatus) &&
                canPublishPosts && (
                    <GridItem className={clsx(classes.stretchContainer, classes.button)}>
                        <SplitButton
                            options={[
                                {
                                    text: isPublishingPost
                                        ? 'Publishing'
                                        : initialPostStatus === 'published'
                                        ? 'Re-publish'
                                        : 'Publish',
                                    onClick: () => {
                                        handleSubmitClick(async () => {
                                            // If we haven't already updated status, update
                                            if (values.status !== PostStatus.Published) {
                                                await setValues({
                                                    ...values,
                                                    status: PostStatus.Published,
                                                });
                                            }
                                            submitForm();
                                        });
                                    },
                                },
                                ...(initialPostStatus !== 'published'
                                    ? [
                                          {
                                              text: isSubmitting
                                                  ? 'Scheduling'
                                                  : 'Schedule',
                                              onClick: () =>
                                                  handleSubmitClick(
                                                      toggleDateTimePickerDialog,
                                                  ),
                                          },
                                      ]
                                    : []),
                            ]}
                            disabled={isSubmitting || Object.keys(errors).length > 0}
                            loading={isSubmitting}
                        />
                    </GridItem>
                )}
        </div>
    );
};

export interface PostActionsProps {
    values: PostEditorFormValues;
    submitForm: () => void;
    isSubmitting: boolean;
    setValues: any;
    handleCancelScheduledPostClick: () => void;
    handleSubmitClick: (onPublishableCb: Function) => void;
    errors: FormikErrors<PostEditorFormValues>;
}

export default PostActions;
