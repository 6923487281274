import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContentContainer: {
            margin: theme.spacing(2),
        },
        cardSectionContainer: {
            minHeight: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    }),
);

export default useStyles;
