import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';
import zxcvbn from 'zxcvbn';

const PasswordStrengthGauge: React.FC<PasswordStrengthGaugeProps> = (props) => {
    const classes = useStyles();
    const { password, onStrengthChange } = props;

    // Mapping of password levels to human-readable color and text values:
    const passwordStrengthProps = {
        default: {
            description: 'No Input',
            trackColor: classes.noInput,
            trackWidth: '0',
        },
        0: {
            description: 'Very Weak',
            trackColor: classes.veryWeak,
            trackWidth: '20',
        },
        1: {
            description: 'Weak',
            trackColor: classes.weak,
            trackWidth: '40',
        },
        2: {
            description: 'Average',
            trackColor: classes.average,
            trackWidth: '60',
        },
        3: {
            description: 'Good',
            trackColor: classes.good,
            trackWidth: '80',
        },
        4: {
            description: 'Excellent',
            trackColor: classes.excellent,
            trackWidth: '100',
        },
    };

    function InterpolatePasswordStrength() {
        if (password.length < 1) {
            return 'default' as const;
        }
        const score = zxcvbn(password).score;
        if (onStrengthChange) {
            onStrengthChange(score);
        }
        return score;
    }

    const passwordStrengthResult = InterpolatePasswordStrength();

    return (
        <div className={classes.root}>
            <div
                className={clsx(
                    classes.linearProgressContainer,
                    passwordStrengthProps[passwordStrengthResult].trackColor,
                )}
            >
                {/*Track*/}
                <div className={classes.track}>
                    {/*Indicator*/}
                    <div
                        className={clsx(
                            classes.indicator,
                            passwordStrengthProps[passwordStrengthResult].trackColor,
                            classes[
                                passwordStrengthProps[passwordStrengthResult].trackWidth
                            ],
                        )}
                    />
                </div>
            </div>
            <Typography component="p" variant="caption">
                {passwordStrengthProps[passwordStrengthResult].description}
            </Typography>
        </div>
    );
};

interface PasswordStrengthGaugeProps {
    password: string;
    onStrengthChange: (strength: number) => void;
}

export default React.memo(PasswordStrengthGauge, (prev, next) => {
    if (prev.password !== next.password) {
        return false;
    }

    return true;
});
