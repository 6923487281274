import React from 'react';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
} from '@material-ui/core';
import Link from '../../atoms/Link';
import useStyles from './styles';

const BottomModal = (props: BottomModalProps) => {
    const classes = useStyles();
    const { subMenu, open, onClose } = props;

    return (
        <div>
            <Drawer anchor="bottom" variant={'temporary'} open={open} onClose={onClose}>
                <List
                    subheader={<ListSubheader>Start As</ListSubheader>}
                    className={classes.drawer}
                >
                    {subMenu.map(({ subMenuText, subMenuIcon, to }) => {
                        return (
                            <ListItem
                                key={subMenuText}
                                component={Link}
                                href={to}
                                className={classes.listItem}
                            >
                                <ListItemIcon className={classes.menuIcon}>
                                    {subMenuIcon}
                                </ListItemIcon>
                                <ListItemText
                                    id={subMenuText}
                                    primary={subMenuText}
                                    classes={{ primary: classes.listItemText }}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Drawer>
        </div>
    );
};

export type BottomModalProps = {
    subMenu: Array<any>;
    open: boolean;
    onClose: () => void;
};

export default BottomModal;
