export type ContentType = 'LINKS' | 'LINES' | 'IMAGE' | 'CREDITS' | 'NODE' | 'SOCIALS';
/**
 * Data types of content that can be rendered within a Data Row component.
 */
const CONTENT_TYPES: Record<ContentType, ContentType> = {
    LINKS: 'LINKS',
    LINES: 'LINES',
    IMAGE: 'IMAGE',
    CREDITS: 'CREDITS',
    NODE: 'NODE',
    SOCIALS: 'SOCIALS',
};

Object.freeze(CONTENT_TYPES);

export default CONTENT_TYPES;
