import React from 'react';
import { Box, CircularProgress, IconButton, Typography } from '@material-ui/core';
import { CalendarClock, Pencil } from 'mdi-material-ui';
import NotificationBox from '../../atoms/NotificationBox';
import clsx from 'clsx';
import useStyles from './styles';

const ScheduledPostInfo: React.FC<ScheduledPostInfoProps> = ({
    isScheduling,
    scheduledDate,
    onEditScheduledDatePress,
}) => {
    const classes = useStyles();

    return (
        <NotificationBox variant={'warning'}>
            {isScheduling ? (
                <div className={clsx(classes.flexRow, classes.alignCenter)}>
                    <Box paddingRight={1}>
                        <Typography variant={'subtitle2'} color={'inherit'}>
                            Scheduling
                        </Typography>
                    </Box>
                    <CircularProgress size={14} color={'inherit'} />
                </div>
            ) : (
                <>
                    <div className={classes.flexRow}>
                        <div className={classes.calendarIconContainer}>
                            <CalendarClock
                                color={'inherit'}
                                className={classes.notificationBoxIcon}
                            />
                        </div>

                        <Typography
                            component={'span'}
                            variant={'body2'}
                            color={'inherit'}
                        >
                            This post is scheduled to be published on the following date
                            time (Chicago Time)
                        </Typography>
                    </div>

                    <div className={classes.dateTimeRowContainer}>
                        <Box paddingLeft={3} paddingRight={1}>
                            <Typography variant={'subtitle2'} color={'inherit'}>
                                {scheduledDate}
                            </Typography>
                        </Box>
                        <IconButton
                            onClick={onEditScheduledDatePress}
                            color={'inherit'}
                            className={classes.editTimeButton}
                        >
                            <Pencil
                                color={'inherit'}
                                fontSize={'small'}
                                className={classes.notificationBoxIcon}
                            />
                        </IconButton>
                    </div>
                </>
            )}
        </NotificationBox>
    );
};

interface ScheduledPostInfoProps {
    isScheduling?: boolean;
    scheduledDate?: string;
    onEditScheduledDatePress?: React.MouseEventHandler<HTMLButtonElement>;
}

export default ScheduledPostInfo;
