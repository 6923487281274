import React from 'react';
import { EVENT_TYPES } from '../../config/SubmissionEventTypes';
import Link from '../../atoms/Link';
import useStyles from './styles';
import { Typography } from '@material-ui/core';
import { getEventActionType, SubmissionEvent } from './EventItem';
import { formatDate } from '@elevatormedia/duffel-bag';

const EventDescription: React.FC<SubmissionEvent> = ({
    metadata,
    eventTime,
    user,
    actionTaken,
}) => {
    const classes = useStyles();
    const time = formatDate(parseInt(eventTime));
    const actor = user.preferredUsername;
    const {
        PAYMENT_RECEIVED,
        SUBMISSION_APPROVAL,
        SUBMISSION_APPROVED,
        SUBMISSION_REJECTED,
        SUBMISSION_FLAGGED,
        SUBMISSION_RESTARTED,
        USER_COMMENT,
        POST_DRAFTED,
        POST_PUBLISHED,
        SUBMISSION_UPDATED,
    } = EVENT_TYPES;

    const getBoldedText = (text: string) => (
        <Typography
            variant={'body2'}
            component={'span'}
            display={'inline'}
            className={classes.boldedText}
        >
            {text}
        </Typography>
    );

    switch (getEventActionType(actionTaken)) {
        case PAYMENT_RECEIVED:
            return (
                <Typography
                    variant={'body2'}
                    className={classes.eventDescription}
                    component={'span'}
                >
                    Payment for a total of ${getBoldedText(metadata.paymentTotal)} was
                    completed at {time}
                </Typography>
            );
        case SUBMISSION_REJECTED:
            return (
                <Typography
                    variant={'body2'}
                    className={classes.eventDescription}
                    component={'span'}
                >
                    {getBoldedText(actor)} declined this submission on {time}
                </Typography>
            );
        case SUBMISSION_APPROVAL:
        case SUBMISSION_APPROVED:
            return (
                <Typography
                    variant={'body2'}
                    className={classes.eventDescription}
                    component={'span'}
                >
                    {getBoldedText(actor)} approved this submission on {time}
                </Typography>
            );
        case SUBMISSION_FLAGGED:
            return (
                <Typography
                    variant={'body2'}
                    className={classes.eventDescription}
                    component={'span'}
                >
                    {getBoldedText(actor)} flagged this submission on {time}
                </Typography>
            );
        case USER_COMMENT:
            return (
                <Typography
                    variant={'body2'}
                    className={classes.eventDescription}
                    component={'span'}
                >
                    {getBoldedText(actor)} commented this workflow on {time}
                </Typography>
            );
        case POST_DRAFTED:
            return (
                <Typography
                    variant={'body2'}
                    className={classes.eventDescription}
                    component={'span'}
                >
                    {getBoldedText(actor)} drafted a post from this workflow on {time}
                </Typography>
            );
        case POST_PUBLISHED:
            return (
                <Typography
                    variant={'body2'}
                    className={classes.eventDescription}
                    component={'span'}
                >
                    {getBoldedText(actor)} published a post from this workflow on {time}.
                    Link is available at:{' '}
                    <Link href={metadata.permalink} display={'inline'} target={'_blank'}>
                        {metadata.permalink}
                    </Link>
                </Typography>
            );
        case SUBMISSION_RESTARTED:
            return (
                <Typography
                    variant={'body2'}
                    className={classes.eventDescription}
                    component={'span'}
                >
                    {getBoldedText(actor)} restarted the workflow for this submission on{' '}
                    {time}
                </Typography>
            );
        case SUBMISSION_UPDATED:
            return (
                <Typography
                    variant={'body2'}
                    className={classes.eventDescription}
                    component={'span'}
                >
                    {getBoldedText(actor)} updated their submission info on {time}
                </Typography>
            );
        default:
            return null;
    }
};

export default EventDescription;
