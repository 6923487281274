import { reduceOpacity } from '@elevatormedia/duffel-bag';

import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(2, 0),
        },
        notificationBox: {
            borderRadius: '3px',

            padding: theme.spacing(2),
        },
        infoVariantBox: {
            backgroundColor: theme.palette.custom.grey.g200,
            color: theme.palette.primary.main,
        },
        errorVariantBox: {
            backgroundColor: reduceOpacity(
                theme.palette.submissionStatuses.rejected,
                0.08,
            ),
            color: theme.palette.submissionStatuses.rejected,
        },
        warningVariantBox: {
            backgroundColor: theme.palette.custom.orange.light,
            color: theme.palette.custom.orange.dark,
        },
    }),
);

export default useStyles;
