import gql from 'graphql-tag';

const CREATE_USER = gql`
    mutation createUser($input: CreateUserInput!) {
        createUser(input: $input) {
            userId
            email
            username
            preferredUsername
            firstName
            lastName
            role
            avatar {
                path
                sourceUrl
                sourceSet
                metadata {
                    bucket
                    mimetype
                    s3Url
                    filename
                }
            }
            bio
            socialMedia {
                type
                link
            }
            disabled
            preferred
            createdAt
            updatedAt
            permissionGroup {
                groupId
                groupName
                groupDesc
                permissionLevel
            }
        }
    }
`;

const UPDATE_USER = gql`
    mutation updateUser($userId: ID!, $input: UpdateUserInput!) {
        updateUser(userId: $userId, input: $input) {
            userId
            email
            emailVerified
            username
            preferredUsername
            firstName
            lastName
            role
            avatar {
                path
                sourceUrl
                sourceSet
                metadata {
                    bucket
                    mimetype
                    s3Url
                    filename
                }
            }
            bio
            socialMedia {
                type
                link
            }
            disabled
            preferred
            createdAt
            updatedAt
            permissionGroup {
                groupId
                groupName
                groupDesc
                permissionLevel
            }
        }
    }
`;

const UPDATE_MY_PROFILE = gql`
    mutation updateMyProfile($input: UpdateMyProfileInput!) {
        updateMyProfile(input: $input) {
            username
            preferredUsername
            firstName
            lastName
        }
    }
`;

const UPDATE_USER_PERMISSIONS = gql`
    mutation updatePermissions($userId: ID!, $permissions: UpdatePermissionsInput!) {
        updateUserPermissions(userId: $userId, permissions: $permissions)
    }
`;

const DELETE_USER = gql`
    mutation deleteUser($userId: ID!) {
        deleteUser(userId: $userId)
    }
`;

const DISABLE_USER = gql`
    mutation disableUser($userId: ID!) {
        disableUser(userId: $userId)
    }
`;

const ENABLE_USER = gql`
    mutation enableUser($userId: ID!) {
        enableUser(userId: $userId)
    }
`;

const RESET_USER = gql`
    mutation masterResetUser($userId: ID!) {
        masterResetUser(userId: $userId)
    }
`;

const ENABLE_REFERRALS = gql`
    mutation enableUserReferrals($userId: ID!) {
        enableUserReferrals(userId: $userId)
    }
`;

const DISABLE_REFERRALS = gql`
    mutation disableUserReferrals($userId: ID!) {
        disableUserReferrals(userId: $userId)
    }
`;

export {
    CREATE_USER,
    UPDATE_USER,
    UPDATE_USER_PERMISSIONS,
    UPDATE_MY_PROFILE,
    DELETE_USER,
    DISABLE_USER,
    ENABLE_USER,
    RESET_USER,
    ENABLE_REFERRALS,
    DISABLE_REFERRALS,
};
