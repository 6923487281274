import React, { useState, useEffect } from 'react';
import { Modal, Paper, Typography } from '@material-ui/core';
import { WRITEUP_QUEUE_MODAL } from 'lib/graphql/queries/submissions';
import useStyles from './styles';
import { useQuery } from '@apollo/client';
import {
    writeUpColumnNameToField,
    WriteupQueueItem,
} from 'layouts/Posts/WriteupQueue/WriteupQueue';
import { TableSortOrder } from 'layouts/Posts/AllPosts/AllPostsContext';
import WriteupQueueTable from 'organisms/WriteupQueueTable';
import SubmissionServicesDropdown from 'molecules/SubmissionServicesDropdown';

type WriteupQueueModalProps = {
    open: boolean;
    handleRowClick: (rowData: WriteupQueueItem) => void;
    handleClose: () => void;
};

const WriteupQueueModal: React.FC<WriteupQueueModalProps> = ({
    open,
    handleRowClick,
    handleClose,
}) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [services, setServices] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [order, setOrder] = React.useState<TableSortOrder>('desc');
    const [orderBy, setOrderBy] = React.useState('submissionTime');

    const { data, loading, refetch } = useQuery<{ submissions: WriteupQueueItem[] }>(
        WRITEUP_QUEUE_MODAL,
        {
            notifyOnNetworkStatusChange: true,
            variables: {
                where: {
                    serviceType: 'submission',
                    services,
                    status: 'Approved',
                    hasPost: false,
                },
                limit: rowsPerPage,
                skip: page * rowsPerPage,
                sort: {
                    field: writeUpColumnNameToField[orderBy],
                    descending: order === 'desc',
                },
            },
            fetchPolicy: 'network-only',
        },
    );

    useEffect(() => {
        if (!loading) {
            setRows(data && data.submissions ? data.submissions : []);
        }
    }, [loading, data]);

    /**
     * Handler for changing the currently service type filter
     */
    const handleChangeServiceType = (serviceId: string) => {
        if (serviceId) setServices([serviceId]);
        else if (services) setServices([]);
    };

    const handleChangeRowsPerPage: React.ChangeEventHandler<
        HTMLTextAreaElement | HTMLInputElement
    > = ({ target: { value } }) => {
        const perPage = Number(value);
        setRowsPerPage(perPage);
        setPage(0);
    };

    const handleRequestSort = (property: string) => {
        let isDesc = false;

        if (orderBy === property) {
            if (order === 'desc') isDesc = false;
            else isDesc = true;
        } else isDesc = true;

        setOrder(isDesc ? 'desc' : 'asc');
        setOrderBy(property);
        refetch({
            sort: {
                field: writeUpColumnNameToField[property],
                descending: isDesc,
            },
        });
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            className={classes.modal}
            aria-labelledby="elevator-media-library"
        >
            <Paper className={classes.paper}>
                <div className={classes.headerRow}>
                    <Typography className={classes.modalHeader} variant="h5">
                        Link Submission to Post
                    </Typography>
                    <SubmissionServicesDropdown
                        onChange={handleChangeServiceType}
                        filterBy="submission"
                    />
                </div>
                <div className={classes.root}>
                    <WriteupQueueTable
                        serviceType="Submissions"
                        rows={rows}
                        rowsPerPage={rowsPerPage}
                        rowCount={rows.length ? rows[0].count : 0}
                        page={page}
                        order={order}
                        orderBy={orderBy}
                        loading={loading}
                        hideActionButtons={true}
                        handleChangePage={setPage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleRequestSort={handleRequestSort}
                        handleRowOnClick={handleRowClick}
                    />
                </div>
            </Paper>
        </Modal>
    );
};

export default WriteupQueueModal;
