const CONTENT_URL_REGEXES = {
    YOUTUBE: ['YOUTUBE', 'YOUTU.BE'],
    SOUNDCLOUD: ['SOUNDCLOUD'],
    VIMEO: ['VIMEO'],
    APPLE_MUSIC: ['MUSIC.APPLE'],
    GOOGLE_DRIVE: ['DRIVE.GOOGLE'],
    SPOTIFY: ['SPOTIFY'],
} as const;

Object.freeze(CONTENT_URL_REGEXES);

export default CONTENT_URL_REGEXES;
