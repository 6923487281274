import React from 'react';
import useStyles from './styles';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

const UserActionRow: React.FC<UserActionRowProps> = ({
    actionTitle,
    actionDescription,
    children,
    dangerous = false,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={clsx(dangerous && classes.dangerBox)}>
                <div className={classes.optionRow}>
                    <div className={classes.optionText}>
                        <Typography variant={'subtitle2'}>{actionTitle}</Typography>
                        <Typography variant={'body2'}>{actionDescription}</Typography>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

interface UserActionRowProps {
    actionTitle: string;
    actionDescription: string;
    dangerous?: boolean;
}

export default UserActionRow;
