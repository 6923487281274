import React from 'react';
import useStyles from './styles';
import EditCreditForm from './EditCreditForm';
import {
    Avatar,
    Button,
    GridContainer,
    GridItem,
    Media,
    useAuth,
} from '@elevatormedia/duffel-bag';
import {
    Divider,
    IconButton,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import { Delete, Pencil, Plus } from 'mdi-material-ui';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { buildUrl, isValidDomain, extractUser } from '@elevatormedia/socialite';
import { CreditFormValues, Credit, CreditInput } from 'types/credits';
import { SocialMediaInput, SocialMediaLink, SocialMediaType } from 'types/socialMedia';
import { Formik, FormikHelpers } from 'formik';
import { object as yupObject, string } from 'yup';
import { splitSocialMediaLink, isNotMalformedInput } from '../../utils/socials';
import CreditInfo from './EditCreditForm/CreditInfo';

export const baseInitialValues: CreditFormValues = {
    type: 'Artist',
    name: '',
    email: '',
    isSubmitter: false,
    instagram: '',
    youtube: '',
    soundcloud: '',
    twitter: '',
    facebook: '',
    spotify: '',
    autoLinked: false,
};

const CreditsAccordion = (props: CreditsAccordionProps) => {
    const classes = useStyles();

    const { credits, removeCredit, saveCredit, header } = props;

    const [editIndex, setEditIndex] = React.useState(-1);
    const [editMode, setEditMode] = React.useState(false);
    const [openIndex, setOpenIndex] = React.useState(-1);

    const [initialValues, setInitialValues] = React.useState(baseInitialValues);
    const [isAddingCredit, setIsAddingCredit] = React.useState(false);

    const socialPrefixes: SocialMediaInput = {};

    const { currentUser } = useAuth();

    const createSocialTestOptions = (
        socialPlatform: Exclude<SocialMediaType, 'appleMusic'>,
    ) => ({
        name: `is-${socialPlatform}`,
        message: `Please enter a valid ${socialPlatform} username`,
        test: (value: string) => {
            if (!value) return true;
            if (isValidDomain(value)) return true;
            const socialUrl =
                socialPlatform !== 'spotify'
                    ? buildUrl(value, socialPlatform)
                    : buildUrl(value, socialPlatform, 'artist');
            return isValidDomain(socialUrl);
        },
    });

    const validationSchema = yupObject({
        type: string().required('A credit type is required'),
        name: string().required('This field is required'),
        email: string().email('Please enter a valid email address'),
        instagram: string()
            .test({ ...createSocialTestOptions('instagram') })
            .test('malformed input', 'Malformed input please check username', (value) => {
                return isNotMalformedInput(value);
            }),
        facebook: string()
            .test({ ...createSocialTestOptions('facebook') })
            .test('malformed input', 'Malformed input please check username', (value) => {
                return isNotMalformedInput(value);
            }),
        youtube: string()
            .test({ ...createSocialTestOptions('youtube') })
            .test('malformed input', 'Malformed input please check username', (value) => {
                return isNotMalformedInput(value);
            }),
        soundcloud: string()
            .test({ ...createSocialTestOptions('soundcloud') })
            .test('malformed input', 'Malformed input please check username', (value) => {
                return isNotMalformedInput(value);
            }),
        twitter: string()
            .test({ ...createSocialTestOptions('twitter') })
            .test('malformed input', 'Malformed input please check username', (value) => {
                return isNotMalformedInput(value);
            }),
    });

    const getFullyQualifiedSocialMediaLink = (values: SocialMediaInput) => {
        const { instagram, youtube, soundcloud, twitter, facebook, spotify } = values;
        const socialMedia: SocialMediaLink[] = [
            ...(instagram && [
                {
                    type: 'instagram' as const,
                    link: isValidDomain(instagram)
                        ? instagram
                        : buildUrl(instagram, 'instagram'),
                },
            ]),
            ...(youtube && [
                {
                    type: 'youtube' as const,
                    link: isValidDomain(youtube) ? youtube : buildUrl(youtube, 'youtube'),
                },
            ]),
            ...(soundcloud && [
                {
                    type: 'soundcloud' as const,
                    link: isValidDomain(soundcloud)
                        ? soundcloud
                        : buildUrl(soundcloud, 'soundcloud'),
                },
            ]),
            ...(twitter && [
                {
                    type: 'twitter' as const,
                    link: isValidDomain(twitter) ? twitter : buildUrl(twitter, 'twitter'),
                },
            ]),
            ...(facebook && [
                {
                    type: 'facebook' as const,
                    link: isValidDomain(facebook)
                        ? facebook
                        : buildUrl(facebook, 'facebook'),
                },
            ]),
            ...(spotify && [
                {
                    type: 'spotify' as const,
                    link: isValidDomain(spotify)
                        ? spotify
                        : buildUrl(spotify, 'spotify', 'artist'),
                },
            ]),
        ];

        return socialMedia;
    };

    const getSocialMediaLink = (
        socialMedia: Array<SocialMediaLink>,
        type: SocialMediaType,
        fullLink: boolean,
    ) => {
        const socialLink = socialMedia.find((social) => social.type === type);

        if (socialLink) {
            // If the user's social media was automatically linked, prevent system from only
            // retrieving the username. Instead return entire link
            if (fullLink) {
                return socialLink.link;
            }

            // Otherwise parse out the username from link
            const [normalizedPrefix, normalizedUsername] = splitSocialMediaLink(
                socialLink.link,
            );
            socialPrefixes[socialLink.type] = normalizedPrefix;
            return extractUser(socialLink.link);
        } else {
            return '';
        }
    };

    const getSocialMediaInitValues = (index: number) => {
        // Retrieve the credit obj for the currently viewed index.
        const targetCredit: Partial<CreditInput> = credits[index];
        const creditSocials = targetCredit.socialMedia;
        const fullLink = targetCredit.autoLinked === true;

        const result = {
            instagram: getSocialMediaLink(creditSocials, 'instagram', fullLink),
            youtube: getSocialMediaLink(creditSocials, 'youtube', fullLink),
            soundcloud: getSocialMediaLink(creditSocials, 'soundcloud', fullLink),
            twitter: getSocialMediaLink(creditSocials, 'twitter', fullLink),
            facebook: getSocialMediaLink(creditSocials, 'facebook', fullLink),
            spotify: getSocialMediaLink(creditSocials, 'spotify', fullLink),
        };

        return result;
    };

    const handleAddNew = () => {
        setIsAddingCredit(true);
    };

    const editCredit = (index: number) => {
        // Set initial values for Formik component by retrieving the
        // proper values from the credits[] array.
        const intialValues = {
            name: credits[index].name,
            email: credits[index].email,
            type: credits[index].creditType.typeName,
            isSubmitter: credits[index].userId
                ? credits[index].userId === currentUser.userId
                : false,
            ...getSocialMediaInitValues(index),
            autoLinked: credits[index].userId ? true : false,
        };

        setEditIndex(index);
        setInitialValues(intialValues);
    };

    const handleSubmitCredit = async (
        values: CreditFormValues,
        actions: FormikHelpers<CreditFormValues>,
    ) => {
        const { type, name, email, isSubmitter, autoLinked } = values;

        // restructure state to credit with socials array
        // Each social will by dynamically included in array based on empty strings
        const socialMedia = getFullyQualifiedSocialMediaLink(values);
        const creditToSave: CreditInput = {
            type,
            name,
            email,
            socialMedia,
            isSubmitter,
            autoLinked,
        };

        await saveCredit(creditToSave, editIndex);

        actions.resetForm();

        isAddingCredit && setIsAddingCredit(false);
    };

    return (
        <Formik
            validationSchema={validationSchema}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmitCredit}
            initialErrors={{ name: 'This field is required' }}
        >
            <>
                <div className={classes.root}>
                    {header && credits.length !== 0 && (
                        <GridContainer
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography
                                variant={'body2'}
                                gutterBottom
                                className={classes.heavyFont}
                            >
                                Credits
                            </Typography>
                        </GridContainer>
                    )}
                    {credits.length >= 1 &&
                        credits.map((item, index) => {
                            const {
                                name,
                                creditType: { typeName },
                            } = item;

                            let avatar: Media = null;
                            if (item.user) {
                                avatar = item.user.avatar;
                            }

                            const creditInfo = credits[index];

                            return (
                                <Accordion
                                    key={item.creditId}
                                    expanded={editIndex === index || openIndex === index}
                                    className={classes.accordion}
                                    id={index.toString()}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        IconButtonProps={{
                                            onClick: () => {
                                                if (openIndex === index) {
                                                    setOpenIndex(-1);
                                                    setEditIndex(-1);
                                                    setEditMode(false);
                                                } else {
                                                    editCredit(index);
                                                    setOpenIndex(index);
                                                }
                                            },
                                        }}
                                    >
                                        <GridContainer
                                            direction={'row'}
                                            alignItems={'center'}
                                            justifyContent={'space-between'}
                                            spacing={1}
                                        >
                                            <GridItem>
                                                <GridContainer
                                                    direction={'row'}
                                                    alignItems={'center'}
                                                    spacing={3}
                                                >
                                                    <Avatar
                                                        username={name}
                                                        variant={'x-small'}
                                                        imageProps={
                                                            avatar && {
                                                                src: avatar.sourceUrl,
                                                                srcSet: avatar.sourceSet,
                                                                alt: name,
                                                            }
                                                        }
                                                    />
                                                    <GridItem
                                                        className={
                                                            classes.creditTextContainer
                                                        }
                                                    >
                                                        <div
                                                            className={classes.creditName}
                                                        >
                                                            {name}
                                                        </div>
                                                        <div className={classes.typeName}>
                                                            {typeName}
                                                        </div>
                                                    </GridItem>
                                                </GridContainer>
                                            </GridItem>
                                            <GridItem>
                                                <>
                                                    <IconButton
                                                        aria-label="edit"
                                                        onClick={() => {
                                                            setEditMode(!editMode);
                                                            setEditIndex(-1);
                                                            setOpenIndex(index);
                                                            editCredit(index);
                                                        }}
                                                    >
                                                        <Pencil />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => {
                                                            removeCredit(index);
                                                        }}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </>
                                            </GridItem>
                                        </GridContainer>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {editMode ? (
                                            <EditCreditForm
                                                onCancel={() => {
                                                    setEditIndex(-1);
                                                    setEditMode(false);
                                                }}
                                                currentUser={currentUser}
                                                editMode={true}
                                            />
                                        ) : (
                                            <CreditInfo credit={creditInfo} />
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                </div>
                {credits.length === 0 || isAddingCredit ? (
                    <div>
                        <Typography className={classes.addCreditHeader}>
                            Add Credit
                        </Typography>
                        <Divider className={classes.addCreditDivider} />
                        <EditCreditForm
                            currentUser={currentUser}
                            onCancel={() => setIsAddingCredit(false)}
                            saveCredit={(credit: CreditInput) => {
                                saveCredit(credit, editIndex);
                            }}
                        />
                    </div>
                ) : (
                    <div>
                        <Button
                            variant={'text'}
                            onClick={handleAddNew}
                            disabled={editIndex !== -1}
                        >
                            <Plus /> ADD CREDIT
                        </Button>
                    </div>
                )}
            </>
        </Formik>
    );
};

export interface CreditsAccordionProps {
    credits: Array<Credit>;
    removeCredit: Function;
    saveCredit: Function;
    header?: boolean;
}

CreditsAccordion.defaultProps = {
    header: true,
};

export default CreditsAccordion;
