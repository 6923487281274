import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        checkboxGroupContainer: {
            padding: theme.spacing(1),
        },
        permissionsContainer: {
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
            },
        },
        columnContainer: {
            flex: 1,
        },
    }),
);

export default useStyles;
