import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        /******************
         * BASE SEARCHBAR *
         ******************/
        root: {
            flexGrow: 1,
            borderRadius: 4,
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(1),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '42px',
        },
        searchInputGroupContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        form: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            flexGrow: 1,
            border: 'none',
        },
        input: {
            width: '100%',
            fontSize: theme.typography.pxToRem(14),
        },
        searchIcon: {
            fontSize: '1.2rem',
            color: theme.palette.text.secondary,
        },
        removeBorder: {
            border: 'none',
        },
        divider: {
            margin: 4,
            width: '1px',
            height: '36px',
        },

        /*******************
         * ADVANCED SEARCH *
         *******************/
        advancedSearchContent: {
            minHeight: '250px',
            width: '500px',
        },
        contentWrapper: {
            margin: theme.spacing(1, 2, 2, 2),
        },
        menuOptionsContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        headerContainer: {
            paddingBottom: theme.spacing(1),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        dropdownArrow: {
            color: theme.palette.text.secondary,
            margin: 0,
        },
        optionRow: {
            maxWidth: '70%',
        },
        selectContainer: {
            minWidth: '170px',
        },
        advSearchCloseIcon: {
            color: theme.palette.text.secondary,
        },
    }),
);

export default useStyles;
