import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        chipsContainer: {
            padding: theme.spacing(1, 0),
        },
        submissionTitle: {
            maxWidth: '90%',
        },
        submitterName: {
            fontWeight: 'bold',
        },
        socialMediaContainer: {
            padding: theme.spacing(2, 0),
        },
        socialMediaEmptyState: {
            paddingTop: '4px',
        },
        card: {
            minHeight: '300px',
            borderRadius: 0,
        },
        approveDenyContainer: {
            minHeight: '60px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
        },
        seeMoreContainer: {
            minHeight: '40px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        approveAction: {
            color: theme.palette.success.main,
        },
        denyAction: {
            color: theme.palette.error.main,
        },
    }),
);

export default useStyles;
