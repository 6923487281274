import React from 'react';
import useStyles from './styles';
import { Check, Help, NotInterested } from '@material-ui/icons';
import {
    Calendar,
    Eye,
    Lock,
    Pencil,
    PlaylistCheck,
    ProgressClock,
} from 'mdi-material-ui';
import clsx from 'clsx';

const PromotionIcon = (props: PromotionIconProps) => {
    const classes = useStyles();

    const { status } = props;

    switch (status) {
        case 'Approve':
            return (
                <div style={{ backgroundColor: '#81C784' }} className={classes.circle}>
                    <Check className={classes.icon} fontSize={'small'} color={'action'} />
                </div>
            );
        case 'Cancelled':
            return (
                <div style={{ backgroundColor: '#F44336' }} className={classes.circle}>
                    <NotInterested
                        className={classes.icon}
                        fontSize={'small'}
                        color={'action'}
                    />
                </div>
            );
        case 'Completed':
            return (
                <div style={{ backgroundColor: '#00A000' }} className={classes.circle}>
                    <PlaylistCheck
                        className={classes.icon}
                        fontSize={'small'}
                        color={'action'}
                    />
                </div>
            );
        case 'Draft':
            return (
                <div style={{ backgroundColor: '#81C784' }} className={classes.circle}>
                    <Pencil
                        className={classes.icon}
                        fontSize={'small'}
                        color={'action'}
                    />
                </div>
            );
        case 'Review':
            return (
                <div style={{ backgroundColor: '#FFB74D' }} className={classes.circle}>
                    <Eye className={classes.icon} fontSize={'small'} color={'action'} />
                </div>
            );
        case 'Revise':
            return (
                <div style={{ backgroundColor: '#BDBDBD' }} className={classes.circle}>
                    <Lock className={classes.icon} fontSize={'small'} color={'action'} />
                </div>
            );
        case 'Running':
            return (
                <div style={{ backgroundColor: '#F4E04B' }} className={classes.circle}>
                    <ProgressClock
                        className={classes.icon}
                        fontSize={'small'}
                        color={'action'}
                    />
                </div>
            );
        case 'Scheduled':
            return (
                <div style={{ backgroundColor: '#2196F3' }} className={classes.circle}>
                    <Calendar
                        className={clsx(classes.icon, classes.calendar)}
                        fontSize={'small'}
                        color={'action'}
                    />
                </div>
            );

        default:
            return (
                <div style={{ backgroundColor: 'black' }} className={classes.circle}>
                    <Help className={classes.icon} fontSize={'small'} color={'action'} />
                </div>
            );
    }
};

export interface PromotionIconProps {
    status: string;
}

export default PromotionIcon;
