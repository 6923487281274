import {
    Avatar,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Box,
} from '@material-ui/core';
import { ChevronRight } from 'mdi-material-ui';
import React from 'react';
import useStyles from './styles';
import { formatDate, User } from '@elevatormedia/duffel-bag';
import { UserListItem } from 'types/user';

const UsersList: React.FC<UsersListProps> = (props) => {
    const classes = useStyles();
    const { handleRowOnClick, rows, page, handleChangePage } = props;

    /**
     * Handles loading more data from the database and appending them to the end of
     * the list
     * NOTE scrolls to top
     */
    const handleLoadMore = () => {
        handleChangePage(page + 1);
    };

    const renderUserRows = () => {
        return rows.map((row, index) => {
            const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
                handleRowOnClick(e, row, index);
            };
            return (
                <ListItem onClick={onClick} key={index} button>
                    <ListItemAvatar className={classes.listItemAvatar}>
                        <Avatar
                            src={row.avatar ? row.avatar.sourceUrl : null}
                            className={classes.avatar}
                        >
                            {!row.avatar && row.preferredUsername[0]}
                        </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                        primary={row.preferredUsername}
                        secondary={
                            <Typography
                                // component="span"
                                variant="body2"
                                color="textPrimary"
                                className={classes.listItemText}
                            >
                                {row.permissionGroup.groupName}
                                {' Added ' +
                                    formatDate(new Date(parseInt(row.createdAt)))}
                            </Typography>
                        }
                    />

                    <ChevronRight />
                </ListItem>
            );
        });
    };

    return (
        <div>
            <List>{renderUserRows()}</List>
            {rows.length && rows[0].count > rows.length ? (
                <Button
                    onClick={handleLoadMore}
                    fullWidth
                    color={'primary'}
                    variant={'outlined'}
                >
                    Load More
                </Button>
            ) : (
                <Box display="flex" justifyContent="center">
                    <Typography variant="body2" color="textSecondary">
                        End of List
                    </Typography>
                </Box>
            )}
        </div>
    );
};

interface UsersListProps {
    handleRowOnClick?: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        row: any,
        index: number,
    ) => void;
    rows?: Array<UserListItem>;
    page?: number;
    handleChangePage?: (page: number) => void;
}

export type WithCount<T = any> = T & { count: number };

export default UsersList;
