import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: '400px',
        },
        usernameField: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        userNameContainer: {
            wordBreak: 'break-all',
        },
        role: {
            wordBreak: 'break-word',
        },
        cardContentContainer: {
            margin: theme.spacing(2),
        },
        userAvatarContainer: {
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'flex-end',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        avatar: {
            width: '140px',
            height: '140px',
            [theme.breakpoints.down('xs')]: {
                width: '130px',
                height: '130px',
            },
            borderRadius: '13px',
            objectFit: 'cover',
            fontSize: theme.typography.pxToRem(40),
            backgroundColor: theme.palette.primary.dark,
        },

        smallCard: {
            width: '100%',
        },
    }),
);

export default useStyles;
