import React from 'react';
import { PostEditorFormValues, SUPPORTED_IMAGE_FORMATS } from '..';
import PostEditorFormContext from '../PostEditorFormContext';
import { FileDropzone } from 'organisms';
import { GridItem } from '@elevatormedia/duffel-bag/dist/atoms/Grid';
import { ImageCropType, Media } from '@elevatormedia/duffel-bag/dist/types/media';
import { cropImage } from 'lib/media';
import { getDownloadURL } from 'lib/utils';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { SaveButtonClick } from 'types/editor';

const ImageForm = (props: ImageFormProps) => {
    const { handleInputChange } = props;

    const {
        values,
        errors,
        handleChange,
        setFieldValue,
        setFieldTouched,
    } = useFormikContext<PostEditorFormValues>();

    const {
        currentUser,

        image,

        onSaveImageTransformations,
        isSavingImageTransformations,
    } = React.useContext(PostEditorFormContext);

    const { enqueueSnackbar } = useSnackbar();

    const [isTransformingImage, setIsTransformingImage] = React.useState(false);

    /**
     * Handler for when a user decides to change the crop image type on an existing
     * network image. Can be 'faces' | 'top' | 'bottom' or none
     */
    const onImageCropChange = async (cropType: ImageCropType) => {
        setIsTransformingImage(true);

        const croppedImage: Media | null = await cropImage(
            values.image,
            cropType,
            currentUser,
        );

        if (!croppedImage) {
            enqueueSnackbar('Error cropping image', {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                variant: 'error',
                preventDuplicate: true,
            });
        } else {
            setFieldValue('image', croppedImage);
        }

        setIsTransformingImage(false);
    };

    return (
        <GridItem>
            <FileDropzone
                onFileChange={(file: File) =>
                    handleInputChange('image', null, handleChange, setFieldTouched, file)
                }
                isTransformingImage={isTransformingImage}
                onCropImageOptionChange={onImageCropChange}
                file={values.image}
                postImage={image}
                hasError={Boolean(errors.image)}
                helperText={
                    (errors.image as string) ||
                    'Images are not required to save a post draft'
                }
                onSaveButtonClick={onSaveImageTransformations as SaveButtonClick}
                disableSaveButton={isSavingImageTransformations}
                hideImageOptions={values.status !== 'published'}
                supportedFileTypes={SUPPORTED_IMAGE_FORMATS}
            />
        </GridItem>
    );
};

export interface ImageFormProps {
    handleInputChange: any;
}

export default ImageForm;
