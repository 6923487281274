import React from 'react';
import { useScrollTrigger } from '@material-ui/core';

/**
 * Elevation Scroll: Listener HOC allowing the appbar to be elevated when
 * scroll pos is not at the top.
 */
const ElevationScroll: React.FC = (props) => {
    const { children } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    // TODO debug type issue
    return React.cloneElement(children as any, {
        elevation: trigger ? 3 : 0,
    });
};

export default ElevationScroll;
