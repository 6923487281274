import React from 'react';
import { Typography } from '@material-ui/core';
import {
    PageLayoutFooter,
    PageLayoutHeader,
    FaceRecognition,
    ImageSizeSelectActual,
} from 'mdi-material-ui';
import clsx from 'clsx';
import useStyles from './styles';
import { ImageCropType } from '@elevatormedia/duffel-bag';

const ImageCropOption: React.FC<ImageCropOptionProps> = (props) => {
    const classes = useStyles();
    const { variant, selected, onClick } = props;

    const getTitleFromVariant = () => {
        switch (variant) {
            case 'faces':
                return 'Focus faces';
            case 'top':
                return 'Focus top';
            case 'bottom':
                return 'Focus bottom';
            default:
                return 'No crop';
        }
    };

    const getIconFromVariant = () => {
        const styledClasses = clsx(classes.icon, selected && classes.activeOption);
        switch (variant) {
            case 'faces':
                return <FaceRecognition className={styledClasses} />;
            case 'top':
                return <PageLayoutHeader className={styledClasses} />;
            case 'bottom':
                return <PageLayoutFooter className={styledClasses} />;
            default:
                return <ImageSizeSelectActual className={styledClasses} />;
        }
    };

    return (
        <div className={classes.imageCropOptionItemRoot}>
            <div
                className={clsx(
                    classes.imageCropOptionContainer,
                    selected && classes.activeCropOption,
                )}
                onClick={onClick}
            >
                {getIconFromVariant()}
                <Typography
                    variant={'subtitle2'}
                    className={clsx(
                        classes.imageCropOptionText,
                        selected && classes.activeOption,
                    )}
                >
                    {getTitleFromVariant()}
                </Typography>
            </div>
        </div>
    );
};

interface ImageCropOptionProps {
    selected?: boolean;
    variant: ImageCropType;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export default ImageCropOption;
