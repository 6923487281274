import gql from 'graphql-tag';

const UPDATE_SUBMISSION_STATUSES = gql`
    mutation updateSubmissionStatuses($ids: [ID!]!, $status: SubmissionStatus!) {
        updateSubmissionStatuses(ids: $ids, status: $status) {
            submissionId
            status
        }
    }
`;

const FLAG_SUBMISSIONS = gql`
    mutation setFlaggedSubmissions($ids: [ID!]!) {
        setFlaggedSubmissions(ids: $ids, flagged: true) {
            submissionId
        }
    }
`;

const START_SUBMISSION_WRITEUP = gql`
    mutation startSubmissionWriteUp($submissionId: ID!) {
        startSubmissionWriteUp(submissionId: $submissionId) {
            postId
            description
            categories {
                name
                description
            }
            tags {
                tagId
                name
            }
            authors {
                userId
                username
            }
        }
    }
`;

export const START_SUBMISSION_PROMOTION = gql`
    mutation startSubmissionPromotion($submissionId: ID!) {
        startSubmissionPromotion(submissionId: $submissionId) {
            promotionId
            name
        }
    }
`;

const UPDATE_SUBMISSION = gql`
    mutation updateSubmission($id: ID!, $updates: UpdateSubmissionInput!) {
        updateSubmission(id: $id, updates: $updates) {
            submissionId
            contentTitle
            contentUrl
            contentDescription
            status
            flagged
            sponsoredBy
            preferredAuthorId
            preferredAuthor {
                userId
                username
                preferredUsername
                displayName
                firstName
                lastName
            }
            contentImage {
                sourceUrl
                sourceSet
            }
            credits {
                creditId
                creditType {
                    typeName
                }
                name
                user {
                    userId
                    preferredUsername
                }
                socialMedia {
                    type
                    link
                }
            }
        }
    }
`;

export {
    UPDATE_SUBMISSION_STATUSES,
    FLAG_SUBMISSIONS,
    START_SUBMISSION_WRITEUP,
    UPDATE_SUBMISSION,
};
