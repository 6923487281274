import React from 'react';
import useStyles from './styles';
import CustomInputBase from 'atoms/CustomInputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

/**
 * Enhanced Dropdown: Wraps the default MUI Select Component to allow for extensible dropdown components.
 * Note: The Parent component using this component should handle its state variables.
 */
const EnhancedDropdown = <T extends OptionVariant>(props: EnhancedDropdownProps<T>) => {
    const classes = useStyles();

    const {
        selectedValue,
        handleSelectedValueChange,
        dropdownLabel,
        dropdownOptions,
        formatItemText,
        className,
        id,
    } = props;

    let object: boolean;
    if (typeof selectedValue == 'string') {
        object = false;
    } else if (typeof selectedValue == 'number') {
        object = false;
    } else {
        object = true;
    }

    return (
        <FormControl className={classes.formControl}>
            <InputLabel htmlFor={`${dropdownLabel}-dropdown`}>{dropdownLabel}</InputLabel>
            <Select
                className={className}
                value={
                    object ? (selectedValue as Record<string, any>)[id] : selectedValue
                }
                input={
                    <CustomInputBase
                        name={dropdownLabel}
                        id={`${dropdownLabel}-dropdown`}
                    />
                }
                variant={'filled'}
                classes={{
                    icon: classes.dropdownArrow,
                }}
            >
                {dropdownOptions.map((value, index) => {
                    let itemText;
                    let selected;

                    if (!object) {
                        itemText = value;
                        selected = value === value;
                    } else {
                        itemText = (value as Record<string, any>)[id];
                        selected =
                            (value as Record<string, any>)[id] ===
                            (value as Record<string, any>)[id];
                    }

                    if (formatItemText) {
                        itemText = formatItemText(value);
                    }

                    return (
                        <MenuItem
                            key={index}
                            onClick={() => handleSelectedValueChange(value)}
                            selected={selected}
                            value={object ? (value as Record<string, any>)[id] : value}
                        >
                            {itemText}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export type OptionVariant = Record<string, any> | string | number;

interface EnhancedDropdownProps<T extends OptionVariant> {
    selectedValue: OptionVariant;
    handleSelectedValueChange: (value: OptionVariant) => void;
    dropdownLabel: string;
    dropdownOptions: Array<OptionVariant>;
    id?: T extends Record<string, any> ? string : never; // If variant is not an Object, this prop will not be allowed
    formatItemText?: Function;
    className?: string;
}

export default EnhancedDropdown;
