import React from 'react';
import { IconButton, Popover, Select, SelectProps, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { ArrowDropDown } from '@material-ui/icons';
import { Close } from 'mdi-material-ui';
import { Searchbar } from '..';
import { GridContainer, GridItem } from '@elevatormedia/duffel-bag';
import useStyles from './styles';

const AdvancedSearch: React.FC<AdvancedSearchProps> = (props) => {
    const classes = useStyles();
    const { onSearch, selectionMenusInit } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectionMenus, setSelectionMenu] = React.useState(
        selectionMenusInit || defaultMenusInit,
    );

    /**
     * Sets new anchor point for which the menu to attach itself too
     */
    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Removes anchor point component ref
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * Handles change in selection menu value.
     */
    const handleChange = (selectionName: string, newValue: string) => {
        const modifiedValue = selectionMenus[selectionName];
        modifiedValue.value = newValue;

        setSelectionMenu({
            ...selectionMenus,
            [selectionName]: modifiedValue,
        });
    };

    /**
     * function call-back to hande search functionality
     */
    const handleSearch = () => {
        alert('SEARCH NOT IMPLEMENTED');
        onSearch(); // callback from props.
    };

    /**
     * Resets the advanced search form to its original state.
     */
    const resetForm = () => {
        setSelectionMenu(selectionMenusInit || defaultMenusInit);
    };

    /**
     * Renders a single selection menu based on a label, and its values.
     */
    const renderOptionRow = ([selectionName, config]: [
        string,
        {
            value: string;
            options: string[];
        },
    ]) => {
        const { value, options } = config;
        const onChange: SelectProps['onChange'] = (event) => {
            handleChange(selectionName, event.target.value as string);
        };
        return (
            <GridContainer
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                className={classes.optionRow}
            >
                <GridItem>
                    <Typography variant={'body2'} color={'textSecondary'}>
                        {selectionName}
                    </Typography>
                </GridItem>
                <GridItem>
                    <Select
                        value={value}
                        onChange={onChange}
                        className={classes.selectContainer}
                        inputProps={{
                            name: selectionName,
                            id: selectionName,
                        }}
                        classes={{ icon: classes.dropdownArrow }}
                    >
                        {options.map((option, index) => (
                            <MenuItem key={index} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </GridItem>
            </GridContainer>
        );
    };

    /**
     * Renders all selection menus
     */
    const renderMenuOptions = () => {
        return (
            <GridContainer
                direction={'column'}
                justifyContent={'space-evenly'}
                spacing={2}
            >
                {Object.entries(selectionMenus).map((entry, index) => (
                    <GridItem key={index}>{renderOptionRow(entry)}</GridItem>
                ))}
            </GridContainer>
        );
    };

    const renderCloseButton = () => {
        return (
            <IconButton onClick={handleClose} className={classes.advSearchCloseIcon}>
                <Close />
            </IconButton>
        );
    };

    /**
     * Renders the trigger for the popover menu. In this case it is a dropdown arrow
     */
    const renderMenuTrigger = () => {
        return (
            <IconButton onClick={handleClick} color={'default'}>
                <ArrowDropDown className={classes.dropdownArrow} />
            </IconButton>
        );
    };

    const renderMenuActions = () => {
        return (
            <GridContainer
                direction={'row'}
                justifyContent={'flex-end'}
                alignItems={'flex-end'}
                spacing={1}
            >
                <GridItem>
                    <Button onClick={resetForm} variant={'text'}>
                        reset
                    </Button>
                </GridItem>
                <GridItem>
                    <Button onClick={handleSearch} variant={'outlined'} color={'primary'}>
                        search
                    </Button>
                </GridItem>
            </GridContainer>
        );
    };

    return (
        <Searchbar onSubmit={onSearch} placeholder={'Search submissions'} fullWidth>
            {renderMenuTrigger()}

            <Popover
                id="advanced search"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className={classes.contentWrapper}>
                    <div className={classes.headerContainer}>
                        <Typography>Advanced Search</Typography>
                        {renderCloseButton()}
                    </div>
                    <GridContainer
                        direction={'column'}
                        spacing={2}
                        justifyContent={'space-between'}
                        className={classes.advancedSearchContent}
                    >
                        <GridItem>{renderMenuOptions()}</GridItem>
                        <GridItem className={classes.menuOptionsContainer}>
                            {renderMenuActions()}
                        </GridItem>
                    </GridContainer>
                </div>
            </Popover>
        </Searchbar>
    );
};

interface AdvancedSearchProps {
    onSearch: () => void;
    selectionMenusInit?: Record<
        string,
        {
            value: string;
            options: string[];
        }
    >;
}

const defaultMenusInit = {
    'Submission Status': {
        value: 'All',
        options: ['All', 'Review', 'Approval', 'Approved'],
    },

    'Submission Type': {
        value: 'All',
        options: ['All', 'Standard', 'Placements', 'Preferred', 'Flagged'],
    },
};

export default AdvancedSearch;
