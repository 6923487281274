import React from 'react';
import { Typography } from '@material-ui/core';
import { getBackgroundColor } from './StatusColors';
import getStatusIcon from './StatusIcon';
import useStyles from './styles';
import clsx from 'clsx';
import { CampaignStatus } from '@elevatormedia/duffel-bag';

/**
 * Convenience Component: Renders a special icon assigned to a Campaign status
 * along with its label.
 */
const CampaignStatusChip: React.FC<CampaignStatusChipProps> = ({ status }) => {
    const classes = useStyles();

    return (
        <div className={classes.chipLayout}>
            <div
                className={clsx(
                    classes.backgroundContainer,
                    getBackgroundColor(status, classes),
                )}
            >
                {getStatusIcon(status, { className: classes.chipIcon })}
            </div>

            <Typography className={classes.statusText} variant={'caption'}>
                {status === 'Cancelled' ? 'Declined' : status}
            </Typography>
        </div>
    );
};

interface CampaignStatusChipProps {
    status: CampaignStatus;
}

export default CampaignStatusChip;
