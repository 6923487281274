import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        text: {
            fontSize: theme.typography.pxToRem(10),
            color: theme.palette.custom.grey.g500,
        },
        icon: {
            fontSize: theme.typography.pxToRem(14),
        },

        dotsHorizontal: {
            color: '#2196F3',
        },
        red: {
            color: '#FF6345',
        },
        green: {
            color: '#71FF4D',
        },
    }),
);

export default useStyles;
