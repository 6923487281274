import React from 'react';
import useStyles from './styles';
import {
    Avatar,
    Button,
    Checkbox,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    CircularProgress,
    Box,
} from '@material-ui/core';
import { formatDate24Hr, formatDateOnly } from '@elevatormedia/duffel-bag';
import { Flag } from 'mdi-material-ui';
import { CampaignStatusCircle } from 'atoms';

const todaysDate = new Date();

const CampaignsList: React.FC<CampaignsListProps> = ({
    handleRowSelectToggle,
    handleRowOnClick,
    rowIDPropName,
    rows,
    isSelected,
    renderCheckbox = false,
    renderCampaignStatus = true,
    page,
    handleChangePage,
    loading,
    searchParam,
}) => {
    const classes = useStyles();

    if (loading) return <CircularProgress />;

    const getDynamicDateTime = (submissionDate: Date, orgTimeObj: string) => {
        const isToday =
            todaysDate.getDate() === submissionDate.getDate() &&
            todaysDate.getMonth() === submissionDate.getMonth() &&
            todaysDate.getFullYear() === submissionDate.getFullYear();

        if (isToday) {
            // submission was made today
            return formatDate24Hr(orgTimeObj);
        } else {
            return formatDateOnly(orgTimeObj);
        }
    };

    /**
     * Handles loading more data from the database and appending them to the end of
     * the list
     * NOTE scrolls to top
     */
    const handleLoadMore = () => {
        handleChangePage(null, page + 1);
    };

    const renderCampaignRows = () => {
        return rows.map((row, index) => {
            const rowIsSelected = isSelected && isSelected(row[rowIDPropName]);

            return (
                <ListItem
                    onClick={(event) => handleRowOnClick(event, row, index)}
                    key={index}
                    button
                    selected={rowIsSelected}
                    className={classes.listItem}
                    alignItems={'flex-start'}
                >
                    {renderCampaignStatus && (
                        <ListItemAvatar className={classes.listItemAvatar}>
                            <Avatar className={classes.campaignStatusAvatar}>
                                <CampaignStatusCircle
                                    size={'small'}
                                    status={row.status}
                                />
                            </Avatar>
                        </ListItemAvatar>
                    )}
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    variant="body1"
                                    className={classes.listItemText}
                                >
                                    {row.name}
                                    {row.flagged && (
                                        <Flag className={classes.flaggedSub} />
                                    )}
                                </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="textSecondary"
                                    className={classes.listItemText}
                                >
                                    {row.order.user.preferredUsername}
                                    {' • ' +
                                        getDynamicDateTime(
                                            new Date(row.createdAt),
                                            row.createdAt,
                                        ) +
                                        ' • '}
                                    <Typography
                                        component="span"
                                        variant="caption"
                                        className={classes.durationBadge}
                                    >
                                        {row.campaignType.duration}
                                    </Typography>
                                </Typography>
                            </React.Fragment>
                        }
                    />

                    <ListItemSecondaryAction className={classes.listItemRight}>
                        {renderCheckbox && (
                            <Checkbox
                                edge="end"
                                color={'primary'}
                                onChange={() => handleRowSelectToggle(row)}
                                checked={rowIsSelected}
                            />
                        )}
                    </ListItemSecondaryAction>
                </ListItem>
            );
        });
    };

    const renderList = () => {
        return (
            <div>
                <List>{renderCampaignRows()}</List>
                {rows.length && rows[0].count > rows.length ? (
                    <Button
                        onClick={handleLoadMore}
                        fullWidth
                        color={'primary'}
                        variant={'outlined'}
                    >
                        Load More
                    </Button>
                ) : (
                    <Box display="flex" justifyContent="center">
                        <Typography variant="body2" color="textSecondary">
                            End of List
                        </Typography>
                    </Box>
                )}
            </div>
        );
    };

    const renderEmptyState = () => {
        return (
            <Box paddingY={2}>
                <Typography color={'textSecondary'}>
                    No Campaigns Found "{searchParam ? searchParam : ''}"
                </Typography>
            </Box>
        );
    };

    return rows.length < 1 ? renderEmptyState() : renderList();
};

interface CampaignsListProps<T = any> {
    handleRowSelectToggle?: (row: any) => void;
    handleRowOnClick?: (e: any, row: any, index: number) => void;
    rowIDPropName?: string;
    rows?: T[];
    searchParam?: string;
    isSelected?: (id: string) => boolean;
    renderCheckbox?: boolean;
    renderCampaignStatus?: boolean;
    loading?: boolean;
    handleChangePage?: (event: any, page: number) => void;
    page?: number;
}

export default CampaignsList;
