export type SocialMediaLink = {
    type: SocialMediaType;
    link: string;
};

export type SocialMediaType =
    | 'instagram'
    | 'youtube'
    | 'soundcloud'
    | 'twitter'
    | 'facebook'
    | 'appleMusic'
    | 'spotify';

export const isSocialMediaType = (key: string): key is SocialMediaType => {
    return [
        'instagram',
        'youtube',
        'soundcloud',
        'twitter',
        'facebook',
        'appleMusic',
        'spotify',
    ].includes(key);
};

export type ShareLinkOptions = {
    excludeEmail?: boolean;
    exludeCopyToClipboard?: boolean;
};

export type SocialMediaItemInput = {
    type: SocialMediaType;
    link: string;
};

export type SocialMediaInput = Partial<Record<SocialMediaType, string>>;
