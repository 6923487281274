import React from 'react';
import { InputBase, InputBaseProps } from '@material-ui/core';
import useStyles from './styles';

/**
 * Styled Wrapping Component around the InputBase. Can be used to
 * Compose other types of inputs which match theme styles.
 */
const CustomInputBase: React.FC<InputBaseProps> = (props) => {
    const classes = useStyles();
    return <InputBase classes={classes} {...props} />;
};

export default CustomInputBase;
