import React from 'react';
import getStatusIcon from './StatusIcon';
import { getBackgroundColor } from './StatusColors';
import clsx from 'clsx';
import useStyles from './styles';
import { SubmissionStatus } from 'types/submission';

/**
 * Convenience Component: Renders a special icon assigned to a Submission status
 * along with its label.
 */
const SubmissionStatusCircle = (props: SubmissionStatusCircleProps) => {
    const { status, highContrast, size } = props;
    const classes = useStyles();

    return (
        <div
            className={clsx(
                classes.statusContainer,
                classes.alignCenter,
                getBackgroundColor(status, classes),
            )}
        >
            {getStatusIcon(status, {
                className: clsx({
                    [classes.regularStatusIcon]: size === 'regular',
                    [classes.smallStatusIcon]: size === 'small',
                    [classes.highContrast]: highContrast,
                }),
            })}
        </div>
    );
};

type SubmissionStatusCircleProps = {
    status: SubmissionStatus;
    highContrast?: boolean;
    size?: 'regular' | 'small';
};

SubmissionStatusCircle.defaultProps = {
    highContrast: false,
    size: 'regular',
};

export default SubmissionStatusCircle;
