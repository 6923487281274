import React from 'react';
import useStyles from './styles';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Chip,
    DialogActions,
    Button,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { CATEGORIES } from '../../lib/graphql/queries/categories';
import { PostCategories, PostCategory } from 'types/post';

const FilterCategoriesModal: React.FC<FilterCategoriesModalProps> = (props) => {
    const classes = useStyles();

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { isOpen, onClose, selectedCategories, setCategories, onClear } = props;

    const [stagedCategories, setStagedCategories] = React.useState(selectedCategories);

    React.useEffect(() => {
        setStagedCategories(selectedCategories);
    }, [selectedCategories]);

    const { data } = useQuery(CATEGORIES, {
        notifyOnNetworkStatusChange: true,
    });

    const categories: PostCategories = data && data.categories ? data.categories : [];

    const isCategorySelected = (categoryId: string) => {
        const categoryIndex = stagedCategories.findIndex((currentCategory) => {
            return currentCategory.categoryId === categoryId;
        });
        return categoryIndex;
    };

    const handleChipClick = (category: PostCategory) => {
        const categoryIndex = isCategorySelected(category.categoryId);
        if (categoryIndex === -1) stagedCategories.push(category);
        else stagedCategories.splice(categoryIndex, 1);

        setStagedCategories([...stagedCategories]);
    };

    const handleSave = () => {
        setCategories(stagedCategories);
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Filter Categories</DialogTitle>
            <DialogContent>
                {categories.map((category) => {
                    const isSelected = isCategorySelected(category.categoryId);
                    return (
                        <Chip
                            key={category.name}
                            color={isSelected === -1 ? 'default' : 'primary'}
                            className={classes.chip}
                            label={category.name}
                            onClick={() => handleChipClick(category)}
                        />
                    );
                })}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button color="secondary" onClick={onClear}>
                    {!mobile ? 'Clear Categories' : 'Clear'}
                </Button>
                <div className={classes.buttonSet}>
                    <Button color="primary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleSave}>
                        Apply
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

interface FilterCategoriesModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedCategories: PostCategories;
    setCategories: (categories: PostCategories) => void;
    onClear: () => void;
}

export default FilterCategoriesModal;
