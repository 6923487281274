import { reduceOpacity } from '@elevatormedia/duffel-bag';

import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        defaultBox: {
            '&:hover': {
                backgroundColor: reduceOpacity(theme.palette.primary.main, 0.15),
                cursor: 'pointer',
                border: `2px solid ${theme.palette.primary.main}`,
            },
        },
        deselectedBox: {
            border: `1px solid ${reduceOpacity(theme.palette.secondary.main, 0.05)}`,
            color: theme.palette.text.textSecondary,
            backgroundColor: reduceOpacity(theme.palette.primary.light, 0.05),
        },
        selectedBox: {
            border: `2px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
            backgroundColor: reduceOpacity(theme.palette.primary.main, 0.05),
        },
        warningBox: {
            borderLeft: `4px solid ${theme.palette.custom.darkYellow}`,
            backgroundColor: theme.palette.custom.lightYellow,
            padding: theme.spacing(2),
            borderRadius: theme.spacing(1),
            color: theme.palette.custom.black60,
        },
    }),
);

export default useStyles;
