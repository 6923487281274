import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        linearProgressContainer: {
            width: '80%',
        },

        // Track (Progress Background)
        track: {
            height: '5px',
            width: '100%',
            opacity: 0.5,
            backgroundColor: 'white',
        },

        // Indicator (Progress Bar)
        indicator: {
            height: '5px',
            opacity: 1,
        },

        // Bar Colors
        noInput: {
            backgroundColor: theme.palette.passwordStrengths.noInput,
        },
        veryWeak: {
            backgroundColor: theme.palette.passwordStrengths.veryWeak,
        },
        weak: {
            backgroundColor: theme.palette.passwordStrengths.weak,
        },
        average: {
            backgroundColor: theme.palette.passwordStrengths.average,
        },
        good: {
            backgroundColor: theme.palette.passwordStrengths.good,
        },
        excellent: {
            backgroundColor: theme.palette.passwordStrengths.excellent,
        },

        // Bar widths
        0: {
            width: '0%',
        },
        20: {
            width: '20%',
        },
        40: {
            width: '40%',
        },
        60: {
            width: '60%',
        },
        80: {
            width: '80%',
        },
        100: {
            width: '100%',
        },
    }),
);

export default useStyles;
