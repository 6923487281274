import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

const CardSectionHeader: React.FC<CardSectionHeaderProps> = (props) => {
    const { sectionTitle } = props;
    const classes = useStyles();

    return (
        <div className={classes.sectionHeaderContainer}>
            <Typography variant={'h6'}>{sectionTitle}</Typography>
        </div>
    );
};

interface CardSectionHeaderProps {
    sectionTitle: string;
}

export default CardSectionHeader;
