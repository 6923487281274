import React from 'react';
import useStyles from './styles';
import PermissionAlert from 'atoms/PermissionAlert';

/* Spacing component to prevent appbar from overlapping content */
const AppbarSpacer: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.root} />
            <PermissionAlert />
        </>
    );
};

export default AppbarSpacer;
