import { SubmissionStatus, SUBMISSION_STATUSES } from '@elevatormedia/duffel-bag';
import useStyles from './styles';

export const getBackgroundColor = (
    status: SubmissionStatus,
    classes: ReturnType<typeof useStyles>,
) => {
    switch (status) {
        case SUBMISSION_STATUSES.APPROVAL:
            return classes.approvalColor;
        case SUBMISSION_STATUSES.APPROVED:
            return classes.approvedColor;
        case SUBMISSION_STATUSES.COMPLETED:
            return classes.completedColor;
        case SUBMISSION_STATUSES.REJECTED:
            return classes.rejectedColor;
        case SUBMISSION_STATUSES.REVIEW:
            return classes.reviewColor;
        default:
            return classes.statusContainer;
    }
};
