import React from 'react';
import { Tooltip } from '@material-ui/core';
import useStyles from './styles';
import { Flag } from 'mdi-material-ui';

/**
 * Convenience Component: Renders a special icon assigned to a Submission
 * that has been flagged
 */
const FlaggedBadge: React.FC = () => {
    const classes = useStyles();

    return (
        <Tooltip title={'This submission has been flagged'}>
            <div className={classes.backgroundContainer}>
                <Flag className={classes.chipIcon} />
            </div>
        </Tooltip>
    );
};

export default FlaggedBadge;
