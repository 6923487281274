import React from 'react';
import { Media } from '@elevatormedia/pitch-hooks/types/schema';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { Replay } from '@material-ui/icons';
import clsx from 'clsx';
import { ROUTES } from 'config/Nav';
import { useRouter } from 'next/router';
import { BaseRouteConfig } from 'types/nav';
import useStyles from './styles';

const UserChip = ({ username, avatar, deleted, onRemoveToggle }: UserChipProps) => {
    const classes = useStyles();
    const router = useRouter();

    return (
        <Chip
            className={clsx(classes.userChip, {
                [classes.deletedChip]: deleted,
            })}
            onClick={() =>
                router.push((ROUTES.users.user as BaseRouteConfig).getPath(username))
            }
            avatar={
                <Avatar src={avatar?.sourceUrl} srcSet={avatar?.sourceSet} alt={username}>
                    {username[0].toUpperCase()}
                </Avatar>
            }
            deleteIcon={deleted ? <Replay /> : undefined}
            label={username}
            onDelete={onRemoveToggle}
        />
    );
};

export type UserChipProps = {
    username: string;
    avatar?: Pick<Media, 'sourceUrl' | 'sourceSet'>;
    deleted?: boolean;
    onRemoveToggle?: () => void;
};

export default UserChip;
