import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { EnhancedDropdown, Searchbar } from 'molecules';
import {
    Media,
    Loading,
    GqlResponse,
    SupportedImageFormat,
    UPLOAD_IMAGE,
} from '@elevatormedia/duffel-bag';
import {
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Paper,
    TablePagination,
    Typography,
    useMediaQuery,
    Button,
    Tooltip,
} from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import { ROUTES } from 'config/Nav';
import { MEDIA_LIST } from 'lib/graphql/queries/media';
import { reportError } from 'lib/errors';
import { useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

const FILE_TO_MIME: Record<string, SupportedImageFormat | undefined> = {
    '.jpg': 'image/jpg',
    '.jpeg': 'image/jpeg',
    '.png': 'image/png',
};

const fileTypeOptions = Object.values(FILE_TO_MIME);

export interface MediaLibraryProps {
    onClickMedia: (media: Media) => void;
}

const MediaLibrary = React.forwardRef<HTMLDivElement, MediaLibraryProps>(
    ({ onClickMedia }, ref) => {
        const classes = useStyles();
        const isSm = useMediaQuery('(max-width:600px)');

        const [media, setMedia] = useState<Media[]>([]);
        const [count, setCount] = useState(100);
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(50);
        const [fileFilter, setFileFilter] = useState<string>('All');
        const [search, setSearch] = useState<string>('');

        const router = useRouter();
        const isModal = router.pathname !== ROUTES.media.to;

        const { enqueueSnackbar, closeSnackbar } = useSnackbar();

        const newMediaRef = React.useRef<HTMLInputElement>(null);

        const [uploadImage] = useMutation(UPLOAD_IMAGE, {
            refetchQueries: [
                {
                    query: MEDIA_LIST,
                    variables: {
                        limit: rowsPerPage,
                        skip: page * rowsPerPage,
                        where: {
                            mimetype: fileFilter === 'All' ? undefined : fileFilter,
                        },
                        sort: {
                            field: 'createdAt',
                            descending: true,
                        },
                        search,
                    },
                },
            ],
        });

        const { data, loading } = useQuery<{ media: Array<GqlResponse<Media>> }>(
            MEDIA_LIST,
            {
                notifyOnNetworkStatusChange: true,
                variables: {
                    limit: rowsPerPage,
                    skip: page * rowsPerPage,
                    where: {
                        mimetype: fileFilter === 'All' ? undefined : fileFilter,
                    },
                    sort: {
                        field: 'createdAt',
                        descending: true,
                    },
                    search,
                },
            },
        );

        useEffect(() => {
            if (data && data.media) {
                setCount(data.media.length > 0 ? data.media[0].count : 0);
                setMedia(data.media);
            }
        }, [data]);

        const handleChangePage = (
            _event: React.MouseEvent<HTMLButtonElement> | null,
            newPage: number,
        ) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const handleChangeFileType = (value: React.SetStateAction<string>) => {
            setFileFilter(value);
        };

        const handleSearch = ({ key, target }: React.KeyboardEvent<HTMLInputElement>) => {
            if (key === 'Enter') {
                setPage(0);
                setSearch((target as HTMLInputElement).value);
            }
        };

        const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = ({
            target: { value },
        }) => {
            if (value === '' || typeof value === 'undefined') {
                setSearch('');
            }
            setSearch(value);
        };

        const onAddNewMediaClick = () => document.getElementById('new-media').click();

        const onNewMedia = async (event: React.ChangeEvent<HTMLInputElement>) => {
            event.preventDefault();

            const file = event.target.files[0];

            if (!file) return;

            const uploadSnackbarKey = enqueueSnackbar('Uploading media asset...', {
                variant: 'info',
                persist: true,
                preventDuplicate: true,
            });

            try {
                await uploadImage({
                    variables: {
                        file,
                    },
                });

                closeSnackbar(uploadSnackbarKey);

                enqueueSnackbar('Media asset uploaded successfully', {
                    variant: 'success',
                    persist: false,
                    preventDuplicate: true,
                });
            } catch (err) {
                reportError(err);

                closeSnackbar(uploadSnackbarKey);

                enqueueSnackbar('Error uploading media asset', {
                    variant: 'error',
                    persist: false,
                    preventDuplicate: true,
                });
            } finally {
                newMediaRef.current.value = '';
            }
        };

        return (
            <Paper
                className={clsx(classes.paper, isModal && classes.modalMargin)}
                ref={ref}
            >
                <div className={classes.headerContainer}>
                    <Typography variant="h5">Elevator Media Library</Typography>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        size={'medium'}
                        onClick={onAddNewMediaClick}
                    >
                        Add New
                        <input
                            id={'new-media'}
                            type={'file'}
                            style={{ display: 'none' }}
                            onChange={onNewMedia}
                            ref={newMediaRef}
                        />
                    </Button>
                </div>
                <div className={classes.mediaContents}>
                    <div className={classes.toolBar}>
                        <div className={classes.searchBarContainer}>
                            <Searchbar
                                onKeyDown={handleSearch}
                                onChange={onSearchChange}
                                value={search}
                            />
                        </div>
                        <EnhancedDropdown
                            dropdownLabel="Filter File Type"
                            selectedValue={fileFilter}
                            dropdownOptions={['All', ...fileTypeOptions]}
                            handleSelectedValueChange={handleChangeFileType}
                        />
                    </div>
                    {loading ? (
                        <Loading />
                    ) : (
                        <ImageList rowHeight={180} cols={isSm ? 1 : 3}>
                            {media.map((item) => {
                                const filename =
                                    item.metadata?.filename || 'Missing Filename';
                                const toolTipInfo = (
                                    <div>
                                        <b>Filename:</b> {filename}
                                        <br />
                                        <b>File path:</b> {item.path}
                                        <br />
                                        <b>File type:</b>{' '}
                                        {item.metadata.mimetype || 'unknown'}
                                    </div>
                                );

                                return (
                                    <ImageListItem
                                        key={item.mediaId}
                                        onClick={() => onClickMedia(item)}
                                    >
                                        <img src={item.sourceUrl} alt={item.path} />
                                        <ImageListItemBar
                                            className={classes.imageItemBar}
                                            title={filename}
                                            position={'top'}
                                            actionIcon={
                                                <Tooltip title={toolTipInfo}>
                                                    <Info className={classes.infoIcon} />
                                                </Tooltip>
                                            }
                                            actionPosition={'right'}
                                        />
                                    </ImageListItem>
                                );
                            })}
                        </ImageList>
                    )}
                </div>
                <TablePagination
                    component="div"
                    count={count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        );
    },
);

// Add a display name for the forwardRef component
MediaLibrary.displayName = 'MediaLibrary';

export default MediaLibrary;
