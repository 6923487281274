import { reduceOpacity } from '@elevatormedia/duffel-bag';

import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flexRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
        },
        alignCenter: {
            alignItems: 'center',
        },
        editTimeButton: {
            '&:hover': {
                backgroundColor: reduceOpacity(
                    theme.palette.custom.orange.hazardCone,
                    0.08,
                ),
            },
        },
        calendarIconContainer: {
            paddingTop: '2px',
            paddingRight: theme.spacing(1),
        },
        dateTimeRowContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        notificationBoxIcon: {
            color: theme.palette.custom.orange.hazardCone,
            fontSize: theme.typography.pxToRem(16),
        },
    }),
);

export default useStyles;
