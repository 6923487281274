import React from 'react';
import useStyles from './styles';
import clsx from 'clsx';

const NotificationBox: React.FC<NotificationBoxProps> = (props) => {
    const classes: any = useStyles();
    const { variant = 'info', children } = props;

    return (
        <div className={classes.root}>
            <div
                className={clsx(classes.notificationBox, classes[`${variant}VariantBox`])}
            >
                {children}
            </div>
        </div>
    );
};

interface NotificationBoxProps {
    variant: 'info' | 'warning' | 'error';
}

export default NotificationBox;
