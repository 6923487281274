import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        /*******************
         * DATA ROW STYLES *
         *******************/
        root: {
            minHeight: '80px',
            paddingBottom: theme.spacing(2),
            minWidth: '30vw',
        },
        dataFieldTitleContainer: {
            paddingTop: theme.spacing(2),
        },
        dataFieldValueContainer: {
            paddingTop: theme.spacing(2),
            width: '30vw',
            [theme.breakpoints.down('xs')]: {
                width: '90vw',
            },
            overflowWrap: 'break-word',
            wordWrap: 'break-word', // for older IE and Edge
        },
        dataFieldTitle: {
            minWidth: theme.spacing(16),
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            '-ms-word-break': 'break-all',
            fontSize: '0.75rem',
            textTransform: 'uppercase',
            color: theme.palette.text.secondary,
        },
        bottomBorder: {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        dataFieldValue: {
            fontSize: '0.90rem',
        },
        dataFieldValueBolded: {
            fontWeight: 'bold',
        },
        popover: {
            pointerEvents: 'none',
            padding: 0,
            margin: 0,
            height: '500px',
        },
        previewButton: {
            color: theme.palette.primary.main,
            fontSize: theme.typography.pxToRem(18),
        },
        previewButtonContainer: {
            paddingLeft: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
        },
        imagePreview: {
            maxHeight: '500px',
            width: 'auto',
        },
        imagePreviewMobile: {
            maxHeight: '200px',
            [theme.breakpoints.down('xs')]: {
                maxHeight: '120px',
            },
        },
        creditSocialMediaIndent: {
            marginLeft: theme.spacing(2),
        },
        link: {
            color: theme.palette.primary.main,
        },

        /***************************
         * TRASNACTION CARD STYLES *
         ***************************/
        transactionCard: {
            maxWidth: '400px',
            minWidth: '300px',
            [theme.breakpoints.down('xs')]: {
                maxWidth: '300px',
            },
            marginTop: theme.spacing(1),
        },
        transactionCardContent: {
            padding: theme.spacing(2),
        },
        addOn: {
            marginLeft: theme.spacing(2),
            fontSize: theme.typography.pxToRem(13.5),
        },
        addOnCost: {
            marginRight: theme.spacing(1),
            fontSize: theme.typography.pxToRem(13.5),
        },
        grandTotalContainer: {
            minHeight: '35px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        boldedTextEmphasis: {
            fontWeight: 'bold',
            paddingLeft: theme.spacing(1),
        },
        transactionCardField: {
            paddingBottom: theme.spacing(1),
        },
        createRefundButton: {
            marginTop: theme.spacing(2),
        },
    }),
);

export default useStyles;
