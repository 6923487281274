import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';

let releaseStage = 'development';

if (process.env.cookieDomain === 'elevatormag.com') releaseStage = 'production';
else if (process.env.cookieDomain === 'efflux.media') releaseStage = 'testing';

const bugsnagClient = bugsnag({
    apiKey: process.env.bugsnagApiKey,
    appVersion: require('../../../package.json').version,
    notifyReleaseStages: ['testing', 'production'],
    releaseStage,
});

bugsnagClient.use(bugsnagReact, React);

export default bugsnagClient;
