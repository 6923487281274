import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { SpotterCustom } from 'types';

const palette: PaletteOptions<SpotterCustom> = {
    primary: {
        main: '#3F51B5',
        light: '#7986CB',
        dark: '#1665D8',
        contrastText: '#FFFFFF',
    },
    secondary: {
        main: '#F50057',
        light: '#FF4081',
    },
    text: {
        hint: '#BDBDBD',
    },
    success: {
        main: '#26A69A',
        light: '#81C784',
        dark: '#00A000',
    },
    error: {
        main: '#F44336',
        light: '#E57373',
        dark: '#D32F2F',
    },
    background: {
        default: '#F8F9FA',
        paper: '#FFFFFF',
    },
    common: {
        white: '#FFFFFF',
    },
    custom: {
        opaqueWhite: 'rgba(255, 255, 255, 0.50)',
        black80: 'rgba(0, 0, 0, 0.80)',
        black60: 'rgba(0, 0, 0, 0.60)',
        black50: 'rgba(0, 0, 0, 0.50)',
        blueGrey: '#F8F9FA',
        paleRed: 'rgba(255, 82, 82, 0.1)',
        darkYellow: '#FFC107',
        lightYellow: '#FFF9C4',
        paleGrey: '#F9FAFB',
        orange: {
            hazardCone: '#fb7d1f',
            huntingVest: '#F68929',
            light: '#ffebdc',
            dark: '#663C00',
        },
        grey: {
            g150: '#D5D5D5',
            g250: '#AAAAAA',
            g750: '#303030',

            g200: '#EEEEEE',
            g300: '#E0E0E0',
            g400: '#BDBDBD',
            g500: '#9E9E9E',
            g600: '#757575',
            g700: '#616161',
            g800: '#424242',
            g900: '#212121',
        },
        background: {
            success: '#EDF7EE',
            error: '#FDECEB',
        },
    },
    passwordStrengths: {
        noInput: '#E4E7EB',
        veryWeak: '#F44336',
        weak: '#F44336',
        average: '#FFC107',
        good: '#00E676',
        excellent: '#00E676',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    action: {
        hover: '#E8F0FE',
        active: '#E8F0FE',
    },
    submissionStatuses: {
        review: '#FFB300',
        approval: '#4DD0E1',
        approved: '#1E88E5',
        completed: '#009688',
        rejected: '#F44336',
    },
    campaignStatus: {
        review: '#FFB300',
        approve: '#4DD0E1',
        hold: '#3a3b3f',
        draft: '#1E88E5',
        pending: '#FFB300',
        revise: '#FFB300',
        scheduled: '#FC7D1F',
        running: '#18C623',
        completed: '#009688',
        cancelled: '#F44336',
    },
};

export default palette;
