import useStyles from './styles';
import { CampaignStatus, CAMPAIGN_STATUSES_MAP } from '@elevatormedia/duffel-bag';

export const getBackgroundColor = (
    status: CampaignStatus,
    classes: ReturnType<typeof useStyles>,
) => {
    switch (status) {
        case CAMPAIGN_STATUSES_MAP.REVIEW:
            return classes.reviewColor;
        case CAMPAIGN_STATUSES_MAP.APPROVE:
            return classes.approveColor;
        case CAMPAIGN_STATUSES_MAP.DRAFT:
            return classes.draftColor;
        case CAMPAIGN_STATUSES_MAP.HOLD:
            return classes.holdColor;
        case CAMPAIGN_STATUSES_MAP.PENDING:
            return classes.pendingColor;
        case CAMPAIGN_STATUSES_MAP.REVISE:
            return classes.reviseColor;
        case CAMPAIGN_STATUSES_MAP.SCHEDULED:
            return classes.scheduledColor;
        case CAMPAIGN_STATUSES_MAP.RUNNING:
            return classes.runningColor;
        case CAMPAIGN_STATUSES_MAP.COMPLETED:
            return classes.completedColor;
        case CAMPAIGN_STATUSES_MAP.CANCELLED:
            return classes.cancelledColor;
        default:
            return classes.statusContainer;
    }
};
