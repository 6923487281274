import React from 'react';
import { Typography, Divider } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';

const SectionHeader: React.FC<SectionHeaderProps> = ({
    children,
    textColor = 'default',
}) => {
    // Need to use any to avoid issues with template string in classes
    const classes: any = useStyles();

    return (
        <div className={classes.root}>
            <Typography
                variant={'subtitle1'}
                className={clsx(classes.headerText, classes[`${textColor}Text`])}
                gutterBottom
            >
                {children}
            </Typography>
            <Divider
                variant={'fullWidth'}
                className={clsx(classes.line, classes[`${textColor}Line`])}
            />
        </div>
    );
};

interface SectionHeaderProps {
    textColor?: 'red' | 'default' | 'primary';
}

export default SectionHeader;
