import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2, 0),
            width: '100%',
        },
        optionRow: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        optionText: {
            maxWidth: '75%',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '45%',
            },
            width: '100%',
        },
        dangerBox: {
            borderRadius: '5px',
            backgroundColor: theme.palette.custom.paleRed,
            padding: theme.spacing(1),
        },
    }),
);

export default useStyles;
