import CampaignStatusCircle from './CampaignStatusCircle';
import { getBackgroundColor as getCampaignBackgroundColor } from './StatusColors';
import CampaignStatusChip from './CampaignStatusChip';
import getStatusIcon from './StatusIcon';

const getCampaignStatusIcon = getStatusIcon;

export {
    CampaignStatusCircle,
    getCampaignBackgroundColor,
    CampaignStatusChip,
    getCampaignStatusIcon,
};
