import gql from 'graphql-tag';

export const CATEGORIES = gql`
    query {
        categories {
            categoryId
            slug
            name
            description
            createdAt
            updatedAt
        }
    }
`;
