import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filterTabs: {
            maxHeight: '30px',
        },
        tab: {
            textTransform: 'none',
            fontSize: theme.typography.pxToRem(14),
        },
        indicator: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',

            '& > div': {
                height: '2px',
                width: '50%',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                backgroundColor: theme.palette.primary.main,
            },
        },
    }),
);

export default useStyles;
