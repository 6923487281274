import { Modal } from '@material-ui/core';
import React from 'react';
import MediaLibrary, { MediaLibraryProps } from '.';
import useStyles from './styles';

type MediaModalProps = {
    open: boolean;
    handleClose: () => void;
} & MediaLibraryProps;

const MediaModal: React.FC<MediaModalProps> = ({
    open,
    handleClose,
    ...libraryProps
}) => {
    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={handleClose}
            className={classes.modal}
            aria-labelledby="elevator-media-library"
        >
            <MediaLibrary {...libraryProps} />
        </Modal>
    );
};

export default MediaModal;
