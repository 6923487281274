import React from 'react';
import { TableRow } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';

const EnhancedRow: React.FC<EnhancedRowProps> = ({
    flagged,
    handleClick,
    isSelected,
    children,
    className,
}) => {
    const classes = useStyles();

    return (
        <TableRow
            hover // Enables color shift onHover
            aria-checked={isSelected}
            selected={isSelected} // Adds selected styling
            onClick={handleClick}
            className={clsx(flagged && classes.flaggedStatus, className)}
        >
            {children}
        </TableRow>
    );
};

interface EnhancedRowProps {
    isSelected?: boolean;
    handleClick?: React.MouseEventHandler<HTMLTableRowElement>;
    flagged?: boolean;
    className?: string;
}

export default EnhancedRow;
