import React from 'react';
import useStyles from './styles';
import { GridContainer, GridItem } from '@elevatormedia/duffel-bag';
import { MenuItem, Button, FormControl, InputLabel, Select } from '@material-ui/core';

const ImageResDownloader = <T extends ImageResDownloaderVariant>(
    props: ImageResDownloaderProps<T>,
) => {
    const classes = useStyles();
    const { type, selectedImageName, setSelectedImageName, imageOptions, source } = props;

    return (
        <GridContainer
            className={classes.exportContainer}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <GridItem>
                {type === 'files' && (
                    <FormControl variant={'outlined'}>
                        <InputLabel id={'imageSelectLabel'}>Image</InputLabel>
                        <Select
                            className={classes.dropdown}
                            label={'Image'}
                            id={'imageSelect'}
                            name={'imageSelect'}
                            labelId={'imageSelectLabel'}
                            variant={'outlined'}
                            fullWidth
                            value={selectedImageName || ''}
                            defaultValue={imageOptions[0]}
                        >
                            {imageOptions.map((image, index) => (
                                <MenuItem
                                    key={index}
                                    value={image}
                                    onClick={() => setSelectedImageName(image)}
                                >
                                    {image}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </GridItem>
            <GridItem>
                <Button
                    className={classes.downloadButton}
                    variant={'contained'}
                    color={'primary'}
                    component={'a'}
                    href={source}
                    target={'_blank'}
                    download
                >
                    Download
                </Button>
            </GridItem>
        </GridContainer>
    );
};

export type ImageResDownloaderVariant = 'file' | 'files';

export interface ImageResDownloaderProps<T extends ImageResDownloaderVariant> {
    type?: ImageResDownloaderVariant;
    selectedImageName?: T extends 'files' ? string : never;
    setSelectedImageName?: T extends 'files' ? any : never;
    imageOptions?: T extends 'files' ? string[] : never;
    source?: string;
}

ImageResDownloader.defaultProps = {
    type: 'file',
};

export default ImageResDownloader;
