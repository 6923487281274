import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        chip: {
            borderRadius: 0,
        },
        chipListContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        chipContainer: {
            paddingRight: theme.spacing(1),
        },
        checkmark: {
            color: theme.palette.common.white,
        },
    });

export default makeStyles(styles);
