import React from 'react';
import useStyles from './styles';
import { PostAuthor, SEARCH_STAFF_USERS } from '@elevatormedia/duffel-bag';
import {
    Avatar,
    Chip,
    DialogContentText,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { Searchbar } from 'molecules/Searchbar';

const PostDialog = (props: PostDialogProps) => {
    const classes = useStyles();

    const { searchInput, onSearchChange, authors, addAuthor, removeAuthor } = props;

    const { data, error } = useQuery(SEARCH_STAFF_USERS, {
        notifyOnNetworkStatusChange: true,
        variables: {
            input: searchInput,
        },
    });

    const searchResults = data && data.searchUsers ? data.searchUsers : [];

    return (
        <>
            <div className={classes.currentAuthorsContainer}>
                <DialogContentText>Current</DialogContentText>
                <div>
                    {authors.length != 0 &&
                        authors.map((author, index) => (
                            <Chip
                                key={index}
                                label={author.preferredUsername}
                                avatar={
                                    <Avatar
                                        src={author.avatar && author.avatar.sourceUrl}
                                        srcSet={author.avatar && author.avatar.sourceSet}
                                    >
                                        {author.preferredUsername[0].toUpperCase()}
                                    </Avatar>
                                }
                                onDelete={() => removeAuthor(index)}
                                className={classes.currentAuthorChip}
                            />
                        ))}
                    {authors.length === 0 && (
                        <Typography className={classes.noAuthorsText}>
                            No authors
                        </Typography>
                    )}
                </div>
            </div>
            <div>
                <DialogContentText>Search</DialogContentText>
                <Searchbar
                    name={'searchInput'}
                    placeholder={'Search authors...'}
                    value={searchInput}
                    onChange={onSearchChange}
                />
                <List>
                    {data?.searchUsers &&
                        (data.searchUsers as PostAuthor[]).map((user, index) => (
                            <ListItem
                                key={index}
                                button
                                onClick={() => addAuthor(user)}
                                divider={index !== searchResults.length - 1}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        alt={`${user.preferredUsername}'s avatar`}
                                        src={user.avatar && user.avatar.sourceUrl}
                                        srcSet={user.avatar && user.avatar.sourceSet}
                                        className={classes.searchResultAvatar}
                                    >
                                        {user.preferredUsername[0].toUpperCase()}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>{user.preferredUsername}</ListItemText>
                            </ListItem>
                        ))}
                    {(data?.searchUsers || []).length === 0 && searchInput.length > 3 && (
                        <Typography className={classes.noAuthorsText}>
                            No users found
                        </Typography>
                    )}
                    {error && (
                        <Typography className={classes.noAuthorsText} color={'error'}>
                            Error searching for users
                        </Typography>
                    )}
                </List>
            </div>
        </>
    );
};

export interface PostDialogProps {
    searchInput: string;
    onSearchChange: React.ChangeEventHandler<HTMLInputElement>;
    authors: PostAuthor[];
    addAuthor: (user: PostAuthor) => void;
    removeAuthor: (index: number) => void;
}

export default PostDialog;
