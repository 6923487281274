import React from 'react';
import useStyles from './styles';
import { Typography } from '@material-ui/core';
import { GridContainer, GridItem, SocialMedia } from '@elevatormedia/duffel-bag';
import ImageResDownloader from '../ImagResDownloader';
import SocialMediaAccountLink from '../../atoms/SocialMediaAccountLink';
import CONTENT_TYPES, { ContentType } from '../../config/DataFieldTypes';
import clsx from 'clsx';
import { SubmissionCreditList } from '..';
import { Credit } from 'types/credits';

/**
 * Data Row: Renders various types of information related to a single submission.
 * Note that this component requires contentType as a prop.
 */
const DataRow: React.FC<DataRowProps> = ({
    label,
    imageProps,
    children,
    sourceSet,
    addBottomBorder = true,
    contentType = CONTENT_TYPES.LINES,
    values = [],
    hrefs = [],
    credits = [],
    socialMedia = [],
}) => {
    const classes = useStyles();

    const renderLinks = () => {
        return hrefs.map((linkValue, index) => (
            <GridItem key={index}>
                <a
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    href={linkValue}
                    className={clsx(classes.dataFieldValue, classes.link)}
                >
                    {linkValue}
                </a>
            </GridItem>
        ));
    };

    const renderImage = () => {
        // If image props were provided, render image preview
        const { source } = imageProps;
        return (
            <GridContainer direction={'column'} alignItems={'flex-start'}>
                <GridItem>
                    <a
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                        href={source}
                        className={classes.link}
                    >
                        (Open External)
                    </a>
                </GridItem>

                <GridItem>
                    <img
                        src={source}
                        title="Attached Image"
                        className={classes.imagePreviewMobile}
                        alt="Image Preview"
                    />
                </GridItem>
                <ImageResDownloader source={source} />
            </GridContainer>
        );
    };

    const renderLinesOfText = () => {
        return values.map((value, index) => (
            <GridItem key={index}>
                <Typography
                    variant={'body1'}
                    color={'textPrimary'}
                    className={classes.dataFieldValue}
                >
                    {value}
                </Typography>
            </GridItem>
        ));
    };

    const renderCredits = () => {
        return (
            <GridItem key={'submission-credits'}>
                <SubmissionCreditList credits={credits} />
            </GridItem>
        );
    };

    const renderSocialMediaLinks = () => {
        if (socialMedia.length === 0)
            return (
                <GridItem key={'socials'}>
                    <Typography
                        variant={'body1'}
                        color={'textPrimary'}
                        component={'i'}
                        className={classes.dataFieldValue}
                    >
                        None
                    </Typography>
                </GridItem>
            );
        return socialMedia.map((socialMediaHandle, index) => (
            <GridItem key={index}>
                <SocialMediaAccountLink
                    type={socialMediaHandle.type}
                    link={socialMediaHandle.link}
                />
            </GridItem>
        ));
    };

    /**
     * Main content renderer method. Will use a subsequent render call based on the
     * the contentType prop value.
     */
    const renderValueContent = () => {
        const { LINKS, LINES, IMAGE, CREDITS, NODE, SOCIALS } = CONTENT_TYPES;

        switch (contentType) {
            case LINKS:
                return renderLinks();
            case LINES:
                return renderLinesOfText();
            case IMAGE:
                return renderImage();
            case CREDITS:
                return renderCredits();
            case SOCIALS:
                return renderSocialMediaLinks();
            case NODE:
                return children;
            default:
                console.error(`Unrecognized contentType: ${contentType}`);
                return null;
        }
    };

    return (
        <GridContainer
            direction={'row'}
            justifyContent={'flex-start'}
            className={clsx(classes.root, addBottomBorder && classes.bottomBorder)}
        >
            <GridItem className={classes.dataFieldTitleContainer}>
                <Typography className={classes.dataFieldTitle} variant={'h6'}>
                    {label}
                </Typography>
            </GridItem>
            <GridItem className={classes.dataFieldValueContainer}>
                <GridContainer spacing={1} direction={'column'}>
                    {renderValueContent()}
                </GridContainer>
            </GridItem>
        </GridContainer>
    );
};

// TODO dynamic props based off of contentType
interface DataRowProps {
    contentType?: ContentType;
    label: string;
    values?: string[];
    addBottomBorder?: boolean;
    hrefs?: string[];
    credits?: Credit[];
    imageProps?: { source: string };
    socialMedia?: SocialMedia;
    sourceSet?: string;
}

export default DataRow;
