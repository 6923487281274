import React from 'react';
import {
    Button,
    Divider,
    Dialog,
    useMediaQuery,
    Typography,
    useTheme,
} from '@material-ui/core';
import useStyles from './styles';
import PickerField from './PickerField';

const DialogSheetPicker: React.FC<DialogSheetPickerProps> = (props) => {
    const classes = useStyles();
    const { sheetOpen, openSheet, closeSheet, fields } = props;

    const initialOptions = fields.map((field) => field.selectedOption);

    // Create a layer of separation between item selection and apply.
    const [stagedOptions, setStagedOptions] = React.useState(initialOptions);

    // Retrieve information about the current view dimensions
    const theme = useTheme();

    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    // Stages a new option
    const setNewStagedOptions = (newOption: string, index: number) => {
        stagedOptions[index] = newOption;
        setStagedOptions([...stagedOptions]);
    };

    // Makes filter option final by commiting and exiting bottomsheet
    const commitStagedOptions = () => {
        fields.forEach((field, index) => {
            if (field.selectedOption !== stagedOptions[index])
                field.setSelected(stagedOptions[index]);
        });
        closeSheet();
    };

    const renderFields = () => {
        return fields.map((field, index) => {
            return (
                <div className={classes.sectionContainer} key={index}>
                    <PickerField
                        label={field.label}
                        stagedOption={stagedOptions[index]}
                        options={field.options}
                        setStagedOption={(value) => setNewStagedOptions(value, index)}
                        valueToOption={field.valueToOption}
                    />
                    {field.getDescription && (
                        <Typography>
                            {field.getDescription(stagedOptions[index])}
                        </Typography>
                    )}
                </div>
            );
        });
    };

    return (
        <Dialog
            // anchor="bottom"
            fullScreen={mobile}
            open={sheetOpen}
            onClose={closeSheet}
            onEnter={openSheet}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            classes={{ paper: classes.root }}
        >
            <div className={classes.bottomDrawer}>
                {renderFields()}
                <div className={classes.sectionContainer}>
                    <Divider variant={'fullWidth'} />
                </div>
                <div className={classes.sectionContainer}>
                    <Button
                        onClick={() => commitStagedOptions()}
                        variant={'contained'}
                        fullWidth
                        color={'primary'}
                    >
                        Apply
                    </Button>
                    <div className={classes.buttonContainer}>
                        <Button
                            onClick={closeSheet}
                            variant={'contained'}
                            fullWidth
                            color={'default'}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

interface DialogSheetPickerProps {
    sheetOpen: boolean;
    openSheet: (node: HTMLElement, isAppearing: boolean) => void;
    closeSheet: () => void;
    fields: {
        label: string;
        setSelected: (selected: string) => void;
        selectedOption: string;
        options: string[];
        valueToOption?: Record<string, string>;
        getDescription?: (value: string) => string;
    }[];
}

export default DialogSheetPicker;
