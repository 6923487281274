import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        detailRowContainer: {
            minHeight: '30px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        titleCase: {
            textTransform: 'capitalize',
            color: 'inherit',
        },
        cardContent: {
            margin: theme.spacing(2),
            color: theme.palette.text.secondary,
        },
        authorsContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
        },
        editAuthorsIconButton: {
            fontSize: theme.typography.pxToRem(14),
            margin: theme.spacing(0.5, 0.5, 0.5, 1),
            '&:hover': {
                cursor: 'pointer',
            },
        },
        inLine: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        fadingUpdateContainer: {
            animation: '$fadeoutBackground 1.5s ease-out',
            borderRadius: '4px',
        },
        '@keyframes fadeoutBackground': {
            from: {
                backgroundColor: theme.palette.custom.grey.g200,
                color: theme.palette.primary.dark,
            },
            to: {
                backgroundColor: 'rgba(0, 200, 0, 0)',
                color: theme.palette.text.secondary,
            },
        },
        autoSaveIcon: {
            fontSize: theme.typography.pxToRem(16),
            marginTop: '2px',
            color: theme.palette.text.secondary,
        },
        rotate: {
            animation: '$rotation 2.5s infinite linear reverse',
        },
        '@keyframes rotation': {
            from: { transform: 'rotate(0deg)' },
            to: { transform: 'rotate(359deg)' },
        },
        savingStatus: {
            paddingLeft: theme.spacing(1),
        },
        shortLinkHover: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
    });

export default makeStyles(styles);
