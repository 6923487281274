import React from 'react';
import debounce from 'lodash.debounce';

/**
 * Adapated from:
 * https://www.synthace.com/autosave-with-react-hooks/
 *
 * Here we create a container component to debounce the save functionality
 * This is good for performance purposes. Rather than triggering a save at set
 * iterval, we "debounce" the callback such that subsequent calls are compounded
 * based on our 5000 milliseconds interval.
 */
export const DebouncedSave = (props: DebouncedSaveProps): null => {
    const { dependencies, dirty, isValid, saveCallback } = props;

    /**
     * The use of useCallBack functions similar to that of useEffect, but
     * memoizes the callback function so its not recreated every time it's called
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedDebounce = React.useCallback(debounce(saveCallback, 5000), []);

    /**
     * Whenever the dependencies obj changes, run the memoized debounced save
     * function with the current values of dirty and isValid
     */
    React.useEffect(() => {
        memoizedDebounce(dirty, isValid);
    }, [dependencies, dirty, isValid, memoizedDebounce]);

    return null;
};

export type DebouncedSaveProps = {
    dependencies: any;
    saveCallback: (...args: any) => void;
    dirty: boolean;
    isValid: boolean;
};
