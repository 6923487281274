import { PostStatus } from '@elevatormedia/duffel-bag';
import { PostEditorFormValues } from 'organisms/PostEditorForm';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { transformCustomEntitiesToHTML } from 'organisms/WYSIWYGEditor/Handlers/Handlers.utils';

const debug = (status: PostStatus, formikValues: PostEditorFormValues) => {
    const {
        title,
        description,
        slug,
        content,
        image,
        tags,
        selectedCategory,
        type,
        featuredPos,
    } = formikValues;

    // Get Raw HTML
    const rawContentState = convertToRaw(content.getCurrentContent());
    const resultingMarkUp = draftToHtml(
        rawContentState,
        null,
        null,
        transformCustomEntitiesToHTML,
    );

    const formattedStatus = 'Status:\n' + status;
    const formattedTitle = 'Title:\n' + title;
    const formattedDescription = 'Description:\n' + description;
    const formattedSlug = 'Slug:\n' + slug;
    const formattedContent = 'Content:\n' + resultingMarkUp;
    const formattedImage = 'Image:\n' + JSON.stringify(image, null, 4);
    const formattedTags = 'Tags:\n' + tags.map((tag) => `"${tag}"`).join('\t');
    const formattedSelectedCategory = 'Category:\n' + selectedCategory;
    const formattedType = 'Post Type:\n' + type;
    const formattedFeaturedPos = 'Featured Position:\n' + featuredPos;

    const formattedPostInfo = [
        formattedStatus,
        formattedType,
        formattedTitle,
        formattedDescription,
        formattedSlug,
        formattedContent,
        formattedImage,
        formattedTags,
        formattedSelectedCategory,
        formattedFeaturedPos,
    ].join('\n\n');

    return '```\n' + formattedPostInfo + '\n```';
};

export default debug;
