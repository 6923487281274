import { IconButton, Tooltip } from '@material-ui/core';
import { NotePlus, PageNext } from 'mdi-material-ui';
import React from 'react';
import { EnhancedColumn, EnhancedRow, EnhancedTable } from '..';
import { camelCaseToRegular, formatDateOnly } from '@elevatormedia/duffel-bag';
import useStyles from './styles';
import { TableSortOrder } from 'layouts/Posts/AllPosts/AllPostsContext';
import { WriteupQueueItem } from '../../layouts/Posts/WriteupQueue/WriteupQueue';

export type WriteupQueueTableProps = {
    serviceType: string;

    handleRowOnClick?: (row: WriteupQueueItem) => void;
    rows: Array<WriteupQueueItem>; // Default collection of rows
    rowCount: number;

    page: number;
    handleChangePage: (newPage: number) => void;
    rowsPerPage: number;
    handleChangeRowsPerPage: React.ChangeEventHandler<
        HTMLTextAreaElement | HTMLInputElement
    >;

    // Table functionality
    handleRequestSort: (property: string) => void;
    order: TableSortOrder;
    orderBy: string;
    loading: boolean;
} & (
    | {
          hideActionButtons: true;
          handleGoToSubmission?: never;
          startNewPost?: never;
          startNewPromotion?: never;
      }
    | {
          hideActionButtons?: false;
          startNewPost: (rowData: WriteupQueueItem) => void;
          startNewPromotion: (rowData: WriteupQueueItem) => void;
          handleGoToSubmission: (submissionId: string) => void;
      }
);

const WriteupQueueTable: React.FC<WriteupQueueTableProps> = (props) => {
    const classes = useStyles();
    const {
        serviceType,

        handleRowOnClick,
        handleGoToSubmission,
        rows, // Default collection of rows
        rowCount,

        page,
        handleChangePage,
        rowsPerPage,
        handleChangeRowsPerPage,

        // Table functionality
        startNewPost,
        startNewPromotion,

        handleRequestSort,
        order,
        orderBy,
        loading,
        hideActionButtons = false,
    } = props;

    /**
     * Utility func which builds a JS object config used in creating the data table.
     */
    const buildColumnConfig = () => {
        if (rows.length < 1) return;

        const columnNames = [
            'status',
            'title',
            'submittedBy',
            'submittedOn',
            'submissionType',
        ];
        const columnConfig = [];

        columnNames.map((columnName) => {
            columnConfig.push({
                id: columnName,
                label: camelCaseToRegular(columnName),
            });
        });

        if (!hideActionButtons) {
            columnConfig.push({
                id: 'Actions',
                label: '',
            });
        }
        // Append Actions Columns (Start new post) Config

        return columnConfig;
    };

    const renderStartWriteup = (rowData: WriteupQueueItem) => {
        const { onClick, label } =
            rowData.service.type === 'submission'
                ? {
                      onClick: () => startNewPost(rowData),
                      label: 'Post',
                  }
                : rowData.service.type === 'promotion'
                ? {
                      onClick: () => startNewPromotion(rowData),
                      label: 'Promotion',
                  }
                : { onClick: () => {}, label: null };

        if (!label) return null;

        return (
            <div className={classes.actionRowContainer}>
                <Tooltip title={`Start a ${label.toLowerCase()} from this submission`}>
                    <IconButton
                        onClick={onClick}
                        aria-label={`Start New ${label}`}
                        className={classes.addNewPostIcon}
                    >
                        <NotePlus />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const renderSeeSubmissionAction = ({ submissionId }: WriteupQueueItem) => {
        return (
            <div className={classes.actionRowContainer}>
                <Tooltip title={'Go to related submission'}>
                    <IconButton
                        onClick={() => handleGoToSubmission(submissionId)}
                        aria-label="See Related Submission"
                        className={classes.addNewPostIcon}
                    >
                        <PageNext />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    /**
     * Function to handle rendering column fields within a specific row.
     */
    const renderRow = (row: WriteupQueueItem, index: number) => {
        // Abstract common props used by EnhancedColumn component.
        const columnProps = {
            rowData: row,
            handleClick: handleRowOnClick
                ? () => handleRowOnClick(row)
                : row.service.type === 'submission'
                ? () => startNewPost(row)
                : row.service.type === 'promotion'
                ? () => startNewPromotion(row)
                : () => {},
            rowIndex: index,
        };

        // Manually render column information. Note the dynamic rendering of columns based on the view mode.
        return (
            <EnhancedRow key={index}>
                <EnhancedColumn {...columnProps}>
                    {row.post || row.promotion ? 'Started' : 'Not started'}
                </EnhancedColumn>
                <EnhancedColumn {...columnProps}>{row.contentTitle}</EnhancedColumn>
                <EnhancedColumn {...columnProps}>
                    {row.order.user.preferredUsername}
                </EnhancedColumn>
                <EnhancedColumn {...columnProps}>
                    {formatDateOnly(row.createdAt)}
                </EnhancedColumn>
                <EnhancedColumn {...columnProps}>{row.service.name}</EnhancedColumn>

                <EnhancedColumn padding={'checkbox'}>
                    <div className={classes.actionRowContainer}>
                        {renderStartWriteup(row)}
                        {renderSeeSubmissionAction(row)}
                    </div>
                </EnhancedColumn>
            </EnhancedRow>
        );
    };

    return (
        <EnhancedTable
            tableName={serviceType}
            disableSelectAll
            /*
             * Based on id, not label. Here, id is the key in the JS object.
             * IgnoreColumns are passed to the renderRow function.
             */
            ignoreColumns={[
                'submissionId',
                'submissionTitle',
                'attachedImage',
                'socialMedia',
                'description',
                'order',
                'credits',
                'submitterIPAddress',
                'terms',
                'history',
                'postTitle',
            ]}
            ignoreColumnsSort={[
                'status',
                'postTitle',
                'title',
                'submittedBy',
                'submittedOn',
                'submissionType',
            ]}
            columnHeaders={buildColumnConfig()}
            rows={rows}
            rowCount={rowCount}
            renderRow={renderRow}
            page={page}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            onRequestSort={handleRequestSort}
            orderBy={orderBy}
            order={order}
            loading={loading}
        />
    );
};

export default WriteupQueueTable;
