import { Theme, makeStyles, createStyles } from '@material-ui/core';
import VARIABLES from '../../theme/variables';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        content: {
            flexGrow: 1,
            width: '100%',
            justiftyContent: 'center',
            alignItems: 'center',
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        contentShift: {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: VARIABLES.sidebarWidth,
                width: `calc(100% - ${VARIABLES.sidebarWidth}px)`,
            },
        },
        contentShiftClosed: {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: VARIABLES.sidebarWidthClosed,
                width: `calc(100% - ${VARIABLES.sidebarWidthClosed}px)`,
            },
        },

        // Desktop Mini Drawer
        drawerOpen: {
            width: VARIABLES.sidebarWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: VARIABLES.sidebarWidthClosed,
        },
    }),
);

export default useStyles;
