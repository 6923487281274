import React, { MouseEventHandler, useEffect } from 'react';
import {
    AppBar,
    Avatar,
    CircularProgress,
    IconButton,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { Close as CloseIcon, Menu as HamburgerMenu } from '@material-ui/icons';
import clsx from 'clsx';
import AccountPopover from '../AccountPopover';
import ElevationScroll from './ElevationScroll';
import useStyles from './styles';
import { User } from '@elevatormedia/duffel-bag';
import { REDIRECTS } from 'config/Nav';
import { useRouter } from 'next/router';

/**
 * Appbar: Wrapping component around the Mui Appbar. This Component houses the navigation header at the top
 * of each screen.
 */
const StyledAppbar: React.FC<AppBarProps> = (props) => {
    const { route, sidebarIsOpen, onClosePress, toggleDrawer, user, isSubRoute } = props;
    const classes = useStyles();
    const { preferredUsername: username, email, avatar } = user;

    // Set up react state hooks
    const [anchorEl, setAnchorEl] = React.useState(null);

    // Retrieve information about the current view dimensions
    const theme = useTheme();

    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [navigating, setNavigating] = React.useState(false);

    const router = useRouter();

    useEffect(() => {
        const onNav = () => setNavigating(true);
        const onNavEnd = () => setNavigating(false);

        router.events.on('routeChangeStart', onNav);

        router.events.on('routeChangeComplete', onNavEnd);

        return () => {
            router.events.off('routeChangeStart', onNav);
            router.events.off('routeChangeComplete', onNavEnd);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Handler function for when the user clicks the avatar. Will set the accountPopOver component's
     * anchor ref point to the avatar.
     */
    const handleClick: MouseEventHandler = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <ElevationScroll>
            <AppBar
                position={'fixed'}
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: sidebarIsOpen,
                    [classes.appBarShiftClosed]: !sidebarIsOpen,
                })}
            >
                <Toolbar>
                    {isSubRoute && !mobile && (
                        <IconButton
                            color={'inherit'}
                            aria-label={'Close Page'}
                            onClick={onClosePress}
                            edge={'start'}
                            className={classes.menuButton}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                    {mobile && (
                        <a
                            className={classes.logoWrapper}
                            href={REDIRECTS.submissions.uri}
                        >
                            <img
                                src={'/static/img/elevator_logo_small.png'}
                                className={classes.brandLetteringAsset}
                                alt="Elevator Logo"
                            />
                        </a>
                    )}
                    <div className={classes.pageTitleContainer}>
                        <Typography variant={'h6'} noWrap>
                            {route}
                        </Typography>
                        {navigating && (
                            <CircularProgress
                                size={'30px'}
                                thickness={4}
                                className={classes.navigatingIndicator}
                            />
                        )}
                    </div>
                    <div className={classes.userNameRight}>
                        {!mobile && (
                            <Typography
                                variant="body2"
                                noWrap
                                color={'textSecondary'}
                                className={classes.userNameContainer}
                            >
                                {username}
                            </Typography>
                        )}

                        <IconButton
                            aria-label="Account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleClick}
                            color="inherit"
                        >
                            <Avatar
                                className={classes.avatar}
                                src={avatar && avatar.sourceUrl}
                            >
                                {username.toUpperCase()[0]}
                            </Avatar>
                        </IconButton>
                        <AccountPopover
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            userName={username}
                            email={email}
                            avatarURL={avatar && avatar.sourceUrl}
                        />
                    </div>

                    {mobile && (
                        <IconButton
                            color={'inherit'}
                            aria-label={'Open drawer'}
                            onClick={toggleDrawer}
                            edge={'start'}
                            className={classes.menuButton}
                        >
                            <HamburgerMenu />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
        </ElevationScroll>
    );
};

interface AppBarProps {
    route: string;
    sidebarIsOpen: boolean;
    onClosePress: () => void;
    user: User;
    isSubRoute: boolean;
    toggleDrawer: () => void;
}

export default StyledAppbar;
