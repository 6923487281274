import React from 'react';
import useStyles from './styles';
import * as Bowser from 'bowser';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography,
    Fade,
    IconButton,
    InputAdornment,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MomentUtils from '@date-io/moment';
import momentTimeZone from 'moment-timezone';
import NotificationBox from '../../atoms/NotificationBox';
import { FormikProps } from 'formik';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

// e.g., 08/05/2020, 02:12 PM
const LOCALE_FORMAT = 'YYYY-MM-DD[T]HH[:]mm';

// e.g., August 5, 2020, 02:12 PM CDT
const HUMAN_READABLE_FORMAT = 'MMMM D, YYYY, hh:mm A z';

// Create a transition component with the appropriate type
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<any>,
) {
    return <Fade ref={ref} {...props} />;
});

const DateTimePickerDialog: React.FC<DateTimePickerDialogProps & FormikProps<any>> = (
    props,
) => {
    const {
        // Formik
        values,
        touched,
        errors,
        handleSubmit,
        isValid,
        setFieldValue,
        setFieldTouched,
        resetForm,

        // Dialog
        variant,
        open,
        handleClose,
        canCancelScheduledPost,
        onCancelScheduledPostClick,
    } = props;
    const classes = useStyles();

    const browser = Bowser.getParser(window.navigator.userAgent);

    const handleDateChange = (date: MaterialUiPickersDate) => {
        setFieldTouched('dateTime', true);
        setFieldValue('dateTime', date);
    };

    const onChangeDateTime: React.ChangeEventHandler<
        HTMLTextAreaElement | HTMLInputElement
    > = (event) => {
        setFieldTouched('dateTime', true);
        setFieldValue('dateTime', new Date(event.target.value));
    };

    const toLocaleString = () => {
        return momentTimeZone(values.dateTime).format(LOCALE_FORMAT);
    };

    const toChicagoTimeString = () => {
        return momentTimeZone
            .tz(values.dateTime, 'America/Chicago')
            .format(HUMAN_READABLE_FORMAT);
    };

    const beforeExit = () => {
        resetForm();
    };

    const onSubmitClick = () => {
        handleSubmit();
    };

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="schedule-post-dialog"
                TransitionComponent={Transition as any} // Type assertion to any
                TransitionProps={{
                    onExit: beforeExit,
                }}
                keepMounted
            >
                <DialogTitle id="schedule-post-dialog">
                    {variant === 'schedule' ? 'Schedule' : 'Reschedule'} Post
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please select a date & time for this post to be published on. You
                        can continue to modify the publish date until the selected date.
                    </DialogContentText>
                    <div className={classes.textFieldGroupContainer}>
                        {browser.getBrowserName() == 'Firefox' ? (
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker
                                    id="datetime-local"
                                    label="Publish Date"
                                    className={classes.dateTimePicker}
                                    inputVariant={'outlined'}
                                    disableToolbar
                                    hideTabs
                                    format={LOCALE_FORMAT}
                                    helperText={touched.dateTime ? errors.dateTime : ''}
                                    value={values.dateTime}
                                    onChange={handleDateChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton color="inherit">
                                                    <DateRangeIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        ) : (
                            <TextField
                                id="datetime-local"
                                required
                                label="Publish Date"
                                type="datetime-local"
                                variant={'outlined'}
                                value={toLocaleString()}
                                onChange={onChangeDateTime}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                error={touched.dateTime && Boolean(errors.dateTime)}
                                helperText={touched.dateTime ? errors.dateTime : ''}
                            />
                        )}
                        {variant === 'reschedule' && canCancelScheduledPost && (
                            <div className={classes.buttonContainer}>
                                <Button
                                    variant={'text'}
                                    onClick={onCancelScheduledPostClick}
                                    className={classes.errorButton}
                                >
                                    Cancel Scheduled Post
                                </Button>
                            </div>
                        )}
                        <NotificationBox variant={'warning'}>
                            <Typography variant={'body2'} gutterBottom>
                                All scheduled posts are published relative to Chicago
                                time. Your post will be published on
                            </Typography>
                            <Typography variant={'subtitle2'}>
                                {toChicagoTimeString()}
                            </Typography>
                        </NotificationBox>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button
                        type={'submit'}
                        onClick={onSubmitClick}
                        disabled={!isValid}
                        color="primary"
                    >
                        {variant === 'schedule' ? 'Schedule' : 'Reschedule'}
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export interface DateTimePickerDialogProps {
    variant?: 'schedule' | 'reschedule';
    handleClose: () => void;
    canCancelScheduledPost?: boolean;
    onCancelScheduledPostClick?: () => void;
    open: boolean;
}

export default DateTimePickerDialog;
