import React, { useEffect } from 'react';
import {
    Box,
    Divider,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { bindHover, usePopupState } from 'material-ui-popup-state/hooks';
import { ChevronLeft, Menu as HamburgerMenu } from 'mdi-material-ui';
import { DrawerItemRoute, DRAWER_CONFIG, REDIRECTS } from '../../config/Nav';
import BottomModal from '../../molecules/BottomModal';
import Link from '../../atoms/Link';
import SubMenuPopover from '../../molecules/SubMenuPopover';
import clsx from 'clsx';
import useStyles from './styles';
import { useAuth } from '@elevatormedia/duffel-bag';
import { useRouter } from 'next/router';

/**
 * Sidebar component: Content which is rendered inside the of <Drawer /> component.
 */
const Sidebar: React.FC<SidebarProps> = (props) => {
    const { handleClose, open } = props;

    const classes = useStyles();
    const { permissionsMap: perms } = useAuth();

    const popupState = usePopupState({ variant: 'popover', popupId: 'subMenuPopover' });

    const [bottomDrawerOpen, setBottomDrawerOpen] = React.useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const router = useRouter();

    /**
     * close drawer on route change
     */
    useEffect(() => {
        if (isMobile) {
            if (open) handleClose();

            if (bottomDrawerOpen) setBottomDrawerOpen(false);
        }
    }, [router.pathname, isMobile, open, handleClose, bottomDrawerOpen]);

    const getDrawerOptions = () => {
        if (!perms) return [];

        return DRAWER_CONFIG.reduce((accRoutes, section) => {
            const { sectionTitle, routes, ...rest } = section;
            const titleKey = sectionTitle.toLowerCase();

            // Check that user has access to at least one of the routes in this
            // section, if not, remove entire section.
            if (perms[titleKey] && !perms[titleKey].access && sectionTitle.length > 0)
                return accRoutes;

            // Otherwise, filter out routes under this section that user
            // has access to.
            const verifiedRoutes = routes.filter((route) => {
                // ignore public routes like dashboard
                if (!route.permissions) return true;

                // generate array of permissions associates with routes
                const res = route.permissions.map(
                    (permission) => perms[permission.type][permission.operation],
                );
                // Only return those user has adequate permissions for
                return res.some((value) => value === true);
            });

            accRoutes.push({
                sectionTitle,
                routes: verifiedRoutes,
                ...rest,
            });

            return accRoutes;
        }, []);
    };

    const renderBrandHeader = () => {
        return (
            <Box>
                <div
                    className={clsx(
                        classes.headerContainer,
                        !open && classes.removeMargin,
                    )}
                >
                    <Box
                        display={'flex'}
                        flexGrow={1}
                        flexDirection={'row'}
                        alignItems={'center'}
                        paddingLeft={open ? 0 : '10px'}
                    >
                        {open ? (
                            <>
                                <a
                                    className={classes.logoWrapper}
                                    href={REDIRECTS.submissions.uri}
                                >
                                    <img
                                        src={'/static/img/elevator_logo_small.png'}
                                        className={classes.brandLetteringAsset}
                                        alt={'Elevator Logo'}
                                    />
                                </a>

                                <Typography
                                    variant={'subtitle1'}
                                    className={classes.brandName}
                                >
                                    ELEVATOR
                                </Typography>
                            </>
                        ) : (
                            <IconButton
                                onClick={handleClose}
                                className={classes.navButtons}
                            >
                                <HamburgerMenu />
                            </IconButton>
                        )}
                    </Box>

                    <IconButton onClick={handleClose}>
                        <ChevronLeft className={classes.navButtons} />
                    </IconButton>
                </div>
            </Box>
        );
    };

    const renderNavSections = () => {
        const sections = getDrawerOptions().map((section, index) => {
            const { sectionTitle, routes, useBottomDivider } = section;
            return (
                <div key={index}>
                    <div className={classes.listContainer}>
                        <List
                            component="div"
                            disablePadding
                            subheader={
                                sectionTitle.length < 1 ? null : (
                                    <ListSubheader
                                        disableGutters
                                        disableSticky
                                        className={clsx(classes.listSubheader, {
                                            [classes.listItemHidden]: !open,
                                            [classes.listItemCollapsed]: !open,
                                        })}
                                    >
                                        {sectionTitle}
                                        <Divider />
                                    </ListSubheader>
                                )
                            }
                        >
                            {renderNavLinkItems(routes)}
                        </List>
                        {useBottomDivider && (
                            <Divider className={classes.sectionDivider} />
                        )}
                    </div>
                </div>
            );
        });

        return sections;
    };

    const renderNavLinkItems = (routes: DrawerItemRoute[]) => {
        return routes.map(
            ({ to, uri, navLinkIcon, navLinkText, isExternal, subMenu }, index) => {
                let listItemProps = {};

                if (subMenu) {
                    listItemProps = {
                        component: Box,
                        ...(isMobile
                            ? { onClick: () => setBottomDrawerOpen(true) }
                            : { ...bindHover(popupState) }),
                    };
                } else {
                    if (isExternal) {
                        listItemProps = {
                            component: 'a',
                            href: uri,
                        };
                    } else {
                        listItemProps = {
                            component: Link,
                            href: to,
                            activeClassName: classes.listItemActive,
                        };
                    }
                }

                return (
                    <div key={index}>
                        <ListItem className={classes.listItem} {...listItemProps}>
                            <ListItemIcon className={classes.listItemIcon}>
                                {navLinkIcon}
                            </ListItemIcon>

                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={navLinkText}
                                className={clsx({
                                    [classes.listItemHidden]: !open,
                                })}
                            />

                            {subMenu ? (
                                <ListItemIcon className={classes.expandIcon}>
                                    <ChevronRight />
                                </ListItemIcon>
                            ) : null}
                        </ListItem>
                        {subMenu ? (
                            <>
                                <Hidden smDown>
                                    <SubMenuPopover
                                        subMenu={subMenu}
                                        popupState={popupState}
                                    />
                                </Hidden>
                                <Hidden mdUp>
                                    <BottomModal
                                        subMenu={subMenu}
                                        open={bottomDrawerOpen}
                                        onClose={() => setBottomDrawerOpen(false)}
                                    />
                                </Hidden>
                            </>
                        ) : null}
                    </div>
                );
            },
        );
    };

    return (
        <div className={classes.root}>
            <div>
                {renderBrandHeader()}
                {renderNavSections()}
            </div>
            <div className={classes.versionContainer}>
                <Typography variant={'caption'}>v{process.env.appVersion}</Typography>
            </div>
        </div>
    );
};

interface SidebarProps {
    open: boolean;
    handleClose: () => void;
}

export default Sidebar;
