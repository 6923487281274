import { createStyles, Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContent: {
            margin: theme.spacing(2),
        },
        spaceContent: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        dialogue: {
            padding: theme.spacing(2),
        },
        button: {
            width: 'max-content',
            marginLeft: theme.spacing(2),
        },
        buttonMargin: {
            marginLeft: theme.spacing(2),
        },
        deleteButtonContainer: {
            width: 'min-content',
        },
        deleteButton: {
            color: 'white',
            '&:hover': {
                backgroundColor: '#E10050',
                color: 'white',
            },
        },
        gridContainer: {
            width: 'inherit',
        },
        savingContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        saveProgress: {
            marginLeft: theme.spacing(1),
        },
        stretchContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        errorButton: {
            color: 'red',
            '&:hover': {
                backgroundColor: theme.palette.custom.paleRed,
            },
        },
        saveSubmitButton: {
            whiteSpace: 'nowrap',
            textAlign: 'center',
        },
    }),
);

export default useStyles;
