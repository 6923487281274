import React, { useEffect } from 'react';
import { Fab, Typography, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import { LinkOff, OpenInNew } from 'mdi-material-ui';
import CONTENT_URL_REGEXES from '../../config/ContentURLTypes';
import { getDomainName, retrieveEmbed } from '../../lib/mediaEmbeds';
import useStyles from './styles';

const MediaEmbed: React.FC<MediaEmbedProps> = (props) => {
    const classes = useStyles();
    const { contentURL, size = 'small' } = props;
    const [mediaEmbed, setEmbed] = React.useState('');
    const [host, setHost] = React.useState('');

    // Media query hooks directly in the component body
    const xl = useMediaQuery('(min-width:1600px)');
    const lg = useMediaQuery('(min-width:1200px)');
    const md = useMediaQuery('(min-width:900px)');

    useEffect(() => {
        (async () => {
            const hostName = getDomainName(contentURL);
            const newEmbed = await retrieveEmbed(hostName, contentURL);

            setEmbed(newEmbed);
            setHost(hostName);
        })();
    }, [contentURL, host]);

    /**
     * Dynamically retrieves the iFrame hiehgt based on consutom media queries of the screen.
     * This allows the iframe to be relatively well-resized based on the rest of the screen
     * content.
     */
    const getiFrameHeight = () => {
        if (xl) {
            return '700px';
        } else if (lg) {
            return '600px';
        } else if (md) {
            return '500px'; //medium screen,
        } else {
            return '350px';
        }
    };

    /**
     * Renders a disclaimer about the inability to render the submission embed.
     * This allows the user to open the submission link by click the Floating Action Button
     */
    const renderInaccessibleEmbedHandler = (hostName: string) => {
        return (
            <div
                className={clsx(
                    classes.thumbnailEmbed,
                    size === 'small' ? classes.embedSmall : classes.embedLarge,
                )}
            >
                <div className={classes.noImageContainer}>
                    <LinkOff className={classes.noEmbedIcon} />

                    <Typography
                        align={'center'}
                        variant={'body2'}
                        className={classes.noEmbedDisclaimer}
                    >
                        This {hostName.toLowerCase()} embed is not accesible or allowed.
                        Click the blue button to open link externally. Link is:{' '}
                        {contentURL}
                    </Typography>
                </div>
                <div className={clsx(classes.fabContainer, classes.centered)}>
                    <Fab href={contentURL} target={'_blank'} color={'primary'}>
                        <OpenInNew />
                    </Fab>
                </div>
            </div>
        );
    };

    /**
     * Renders an iframe by dynamically retreiving the iframe src attribute based on the contentURL
     */
    const renderEmbed = () => {
        // Note that the desctructured order of these is important to maintain consistency!
        const [YOUTUBE, SOUNDCLOUD, VIMEO, APPLE_MUSIC, GOOGLE_DRIVE, SPOTIFY] =
            Object.keys(CONTENT_URL_REGEXES);

        const iframeProps = {
            width: '100%',
            height: getiFrameHeight(),
            frameBorder: '0',
            allow: 'autoplay; encrypted-media',
            allowFullScreen: true,
        };

        if (!mediaEmbed) {
            return renderInaccessibleEmbedHandler(host);
        }

        switch (host) {
            case YOUTUBE:
                return <iframe src={mediaEmbed} {...iframeProps} />;
            case SOUNDCLOUD:
                return <iframe {...iframeProps} src={mediaEmbed} />;
            case VIMEO:
                return <iframe src={mediaEmbed} {...iframeProps} />;
            case APPLE_MUSIC:
                return <iframe src={mediaEmbed} {...iframeProps} />;
            case SPOTIFY:
                return (
                    <iframe
                        src={`https://embed.spotify.com/?uri=${mediaEmbed}&view=coverart&theme=dark`}
                        {...iframeProps}
                    />
                );
            case GOOGLE_DRIVE:
            default:
                return renderInaccessibleEmbedHandler(host);
        }
    };

    return <div className={classes.container}>{renderEmbed()}</div>;
};

interface MediaEmbedProps {
    contentURL: string;
    size?: 'small' | 'large';
}

export default MediaEmbed;
