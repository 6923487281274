import React from 'react';
import EnhancedDropdown from 'molecules/EnhancedDropdown';

const ServiceTypeDropDown: React.FC<ServiceTypeDropDownProps> = ({
    onChange,
    dropdownOptions,
}) => {
    const [selected, setSelected] = React.useState('All');

    const handleSelect = (value: React.SetStateAction<string>) => {
        onChange(value);
        setSelected(value);
    };

    return (
        <EnhancedDropdown
            dropdownLabel="Service Type"
            handleSelectedValueChange={handleSelect}
            dropdownOptions={['All', ...dropdownOptions]}
            selectedValue={selected}
        />
    );
};

export interface ServiceTypeDropDownProps {
    onChange: (val: any) => void;
    dropdownOptions: string[];
}

export default ServiceTypeDropDown;
