import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // Shared
        statusContainer: {
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            width: '100%',
        },

        // Submission Status Circle
        alignCenter: {
            justifyContent: 'center',
            alignItems: 'center',
        },

        highContrast: {
            color: theme.palette.common.white,
        },

        // Submission Status Chip
        chipLayout: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        reviewColor: {
            backgroundColor: theme.palette.campaignStatus.review,
        },
        approveColor: {
            backgroundColor: theme.palette.campaignStatus.approve,
        },
        draftColor: {
            backgroundColor: theme.palette.campaignStatus.draft,
        },
        holdColor: {
            backgroundColor: theme.palette.campaignStatus.hold,
        },
        pendingColor: {
            backgroundColor: theme.palette.campaignStatus.pending,
        },
        reviseColor: {
            backgroundColor: theme.palette.campaignStatus.review,
        },
        scheduledColor: {
            backgroundColor: theme.palette.campaignStatus.scheduled,
        },
        runningColor: {
            backgroundColor: theme.palette.campaignStatus.running,
        },
        completedColor: {
            backgroundColor: theme.palette.campaignStatus.completed,
        },
        cancelledColor: {
            backgroundColor: theme.palette.campaignStatus.cancelled,
        },
        backgroundContainer: {
            height: '24px',
            width: '24px',
            borderRadius: '3px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        chipIcon: {
            fontSize: theme.typography.pxToRem(16),

            color: 'white',
        },
        statusText: {
            paddingLeft: theme.spacing(1),
            fontSize: theme.typography.pxToRem(13),
        },
        regularStatusIcon: {
            fontSize: theme.typography.pxToRem(24),
        },
        smallStatusIcon: {
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(22),
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: theme.typography.pxToRem(18),
            },
        },
    }),
);

export default useStyles;
