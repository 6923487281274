import React from 'react';
import { Typography } from '@material-ui/core';
import { GridContainer, GridItem } from '@elevatormedia/duffel-bag';
import SocialMediaAccountLink from '../../atoms/SocialMediaAccountLink';
import clsx from 'clsx';
import { buildUrl, extractUser } from '@elevatormedia/socialite';
import useStyles from './styles';
import { Credit } from 'types/credits';

const SubmissionCreditList: React.FC<SubmissionCreditListProps> = (props) => {
    const classes = useStyles();
    const { credits, boldCreditType = true, color = 'textPrimary' } = props;

    if (!credits.length) {
        return (
            <Typography
                variant={'body1'}
                color={color}
                className={classes.dataFieldValue}
            >
                (None)
            </Typography>
        );
    }

    return (
        <>
            {credits.map((credit, index) => {
                const socialMedia =
                    credit.user && credit.user.socialMedia
                        ? credit.user.socialMedia
                        : credit.socialMedia;

                return (
                    <GridContainer
                        key={index}
                        spacing={1}
                        direction={'column'}
                        className={classes.socialMediaCreditField}
                    >
                        <GridItem>
                            <Typography
                                variant={'body1'}
                                color={color}
                                component={'span'}
                                className={clsx(
                                    classes.dataFieldValue,
                                    boldCreditType && classes.dataFieldValueBolded,
                                    !boldCreditType && classes.dataFieldItalicized,
                                )}
                            >
                                {credit.creditType.typeName}
                                {': '}
                                <Typography
                                    variant={'body1'}
                                    color={color}
                                    align={'right'}
                                    display={'inline'}
                                    className={classes.dataFieldValue}
                                >
                                    {credit.name}
                                </Typography>
                            </Typography>
                        </GridItem>
                        {socialMedia.length === 0 ? (
                            <GridItem
                                key={index}
                                className={classes.creditSocialMediaIndent}
                            >
                                <Typography
                                    variant={'body1'}
                                    color={color}
                                    align={'right'}
                                    display={'inline'}
                                    className={classes.dataFieldValue}
                                >
                                    (None)
                                </Typography>
                            </GridItem>
                        ) : (
                            socialMedia.map((socialMediaHandle, index) => (
                                <GridItem
                                    key={index}
                                    className={classes.creditSocialMediaIndent}
                                >
                                    <SocialMediaAccountLink
                                        type={socialMediaHandle.type}
                                        link={buildUrl(
                                            extractUser(socialMediaHandle.link),
                                            // TODO: handle 'appleMusic' in Socialite
                                            socialMediaHandle.type as any,
                                        )}
                                    />
                                </GridItem>
                            ))
                        )}
                    </GridContainer>
                );
            })}
        </>
    );
};

interface SubmissionCreditListProps {
    credits: Credit[];
    boldCreditType?: boolean;
    color?: 'textPrimary' | 'textSecondary';
}

export default SubmissionCreditList;
