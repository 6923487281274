import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            padding: theme.spacing(1, 0),
        },
        headerText: {
            fontWeight: 500,
            textTransform: 'uppercase',
        },
        redText: {
            color: theme.palette.submissionStatuses.rejected,
        },
        defaultText: {
            color: theme.palette.text.primary,
        },
        primaryText: {
            color: theme.palette.primary.main,
        },
        line: {
            height: '2px',
        },
        redLine: {
            backgroundColor: theme.palette.submissionStatuses.rejected,
        },
        defaultLine: {
            backgroundColor: theme.palette.text.primary,
        },
        primaryLine: {
            backgroundColor: theme.palette.primary.main,
        },
    }),
);

export default useStyles;
