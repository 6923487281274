import React from 'react';
import useStyles from './styles';
import PostDialog from './PostDialog';
import PromotionDialog from './PromotionDialog';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';
import { PostAuthor, User } from '@elevatormedia/duffel-bag';
import { PromotionAuthor } from 'types/promotion';

const EditAuthorsDialog = <T extends DialogVariant>(props: EditAuthorsDialogProps<T>) => {
    const classes = useStyles();
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { type, isOpen, closeDialog, author, authors, setAuthor, setAuthors } = props;

    const [searchInput, setSearchInput] = React.useState('');

    const addAuthor = (newAuthor: PostAuthor | User) => {
        if (type === 'posts') {
            const existingAuthor = authors.find(
                (author) => author.userId === newAuthor.userId,
            );

            if (!existingAuthor) {
                authors.push(newAuthor);
                setAuthors(authors);
            }
        } else {
            setAuthor('author', newAuthor as User);
        }

        setSearchInput('');
    };

    const removeAuthor = (index: number) => {
        if (type === 'posts') {
            authors.splice(index, 1);
            setAuthors([...authors]);
        } else if (type === 'promotions') {
            setAuthor('author', null);
        }
    };

    const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = ({
        target: { value },
    }) => {
        setSearchInput(value);
    };

    return (
        <Dialog
            open={isOpen}
            fullScreen={fullScreen}
            onClose={closeDialog}
            aria-labelledby="edit-authors-dialog"
            keepMounted
            className={classes.dialogContainer}
        >
            {type === 'posts' ? (
                <DialogTitle id="edit-authors-dialog">Authors</DialogTitle>
            ) : (
                <DialogTitle id="edit-authors-dialog">Author</DialogTitle>
            )}
            <DialogContent className={classes.dialogContent}>
                {type === 'posts' && (
                    <PostDialog
                        searchInput={searchInput}
                        onSearchChange={onSearchChange}
                        authors={authors as PostAuthor[]}
                        addAuthor={addAuthor}
                        removeAuthor={removeAuthor}
                    />
                )}
                {type === 'promotions' && author != null && (
                    <PromotionDialog author={author} />
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={closeDialog}
                    color={'primary'}
                    disabled={type === 'posts' ? authors.length === 0 : author == null}
                >
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export type DialogVariant = 'posts' | 'promotions';

export interface EditAuthorsDialogProps<T extends DialogVariant> {
    type: DialogVariant;
    isOpen: boolean;
    closeDialog: () => void;
    author?: T extends 'posts' ? never : PromotionAuthor; // if type is 'posts', this prop will not be allowed
    authors?: T extends 'posts' ? PostAuthor[] : never; // if type is NOT 'posts', this prop will not be allowed
    setAuthor?: T extends 'posts' ? never : (fieldname: string, author: User) => void;
    setAuthors?: T extends 'posts' ? (authors: PostAuthor[]) => void : never;
}

export default EditAuthorsDialog;
