import Dropdown from './SubmissionServicesDropdown';
import { SUBMISSION_SERVICES } from 'lib/graphql/queries/services';
import { useQuery } from '@apollo/client';
import { ServiceType } from 'types/service';

interface SubmissionServicesDropdownProps {
    onChange: (val: string) => void;
    filterBy?: string;
}

type SubmissionServicesReturn = {
    submissionServices: {
        name: string;
        services: {
            serviceId: string;
            name: string;
            description: string;
            details: string;
            cost: number;
            type: ServiceType;
        }[];
    }[];
};

const SubmissionServicesDropdown: React.FC<SubmissionServicesDropdownProps> = ({
    onChange,
    filterBy,
}) => {
    const handleChange = (val: string) => {
        onChange(nameToIdMap[val]);
    };

    const { data } = useQuery<SubmissionServicesReturn>(SUBMISSION_SERVICES, {
        notifyOnNetworkStatusChange: true,
    });

    const submissionServices =
        data && data.submissionServices ? data.submissionServices : [];

    const services = submissionServices
        .map(({ services }) =>
            services.map(({ name, serviceId, type }) => ({ name, serviceId, type })),
        )
        .flat();

    const filteredServices = filterBy
        ? services.filter((service) => (service.type as string) == filterBy)
        : services;

    const nameToIdMap = filteredServices.reduce((result, { name, serviceId }) => {
        result[name] = serviceId;
        return result;
    }, {} as Record<string, string>);

    return (
        <Dropdown onChange={handleChange} dropdownOptions={Object.keys(nameToIdMap)} />
    );
};

export default SubmissionServicesDropdown;
