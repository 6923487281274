import VARIABLES from '../../theme/variables';

import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: theme.palette.custom.grey.g800,
            borderBottom: `1px solid ${theme.palette.divider}`,
            borderLeft: 'none',

            // Assures the appbar always stays above the  sidebar
            zIndex: theme.zIndex.drawer + 1,
        },
        appBarShift: {
            width: '100%',
            marginLeft: VARIABLES.sidebarWidth,
            [theme.breakpoints.up('md')]: {
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                width: `calc(100% - ${VARIABLES.sidebarWidth}px)`,
            },
        },
        appBarShiftClosed: {
            width: '100%',
            marginLeft: VARIABLES.sidebarWidthClosed,
            [theme.breakpoints.up('md')]: {
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                width: `calc(100% - ${VARIABLES.sidebarWidthClosed}px)`,
            },
        },
        pageTitleContainer: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            color: theme.palette.primary.contrastText,
        },
        navigatingIndicator: {
            marginLeft: theme.spacing(1.5),
        },
        menuButton: {
            color: theme.palette.primary.contrastText,
            [theme.breakpoints.up('xs')]: {
                marginLeft: theme.spacing(0.25),
            },
        },
        userNameRight: {
            display: 'flex',
            alignItems: 'center',
        },
        userNameContainer: {
            paddingRight: theme.spacing(0.5),
            color: theme.palette.primary.contrastText,
            [theme.breakpoints.up('xs')]: {
                paddingRight: theme.spacing(0),
            },
        },
        avatar: {
            width: '33px',
            height: '33px',
            backgroundColor: theme.palette.primary.dark,
        },
        logoWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.common.black,
            cursor: 'pointer',
        },
        brandLetteringAsset: {
            height: '30px',
            width: 'auto',
            padding: theme.spacing(0, 2, 0, 0),
        },
    }),
);

export default useStyles;
