import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        sectionHeader: {
            fontSize: theme.typography.pxToRem(16),
            height: theme.typography.pxToRem(32),
            paddingTop: theme.spacing(1),
        },
        chipList: {
            marginTop: theme.spacing(1),
        },
        expandToggler: {
            paddingLeft: theme.spacing(0.75),
            '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
            },
        },
        expandListAvatarContainer: {
            display: 'inline-flex',
            margin: theme.spacing(0.5),
        },
        expandListAvatar: {
            height: '32px',
            width: '32px',
            fontSize: '14px',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        emptyMessage: {
            width: '100%',
            textAlign: 'center',
            padding: theme.spacing(4, 0, 2.5, 0),
        },
    }),
);

export default useStyles;
