import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        dropdownContainer: {
            margin: theme.spacing(2, 0, 2, 0),
        },
    }),
);

export default useStyles;
