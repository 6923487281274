import React from 'react';
import useStyles from './styles';
import { Typography, Paper, Avatar } from '@material-ui/core';
import {
    AttachMoney as MoneyIcon,
    Done as ApprovalIcon,
    DoneAll as ApprovedIcon,
    Close as RejectIcon,
    RateReview as CommentIcon,
    Flag as FlagIcon,
    Edit as DraftIcon,
    ArtTrack as PublishedIcon,
    HelpOutline as UnknownStatusIcon,
    Refresh as RestartWorkflowIcon,
} from '@material-ui/icons';
import { Pencil as SubmissionUpdatedIcon } from 'mdi-material-ui';
import { EVENT_TYPES } from '../../config/SubmissionEventTypes';
import EventDescription from './EventDescription';
import { GridItem, GridContainer, User } from '@elevatormedia/duffel-bag';
import { Activity, EventAction } from 'types/submission';

export const getEventActionType = (actionTaken: EventAction) => {
    if (actionTaken === 'submitted') return EVENT_TYPES.PAYMENT_RECEIVED;
    else if (actionTaken.startsWith('updateSubmissionStatuses')) {
        const paramIndex = actionTaken.lastIndexOf(':');
        const param = actionTaken.substr(paramIndex + 1);

        switch (param) {
            case 'Approval':
                return EVENT_TYPES.SUBMISSION_APPROVAL;
            case 'Approved':
                return EVENT_TYPES.SUBMISSION_APPROVED;
            case 'Rejected':
                return EVENT_TYPES.SUBMISSION_REJECTED;
            case 'Review':
                return EVENT_TYPES.SUBMISSION_RESTARTED;
        }
    } else if (actionTaken === 'setFlaggedSubmissions')
        return EVENT_TYPES.SUBMISSION_FLAGGED;
    else if (actionTaken === 'startSubmissionWriteUp') return EVENT_TYPES.POST_DRAFTED;
    else if (actionTaken === 'updateSubmission') return EVENT_TYPES.SUBMISSION_UPDATED;
};

const EventItem: React.FC<EventItemProps> = (props) => {
    const { event } = props;
    const classes = useStyles();
    const { actionTaken, user } = event;
    const {
        PAYMENT_RECEIVED,
        SUBMISSION_APPROVAL,
        SUBMISSION_APPROVED,
        SUBMISSION_REJECTED,
        SUBMISSION_FLAGGED,
        USER_COMMENT,
        POST_DRAFTED,
        POST_PUBLISHED,
        SUBMISSION_RESTARTED,
        SUBMISSION_UPDATED,
    } = EVENT_TYPES;

    const getEventTypeIcon = () => {
        const type = getEventActionType(actionTaken);

        switch (type) {
            case PAYMENT_RECEIVED:
                return <MoneyIcon className={classes.eventIcon} />;
            case SUBMISSION_APPROVAL:
                return <ApprovalIcon className={classes.eventIcon} />;
            case SUBMISSION_APPROVED:
                return <ApprovedIcon className={classes.eventIcon} />;
            case SUBMISSION_REJECTED:
                return <RejectIcon className={classes.eventIcon} />;
            case SUBMISSION_FLAGGED:
                return <FlagIcon className={classes.eventIcon} />;
            // NOTE comment feature
            case USER_COMMENT:
                return <CommentIcon className={classes.eventIcon} />;
            case POST_DRAFTED:
                return <DraftIcon className={classes.eventIcon} />;
            // NOTE figure out logic
            case POST_PUBLISHED:
                return <PublishedIcon className={classes.eventIcon} />;
            case SUBMISSION_RESTARTED:
                return <RestartWorkflowIcon className={classes.eventIcon} />;
            case SUBMISSION_UPDATED:
                return <SubmissionUpdatedIcon className={classes.eventIcon} />;
            default:
                return <UnknownStatusIcon className={classes.eventIcon} />;
        }
    };

    // NOTE comment feature
    const renderUserComment = () => {
        const { preferredUsername } = user;

        return (
            <Paper>
                <GridContainer
                    direction={'column'}
                    spacing={2}
                    className={classes.userCommentCard}
                >
                    <GridItem className={classes.userCommentCardHeader}>
                        <GridContainer direction={'row'} alignItems={'flex-start'}>
                            <GridItem>
                                <Avatar
                                    className={classes.userCommentCartAvatar}
                                    src={user.avatar && user.avatar.sourceUrl}
                                >
                                    {preferredUsername[0].toUpperCase()}
                                </Avatar>
                            </GridItem>
                            <GridItem className={classes.alignTop}>
                                <Typography
                                    variant={'h6'}
                                    color={'textPrimary'}
                                    className={classes.commentCardText}
                                >
                                    {preferredUsername}
                                </Typography>
                                {/* <Typography
                                    variant={'body2'}
                                    className={classes.commentCardText}
                                >
                                    {commentBody}
                                </Typography> */}
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </Paper>
        );
    };

    const renderEventIcon = () => (
        <div className={classes.historyItemIcon}>{getEventTypeIcon()}</div>
    );

    return (
        <div className={classes.historyItemContainer}>
            {renderEventIcon()}
            <div className={classes.historyItemContentContainer}>
                <EventDescription {...event} />
                {getEventActionType(actionTaken) === USER_COMMENT && renderUserComment()}
            </div>
        </div>
    );
};

export interface SubmissionEvent<T = any>
    extends Pick<Activity, 'eventTime' | 'user' | 'actionTaken'> {
    metadata?: T;
}

interface EventItemProps<T = any> {
    event: SubmissionEvent<T>;
}

export default EventItem;
