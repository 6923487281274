import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        root: {
            flexGrow: 1,
            margin: theme.spacing(1, 2, 2, 2),
            overflow: 'scroll',
        },
        headerRow: {
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            margin: theme.spacing(2, 4, 0, 2),
        },
        modalHeader: {
            fontWeight: 'bold',
        },
        paper: {
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '80vh',
            width: '80vw',
        },
    }),
);

export default useStyles;
