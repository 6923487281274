import React from 'react';
import { getBackgroundColor, getStatusIcon } from '../SubmissionStatus';
import clsx from 'clsx';
import useStyles from './styles';
import submissionStatusStyles from '../SubmissionStatus/styles';
import { Snackbar, Grow } from '@material-ui/core';
import { SubmissionStatus } from '@elevatormedia/duffel-bag';

const MobileSnackbar = React.forwardRef<any, MobileSnackbarProps>((props, ref) => {
    const classes = useStyles();
    const submissionClasses = submissionStatusStyles();
    const { newStatus, open, onClose, ...rest } = props;

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            autoHideDuration={1500}
            classes={{
                root: classes.disableClickEvents,
            }}
            open={open}
            onClose={onClose}
            {...rest}
        >
            <Grow in>
                <div
                    ref={ref}
                    className={clsx(
                        classes.root,
                        getBackgroundColor(newStatus, submissionClasses),
                    )}
                >
                    {getStatusIcon(newStatus, {
                        fontSize: 'small',
                    })}
                </div>
            </Grow>
        </Snackbar>
    );
});

interface MobileSnackbarProps {
    newStatus: SubmissionStatus;
    open?: boolean;
    onClose?: () => void;
}

// Add display name to the forwarded component
MobileSnackbar.displayName = 'MobileSnackbar';

export default MobileSnackbar;
