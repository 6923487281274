import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        exportContainer: {
            marginTop: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        formControl: {
            marginRight: theme.spacing(1),
        },
        label: {
            fontSize: theme.typography.pxToRem(16),
        },
        dropdown: {
            minWidth: theme.typography.pxToRem(175),
            maxWidth: theme.typography.pxToRem(200),
            height: theme.typography.pxToRem(40),
            fontSize: theme.typography.pxToRem(16),
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                marginBottom: theme.spacing(2),
            },
        },
        downloadButton: {
            backgroundColor: '#3F51B5',
            '&:hover': {
                backgroundColor: '#606fc7',
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: theme.typography.pxToRem(75),
                fontSize: theme.typography.pxToRem(10),
            },
        },
    }),
);

export default useStyles;
