import React from 'react';
import useStyles from './styles';
import { useQuery, useMutation } from '@apollo/client';
import { WRITEUP_QUEUE } from '../../../lib/graphql/queries/submissions';
import {
    START_SUBMISSION_PROMOTION,
    START_SUBMISSION_WRITEUP,
} from '../../../lib/graphql/mutations/submissions';
import { useRouter } from 'next/router';
import { reportError } from '../../../lib/errors';
import { useAuth } from '@elevatormedia/duffel-bag';
import { useSnackbar } from 'notistack';
import { Post } from 'types/post';
import { Service } from 'types/service';
import { SubmissionListItem } from 'types/submission';
import { TableSortOrder } from '../AllPosts/AllPostsContext';
import WriteupQueueTable, {
    WriteupQueueTableProps,
} from 'organisms/WriteupQueueTable/WriteupQueueTable';
import { Promotion } from '@elevatormedia/pitch-hooks/types/schema';
import { ROUTES } from 'config/Nav';
import { BaseRouteConfig } from 'types/nav';
import { EnhancedTabFilter, SubmissionServicesDropdown } from 'molecules';
import { ContentSpacer } from 'atoms';

/**
 * This component may be abstracted out more if necessary. If we find that
 * alot of the functionality here is shared with other tables, it may be worth
 * converting the common components into a Table HOC.
 */

export const writeUpColumnNameToField: Record<string, string> = {
    submissionTime: 'createdAt',
    contentUrl: 'contentUrl',
    submissionType: 'submissionType',
};
export const writeUpTabLabels = ['Submissions', 'Promotions', 'Advertisements'];

export const writeUpLabelsToType: Record<typeof writeUpTabLabels[number], string> = {
    Submissions: 'submission',
    Promotions: 'promotion',
    Advertisements: 'advertisement',
};

export type WriteupQueueItem = SubmissionListItem & {
    post: Pick<Post, 'postId' | 'title'>;
    promotion?: Pick<Promotion, 'promotionId' | 'name'>;
    service: Pick<Service, 'serviceId' | 'name' | 'cost'>;
    count: number;
};

const WriteupQueue: React.FC = () => {
    const router = useRouter();
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const { currentUser } = useAuth();

    // Keeps track of which column the data is ordered by and in which order
    const [order, setOrder] = React.useState<TableSortOrder>('desc');
    const [orderBy, setOrderBy] = React.useState('submissionTime');

    const [page, setPage] = React.useState(0); // Active Page Displayed
    const [rowsPerPage, setRowsPerPage] = React.useState(25); // Display Rows per page

    const [variables, setVariables] = React.useState({
        limit: rowsPerPage,
        sort: {
            field: writeUpColumnNameToField[orderBy],
            descending: order === 'desc',
        },
        serviceType: 'submission',
        services: [],
    });

    const [activeTab, setActiveTab] = React.useState(0); // Tabs

    const [startWriteUp] = useMutation(START_SUBMISSION_WRITEUP);
    const [startPromotion] = useMutation(START_SUBMISSION_PROMOTION);

    const { data, loading, refetch } = useQuery<{ submissions: WriteupQueueItem[] }>(
        WRITEUP_QUEUE,
        {
            notifyOnNetworkStatusChange: true,
            variables: { ...variables },
            fetchPolicy: 'network-only',
        },
    );

    /**
     * Handler for changing the currently service type filter
     */
    const handleChangeServiceType = (serviceId: string) => {
        if (serviceId) variables.services = [serviceId];
        else if (variables.services) variables.services = [];

        setVariables(variables);
        refetch(variables);
    };

    /**
     * Handler func for setting the new active displayed page
     */
    const handleChangePage = (newPage: number) => {
        setPage(newPage);
        const newLimit = (newPage + 1) * rowsPerPage;
        if (newLimit > variables.limit) {
            variables.limit = newLimit;
            setVariables(variables);
            refetch(variables);
        }
    };

    /**
     * Handler func for setting the number of rows diplayed per page
     */
    const handleChangeRowsPerPage: React.ChangeEventHandler<
        HTMLTextAreaElement | HTMLInputElement
    > = ({ target: { value } }) => {
        const perPage = Number(value);
        setRowsPerPage(perPage);
        variables.limit = perPage;
        setVariables(variables);
        setPage(0); // Reset page to 0 after change
        refetch(variables);
    };

    /**
     * Handler func for toggling the sort properties of the table
     * */
    const handleRequestSort = (property: string) => {
        let isDesc = false;

        if (orderBy === property) {
            if (order === 'desc') isDesc = false;
            else isDesc = true;
        } else isDesc = true;

        setOrder(isDesc ? 'desc' : 'asc');
        setOrderBy(property);
        refetch({
            sort: {
                field: writeUpColumnNameToField[property],
                descending: isDesc,
            },
        });
    };

    /**
     * Handler function for when a user clicks a row.
     */
    const handleGoToSubmission = (submissionId: string) => {
        router.push(`/submissions/${submissionId}`);
    };

    /**
     * Handler for creating a new post from a row
     */
    const handleStartNewPost = async (rowData: WriteupQueueItem) => {
        if (rowData.post && rowData.post.postId) {
            router.push(
                `${(ROUTES.posts.editPost as BaseRouteConfig).to}?postId=${
                    rowData.post.postId
                }`,
            );
        } else {
            try {
                const { data } = await startWriteUp({
                    variables: {
                        submissionId: rowData.submissionId,
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: WRITEUP_QUEUE,
                            variables,
                        },
                    ],
                });

                const postId = data.startSubmissionWriteUp.postId;
                router.push(`/posts/edit?postId=${postId}`);
            } catch (err) {
                console.error(err);
                reportError(err, {
                    metaData: {
                        operation: 'mutation startWriteUp',
                    },
                    user: {
                        username: currentUser.username,
                    },
                });
                enqueueSnackbar('Error creating post', {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    preventDuplicate: true,
                    variant: 'error',
                });
            }
        }
    };

    const handleStartNewPromotion = async (rowData: WriteupQueueItem) => {
        if (rowData.promotion && rowData.promotion.promotionId) {
            router.push(
                (ROUTES.promotions.editPromotion as BaseRouteConfig).getPath(
                    rowData.promotion.promotionId,
                ),
            );
        } else {
            try {
                const { data } = await startPromotion({
                    variables: {
                        submissionId: rowData.submissionId,
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: [
                        {
                            query: WRITEUP_QUEUE,
                            variables,
                        },
                    ],
                });

                const promotionId = data.startSubmissionPromotion.promotionId;
                router.push(
                    (ROUTES.promotions.editPromotion as BaseRouteConfig).getPath(
                        promotionId,
                    ),
                );
            } catch (err) {
                console.error(err);
                reportError(err, {
                    metaData: {
                        operation: 'mutation startSubmissionPromotion',
                    },
                    user: {
                        username: currentUser.username,
                    },
                });
                enqueueSnackbar('Error creating post', {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    preventDuplicate: true,
                    variant: 'error',
                });
            }
        }
    };

    const rows = data && data.submissions ? data.submissions : [];

    const dataProps: WriteupQueueTableProps = {
        rows,
        rowCount: rows.length ? rows[0].count : 0,
        startNewPost: handleStartNewPost,
        startNewPromotion: handleStartNewPromotion,
        handleGoToSubmission: handleGoToSubmission,

        serviceType: writeUpTabLabels[activeTab],

        page,
        handleChangePage,
        rowsPerPage,
        handleChangeRowsPerPage,

        handleRequestSort,
        order,
        orderBy,
        loading,
    };

    const handleChangeTab = (_event: any, newTab: number) => {
        variables.serviceType = writeUpLabelsToType[writeUpTabLabels[newTab]];

        setVariables(variables);
        setActiveTab(newTab);
        refetch({ ...variables });
    };

    return (
        <>
            <EnhancedTabFilter
                activeTab={activeTab}
                handleChangeTab={handleChangeTab}
                tabLabels={writeUpTabLabels}
            />
            <ContentSpacer>
                <div className={classes.dropdownContainer}>
                    <SubmissionServicesDropdown
                        onChange={handleChangeServiceType}
                        filterBy={writeUpLabelsToType[writeUpTabLabels[activeTab]]}
                    />
                </div>
                <div className={classes.root}>
                    <WriteupQueueTable {...dataProps} />
                </div>
            </ContentSpacer>
        </>
    );
};

export default WriteupQueue;
