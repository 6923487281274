import EnhancedTable from './EnhancedTable';
import EnhancedColumn from './EnhancedColumn';
import EnhancedRow from './EnhancedRow';
import EnhancedToolbar from './EnhancedToolbar';
import EnhancedTableHeader, { TableHeaderItem } from './EnhancedTableHeader';
import { camelCaseToRegular } from '@elevatormedia/duffel-bag/dist/utils/string';

const buildColumnHeaders = (columnNames: string[]) => {
    const columnConfig = columnNames.map(
        (columnName) =>
            ({
                id: columnName,
                label: camelCaseToRegular(columnName),
            } as TableHeaderItem),
    );

    return columnConfig;
};

export {
    EnhancedTable,
    EnhancedColumn,
    EnhancedRow,
    EnhancedToolbar,
    EnhancedTableHeader,
    buildColumnHeaders,
};
