import React from 'react';
import useStyles from './styles';
import {
    IconButton,
    Link as MuiLink,
    Paper,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { ContentCopy } from 'mdi-material-ui';
import { SubmissionSummary } from 'types/submission';
import { copyContentLink, copyCreditsAsFormatted } from '../../lib/credits';
import Link from '../../atoms/Link';
import EnhancedCardHeader from 'atoms/EnhancedCardHeader';
import SubmissionCreditList from 'molecules/SubmissionCreditList';

const SubmissionDetailsCard = (props: SubmissionDetailsCardProps) => {
    const classes = useStyles();

    const { showSubmissionDetails, submission, enqueueSnackbar } = props;

    const {
        submissionId,
        credits,
        contentTitle,
        order,
        contentUrl,
        service,
        services,
    } = submission as SubmissionSummary;

    const addOns = services
        .filter((serv) => serv.name != service.name)
        .map((serv) => {
            return serv.name;
        });

    const onShareFinished = (msg: string) => {
        enqueueSnackbar(msg, {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            variant: 'info',
            preventDuplicate: true,
        });
    };

    const renderPostDetailRow = (key: string, value: string | React.ReactNode) => {
        return (
            <div className={classes.detailRowContainer}>
                <Typography
                    variant={'body2'}
                    color={'textSecondary'}
                    className={classes.titleCase}
                >
                    {key} — {value}
                </Typography>
            </div>
        );
    };

    const SectionHeader = ({ children }: { children: React.ReactNode }) => {
        return (
            <Typography variant={'subtitle2'} gutterBottom>
                {children}
            </Typography>
        );
    };

    const renderContent = () => {
        const linkProps = {
            href: '/users/[preferredUserName]',
            as: `/users/${order.user.preferredUsername}`,
        };

        return (
            <>
                <EnhancedCardHeader title={'Submission Info'} color={'textPrimary'} />

                <div className={classes.cardContent}>
                    <SectionHeader>Submission</SectionHeader>
                    {renderPostDetailRow('Submission Service', service.name)}
                    {addOns.length > 0 &&
                        renderPostDetailRow(
                            'Add Ons',
                            addOns.map((service) => service),
                        )}
                    {renderPostDetailRow(
                        'Submission Link',
                        <React.Fragment>
                            <MuiLink
                                href={'/submissions/' + submissionId}
                                target={'_blank'}
                                rel="noopener noreferrer"
                            >
                                Open in new window
                            </MuiLink>
                        </React.Fragment>,
                    )}
                    {renderPostDetailRow('Title', `"${contentTitle}"`)}
                    {renderPostDetailRow(
                        'Preferred Username',
                        <Link {...linkProps}>{order.user.preferredUsername}</Link>,
                    )}
                    {renderPostDetailRow(
                        'Legal Name',
                        `${order.user.firstName} ${order.user.lastName}`,
                    )}{' '}
                    {renderPostDetailRow(
                        'Content Link',
                        <>
                            <MuiLink
                                href={contentUrl}
                                target={'_blank'}
                                rel="noopener noreferrer"
                            >
                                Open in new window
                            </MuiLink>
                            <Tooltip title={'Copy link'}>
                                <IconButton
                                    onClick={() =>
                                        copyContentLink(contentUrl, onShareFinished)
                                    }
                                    color={'primary'}
                                >
                                    <ContentCopy className={classes.copyIcon} />
                                </IconButton>
                            </Tooltip>
                        </>,
                    )}
                    {submission && submission.preferredAuthor
                        ? renderPostDetailRow(
                              'Preferred Author',
                              <Link
                                  href={'/users/' + submission.preferredAuthor.username}
                              >
                                  {submission &&
                                  submission.preferredAuthor &&
                                  submission.preferredAuthor.preferredUsername
                                      ? `${submission.preferredAuthor.preferredUsername}`
                                      : 'None'}
                              </Link>,
                          )
                        : null}
                </div>
                {credits && credits.length > 0 && (
                    <div className={classes.cardContent}>
                        <SectionHeader>
                            <React.Fragment>
                                Credits{' '}
                                <Tooltip title={'Copy credits'}>
                                    <IconButton
                                        onClick={() =>
                                            copyCreditsAsFormatted(
                                                credits,
                                                onShareFinished,
                                            )
                                        }
                                        color={'primary'}
                                    >
                                        <ContentCopy className={classes.copyIcon} />
                                    </IconButton>
                                </Tooltip>
                            </React.Fragment>
                        </SectionHeader>
                        <SubmissionCreditList
                            credits={credits}
                            boldCreditType={false}
                            color={'textSecondary'}
                        />
                    </div>
                )}
            </>
        );
    };

    const renderPaperCard = () => {
        return (
            <div className={classes.submissionsDetailCardRoot}>
                <Paper>{renderContent()}</Paper>
            </div>
        );
    };

    return showSubmissionDetails ? renderPaperCard() : null;
};

export type SubmissionDetailsCardProps = {
    showSubmissionDetails: boolean;
    submission: SubmissionSummary;
    enqueueSnackbar: Function;
};

export default SubmissionDetailsCard;
