import React from 'react';
import useStyles from './styles';
import CreatorInfo from './CreatorInfo';
import { baseInitialValues } from '../CreditsAccordion';
import CreditTypeSelector from 'atoms/CreditTypeSelector';
import UsernameSearchInput from 'molecules/UsernameSearchInput';
import { Button, CardSectionHeader } from '@elevatormedia/duffel-bag';
import { Box, Collapse, Typography } from '@material-ui/core';
import { LinkVariantOff } from 'mdi-material-ui';
import { CreditFormValues, CreditInput } from 'types/credits';
import { CurrentUser, SearchUserResult } from 'types/user';
import { useFormikContext } from 'formik';

const EditCreditForm: React.FC<EditCreditFormProps> = ({
    currentUser,
    onCancel,
    saveCredit,
    editMode,
}) => {
    const {
        resetForm,
        submitForm,
        isValid,
        values,
        setFieldValue,
        setFieldTouched,
    } = useFormikContext<CreditFormValues>();

    const classes = useStyles();

    // Keeps track of the search input text
    const [textEntry, setTextEntry] = React.useState('');
    const [isManuallyAddingCredit, setIsManuallyAddingCredit] = React.useState(editMode);

    const [stagedCredit, setStagedCredit] = React.useState<CreditInput>(null);

    const onCreditTypeChange = (newType: string) => {
        setFieldValue('type', newType);
        setFieldTouched('type', true);
    };

    /**
     * Handler func for when a user selects an option from a auto-suggested list of
     * users
     */
    const onUserSelected = (user: SearchUserResult) => {
        // Reset any active fields first

        let isCurrentUser = false;

        if (currentUser && currentUser.preferredUsername === user.preferredUsername)
            isCurrentUser = true;

        const newFormValues: CreditInput = {
            name: user.preferredUsername,
            autoLinked: true,
            email: user.email,
            isSubmitter: isCurrentUser,
            type: values.type,
            socialMedia: user.socialMedia,
        };
        // Update current state
        setStagedCredit(newFormValues);

        // Clear search input
        setTextEntry('');
    };

    /**
     * Handler function for when the user hits the 'save' button after editing a credit
     */
    const handleOnClickSave = async () => {
        isManuallyAddingCredit ? await submitForm() : await saveCredit(stagedCredit);
        onCancel();
    };

    const handleAddNew = () => {
        setIsManuallyAddingCredit(true);
        resetForm({
            values: {
                ...baseInitialValues,
                type: values.type,
            },
        });
    };

    /**
     * Handler function for when a user decides to clear (or 'unlink' rather) an auto-filled
     * form
     */
    const onClearUser = () => {
        resetForm({ values: baseInitialValues });
        setStagedCredit(null);
        setTextEntry('');
    };

    const renderLinkedBadge = () => {
        return (
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                marginTop={2}
                marginLeft={1}
                paddingX={2}
                borderLeft={'4px solid black'}
            >
                <Typography variant={'body2'}>
                    <b>{stagedCredit.name}'s</b> info has been linked!
                </Typography>
                {/**Unlink button*/}
                <Button onClick={onClearUser} variant={'text'} size={'small'}>
                    <Box
                        paddingRight={1}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                    >
                        <LinkVariantOff className={classes.linkIcon} fontSize={'small'} />
                    </Box>
                    Unlink
                </Button>
            </Box>
        );
    };

    const renderUserSelection = () => {
        return (
            <Box margin={0}>
                <div className={classes.searchFieldContainer}>
                    <UsernameSearchInput
                        id={'search'}
                        typeVariant={'autoComplete'}
                        placeholder={'Username'}
                        disableUnderline={true}
                        onUserSelected={onUserSelected}
                        textFieldInput={textEntry}
                        setTextFieldInput={setTextEntry}
                        disabled={false}
                        isAddCreditOption={true}
                        handleManualAddNew={handleAddNew}
                    />
                </div>
                {stagedCredit && renderLinkedBadge()}
            </Box>
        );
    };

    return (
        <div className={classes.addCreditFormRoot}>
            <div className={classes.formRoot}>
                <CardSectionHeader sectionTitle={'Credit Type'} />
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                >
                    <Box paddingBottom={2}>
                        <CreditTypeSelector
                            currentType={values.type}
                            handleChange={onCreditTypeChange}
                        />
                    </Box>
                    <CardSectionHeader
                        sectionTitle={isManuallyAddingCredit ? 'Creator Info' : 'Creator'}
                    />
                    <Box paddingBottom={2}>
                        <Collapse in={!isManuallyAddingCredit}>
                            {renderUserSelection()}
                        </Collapse>
                        <Collapse in={isManuallyAddingCredit}>
                            <CreatorInfo
                                disableUnderline={false}
                                currentUser={currentUser}
                            />
                        </Collapse>
                    </Box>
                </Box>
            </div>

            <div className={classes.dialogActionsContainer}>
                <Button
                    size={'large'}
                    variant={'text'}
                    onClick={() =>
                        editMode
                            ? onCancel()
                            : isManuallyAddingCredit
                            ? setIsManuallyAddingCredit(false)
                            : onCancel()
                    }
                >
                    Cancel
                </Button>
                <Button
                    disabled={isManuallyAddingCredit ? !isValid : !stagedCredit}
                    size={'large'}
                    variant={'text'}
                    onClick={handleOnClickSave}
                >
                    Save
                </Button>
            </div>
        </div>
    );
};

export interface EditCreditFormProps {
    onCancel: Function;
    currentUser: CurrentUser;
    saveCredit?: Function;
    editMode?: boolean;
}

EditCreditForm.defaultProps = {
    editMode: false,
};

export default EditCreditForm;
