import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(2, 2, 0, 2),
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1.5),
            },
        },
        missingPermissions: {
            fontWeight: 'bold',
        },
        permissionName: {
            fontSize: '0.9rem',
        },
        close: {
            color: theme.palette.error.main,
            borderRadius: '50%',
            fontSize: '1.5rem',
            '&:hover': {
                backgroundColor: `${theme.palette.error.main}80`,
            },
        },
    }),
);

export default useStyles;
