import React from 'react';
import { Paper, Typography, Avatar, Button } from '@material-ui/core';
import { GridContainer, GridItem, Media } from '@elevatormedia/duffel-bag';
import useStyles from './styles';

const UserAvatarCard: React.FC<UserAvatarCardProps> = (props) => {
    const classes = useStyles();
    const { role, permissionGroup, preferredUsername, avatar } = props;

    const renderUserNameRole = () => {
        return (
            <GridItem xs={7} className={classes.usernameField}>
                <div>
                    <div className={classes.userNameContainer}>
                        <Typography variant={'h6'}>{preferredUsername}</Typography>
                    </div>
                    <div className={classes.role}>
                        <Typography variant={'body2'} color={'textSecondary'}>
                            {role} ― {permissionGroup}
                        </Typography>
                    </div>
                </div>
                <Button
                    href={`https://${process.env.cookieDomain}/users/${preferredUsername}`}
                    component={'a'}
                    size={'small'}
                    color={'default'}
                    variant={'outlined'}
                >
                    View Profile
                </Button>
            </GridItem>
        );
    };

    const renderAvatar = () => {
        return (
            <GridItem className={classes.userAvatarContainer} xs={5}>
                <Avatar src={avatar && avatar.sourceUrl} className={classes.avatar}>
                    {preferredUsername.toUpperCase()[0]}
                </Avatar>
            </GridItem>
        );
    };

    return (
        <GridItem className={classes.root}>
            <Paper className={classes.smallCard}>
                <div className={classes.cardContentContainer}>
                    <GridContainer
                        direction={'row'}
                        justifyContent={'space-between'}
                        spacing={2}
                    >
                        {renderUserNameRole()}
                        {renderAvatar()}
                    </GridContainer>
                </div>
                {/**
                TODO: Comment back in when uploads are ready */}
                {/* <Divider />
                <div
                    className={clsx(
                        classes.cardContentContainer,
                        classes.cardSectionContainer,
                    )}
                >
                    <GridContainer direction={'row'} justifyContent={'flex-end'} spacing={2}>
                        <Button size={'small'} variant={'text'} color={'primary'}>
                            Upload Picture
                        </Button>
                        <Button size={'small'} variant={'text'} color={'secondary'}>
                            Remove Picture
                        </Button>
                    </GridContainer>
                </div> */}
            </Paper>
        </GridItem>
    );
};

interface UserAvatarCardProps {
    role: string;
    permissionGroup: string;
    preferredUsername: string;
    avatar?: Media;
}

// UserAvatarCard.defaultProps = {
//     role: 'Role',
//     permissionGroup: 'Permission Group',
//     preferredUsername: 'User_Name',
// };

export default UserAvatarCard;
