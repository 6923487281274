import { Theme, makeStyles, createStyles } from '@material-ui/core';
import VARIABLES from '../../theme/variables';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                width: VARIABLES.sidebarWidth,
            },
        },

        /**
         * Sidebar Header
         */
        headerContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            minHeight: '64px',
            margin: theme.spacing(0, 2),
        },
        removeMargin: {
            margin: 0,
        },
        logoWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.common.black,
            cursor: 'pointer',
        },
        brandLetteringAsset: {
            height: '30px',
            width: 'auto',
            padding: theme.spacing(0, 2, 0, 0),
        },
        sideBarHeader: {
            fontSize: '1.2rem',
            fontWeight: 'normal',
        },
        brandName: {
            fontWeight: 600,
            color: theme.palette.custom.black80,
        },
        navButtons: {
            color: theme.palette.custom.grey.g400,
        },

        /**
         * Navigation Links
         */
        listContainer: {
            padding: theme.spacing(1, 0),
        },
        listSubheader: {
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(30),
            padding: theme.spacing(0, 2),
            color: theme.palette.custom.black60,
        },
        listItem: {
            cursor: 'pointer',
            borderLeft: '4px solid transparent',
            textDecoration: 'none',
            /**
             * Provide multiple nested query selectors for children
             */
            '&:hover': {
                backgroundColor: theme.palette.custom.grey.g200,
                '& $listItemIcon': {
                    color: theme.palette.primary.light,
                    paddingRight: theme.spacing(2),
                },
                '& $listItemText': {
                    color: theme.palette.primary.light,
                    textDecoration: 'none',
                },
                '& $expandIcon': {
                    color: theme.palette.primary.light,
                },
            },
            whiteSpace: 'nowrap',
            overflow: 'auto',
            msOverflowX: 'hidden',
            '-ms-overflow-style': 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            scrollbarWidth: 'none',
        },
        listItemHidden: {
            transition: theme.transitions.create(['opacity'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            opacity: 0,
        },
        listItemCollapsed: {
            transition: theme.transitions.create(['height'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            height: 0,
        },
        listItemActive: {
            backgroundColor: theme.palette.custom.grey.g200,
            borderLeft: `4px solid ${theme.palette.primary.light}`,
            borderLeftRadius: '4px',
            '& $listItemIcon': {
                color: theme.palette.primary.light,
                paddingRight: theme.spacing(2),
            },
            '& $listItemText': {
                color: theme.palette.primary.light,
                textDecoration: 'none',
            },
        },
        listItemIcon: {
            color: theme.palette.text.secondary,
            paddingRight: theme.spacing(2),
            marginLeft: `2px`,
            minWidth: '14px',
            fontSize: 14,
        },
        expandIcon: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            color: theme.palette.text.secondary,
            fontSize: 14,
        },
        listItemText: {
            fontWeight: 500,
            color: theme.palette.custom.black80,
            fontSize: '0.85rem',
        },
        listDivider: {
            margin: theme.spacing(2, 0),
        },

        versionContainer: {
            height: '40px',
            textAlign: 'center',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            paddingBottom: theme.spacing(1.5),
            color: theme.palette.custom.grey.g600,
        },

        menu: {
            minWidth: '250px',
        },
        menuItem: {
            padding: theme.spacing(1, 1.5),
        },
        menuIcon: {
            padding: theme.spacing(0, 1, 0, 0),
            color: theme.palette.text.secondary,
        },

        sectionDivider: {
            marginTop: theme.spacing(1),
            color: theme.palette.custom.grey.dark,
            height: '1.5px',
        },
    }),
);

export default useStyles;
