import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        accountMenu: {
            minWidth: '330px',
            border: 'none',
            overflow: 'hidden',
        },
        cardHeader: {
            display: 'flex',
            alignItems: 'center',
            height: '80px',
            width: '100%',
            backgroundColor: theme.palette.background.default,
        },
        accountInfoContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        avatarContainer: {
            paddingLeft: theme.spacing(2.5),
            paddingRight: theme.spacing(1.8),
        },
        accountCardAvatar: {
            width: '45px',
            height: '45px',
            backgroundColor: theme.palette.primary.dark,
        },
        userName: {
            fontSize: '1rem',
        },
        menuItem: {
            minHeight: '60px',
        },
        listItemContainer: {
            height: '100%',
            textAlign: 'center',
        },
        listItemIcon: {
            paddingRight: theme.spacing(2),
            marginTop: theme.spacing(1),
            minWidth: '14px',
            fontSize: '0.85rem',
            color: theme.palette.text.secondary,
        },
        listItemText: {
            fontWeight: 500,
            fontSize: '0.85rem',
            color: theme.palette.text.secondary,
        },
        supportHeader: {
            marginLeft: theme.spacing(2),
        },
        divider: {
            width: '90%',
            marginLeft: theme.spacing(1.5),
        },
        versionText: {
            marginBottom: theme.spacing(1),
            color: theme.palette.text.secondary,
            fontSize: '0.75rem',
        },
        versionIcon: {
            color: theme.palette.text.secondary,
            fontSize: '0.90rem',
        },
    }),
);

export default useStyles;
