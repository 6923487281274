import { createStyles, Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        togglePostDetailsButtonText: {
            paddingRight: theme.spacing(1),
        },
        textFieldContainer: {
            width: '100%',
        },
        sectionHeader: {
            fontSize: theme.typography.pxToRem(16),
            paddingBottom: theme.spacing(1),
        },
        copyIcon: {
            fontSize: theme.typography.pxToRem(18),
        },
        dropdown: {
            maxWidth: '300px',
            width: '100%',
        },
        dropdownarrow: {
            color: theme.palette.text.secondary,
        },
        formControl: {
            margin: 0,
        },
        notAllowedCursor: {
            cursor: 'not-allowed',
        },
        cardContent: {
            margin: theme.spacing(2),
        },
        postEditorCardContainer: {
            height: '100%',
            maxWidth: '910px',
            padding: theme.spacing(2, 1, 0, 0),
            [theme.breakpoints.down(1100)]: {
                padding: 0,
            },
        },
        paper: {
            marginBottom: theme.spacing(2),
        },
        cardHeaderText: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
        },
        postIdHeader: {
            padding: '4px',
            backgroundColor: '#F9F9FA',
            borderRadius: 2,
            textTransform: 'none',
        },
        linkSubmissionTitle: {
            marginLeft: theme.spacing(1),
        },
    }),
);

export default useStyles;
