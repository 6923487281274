import React from 'react';
import getStatusIcon from './StatusIcon';
import { getBackgroundColor } from './StatusColors';
import useStyles from './styles';
import clsx from 'clsx';
import { CampaignStatus } from '@elevatormedia/duffel-bag';

/**
 * Convenience Component: Renders a special icon assigned to a Campaign status
 * along with its label.
 */
const CampaignStatusCircle: React.FC<CampaignStatusCircleProps> = (props) => {
    const classes = useStyles();
    const { status, highContrast = false, size = 'regular' } = props;

    return (
        <div
            className={clsx(
                classes.statusContainer,
                classes.alignCenter,
                getBackgroundColor(status, classes),
            )}
        >
            {getStatusIcon(status, {
                className: clsx({
                    [classes.regularStatusIcon]: size === 'regular',
                    [classes.smallStatusIcon]: size === 'small',
                    [classes.highContrast]: highContrast,
                }),
            })}
        </div>
    );
};

interface CampaignStatusCircleProps {
    status: CampaignStatus;
    highContrast?: boolean;
    size?: 'regular' | 'small';
}

export default CampaignStatusCircle;
