import React from 'react';
import useStyles from './styles';
import { Typography } from '@material-ui/core';

const EventTimeline: React.FC = ({ children }) => {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.historyTimelineContainer}>{children}</div>
            <div className={classes.historyListFooter}>
                <Typography variant={'body2'} color={'textSecondary'}>
                    All Caught Up!
                </Typography>
            </div>
        </div>
    );
};

export default EventTimeline;
