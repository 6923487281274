import gql from 'graphql-tag';

export const SEARCH_TAGS = gql`
    query searchTags($search: String!) {
        tags(search: $search) {
            tagId
            displayName
            name
        }
    }
`;
