import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '40px',
            width: '40px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
            boxShadow: theme.shadows[2],
            color: 'white',
        },
        disableClickEvents: {
            pointerEvents: 'none',
        },
    }),
);

export default useStyles;
