import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...theme.mixins.toolbar,
        },
    }),
);

export default useStyles;
