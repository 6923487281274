import React, { useEffect, useState, useCallback } from 'react';
import { Drawer, Hidden, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { Appbar, Sidebar } from '..';
import { useRouter } from 'next/router';
import useStyles from './styles';
import { Loading, useAuth } from '@elevatormedia/duffel-bag';
import { PATH_TO_PAGE_NAME, REDIRECTS } from 'config/Nav';
import { encodeFromURL } from 'lib/redirects';
import AppbarSpacer from 'atoms/AppbarSpacer';

/**
 * NavWrapper: Wrapping Component around the Drawer, Appbar + Screen Content.
 */
const NavWrapper: React.FC = (props) => {
    const classes = useStyles();

    const { currentUser, lastAuth, isTeamMember } = useAuth();
    const router = useRouter();

    const [renderComponent, setRenderComponent] = useState(false);

    // Retrieve information about the current view dimensions
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // React state hook for opening and closing Drawer
    const [open, setOpen] = React.useState(true);

    /**
     * Handle sidebar open state
     */
    useEffect(() => {
        setOpen(true);
    }, [isMobile]);

    /**
     * Handle auth redirect
     */

    const shouldRedirect = useCallback(() => {
        if (currentUser && lastAuth) {
            if (isTeamMember()) {
                setRenderComponent(true);
            } else {
                window.location.replace(REDIRECTS.submissions.uri);
            }
        } else if (!currentUser && lastAuth) {
            const requestedRedirect = window.location.href;
            const b64EncodedFromURL = encodeFromURL(requestedRedirect);

            if (b64EncodedFromURL) {
                window.location.replace(
                    `${REDIRECTS.auth.signin.uri}?from=${b64EncodedFromURL}`,
                );
            } else {
                window.location.replace(REDIRECTS.auth.signin.uri);
            }
        }
    }, [currentUser, lastAuth, isTeamMember]);

    useEffect(() => {
        shouldRedirect();
    }, [shouldRedirect]);

    /**
     * Handle Drawer open and close state using React Hooks.
     */
    const toggleDrawer = () => {
        const newValue = open ? false : true;
        setOpen(newValue);
    };

    /**
     * String formatting function: Parses raw path information
     * into formatted String used as the Page header.
     */
    const getRouteName = () => PATH_TO_PAGE_NAME[router.pathname].navLinkText;

    const isSubRoute = () => {
        const { pathname } = router;
        const routeConfig = PATH_TO_PAGE_NAME[pathname];

        return Boolean(routeConfig.parent);
    };

    const onClosePress = () => {
        /**
         * If the user navigated from a bookmark or was redirected to this page,
         * navigate to the root, otherwise default to popping from the history stack.
         */
        const referrer = document.referrer && new URL(document.referrer);
        if (
            (!document.referrer || window.location.host !== referrer.host) &&
            !isSubRoute()
        ) {
            router.push('/');
        } else {
            router.back();
        }
    };

    return !renderComponent ? (
        <Loading />
    ) : (
        <div className={classes.root}>
            <Appbar
                route={getRouteName()}
                isSubRoute={isSubRoute()}
                onClosePress={onClosePress}
                toggleDrawer={toggleDrawer}
                sidebarIsOpen={open}
                user={currentUser}
            />

            <nav>
                {/** 
                    Dynamically Renders a mobile-friendly or desktop
                    drawer depending on the screen width.
                */}
                <Hidden smDown>
                    <Drawer
                        anchor="left"
                        onClose={toggleDrawer}
                        open={open}
                        variant={'permanent'}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            }),
                        }}
                    >
                        <Sidebar open={open} handleClose={toggleDrawer} />
                    </Drawer>
                </Hidden>
                <Hidden mdUp>
                    <Drawer
                        anchor="left"
                        onClose={toggleDrawer}
                        open={open}
                        variant={'temporary'}
                        ModalProps={{ keepMounted: true }}
                    >
                        <Sidebar open={open} handleClose={toggleDrawer} />
                    </Drawer>
                </Hidden>
            </nav>

            <div
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                    [classes.contentShiftClosed]: !open,
                })}
            >
                <AppbarSpacer />
                <main>{props.children}</main>
            </div>
        </div>
    );
};

export default NavWrapper;
