import { reduceOpacity } from '@elevatormedia/duffel-bag';

import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(1),
            overflowX: 'auto',
        },
        table: {
            width: '100%',
        },
        tableWrapper: {
            overflow: 'auto',
        },
        tableCellLink: {
            color: theme.palette.primary.main,
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        toolbarRoot: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            minHeight: '80px',
        },
        highlight: {
            backgroundColor: reduceOpacity(theme.palette.primary.main, 0.12),
        },
        spacer: {
            flex: '1 1 100%',
        },
        title: {
            flex: '0 0 auto',
        },
        selectedText: {
            fontFamily: 'Roboto',
            fontWeight: 'bold',
        },
        actions: {
            flexGrow: 1,
        },
        nonContentStateContainer: {
            minHeight: '400px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        emptyStateIcon: {
            fontSize: theme.typography.pxToRem(150),
            color: theme.palette.passwordStrengths.noInput,
        },
        emptyStateText: {
            color: theme.palette.passwordStrengths.noInput,
        },
        baseHeaderText: {
            opacity: 0.5,
        },
        lockIconContainer: {
            paddingLeft: '4px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom: '2px',
        },
        lockIcon: {
            fontSize: theme.typography.pxToRem(13),
        },
        flaggedStatus: {
            borderLeft: `8px solid ${theme.palette.custom.orange.huntingVest}`,
            position: 'relative',
        },
        tableCell: {
            maxWidth: '200px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
    }),
);

export default useStyles;
