import React, { useMemo } from 'react';
import useStyles from './styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { IconButton, Typography } from '@material-ui/core';
import { useAuth } from '@elevatormedia/duffel-bag';
import { useRouter } from 'next/router';
import { PATH_TO_PAGE_NAME } from 'config/Nav';
import Close from 'mdi-material-ui/Close';

/**
 * PermissionAlert component: displays a message to the user when they have insufficient permissions on a page.
 */
const PermissionAlert: React.FC<PermissionAlertProps> = () => {
    const classes = useStyles();

    const router = useRouter();
    const { permissionsMap } = useAuth();

    const [dismissed, setDismissed] = React.useState(false);

    const pagePermissions = useMemo(() => {
        return PATH_TO_PAGE_NAME[router.pathname].permissions || [];
    }, [router.pathname]);

    const { required, optional } = useMemo(() => {
        return pagePermissions
            .filter(({ type, operation }) => !permissionsMap[type][operation])
            .reduce(
                (prev, { required, type, operation }) => {
                    if (required) {
                        prev.required.push(`${type}:${operation}`);
                    } else prev.optional.push(`${type}:${operation}`);

                    return prev;
                },
                { required: [], optional: [] },
            );
    }, [permissionsMap, pagePermissions]);

    if (
        dismissed ||
        pagePermissions.length === 0 ||
        (required.length === 0 && optional.length === 0)
    )
        return null;

    return (
        <Alert
            severity={'error'}
            classes={{ root: classes.root }}
            action={
                <IconButton onClick={() => setDismissed(true)}>
                    <Close classes={{ root: classes.close }} />
                </IconButton>
            }
        >
            <AlertTitle>Insufficient Permissions</AlertTitle>
            <Typography variant={'body2'}>
                You are missing one or more permissions required to use features on this
                page.
            </Typography>
            {required.length > 0 && (
                <Typography variant={'body2'}>
                    <span className={classes.missingPermissions}>
                        Required Permissions:
                    </span>{' '}
                    <code className={classes.permissionName}>{required.join(', ')}</code>
                </Typography>
            )}
            {optional.length > 0 && (
                <Typography variant={'body2'}>
                    <span className={classes.missingPermissions}>
                        Optional Permissions:
                    </span>{' '}
                    <code className={classes.permissionName}>{optional.join(', ')}</code>
                </Typography>
            )}
        </Alert>
    );
};

interface PermissionAlertProps {}

export default PermissionAlert;
