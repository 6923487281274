import React from 'react';
import { Button, Chip, Typography, Divider } from '@material-ui/core';
import { GridContainer, GridItem } from '@elevatormedia/duffel-bag';
import CONTENT_TYPES from 'config/DataFieldTypes';
import FlaggedBadge from '../../atoms/FlaggedBadge';
import Link from '../../atoms/Link';
import { ArtTrack, Refresh } from '@material-ui/icons';
import { ROUTES } from 'config/Nav';
import { SubmissionStatus } from 'types/submission';
import { SocialMediaLink } from 'types/user';
import { Post } from 'types/post';
import { Service } from 'types/service';
import useStyles from './styles';
import { BaseRouteConfig } from 'types/nav';
import { SubmissionStatusChip } from 'atoms/SubmissionStatus';
import { DataRow } from 'molecules/DataRow';

const SubmissionSummaryInfo = (props: SubmissionSummaryInfoProps) => {
    const {
        service,
        status,
        flagged,
        submitter,
        socialMedia,
        onEdit,
        onApproveClick,
        onRejectClick,
        restartWorkflow,
        isSavingChanges,
        description,
        submissionTitle,
        post,
        submissionDate,
    } = props;

    const classes = useStyles();

    const renderSeeRelatedPost = (fullWidth = false) => {
        const relatedPostHref =
            (ROUTES.posts.editPost as BaseRouteConfig).to + `?postId=${post.postId}`;

        return (
            <Button
                size={fullWidth ? 'medium' : 'small'}
                variant={'text'}
                color={'primary'}
                component={Link}
                href={relatedPostHref}
                as={relatedPostHref}
                fullWidth={fullWidth}
            >
                <ArtTrack className={classes.inlineButtonIcon} fontSize={'small'} />
                See Post
            </Button>
        );
    };

    return (
        <div className={classes.root}>
            <GridContainer
                direction={'row'}
                spacing={1}
                justifyContent={'space-between'}
                alignItems={'center'}
                className={classes.quickViewChips}
            >
                <GridItem xs={4}>
                    <GridContainer direction={'row'} alignItems={'center'} spacing={1}>
                        {flagged && (
                            <GridItem>
                                <FlaggedBadge />
                            </GridItem>
                        )}

                        <GridItem>
                            <SubmissionStatusChip status={status} />
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs>
                    <GridContainer
                        direction={'row'}
                        justifyContent={'flex-end'}
                        alignItems={'center'}
                        spacing={1}
                    >
                        {status === 'Approved' && service.type !== 'submission' && (
                            <>
                                <GridItem>
                                    <Button
                                        size={'small'}
                                        onClick={onApproveClick}
                                        variant={'outlined'}
                                        className={classes.approveAction}
                                        disabled={isSavingChanges}
                                    >
                                        Complete
                                    </Button>
                                </GridItem>
                                <GridItem>
                                    <Button
                                        size={'small'}
                                        onClick={onRejectClick}
                                        variant={'outlined'}
                                        className={classes.denyAction}
                                        disabled={isSavingChanges}
                                    >
                                        Cancel & Refund
                                    </Button>
                                </GridItem>
                            </>
                        )}
                        {!['Completed', 'Rejected', 'Approved'].includes(status) ? (
                            <>
                                {onEdit ? (
                                    <GridItem>
                                        <Button
                                            size={'small'}
                                            onClick={onEdit}
                                            variant={'outlined'}
                                            className={classes.editButton}
                                            disabled={isSavingChanges}
                                        >
                                            Edit
                                        </Button>
                                    </GridItem>
                                ) : null}
                                <GridItem>
                                    <Button
                                        size={'small'}
                                        onClick={onApproveClick}
                                        variant={'outlined'}
                                        className={classes.approveAction}
                                        disabled={isSavingChanges}
                                    >
                                        Approve
                                    </Button>
                                </GridItem>
                                <GridItem>
                                    <Button
                                        size={'small'}
                                        onClick={onRejectClick}
                                        variant={'outlined'}
                                        className={classes.denyAction}
                                        disabled={isSavingChanges}
                                    >
                                        Decline
                                    </Button>
                                </GridItem>
                            </>
                        ) : (
                            service.type === 'submission' && (
                                <>
                                    {post && (
                                        <GridItem
                                            className={
                                                classes.desktopSeeRelatedPostContainer
                                            }
                                        >
                                            {renderSeeRelatedPost()}
                                        </GridItem>
                                    )}

                                    <GridItem>
                                        <Button
                                            size={'small'}
                                            onClick={restartWorkflow}
                                            variant={'outlined'}
                                            className={classes.denyAction}
                                        >
                                            <Refresh
                                                className={classes.inlineButtonIcon}
                                                fontSize={'small'}
                                            />
                                            Restart Workflow
                                        </Button>
                                    </GridItem>
                                </>
                            )
                        )}
                    </GridContainer>
                </GridItem>
            </GridContainer>
            {post && (
                <div className={classes.mobileSeeRelatedPostContainer}>
                    {renderSeeRelatedPost(true)}
                </div>
            )}

            <Divider />

            {/** Layer 2: Submitter's name, description, social media */}
            <div>
                {/** Layer 2: Title, Submitter, Description */}
                {typeof submissionTitle === 'string' ? (
                    <Typography
                        component={'span'}
                        variant={'h5'}
                        className={classes.submissionTitle}
                    >
                        {submissionTitle}
                        <div className={classes.submissionTypeChipContainer}>
                            <Chip
                                size={'small'}
                                variant={'default'}
                                label={service.name}
                                className={classes.typeChip}
                            />
                        </div>
                    </Typography>
                ) : (
                    submissionTitle
                )}

                <div className={classes.submitterInfoContainer}>
                    <Typography
                        variant={'body2'}
                        color={'textSecondary'}
                        className={classes.submitterInfo}
                    >
                        {submitter}
                        {' • '}
                        {submissionDate}
                    </Typography>

                    <Typography variant={'body1'} className={classes.contentDescription}>
                        {description}
                    </Typography>

                    <DataRow
                        label={'Social Media'}
                        socialMedia={socialMedia}
                        contentType={CONTENT_TYPES.SOCIALS}
                        addBottomBorder={false}
                    />
                </div>
            </div>
        </div>
    );
};

export type SubmissionSummaryInfoProps = {
    service: Service;
    submissionDate: string;
    status: SubmissionStatus;
    submitter: string;
    socialMedia?: Array<SocialMediaLink>;
    onEdit?: () => void;
    onApproveClick: () => void;
    onRejectClick: () => void;
    restartWorkflow: () => void;
    description: string;
    submissionTitle: string;
    flagged?: boolean;
    isSavingChanges?: boolean;
    post?: Post;
};

export default SubmissionSummaryInfo;
