import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        addNewPostIcon: {
            color: theme.palette.text.secondary,
        },
        actionRowContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
    }),
);

export default useStyles;
