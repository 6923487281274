import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '150px',
            width: '100%',
            minWidth: '200px',
            [theme.breakpoints.down('xs')]: {
                flexGrow: 1,
                height: '100px',
                minWidth: '150px',
            },
            border: `1px solid ${theme.palette.divider}`,
            boxShadow: 'none',
        },
        highContrastBackground: {
            backgroundColor: theme.palette.custom.background.success,
        },
        transparentBackground: {
            backgroundColor: 'transparent',
        },
        contents: {
            alignItems: 'center',
            display: 'flex',
        },
        title: {
            fontWeight: 700,
        },
        cardMetricText: {
            fontWeight: 500,
        },
        avatar: {
            height: 64,
            width: 64,
        },
        difference: {
            paddingTop: theme.spacing(4.5),
            display: 'flex',
            alignItems: 'center',
        },
        metricChangeContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        changeValue: {
            marginRight: theme.spacing(1),
        },
        negativeChange: {
            color: theme.palette.error.main,
        },
        positiveChange: {
            color: theme.palette.success.main,
        },
        cardActionArea: {
            flexGrow: 1,
            height: '100%',
        },
    }),
);

export default useStyles;
