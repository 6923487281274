import React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import useStyles from './styles';

const EnhancedTabFilter: React.FC<EnhancedTabFilterProps> = (props) => {
    const classes = useStyles();

    const { activeTab, handleChangeTab, tabLabels } = props;

    const renderTabs = () => {
        return tabLabels.map((label, index) => (
            <Tab
                disableRipple
                key={index}
                classes={{ root: classes.tab }}
                label={label}
            />
        ));
    };

    return (
        <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            TabIndicatorProps={{ children: <div /> }}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
            classes={{ root: classes.filterTabs, indicator: classes.indicator }}
        >
            {renderTabs()}
        </Tabs>
    );
};

export interface EnhancedTabFilterProps {
    activeTab: number;
    handleChangeTab: (event: any, newTab: number) => void;
    tabLabels: string[];
}

export default EnhancedTabFilter;
