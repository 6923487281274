import gql from 'graphql-tag';

const WRITEUP_QUEUE = gql`
    query writeUpQueue(
        $sort: SortInput
        $limit: Int
        $serviceType: ServiceType
        $services: [ID!]
    ) {
        submissions(
            where: {
                status: Approved
                flagged: false
                serviceType: $serviceType
                services: $services
            }
            sort: $sort
            limit: $limit
        ) {
            submissionId
            submissionType
            contentTitle
            contentUrl
            createdAt
            order {
                user {
                    userId
                    email
                    preferredUsername
                }
            }
            post {
                postId
                title
            }
            promotion {
                promotionId
                name
            }
            service {
                serviceId
                name
                type
                cost
            }
            count
        }
    }
`;

export const WRITEUP_QUEUE_MODAL = gql`
    query writeUpQueue($sort: SortInput, $limit: Int, $where: SubmissionWhereInput) {
        submissions(where: $where, sort: $sort, limit: $limit) {
            submissionId
            submissionType
            contentTitle
            contentUrl
            createdAt
            order {
                user {
                    userId
                    email
                    preferredUsername
                }
            }
            post {
                postId
                title
            }
            service {
                serviceId
                name
                type
                cost
            }
            count
        }
    }
`;

const SUBMISSIONS_INBOX = gql`
    query submissionsInbox(
        $where: SubmissionWhereInput
        $search: SubmissionSearchInput
        $limit: Int
        $skip: Int
        $sort: SortInput
    ) {
        submissionsInbox(
            where: $where
            search: $search
            limit: $limit
            skip: $skip
            sort: $sort
        ) {
            submissionId
            contentTitle
            contentUrl
            status
            flagged
            createdAt
            submissionType
            contentDescription
            contentImage {
                path
                sourceUrl
                sourceSet
                metadata {
                    bucket
                    mimetype
                    s3Url
                    filename
                }
            }
            order {
                orderId
                ipAddr
                isPaid
                status
                amountPaid
                flagged
                paymentType
                ccLast4
                transactionId
                user {
                    userId
                    preferredUsername
                    firstName
                    lastName
                    email
                    socialMedia {
                        type
                        link
                    }
                }
                referrer {
                    userId
                    preferredUsername
                    email
                    avatar {
                        path
                        sourceUrl
                        sourceSet
                    }
                }
            }
            preferredAuthorId
            preferredAuthor {
                userId
                username
                preferredUsername
                displayName
                firstName
                lastName
            }
            credits {
                creditId
                creditType {
                    typeName
                }
                name
                user {
                    userId
                    preferredUsername
                }
                socialMedia {
                    type
                    link
                }
            }
            activity {
                eventId
                eventType
                eventTime
                actionTaken
                arguments
                success
                user {
                    preferredUsername
                    avatar {
                        sourceUrl
                    }
                }
            }
            service {
                serviceId
                name
                type
                cost
            }
            count
        }
    }
`;

const ALL_SUBMISSIONS = gql`
    query submissions(
        $where: SubmissionWhereInput
        $search: SubmissionSearchInput
        $limit: Int
        $skip: Int
        $sort: SortInput
    ) {
        submissions(
            where: $where
            search: $search
            limit: $limit
            skip: $skip
            sort: $sort
        ) {
            submissionId
            contentTitle
            contentUrl
            contentDescription
            status
            flagged
            createdAt
            submissionType
            sponsoredBy
            order {
                user {
                    userId
                    preferredUsername
                    email
                }
            }
            preferredAuthorId
            preferredAuthor {
                userId
                username
                preferredUsername
                displayName
                firstName
                lastName
            }
            service {
                serviceId
                name
                type
                cost
                parent {
                    serviceId
                }
                addOns {
                    serviceId
                    name
                    cost
                    type
                    autoCapture
                }
            }
            services {
                serviceId
                name
                type
                cost
                parent {
                    serviceId
                }
                addOns {
                    serviceId
                    name
                    cost
                    type
                    autoCapture
                }
            }
            count
        }
    }
`;

const FLAGGED_SUBMISSIONS = gql`
    query submissions(
        $sort: SortInput
        $limit: Int
        $status: SubmissionStatus
        $submissionType: SubmissionType
        $search: SubmissionSearchInput
        $services: [ID!]
    ) {
        submissions(
            where: {
                status: $status
                flagged: true
                submissionType: $submissionType
                services: $services
            }
            search: $search
            limit: $limit
            sort: $sort
        ) {
            submissionId
            contentTitle
            contentUrl
            contentDescription
            status
            createdAt
            submissionType
            order {
                paymentType
                isPaid
                user {
                    userId
                    preferredUsername
                    email
                }
            }
            service {
                serviceId
                name
                type
                cost
            }
            count
        }
    }
`;

const SUBMISSION_BY_ID = gql`
    query($submissionId: ID!) {
        submission(submissionId: $submissionId) {
            post {
                postId
            }
            submissionId
            orderNumber
            contentTitle
            contentUrl
            status
            flagged
            createdAt
            submissionType
            contentDescription
            contentImage {
                path
                sourceUrl
                sourceSet
                metadata {
                    bucket
                    mimetype
                    s3Url
                    filename
                }
            }
            sponsoredBy
            order {
                orderId
                ipAddr
                isPaid
                status
                flagged
                ccLast4
                transactionId
                amountPaid
                paymentType
                user {
                    userId
                    preferredUsername
                    firstName
                    lastName
                    email
                    socialMedia {
                        type
                        link
                    }
                }
                referrer {
                    userId
                    preferredUsername
                    email
                    avatar {
                        path
                        sourceUrl
                        sourceSet
                    }
                }
            }
            preferredAuthorId
            preferredAuthor {
                userId
                username
                preferredUsername
                displayName
                firstName
                lastName
            }
            credits {
                creditId
                creditType {
                    typeName
                }
                name
                user {
                    userId
                    preferredUsername
                    avatar {
                        sourceUrl
                        sourceSet
                    }
                    socialMedia {
                        type
                        link
                    }
                }
                socialMedia {
                    type
                    link
                }
            }
            activity {
                eventId
                eventType
                eventTime
                actionTaken
                arguments
                success
                user {
                    preferredUsername
                    avatar {
                        sourceUrl
                    }
                }
            }
            service {
                serviceId
                name
                type
                cost
                parent {
                    serviceId
                }
                addOns {
                    serviceId
                    name
                    cost
                    type
                    autoCapture
                }
            }
            services {
                serviceId
                name
                type
                cost
                parent {
                    serviceId
                }
                addOns {
                    serviceId
                    name
                    cost
                    type
                    autoCapture
                }
            }
        }
    }
`;

export {
    WRITEUP_QUEUE,
    SUBMISSIONS_INBOX,
    ALL_SUBMISSIONS,
    FLAGGED_SUBMISSIONS,
    SUBMISSION_BY_ID,
};
