import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: '500px',
        },
        bottomDrawer: {
            margin: theme.spacing(1, 2),
        },
        sectionContainer: {
            padding: theme.spacing(2, 0),
        },
        buttonContainer: {
            paddingTop: theme.spacing(2),
        },
    }),
);

export default useStyles;
