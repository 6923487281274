import React from 'react';
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Collapse,
    Container,
    Typography,
} from '@material-ui/core';
import Link from '../../atoms/Link';
import Grow from '@material-ui/core/Grow';
import { SubmissionSummaryInfo } from '..';
import CONTENT_TYPES from '../../config/DataFieldTypes';
import { formatDate, formatPrice } from '@elevatormedia/duffel-bag';
import { DataRow, TransactionCard } from '../../molecules/DataRow';
import EnhancedCardHeader from '../../atoms/EnhancedCardHeader';
import { EventItem, EventTimeline } from '../EventTimeline';
import MediaEmbed from '../../molecules/MediaEmbed/MediaEmbed';
import { FullSubmission } from 'types/submission';
import useStyles from './styles';
import UserChip from 'molecules/UserChip';

const SubmissionReport: React.FC<SubmissionReportProps> = ({
    submission,
    approveSubmission,
    rejectSubmission,
    restartWorkflow,
    isSavingChanges,
    rowIndex,
    canCreateRefund,
}) => {
    const [expanded, setExpanded] = React.useState(false);
    const classes = useStyles();

    const {
        contentUrl: contentURL,
        submissionId: id,
        createdAt: submissionTime,
        contentDescription: description,
        status,
        contentImage,
        credits,
        order,
        contentTitle: submissionTitle,
        post,
        service,
        services,
        orderNumber,
    } = submission;

    const { amountPaid, ipAddr: submitterIPAddress, user } = order;

    const { preferredUsername: submitter, email: submitterEmail } = user;

    const amountPaidFormatted = formatPrice(amountPaid);

    const addOnNameArray = services
        .filter((service) => service.parent)
        .map((service) => {
            return service.name;
        });

    const handleSeeMoreclick = () => {
        setExpanded(!expanded);
    };

    /**
     * Renders the minimal identifying information pertaining to a single submission
     * which includes:
     * - Submission Title
     * - Submitter's Name
     * - Submission Type
     * - Submission Status
     * - Description
     */
    const renderSubmissionInfoBasic = () => {
        return (
            <SubmissionSummaryInfo
                submissionTitle={submissionTitle}
                service={service}
                status={status}
                submitter={submitter}
                socialMedia={submission.order.user.socialMedia}
                onApproveClick={() => {
                    approveSubmission(submission, rowIndex);
                }}
                onRejectClick={() => {
                    rejectSubmission(submission, rowIndex);
                }}
                restartWorkflow={() => restartWorkflow(submission, rowIndex)}
                description={description}
                submissionDate={formatDate(new Date(parseInt(submissionTime))) as string}
                flagged={submission.flagged}
                isSavingChanges={isSavingChanges}
                post={post}
            />
        );
    };

    /**
     * Rendered below the basic submission info. allows viewing a detailed view of the
     * submission data
     */
    const renderSeeMoreToggle = () => {
        return (
            <Box paddingTop={2}>
                <CardActionArea>
                    <Container
                        onClick={handleSeeMoreclick}
                        className={classes.seeMoreContainer}
                    >
                        <Typography variant={'button'} color={'primary'}>
                            {expanded ? 'See Less' : 'See More'}
                        </Typography>
                    </Container>
                </CardActionArea>
            </Box>
        );
    };

    /**
     * Renders the detailed version of the submission info
     */
    const renderSubmissionInfoExtended = () => (
        <div>
            <CardContent>
                <DataRow
                    label={'Submission Id'}
                    values={[id]}
                    contentType={CONTENT_TYPES.LINES}
                />
                <DataRow
                    label={'Submission Date'}
                    values={[formatDate(parseInt(submissionTime)) as string]}
                    contentType={CONTENT_TYPES.LINES}
                />
                <DataRow
                    label={'Name'}
                    values={[submitter]}
                    contentType={CONTENT_TYPES.LINES}
                />
                <DataRow
                    label={'Email'}
                    values={[submitterEmail]}
                    contentType={CONTENT_TYPES.LINES}
                />
                <DataRow
                    label={'Add Ons'}
                    values={addOnNameArray}
                    contentType={CONTENT_TYPES.LINES}
                />
                <DataRow
                    label={'Link'}
                    hrefs={[contentURL]}
                    contentType={CONTENT_TYPES.LINKS}
                />
                {contentImage && (
                    <DataRow
                        label={'Art'}
                        imageProps={{ source: contentImage.sourceUrl }}
                        contentType={CONTENT_TYPES.IMAGE}
                        sourceSet={contentImage.sourceSet}
                    />
                )}
                <DataRow
                    label={'Credits'}
                    credits={credits}
                    contentType={CONTENT_TYPES.CREDITS}
                />
                {submission && submission.preferredAuthor ? (
                    <DataRow label={'Preferred Author'} contentType={CONTENT_TYPES.NODE}>
                        <Link href={'/users/' + submission.preferredAuthor.username}>
                            {submission &&
                            submission.preferredAuthor &&
                            submission.preferredAuthor.preferredUsername
                                ? `${submission.preferredAuthor.preferredUsername}`
                                : 'None'}
                        </Link>
                        ,
                    </DataRow>
                ) : null}

                {order.referrer ? (
                    <DataRow label={'Referrer'} contentType={CONTENT_TYPES.NODE}>
                        <div>
                            <UserChip
                                username={order.referrer?.preferredUsername}
                                avatar={{
                                    sourceUrl: order.referrer?.avatar.sourceUrl,
                                    sourceSet: order.referrer?.avatar.sourceSet,
                                }}
                            />
                        </div>
                    </DataRow>
                ) : null}

                <DataRow
                    label={'Ip Address'}
                    values={[submitterIPAddress]}
                    contentType={CONTENT_TYPES.LINES}
                />
                <DataRow
                    label={'Transaction ID'}
                    values={[order.transactionId]}
                    contentType={CONTENT_TYPES.LINES}
                />
                <DataRow
                    label={'Order Number'}
                    values={[orderNumber]}
                    contentType={CONTENT_TYPES.LINES}
                />
                <DataRow
                    label={'Order Details'}
                    contentType={CONTENT_TYPES.NODE}
                    addBottomBorder={false}
                >
                    <TransactionCard
                        services={services}
                        order={order}
                        submissionId={id}
                        canCreateRefund={canCreateRefund}
                    />
                </DataRow>
            </CardContent>
        </div>
    );

    return (
        <>
            <div className={classes.root}>
                <Grow in timeout={500}>
                    <Card elevation={0} className={classes.card}>
                        <MediaEmbed contentURL={contentURL} />
                        <CardContent>
                            {renderSubmissionInfoBasic()}
                            {renderSeeMoreToggle()}
                        </CardContent>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            {renderSubmissionInfoExtended()}
                        </Collapse>
                    </Card>
                </Grow>
                <Box paddingTop={2} width={'100%'}>
                    <div>
                        <EnhancedCardHeader title={'Submission History'} />
                        <Box paddingTop={2}>
                            <EventTimeline>
                                <EventItem
                                    key={0}
                                    event={{
                                        actionTaken: 'submitted',
                                        eventTime: submissionTime,
                                        user,
                                        metadata: {
                                            paymentTotal: amountPaidFormatted,
                                        },
                                    }}
                                />
                                {submission.activity.map((eventItem, index) => (
                                    <EventItem key={index + 1} event={eventItem} />
                                ))}
                            </EventTimeline>
                        </Box>
                    </div>
                </Box>
            </div>
        </>
    );
};

interface SubmissionReportProps {
    submission: FullSubmission;
    approveSubmission:
        | (() => void)
        | ((submission: FullSubmission, index: number) => void);
    rejectSubmission:
        | (() => void)
        | ((submission: FullSubmission, index: number) => void);
    restartWorkflow: (() => void) | ((submission: FullSubmission, index: number) => void);
    canCreateRefund: boolean;
    isSavingChanges?: boolean;
    onNext?: () => void;
    rowIndex?: number;
}

export default SubmissionReport;
