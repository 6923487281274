import React from 'react';
import DateTimePickerDialog, { DateTimePickerDialogProps } from './DateTimePickerDialog';
import { object as yupObject, date as yupDate } from 'yup';
import { Formik } from 'formik';

const Index: React.FC<DateTimePickerIndexProps> = (props) => {
    const { initialDate, handleClose, onSchedule, ...restDateTimePickerDialogProps } =
        props;

    const validationSchema = yupObject({
        dateTime: yupDate()
            .test('noPriorDates', 'Selection must be future date & time', (dateTime) => {
                if (!dateTime) return true;

                const currentDateTime = new Date(Date.now());

                return dateTime.getTime() > currentDateTime.getTime();
            })
            .required('A valid date is required'),
    });

    const initialValues = {
        dateTime: new Date(initialDate || Date.now()),
        // TODO check date parsing is still valid
        // dateTime: initialDate ? formatDate(initialDate, false) : new Date(Date.now()),
    };

    const handleSubmit = (values: typeof initialValues) => {
        const { dateTime } = values;
        onSchedule(dateTime);
        handleClose();
    };

    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initialValues}
            validOnMount={false}
            onSubmit={handleSubmit}
        >
            {(props) => (
                <DateTimePickerDialog
                    handleClose={handleClose}
                    {...restDateTimePickerDialogProps}
                    {...props}
                />
            )}
        </Formik>
    );
};

export default Index;

interface DateTimePickerIndexProps extends DateTimePickerDialogProps {
    initialDate?: string | number | Date;
    onSchedule: (datetime: Date) => void;
}
