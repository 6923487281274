import React from 'react';
import clsx from 'clsx';
import { EnhancedTabFilter } from '..';
import useStyles from './styles';
import { EnhancedTabFilterProps } from 'molecules/EnhancedTabFilter/EnhancedTabFilter';

// NOTE: Allow Dynamic count on submissions for Tabfilters.
const SubmissionTypeTabFilter: React.FC<EnhancedTabFilterProps> = (props) => {
    const classes = useStyles();

    const { activeTab, handleChangeTab, tabLabels } = props;

    return (
        <div className={clsx(classes.screenToolbar, classes.borderBottom)}>
            <EnhancedTabFilter
                activeTab={activeTab}
                handleChangeTab={handleChangeTab}
                tabLabels={tabLabels}
            />
        </div>
    );
};

export default SubmissionTypeTabFilter;
