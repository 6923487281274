import React from 'react';
import useStyles from './styles';
import MaterialIcon from '@material/react-material-icon';
import {
    Settings as AccountSettingsIcon,
    ExitToAppOutlined as SignOutIcon,
    Home,
} from '@material-ui/icons';
import { BookOpenPageVariant as GoToDocsIcon, Lifebuoy, Bug } from 'mdi-material-ui';
import {
    MenuItem,
    Typography,
    Avatar,
    Popover,
    MenuList,
    Paper,
    ListItemIcon,
    ListItemText,
    Divider,
} from '@material-ui/core';
import Router from 'next/router';
import { ROUTES, REDIRECTS } from '../../config/Nav';
import { GridContainer, GridItem, signOut } from '@elevatormedia/duffel-bag';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import { authEndpoint } from 'lib/auth';
import { BaseRouteConfig } from 'types/nav';

/**
 * Account Popover component: a popup card used to display account information
 * about the user as well as clickable options for navigation.
 */
const AccountPopover: React.FC<AccountPopoverProps> = (props) => {
    const classes = useStyles();

    const { anchorEl, avatarURL, userName, email, setAnchorEl } = props;
    const open = Boolean(anchorEl);
    const id = open ? 'account-popover' : undefined;

    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const apolloClient = useApolloClient();

    // Configurable Menu Options as JS objects
    const menuOptions = [
        {
            listItemIcon: <Home />,
            listItemText: 'Site',
            onListItemPress: () => {
                window && window.open(REDIRECTS.website.uri);
            },
        },
        {
            listItemIcon: <AccountSettingsIcon />,
            listItemText: 'Settings',
            onListItemPress: () => {
                Router.push((ROUTES.me.accountSettings as BaseRouteConfig).to);
            },
        },
        {
            listItemIcon: <SignOutIcon />,
            listItemText: 'Logout',
            onListItemPress: async () => {
                await signOut(authEndpoint);
                await apolloClient.clearStore();
                window.location.replace(REDIRECTS.auth.signin.uri);
                enqueueSnackbar(`You've been successfully logged out`, {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    variant: 'success',
                    preventDuplicate: true,
                });
            },
        },
    ];
    const supportOptions = [
        {
            listItemIcon: <Bug />,
            listItemText: 'Report Bug',
            onListItemPress: () => {
                window &&
                    window.location.replace(
                        (ROUTES.support.reportAnIssue as BaseRouteConfig).to,
                    );
            },
        },
        {
            listItemIcon: <GoToDocsIcon />,
            listItemText: 'Developer Docs',
            onListItemPress: () => {
                window && window.location.replace(REDIRECTS.docsite.uri);
            },
        },
    ];

    /**
     * Wrapping function which assures that the AccountPopover component
     * closes upon the callback of the parameter function.
     */
    const handleListItemPress = (itemOnPress: () => void) => {
        itemOnPress();
        handleClose();
    };

    /**
     * Handler function for closing the popover menu
     */
    const handleClose = () => {
        closeSnackbar();
        setAnchorEl(null);
    };

    /**
     * Dynamically Renders Menu Items based on JS object configuration
     */
    const renderPopoverMenuItems = (options: Array<any>) => {
        return options.map(({ listItemIcon, listItemText, onListItemPress }, index) => {
            return (
                <MenuItem
                    className={classes.menuItem}
                    key={index}
                    onClick={() => {
                        handleListItemPress(onListItemPress);
                    }}
                >
                    <GridContainer
                        className={classes.listItemContainer}
                        direction={'row'}
                        alignItems={'center'}
                    >
                        <GridItem>
                            <ListItemIcon className={classes.listItemIcon}>
                                {listItemIcon}
                            </ListItemIcon>
                        </GridItem>
                        <GridItem>
                            <ListItemText classes={{ primary: classes.listItemText }}>
                                {listItemText}
                            </ListItemText>
                        </GridItem>
                    </GridContainer>
                </MenuItem>
            );
        });
    };

    const renderVersionText = () => {
        return (
            <GridContainer direction={'row'} spacing={1} justifyContent={'center'}>
                <GridItem>
                    <MaterialIcon icon="api" className={classes.versionIcon} />
                </GridItem>
                <GridItem>
                    <Typography className={classes.versionText}>
                        v{require('../../../package.json').version}
                    </Typography>
                </GridItem>
            </GridContainer>
        );
    };

    return (
        <Popover
            id={id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Paper id="menu" className={classes.accountMenu}>
                <div className={classes.cardHeader}>
                    <div className={classes.avatarContainer}>
                        <Avatar className={classes.accountCardAvatar} src={avatarURL}>
                            {userName.toUpperCase()[0]}
                        </Avatar>
                    </div>
                    <div className={classes.accountInfoContainer}>
                        <Typography
                            component={'p'}
                            variant={'h6'}
                            className={classes.userName}
                        >
                            {userName}
                        </Typography>
                        <Typography
                            component={'p'}
                            variant={'body2'}
                            color={'textSecondary'}
                        >
                            {email}
                        </Typography>
                    </div>
                </div>
                <MenuList>{renderPopoverMenuItems(menuOptions)}</MenuList>
                <Typography className={classes.supportHeader} variant={'h6'}>
                    Support
                </Typography>
                <Divider className={classes.divider} />
                <MenuList>{renderPopoverMenuItems(supportOptions)}</MenuList>
                {renderVersionText()}
            </Paper>
        </Popover>
    );
};

interface AccountPopoverProps {
    userName: string;
    email: string;
    avatarURL?: string;
    anchorEl?: any;
    setAnchorEl?: (value: any) => void;
}

export default AccountPopover;
