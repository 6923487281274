import React from 'react';
import Searchbar from './Searchbar';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import useStyles from './styles';

const FieldSpecificSearchbar: React.FC<FieldSpecificSearchbarProps> = (props) => {
    const classes = useStyles();
    const {
        fieldQualifiers,
        searchBy,
        setSearchBy,
        onSearch,
        onClear,
        setQueryVariable,
        searchValue,
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleSearchChange: React.ChangeEventHandler<
        HTMLTextAreaElement | HTMLInputElement
    > = (event) => {
        setQueryVariable(event.target.value);

        // If the text has been cleared and onClear cb is defined, execute cb
        if (event.target.value.length < 1) {
            if (onClear) {
                onClear();
            }
        }
    };
    /**
     * Handles Setting a new element as the selected element.
     */
    const handleMenuItemClick = (value: string) => {
        onClear && onClear();
        setSearchBy(value);
        handleClose();
    };

    /**
     * Sets new anchor point for which the menu to attach itself too
     */
    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Removes anchor point component ref
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * Renders the trigger for the popover menu. In this case it is a dropdown arrow
     */
    const renderMenuTrigger = () => {
        return (
            <IconButton onClick={handleClick} color={'default'}>
                <ArrowDropDown className={classes.dropdownArrow} />
            </IconButton>
        );
    };

    const renderMenuPopup = () => {
        return (
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {fieldQualifiers.map((option, index) => (
                    <MenuItem
                        key={index}
                        selected={searchBy === fieldQualifiers[index]}
                        onClick={() => handleMenuItemClick(option)}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        );
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            onSearch(searchValue);
        }
    };

    return (
        <Searchbar
            onSubmit={() => onSearch(searchValue)}
            onChange={handleSearchChange}
            placeholder={`Search By ${searchBy}`}
            value={searchValue}
            onKeyPress={handleKeyPress}
        >
            {renderMenuTrigger()}
            {renderMenuPopup()}
        </Searchbar>
    );
};

interface FieldSpecificSearchbarProps {
    fieldQualifiers: string[];
    searchBy: string;
    setSearchBy: (search: string) => void;
    onSearch: (searchValue: string) => void;
    setQueryVariable: (query: string) => void;
    searchValue: string;
    onClear?: () => void;
}

export default FieldSpecificSearchbar;
