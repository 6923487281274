import React from 'react';
import {
    Avatar,
    Button,
    Checkbox,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    CircularProgress,
    Box,
} from '@material-ui/core';
import { formatDate24Hr, formatDateOnly } from '@elevatormedia/duffel-bag';
import useStyles from './styles';
import { Flag } from 'mdi-material-ui';
import { SubmissionStatusCircle } from 'atoms/SubmissionStatus';

const todaysDate = new Date();

const SubmissionsList: React.FC<SubmissionsListProp> = (props) => {
    const classes = useStyles();
    const {
        handleRowSelectToggle,
        handleRowOnClick,
        rowIDPropName,
        rows,
        isSelected,
        renderCheckbox = true,
        renderSubmissionStatus = true,
        page,
        handleChangePage,
        loading,
        searchParam,
    } = props;

    if (loading) return <CircularProgress />;

    const getDynamicDateTime = (submissionDate: Date, orgTimeObj: string) => {
        const isToday =
            todaysDate.getDate() === submissionDate.getDate() &&
            todaysDate.getMonth() === submissionDate.getMonth() &&
            todaysDate.getFullYear() === submissionDate.getFullYear();

        if (isToday) {
            // submission was made today
            return formatDate24Hr(orgTimeObj);
        } else {
            return formatDateOnly(orgTimeObj);
        }
    };

    /**
     * Handles loading more data from the database and appending them to the end of
     * the list
     * NOTE scrolls to top
     */
    const handleLoadMore = () => {
        handleChangePage(page + 1);
    };

    const renderSubmissionRows = () => {
        return rows.map((row, index) => {
            const rowIsSelected = isSelected && isSelected(row[rowIDPropName]);
            const onClick = () => handleRowOnClick(row, index);
            return (
                <ListItem
                    onClick={onClick}
                    key={index}
                    button
                    selected={rowIsSelected}
                    className={classes.listItem}
                    alignItems={'flex-start'}
                >
                    {renderSubmissionStatus && (
                        <ListItemAvatar className={classes.listItemAvatar}>
                            <Avatar className={classes.submissionStatusAvatar}>
                                <SubmissionStatusCircle
                                    size={'small'}
                                    status={row.status}
                                />
                            </Avatar>
                        </ListItemAvatar>
                    )}
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    variant="body1"
                                    className={classes.listItemText}
                                >
                                    {row.contentTitle}
                                </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="textSecondary"
                                    className={classes.listItemText}
                                >
                                    {row.order.user.preferredUsername}
                                    {' • ' +
                                        getDynamicDateTime(
                                            new Date(parseInt(row.createdAt)),
                                            row.createdAt,
                                        )}
                                    {row.flagged && (
                                        <>
                                            {' • '}
                                            <Flag className={classes.flaggedSub} />
                                        </>
                                    )}
                                </Typography>
                            </React.Fragment>
                        }
                    />

                    <ListItemSecondaryAction className={classes.listItemRight}>
                        {renderCheckbox && (
                            <Checkbox
                                edge="end"
                                color={'primary'}
                                onChange={() => handleRowSelectToggle(row)}
                                checked={rowIsSelected}
                            />
                        )}
                    </ListItemSecondaryAction>
                </ListItem>
            );
        });
    };

    const renderList = () => {
        return (
            <div>
                <List>{renderSubmissionRows()}</List>
                {rows.length && rows[0].count > rows.length ? (
                    <Button
                        onClick={handleLoadMore}
                        fullWidth
                        color={'primary'}
                        variant={'outlined'}
                    >
                        Load More
                    </Button>
                ) : (
                    <Box display="flex" justifyContent="center">
                        <Typography variant="body2" color="textSecondary">
                            End of List
                        </Typography>
                    </Box>
                )}
            </div>
        );
    };

    const renderEmptyState = () => {
        return (
            <Box paddingY={2}>
                <Typography color={'textSecondary'}>
                    No Submissions Found "{searchParam ? searchParam : ''}"
                </Typography>
            </Box>
        );
    };

    return rows.length < 1 ? renderEmptyState() : renderList();
};

interface SubmissionsListProp<T = any> {
    rows?: T[];
    rowIDPropName?: string;
    renderSubmissionStatus?: boolean;
    renderCheckbox?: boolean;
    loading?: boolean;
    isSelected?: (rowId: string) => boolean;
    searchParam?: string;
    handleRowSelectToggle?: (row: T) => void;
    handleRowOnClick?: (row: T, index: number) => void;
    page?: number;
    handleChangePage?: (page: number) => void;
}

export default SubmissionsList;
