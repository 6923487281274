import React from 'react';
import {
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Checkbox,
} from '@material-ui/core';
import useStyles from './styles';
import { Lock } from 'mdi-material-ui';
import clsx from 'clsx';

const EnhancedTableHeader: React.FC<EnhancedTableHeaderProps> = ({
    columnHeaders,
    onSelectAllClick,
    order,
    orderBy,
    onRequestSort,
    rowsPerPage,
    numSelected = 0,
    ignoreColumns = [],
    ignoreColumnsSort = [],
    disableSelectAll = false,
}) => {
    const classes = useStyles();

    /**
     * Handler function which is called when a user clicks on a column
     * to be sorted. This function then passes the name of the the column
     * to be sorted to the cb.
     */
    const createSortHandler =
        (property: string): React.MouseEventHandler<HTMLSpanElement> =>
        () => {
            onRequestSort(property);
        };

    /**
     * Renders a table header text label which allows onClick functionality
     * for sorting.
     */
    const renderTableCellContent = (headerConfig: TableHeaderItem) => {
        if (!headerConfig.label) return null;

        const label = headerConfig.label;

        const isDisabled = ignoreColumnsSort.includes(headerConfig.id);

        return (
            <TableSortLabel
                active={orderBy === headerConfig.id}
                direction={order}
                onClick={createSortHandler(headerConfig.id)}
                disabled={isDisabled}
                className={clsx(isDisabled && classes.baseHeaderText)}
            >
                {label}
                {isDisabled && (
                    <div className={classes.lockIconContainer}>
                        <Lock className={classes.lockIcon} />
                    </div>
                )}
            </TableSortLabel>
        );
    };

    return (
        <TableHead>
            <TableRow>
                {!disableSelectAll && (
                    <TableCell padding={'checkbox'}>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowsPerPage}
                            checked={numSelected === rowsPerPage}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all rows' }}
                            color={'primary'}
                        />
                    </TableCell>
                )}
                {columnHeaders.map((headerConfig, index) => {
                    if (ignoreColumns.includes(headerConfig.id)) return;
                    return (
                        <TableCell
                            key={index}
                            align={'left'}
                            padding={'normal'}
                            sortDirection={orderBy === headerConfig.id ? order : false}
                        >
                            {renderTableCellContent(headerConfig)}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

export type TableHeaderItem = {
    id: string;
    label?: string;
};

export interface EnhancedTableHeaderProps {
    columnHeaders: Array<TableHeaderItem>;
    onSelectAllClick?: (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ) => void;
    order: 'asc' | 'desc';
    orderBy?: string;
    numSelected?: number;
    rowsPerPage: number;
    onRequestSort: (property: string) => void;
    ignoreColumns?: string[];
    ignoreColumnsSort?: string[];
    disableSelectAll?: boolean;
}

export default EnhancedTableHeader;
