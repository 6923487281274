import gql from 'graphql-tag';

const ALL_USERS = gql`
    query allUsers(
        $where: UserWhereInput
        $search: UserSearchInput
        $limit: Int
        $skip: Int
        $sort: SortInput
    ) {
        users(where: $where, search: $search, limit: $limit, skip: $skip, sort: $sort) {
            createdAt
            userId
            email
            preferredUsername
            firstName
            lastName
            avatar {
                path
                sourceUrl
                sourceSet
                metadata {
                    bucket
                    mimetype
                    s3Url
                    filename
                }
            }
            permissionGroup {
                groupId
                groupName
            }
            count
        }
    }
`;

const AUTHORS = gql`
    query authors {
        authors {
            userId
            preferredUsername
            displayName
            avatar {
                sourceUrl
                sourceSet
            }
        }
    }
`;

const USER_EXISTS = gql`
    query userExists($email: String, $username: String) {
        emailUsed: userExists(email: $email)
        usernameUsed: userExists(preferredUsername: $username)
    }
`;

const USER_AND_PERMISSIONS = gql`
    query user($userId: ID, $email: String, $preferredUsername: String) {
        user(userId: $userId, email: $email, preferredUsername: $preferredUsername) {
            userId
            email
            emailVerified
            username
            preferredUsername
            firstName
            lastName
            role
            avatar {
                path
                sourceUrl
                sourceSet
                metadata {
                    bucket
                    mimetype
                    s3Url
                    filename
                }
            }
            bio
            socialMedia {
                type
                link
            }
            disabled
            preferred
            referToken
            createdAt
            updatedAt
            permissionGroup {
                groupId
                groupName
                groupDesc
                permissionLevel
            }
        }
        userPermissions(userId: $userId, preferredUsername: $preferredUsername) {
            permissionId
            access
            operationName
            description
            type
            permissionGroup {
                groupId
                groupName
                groupDesc
                permissionLevel
            }
        }
    }
`;

const USER = gql`
    query user($userId: ID, $email: String, $preferredUsername: String) {
        user(userId: $userId, email: $email, preferredUsername: $preferredUsername) {
            userId
            email
            emailVerified
            username
            preferredUsername
            firstName
            lastName
            role
            avatar {
                path
                sourceUrl
                sourceSet
                metadata {
                    bucket
                    mimetype
                    s3Url
                    filename
                }
            }
            bio
            socialMedia {
                type
                link
            }
            disabled
            preferred
            referToken
            createdAt
            updatedAt
            permissionGroup {
                groupId
                groupName
                groupDesc
                permissionLevel
            }
        }
    }
`;

const USER_PERMISSIONS = gql`
    query userPermissions($userId: ID, $preferredUsername: String) {
        userPermissions(userId: $userId, preferredUsername: $preferredUsername) {
            permissionId
            access
            operationName
            description
            type
            permissionGroup {
                groupId
                groupName
                groupDesc
                permissionLevel
            }
        }
    }
`;

const MY_USER_AND_PERMISSIONS = gql`
    query myUser {
        myUser {
            userId
            email
            emailVerified
            username
            preferredUsername
            firstName
            lastName
            role
            avatar {
                path
                sourceUrl
                sourceSet
                metadata {
                    bucket
                    mimetype
                    s3Url
                    filename
                }
            }
            bio
            socialMedia {
                type
                link
            }
            disabled
            preferred
            referToken
            createdAt
            updatedAt
            permissionGroup {
                groupId
                groupName
                groupDesc
                permissionLevel
            }
        }
        userPermissions: myUserPermissions {
            permissionId
            access
            operationName
            description
            type
            permissionGroup {
                groupId
                groupName
                groupDesc
                permissionLevel
            }
        }
    }
`;

const MY_USER = gql`
    query myUser {
        myUser {
            userId
            email
            username
            preferredUsername
            firstName
            lastName
            role
            avatar {
                path
                sourceUrl
                sourceSet
                metadata {
                    bucket
                    mimetype
                    s3Url
                    filename
                }
            }
            bio
            socialMedia {
                type
                link
            }
            disabled
            preferred
            createdAt
            updatedAt
            permissionGroup {
                groupId
                groupName
                groupDesc
                permissionLevel
            }
        }
    }
`;

const MY_PERMISSIONS = gql`
    query myPermissions {
        userPermissions: myUserPermissions {
            permissionId
            access
            operationName
            description
            type
            permissionGroup {
                groupId
                groupName
                groupDesc
                permissionLevel
            }
        }
    }
`;

const SEARCH_USERS = gql`
    query searchUsers($input: String!) {
        searchUsers(input: $input) {
            userId
            preferredUsername
            email
            socialMedia {
                type
                link
            }
            avatar {
                sourceUrl
                sourceSet
            }
        }
    }
`;

export {
    ALL_USERS,
    AUTHORS,
    USER_EXISTS,
    USER_AND_PERMISSIONS,
    USER,
    USER_PERMISSIONS,
    MY_USER_AND_PERMISSIONS,
    MY_USER,
    MY_PERMISSIONS,
    SEARCH_USERS,
};
