import React from 'react';
import { Padding, TableCell, TableCellProps } from '@material-ui/core';
import useStyles from './styles';

const EnhancedColumn: React.FC<EnhancedColumnProps> = ({
    children,
    padding = 'normal',
    handleClick,
    rowData,
    rowIndex,
}) => {
    const classes = useStyles();

    const handleRowOnClick: TableCellProps['onClick'] = (event) => {
        if (handleClick) {
            handleClick(event, rowData, rowIndex);
        }
    };

    return (
        <TableCell
            onClick={handleRowOnClick}
            padding={padding}
            align={'left'}
            scope={'row'}
            className={classes.tableCell}
        >
            {children}
        </TableCell>
    );
};

interface EnhancedColumnProps<T = any> {
    handleClick?: T extends undefined
        ? never
        : (event: any, rowData: T, rowIndex: number) => void;
    rowData?: T extends undefined ? never : T;
    rowIndex?: T extends undefined ? never : number;
    padding?: Padding;
}

export default EnhancedColumn;
