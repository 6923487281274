import React from 'react';
import useStyles from '../styles';
import {
    Box,
    Collapse,
    FormControlLabel,
    Switch,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { GridItem } from '@elevatormedia/duffel-bag/dist/atoms/Grid';
import { FeaturedPositionSelector } from 'molecules';
import { useFormikContext } from 'formik';
import { PostEditorFormValues } from '..';
import { FeaturedPostPosition } from 'types/post';

const PostOptionsForm = (props: PostOptionsFormProps) => {
    const classes = useStyles();

    const { featured, postId, handleTouchedField, handleInputChange } = props;

    const {
        values,
        handleChange,
        isSubmitting,
        setFieldTouched,
    } = useFormikContext<PostEditorFormValues>();

    const cantBeFeatured = values.status !== 'published';

    return (
        <>
            <GridItem className={classes.textFieldContainer}>
                <Tooltip
                    title={
                        cantBeFeatured
                            ? 'A post must be published before it can be featured on the home page'
                            : ''
                    }
                >
                    <FormControlLabel
                        disabled={cantBeFeatured || featured !== null || isSubmitting}
                        control={
                            <Switch
                                color={'primary'}
                                checked={values.featuredPos !== -1}
                                onChange={() => {
                                    handleInputChange(
                                        'featuredPos',
                                        null,
                                        handleChange,
                                        setFieldTouched,
                                        values.featuredPos !== -1 ? -1 : 1,
                                    );
                                    handleTouchedField(
                                        'featuredPos',
                                        values.featuredPos !== -1 ? -1 : 1,
                                    );
                                }}
                                value={values.featuredPos !== -1}
                                className={
                                    cantBeFeatured ? classes.notAllowedCursor : null
                                }
                            />
                        }
                        labelPlacement="start"
                        label={
                            <Box paddingRight={4}>
                                <Typography
                                    variant={'subtitle2'}
                                    className={classes.sectionHeader}
                                >
                                    Feature This Post
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant={'body2'}
                                    color={'textSecondary'}
                                >
                                    Display this post in the featured area of the home
                                    page
                                </Typography>
                            </Box>
                        }
                        classes={{
                            root: classes.formControl,
                            disabled: classes.notAllowedCursor,
                        }}
                    />
                </Tooltip>

                <Collapse in={values.featuredPos !== -1}>
                    <FeaturedPositionSelector
                        activePosition={values.featuredPos}
                        onActivePositionChange={(newPos) => {
                            handleInputChange(
                                'featuredPos',
                                null,
                                handleChange,
                                setFieldTouched,
                                newPos,
                            );
                            handleTouchedField('featuredPos', newPos);
                        }}
                        currentPostId={postId}
                        alreadyFeatured={featured !== null}
                    />
                </Collapse>
            </GridItem>
        </>
    );
};

export interface PostOptionsFormProps {
    featured: FeaturedPostPosition;
    postId: string;
    handleTouchedField: (field: string, value: any) => void;
    handleInputChange: (
        fieldName: string,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        handleChange: Function,
        setTouched: Function,
        imperativeValue?: any,
    ) => void;
}

export default PostOptionsForm;
