import gql from 'graphql-tag';

export const REFUND_ORDER = gql`
    mutation refundOrder($orderId: ID!) {
        refundOrder(orderId: $orderId) {
            orderId
            ipAddr
            isPaid
            status
            amountPaid
            flagged
            paymentType
            ccLast4
            transactionId
        }
    }
`;
