import React from 'react';
import { Typography } from '@material-ui/core';
import { getBackgroundColor } from './StatusColors';
import getStatusIcon from './StatusIcon';
import useStyles from './styles';
import clsx from 'clsx';
import { SubmissionStatus } from 'types/submission';

/**
 * Convenience Component: Renders a special icon assigned to a Submission status
 * along with its label.
 */
const SubmissionStatusChip = (props: SubmissionStatusChipProps) => {
    const { status, ...rest } = props;
    const classes = useStyles();

    return (
        <div className={classes.chipLayout}>
            <div
                className={clsx(
                    classes.backgroundContainer,
                    getBackgroundColor(status, classes),
                )}
            >
                {getStatusIcon(status, { className: classes.chipIcon })}
            </div>

            <Typography className={classes.statusText} variant={'caption'}>
                {status === 'Rejected' ? 'Declined' : status}
            </Typography>
        </div>
    );
};

type SubmissionStatusChipProps = {
    status: SubmissionStatus;
};

export default SubmissionStatusChip;
