import React from 'react';
import useStyles from './styles';
import PostEditorFormContext from './PostEditorFormContext';
import {
    Button,
    Collapse,
    Divider,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { InfoOutlined, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { EditorState } from 'draft-js';
import { ContentCopy } from 'mdi-material-ui';
import { useHotkeys } from 'react-hotkeys-hook';
import { PostEditorFormValues } from '.';
import { UploadImageCallback } from '../../types/editor';
import {
    slugifyInput,
    GridContainer,
    GridItem,
    useAuth,
    copyStringToClipboard,
} from '@elevatormedia/duffel-bag';
import { PostStatus } from '../../types/post';
import formatValues from '../../utils/debugger';
import { DebouncedSave } from './DebouncedSave';
import { DOMAIN_BASE } from 'config/Nav';
import PostMetadataForm from './Forms/PostMetadataForm';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { DateTimePickerDialog, WYSIWYGEditor } from 'organisms';
import { EnhancedCardHeader } from 'atoms';
import PostActions from 'organisms/PostEditorForm/PostActions';
import PostOptionsForm from './Forms/PostOptionsForm';
import ImageForm from './Forms/ImageForm';

const PostEditorForm = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const {
        values,
        errors,
        dirty,
        touched,
        handleChange,
        submitForm,
        isValid,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        setValues,
        setErrors,
    } = useFormikContext<PostEditorFormValues>();

    const {
        categories,

        featured,
        postId,
        publishedAt,
        hasSubmission,

        togglePostDetailsVisibility,
        showPostDetails,

        uploadImageCallBack,
        convertToRawHTML,
        getMissingPublishingRequirements,
        setIsPublishingPost,

        dateTimePickerDialogOpen,
        toggleDateTimePickerDialog,

        handleTouchedField,
    } = React.useContext(PostEditorFormContext);

    const [imageEditorIn, setImageEditorIn] = React.useState(true);
    const [optionEditorIn, setOptionsEditorIn] = React.useState(true);

    const { permissionsMap } = useAuth();

    // Permissions
    const canPublishPosts = permissionsMap.posts.publishPost;

    // Field References via DOM
    const titleRef = React.useRef(null);
    const descriptionRef = React.useRef(null);
    const slugRef = React.useRef(null);

    const autoSave = async (isDirty: boolean, isValid: boolean) => {
        // prevent save if user hasn't modified form or form itself is invalid
        if (!isDirty || !isValid) {
            return;
        }

        await setFieldValue('isAutoSaving', true);

        submitForm();
    };

    const onBlurSave = async () => {
        // prevent save if user hasn't modified form or form itself is invalid
        if (!dirty || !isValid || !touched.content) {
            return;
        }

        await setFieldValue('isAutoSaving', false);

        submitForm();
    };

    /**
     * Copy post content to clipboard via Ctrl + k
     * TODO: Change Hotkey on this. Some Windows users' browsers
     * use this hotkey combo to interact with bookmarks or see history
     */
    useHotkeys(
        'ctrl+k',
        () => {
            const formattedInfo = formatValues(values.status, values);

            const placeholder = document.createElement('textarea');
            placeholder.value = formattedInfo;

            document.body.appendChild(placeholder);

            placeholder.select();
            document.execCommand('copy');
            document.body.removeChild(placeholder);
            enqueueSnackbar('🐞 DEBUG MODE: Post copied to clipboard', {
                persist: false,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
        },
        {},
        [values],
    );

    /**
     * Adapted from:
     * https://stackoverflow.com/questions/39501289/in-reactjs-how-to-copy-text-to-clipboard
     */
    const onCopyToClipboard = (
        inputRef: React.RefObject<HTMLInputElement>,
        value?: string,
    ) => {
        if (typeof value !== 'undefined') {
            copyStringToClipboard(value);
        } else {
            inputRef.current.select();
            document.execCommand('copy', false, value);
        }

        onCopyToClipboardFinished('Copied to clipboard');
    };

    /**
     * Copy the Post Body content to the clipboard as HTML
     */
    const copyHTML = () => {
        const placeholder = document.createElement('INPUT') as HTMLInputElement;
        document.body.appendChild(placeholder);

        placeholder.setAttribute('value', convertToRawHTML(values.content));
        placeholder.select();
        document.execCommand('copy');
        document.body.removeChild(placeholder);

        onCopyToClipboardFinished('HTML copied to clipboard');
    };

    const onCopyToClipboardFinished = (msg: string) => {
        enqueueSnackbar(msg, {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            variant: 'info',
            preventDuplicate: true,
        });
    };

    /**
     * Handles state tracking for basic input field on formik
     */
    const handleInputChange = (
        fieldName: string,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        handleChange: Function,
        setTouched: Function,
        imperativeValue: any = undefined,
    ) => {
        let fieldTouched = true;

        if (imperativeValue !== undefined) {
            if (fieldName === 'content') {
                const prev = JSON.stringify(values.content.getCurrentContent());
                const next = JSON.stringify(imperativeValue.getCurrentContent());

                if (prev === next) fieldTouched = false;
            }

            setFieldValue(fieldName, imperativeValue);
        } else {
            handleChange(event);
        }

        setTouched(fieldName, fieldTouched);
    };

    /**
     * Wrapper function around the handleSubmit() callback on form. Will verify that form
     * has all the necessary fields to publish post. If errors are present or values are missing,
     * errors will be displayed as helper text under text fields
     *
     * @param onPublishableCb callback to be called after function verifies post has all
     * necessary values to be published
     */
    const handleSubmitClick = (onPublishableCb: Function) => {
        /**
         * Aggregate a list of missing requirements to publish post
         * if there is at least one, abort publishing operation and display relevant
         * errors
         */
        const missingPublishingRequirements = getMissingPublishingRequirements(values);

        if (Object.keys(missingPublishingRequirements).length > 0) {
            setErrors(missingPublishingRequirements);
            setIsPublishingPost(false);

            Object.values(missingPublishingRequirements).forEach((errorMsg) => {
                enqueueSnackbar(errorMsg, {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    variant: 'error',
                    preventDuplicate: true,
                });
            });

            return;
        }

        onPublishableCb();
    };

    const handleCancelScheduledPostClick = () => {
        if (dateTimePickerDialogOpen) toggleDateTimePickerDialog();

        setValues({
            ...values,
            status: PostStatus.Draft,
        });

        submitForm();
    };

    const renderCardHeader = (
        title: string,
        renderDetailsToggle: boolean,
        renderDropdownArrow: boolean,
        collapseIn?: boolean,
        setCollapseIn?: any,
    ) => {
        return (
            <EnhancedCardHeader title={title}>
                {renderDetailsToggle && (
                    <Button
                        onClick={togglePostDetailsVisibility}
                        color={'primary'}
                        variant={'text'}
                    >
                        <Typography
                            variant={'overline'}
                            color={'primary'}
                            className={classes.togglePostDetailsButtonText}
                        >
                            {showPostDetails ? 'Less' : 'More'} Details
                        </Typography>
                        <InfoOutlined />
                    </Button>
                )}
                {renderDropdownArrow && (
                    <Button color={'default'} onClick={() => setCollapseIn(!collapseIn)}>
                        {collapseIn ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </Button>
                )}
            </EnhancedCardHeader>
        );
    };

    const renderCopyContentBtn = (
        inputRef: React.RefObject<HTMLInputElement>,
        isPermalink = false,
    ) => {
        return (
            <InputAdornment position="end">
                <Tooltip title={'Copy Text'}>
                    <IconButton
                        onClick={() =>
                            onCopyToClipboard(
                                inputRef,
                                isPermalink
                                    ? `${DOMAIN_BASE}/${inputRef.current.value}`
                                    : undefined,
                            )
                        }
                        color={'primary'}
                        size={'medium'}
                    >
                        <ContentCopy className={classes.copyIcon} />
                    </IconButton>
                </Tooltip>
            </InputAdornment>
        );
    };

    const renderCopyHTMLBtn = () => {
        return (
            <IconButton onClick={copyHTML} color={'primary'} size={'medium'}>
                <ContentCopy className={classes.copyIcon} />
            </IconButton>
        );
    };

    const renderBasicPostInfoFragment = () => {
        return (
            <React.Fragment>
                <GridItem className={classes.textFieldContainer}>
                    <TextField
                        id={'title'}
                        inputRef={titleRef}
                        required
                        label={'Title'}
                        variant={'outlined'}
                        value={values.title}
                        onChange={(event) =>
                            handleInputChange(
                                'title',
                                event,
                                handleChange,
                                setFieldTouched,
                            )
                        }
                        helperText={errors.title || ''}
                        onBlur={(event) =>
                            handleTouchedField('title', event.target.value)
                        }
                        error={Boolean(errors.title)}
                        InputProps={{
                            endAdornment: renderCopyContentBtn(titleRef),
                        }}
                        fullWidth
                    />
                </GridItem>
                <GridItem className={classes.textFieldContainer}>
                    <TextField
                        id={'description'}
                        inputRef={descriptionRef}
                        required
                        label={'Excerpt'}
                        variant={'outlined'}
                        value={values.description}
                        error={Boolean(errors.description)}
                        helperText={errors.description || ''}
                        onChange={(event) =>
                            handleInputChange(
                                'description',
                                event,
                                handleChange,
                                setFieldTouched,
                            )
                        }
                        InputProps={{
                            endAdornment: renderCopyContentBtn(descriptionRef),
                        }}
                        onBlur={(event) =>
                            handleTouchedField('description', event.target.value)
                        }
                        fullWidth
                    />
                </GridItem>
                <GridItem className={classes.textFieldContainer}>
                    <TextField
                        id={'slug'}
                        inputRef={slugRef}
                        required
                        label={'Permalink'}
                        variant={'outlined'}
                        value={values.slug}
                        error={Boolean(errors.slug)}
                        helperText={errors.slug || ''}
                        onChange={(event) =>
                            handleInputChange(
                                'slug',
                                event,
                                handleChange,
                                setFieldTouched,
                            )
                        }
                        onBlur={(event) => {
                            const newValue = slugifyInput(event.target.value);
                            handleTouchedField('slug', newValue);
                            setFieldValue('slug', newValue);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <Typography variant={'body2'} color={'textSecondary'}>
                                        {'https://elevatormag.com/ '}
                                    </Typography>
                                </InputAdornment>
                            ),
                            endAdornment: renderCopyContentBtn(slugRef, true),
                        }}
                        fullWidth
                    />
                </GridItem>
            </React.Fragment>
        );
    };

    return (
        <div className={classes.postEditorCardContainer}>
            <form>
                <Paper className={classes.paper}>
                    {renderCardHeader('Post Editor', true, false)}
                    <Divider />

                    <div className={classes.cardContent}>
                        <GridContainer direction={'column'} spacing={4}>
                            {renderBasicPostInfoFragment()}
                            <GridItem>
                                <WYSIWYGEditor
                                    value={values.content}
                                    handleRTEInputChange={(value: EditorState) => {
                                        handleInputChange(
                                            'content',
                                            null,
                                            handleChange,
                                            setFieldTouched,
                                            value,
                                        );
                                    }}
                                    uploadImageCallBack={
                                        uploadImageCallBack as UploadImageCallback
                                    }
                                    hasError={Boolean(errors.content)}
                                    helperText={
                                        (errors.content as string) || ('' as string)
                                    }
                                    CopyHTMLButton={() => renderCopyHTMLBtn()}
                                    onBlurSave={onBlurSave}
                                />
                            </GridItem>
                            <PostMetadataForm
                                categories={categories}
                                hasSubmission={hasSubmission}
                                handleTouchedField={handleTouchedField}
                                handleInputChange={handleInputChange}
                            />
                        </GridContainer>
                    </div>

                    <Divider />
                    <PostActions
                        values={values}
                        submitForm={submitForm}
                        isSubmitting={isSubmitting}
                        setValues={setValues}
                        handleCancelScheduledPostClick={handleCancelScheduledPostClick}
                        handleSubmitClick={handleSubmitClick}
                        errors={errors}
                    />
                </Paper>

                <Paper className={classes.paper}>
                    {renderCardHeader(
                        'Featured Image',
                        false,
                        true,
                        imageEditorIn,
                        setImageEditorIn,
                    )}

                    <Collapse in={imageEditorIn}>
                        <Divider />
                        <div className={classes.cardContent}>
                            <GridContainer direction={'column'} spacing={4}>
                                <ImageForm handleInputChange={handleInputChange} />
                            </GridContainer>
                        </div>
                    </Collapse>

                    <Divider />
                </Paper>

                <Paper className={classes.paper}>
                    {renderCardHeader(
                        'Post Options',
                        false,
                        true,
                        optionEditorIn,
                        setOptionsEditorIn,
                    )}

                    <Collapse in={optionEditorIn}>
                        <Divider />
                        <div className={classes.cardContent}>
                            <GridContainer direction={'column'} spacing={4}>
                                <PostOptionsForm
                                    featured={featured}
                                    postId={postId}
                                    handleTouchedField={handleTouchedField}
                                    handleInputChange={handleInputChange}
                                />
                            </GridContainer>
                        </div>
                    </Collapse>

                    <Divider />
                </Paper>
            </form>
            <DateTimePickerDialog
                open={dateTimePickerDialogOpen}
                handleClose={toggleDateTimePickerDialog}
                initialDate={publishedAt ? publishedAt : ''}
                canCancelScheduledPost={canPublishPosts}
                onCancelScheduledPostClick={handleCancelScheduledPostClick}
                onSchedule={(scheduleDate: Date) => {
                    handleSubmitClick(() => {
                        setValues({
                            ...values,
                            status: PostStatus.Scheduled,
                            publishedAt: scheduleDate,
                        });
                        submitForm();
                    });
                }}
                variant={values.status === 'scheduled' ? 'reschedule' : 'schedule'}
            />

            {values.status !== 'published' && (
                <DebouncedSave
                    dependencies={values}
                    saveCallback={autoSave}
                    dirty={dirty}
                    isValid={isValid}
                />
            )}
        </div>
    );
};

export default PostEditorForm;
