import React from 'react';
import useStyles from './styles';
import { StatsCardIndexProps } from '.';
import { GridContainer, GridItem } from '@elevatormedia/duffel-bag/dist/atoms/Grid';
import Typography from '@material-ui/core/Typography';

const RowStatsCard = (props: RowStatsCardProps) => {
    const classes = useStyles();

    const { title, labels, values, valueColors } = props;

    const renderItem = (label: string, value: any, index: number, leftPadding = true) => {
        const displayValue = isNaN(value) ? 'N/A' : value;

        return (
            <GridContainer
                key={`item-${index}`}
                className={leftPadding ? classes.itemContainer : ''}
                direction={'column'}
                alignItems={'center'}
            >
                <GridItem>
                    <Typography
                        className={classes.valueText}
                        variant={'h5'}
                        style={{ color: valueColors ? valueColors[index] : 'black' }}
                    >
                        {displayValue}
                    </Typography>
                </GridItem>
                <GridItem>
                    <Typography className={classes.labelText} align={'center'}>
                        {label.toLocaleUpperCase()}
                    </Typography>
                </GridItem>
            </GridContainer>
        );
    };

    return (
        <>
            <div className={classes.titleText}>{title.toLocaleUpperCase()}</div>
            <GridContainer direction={'row'} spacing={1} justifyContent={'space-between'}>
                <GridItem>{renderItem(labels[0], values[0], 0, false)}</GridItem>
                <GridItem>
                    <div className={classes.rightContainer}>
                        {values.slice(1).map((value: any, index: number) => {
                            return renderItem(labels[index + 1], value, index + 1);
                        })}
                    </div>
                </GridItem>
            </GridContainer>
        </>
    );
};

export type RowStatsCardProps = Omit<StatsCardIndexProps<'row'>, 'variant'>;

export default RowStatsCard;
