import validator from 'validator';
import parseURL from 'url-parse';
import { ContentState, EditorState, RawDraftEntity } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

/**
 * Because Instagram and Twitter embed code retrieves an HTML Fragment
 * with a <script /> such as:
 *
 * <blockquote></blockquote>
 * <script></script>
 *
 * We parse our the script tag if present and return the target string
 */
export const getBlockQuoteWithNoScript = (rawHTML: string) => {
    const indexOfScriptSrc = rawHTML.indexOf('<script');
    if (indexOfScriptSrc === -1) {
        return rawHTML; // Return string assuming there is not script tag
    }

    const withoutScript = rawHTML.slice(0, indexOfScriptSrc).trim();
    return withoutScript;
};

/**
 * Determine whether the <blockquote></blockquote> passed to this function
 * is a handled social media post embed. As of this writing we can only handle
 * instagram and twitter embeds. The likes of which follow the same pattern:
 *
 * Twitter blockquotes:
 * <blockquote class="twitter-tweet"> ...
 *
 * Instagram bloquotes:
 * <blockquote class="instagram-media"
 */
export const isHandledSocialMediaEmbed = (rawHTML: string) => {
    if (!rawHTML.startsWith('<blockquote')) {
        return false;
    }

    const handledSocialMediaTypes = ['instagram-media', 'twitter-tweet'];

    const classAttrValue = getClassAttrValue(rawHTML);

    // Failed to find attribute to get media type from
    if (!classAttrValue) {
        return false;
    }

    if (handledSocialMediaTypes.includes(classAttrValue)) {
        return true;
    }

    return false;
};

export const isSocialMediaLink = (rawLink: string) => {
    if (!validator.isURL(rawLink)) {
        return false;
    }

    const validHosts = [
        'twitter.com',
        'instagram.com',
        'www.twitter.com',
        'www.instagram.com',
    ];
    const hostName = parseURL(rawLink, true).hostname;

    if (!hostName) {
        return false;
    }

    if (validHosts.includes(hostName)) {
        return true;
    }

    return false;
};

/**
 * Extracts the social media platform name from a Blockquote tag
 * This function can return one of 'instagram', 'twitter' or null if
 * no class attribute is returned or the raw HTML is parsed incorrectly.
 */
export const getSocialMediaTypeFromBlockQuote = (rawHTML: string) => {
    const classAttrValue = getClassAttrValue(rawHTML);

    // Failed to find attribute to get media name from
    if (!classAttrValue) {
        return null;
    }

    if (classAttrValue.startsWith('twitter')) {
        return 'twitter';
    } else if (classAttrValue.startsWith('instagram')) {
        return 'instagram';
    } else {
        return null;
    }
};

/**
 * Digests parameters from draftJsToHtml to handle converting
 * user-defined custom-entities into HTML elements
 *
 * NOTE: The entity parameter is intentionally left as an 'any' type.
 * This is because there is discrepency between the type definitions
 * of draftjs-to-html and the proper types of draft-js. This is most
 * likely a result of the package being written in Javascript and the
 * mis-typed entity is either a version behind, or an oversight. To
 * prevent crashes, we forgo the use of type definitions here.
 */
export const transformCustomEntitiesToHTML = (entity: any, _: any) => {
    if (entity.type === 'SOCIAL_MEDIA') {
        // Transform Social Media iframes back to <blockquotes />
        return entity.data.html;
    } else if (entity.type === 'CUSTOM_IMAGE') {
        const srcset = entity.data.srcset ? `srcset="${entity.data.srcset}"` : '';
        return `<img src="${entity.data.src}" ${srcset} alt="${entity.data.alt}" />`;
    }

    return; // Let default fall-through function
};

/**
 * Digest paramaeters from HtmlToDraftJs (nodeName, node) and handles
 * transforming tags into custom draft js atomic entities
 */
export const transformHTMLToCustomEntities = (nodeName: string, node: HTMLElement) => {
    const htmlString = node.outerHTML;

    if (nodeName === 'blockquote' && isHandledSocialMediaEmbed(htmlString)) {
        const socialMediaPlatform = getSocialMediaTypeFromBlockQuote(htmlString);

        return {
            type: 'SOCIAL_MEDIA',
            mutability: 'IMMUTABLE',
            data: {
                html: htmlString,
                type: socialMediaPlatform,
                containerId: generateRandomContainerId(socialMediaPlatform),
                pasted: false,
            },
        } as RawDraftEntity<{ [key: string]: any }>;
    } else if (nodeName === 'img' && node instanceof HTMLImageElement) {
        return {
            type: 'CUSTOM_IMAGE',
            mutability: 'MUTABLE',
            data: {
                src: node.src,
                alt: node.alt,
                //style: node.style,
                srcset: node.srcset,
            },
        } as RawDraftEntity<{ [key: string]: any }>;
    }
};

/**
 * Generates a random string id that can be used as the id attribute of a container
 */
export const generateRandomContainerId = (prefix: string) => {
    /**
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
     */
    return `${prefix}-${Math.floor(Math.random() * Math.floor(1000000))}`;
};

/**
 * Extracts the value of the first "class" attribute attached to an HTML
 * Tag. Returns null otherwise if not found.
 */
const getClassAttrValue = (rawHTML: string) => {
    const indexOfClassAttr = rawHTML.indexOf('class=');
    if (indexOfClassAttr === -1) {
        return null;
    }

    // Look for ending " quote
    const sliceEndIndex = rawHTML.indexOf('"', indexOfClassAttr + 7);
    if (sliceEndIndex === -1) {
        return null;
    }

    return rawHTML.slice(indexOfClassAttr + 7, sliceEndIndex).trim();
};

/**
 * Search Document body for blockquotes to be converted to an instagram embed
 */
export const processInstagramEmbeds = () => {
    window.instgrm.Embeds.process();
};

/**
 * Search Document body for blockquote to be converted to an tweet embed.
 *
 * Optionally pass a div container id to only load a single tweet (better performance)
 */
export const processTweetEmbeds = (containerId: string = null) => {
    if (containerId) {
        window.twttr.widgets.load(document.getElementById(containerId));
    } else {
        window.twttr.widgets.load();
    }
};

export const scriptIsLoaded = (scriptId: string) => {
    const scriptElement = document.getElementById(scriptId);

    return scriptElement ? true : false;
};

export const createEditorStateFromHtml = (html: string, prevState?: EditorState) => {
    const cleanHtml = html.replace(/&amp;/g, '&').replace(/&nbsp;/g, ' ');

    const { contentBlocks, entityMap } = htmlToDraft(
        cleanHtml,
        transformHTMLToCustomEntities,
    );
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    let editorState = EditorState.createWithContent(contentState);

    if (prevState) {
        editorState = EditorState.set(editorState, {
            undoStack: prevState.getUndoStack(),
            redoStack: prevState.getRedoStack(),
        });
    }

    return editorState;
};
