import React from 'react';
import { SubmissionStatus, SUBMISSION_STATUSES } from '@elevatormedia/duffel-bag';
import {
    Done as ApprovalIcon,
    DoneAll as ApprovedIcon,
    ArtTrack as CompletedIcon,
    Close as RejectedIcon,
    HowToVote as ReviewIcon,
    HelpOutline as UnknownStatusIcon,
} from '@material-ui/icons';
import { SvgIconProps } from '@material-ui/core';

const getStatusIcon = (status: SubmissionStatus, props: SvgIconProps) => {
    switch (status) {
        case SUBMISSION_STATUSES.APPROVAL:
            return <ApprovalIcon {...props} />;
        case SUBMISSION_STATUSES.APPROVED:
            return <ApprovedIcon {...props} />;
        case SUBMISSION_STATUSES.COMPLETED:
            return <CompletedIcon {...props} />;
        case SUBMISSION_STATUSES.REJECTED:
            return <RejectedIcon {...props} />;
        case SUBMISSION_STATUSES.REVIEW:
            return <ReviewIcon {...props} />;
        default:
            return <UnknownStatusIcon {...props} />;
    }
};

export default getStatusIcon;
