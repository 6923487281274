import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            margin: theme.spacing(0.5),
        },
        actions: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        buttonSet: {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'row',
            },
        },
    }),
);

export default useStyles;
