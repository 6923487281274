import React from 'react';
import RowStatsCard, { RowStatsCardProps } from './RowStatsCard';
import StackedStatsCard, { StackedStatsCardProps } from './StackedStatsCard';
import useStyles from './styles';
import Paper from '@material-ui/core/Paper';
import { FixedLengthArray } from 'types/fixedLengthArray';

const Index = <T extends StatsCardVariant>(props: StatsCardIndexProps<T>) => {
    const classes = useStyles();

    const { variant, ...rest } = props;

    return (
        <Paper className={classes.root}>
            {variant === 'row' ? (
                <RowStatsCard {...(rest as RowStatsCardProps)} />
            ) : (
                <StackedStatsCard {...(rest as StackedStatsCardProps)} />
            )}
        </Paper>
    );
};

export type StatsCardVariant = 'row' | 'stacked';

export interface StatsCardIndexProps<T extends StatsCardVariant> {
    variant?: StatsCardVariant;
    title: string;

    labels: T extends 'row' ? FixedLengthArray<string, 4> : FixedLengthArray<string, 2>;
    values: T extends 'row' ? FixedLengthArray<any, 4> : FixedLengthArray<string, 3>;
    valueColors?: T extends 'row'
        ? FixedLengthArray<string, 4>
        : FixedLengthArray<string, 3>;
}

Index.defaultProps = {
    variant: 'row',
};

export default Index;
