import { gql } from '@apollo/client';

export const SUBMISSION_SERVICES = gql`
    query {
        submissionServices {
            name
            services {
                serviceId
                name
                cost
                type
            }
        }
    }
`;

export const ALL_SERVICES = gql`
    query allServices {
        allServices {
            serviceId
            name
            cost
            type
            autoCapture
            parent {
                serviceId
                name
                cost
                type
                autoCapture
            }
            parentId
            addOns {
                serviceId
                name
                cost
                type
                autoCapture
            }
        }
    }
`;

export const SERVICE_BY_ID = gql`
    query service($serviceId: ID) {
        service(serviceId: $serviceId) {
            serviceId
            name
            type
            platform
            cost
            autoCapture
            description
            details
            seller
            vendorId
            vendor {
                organizationId
                name
                owner {
                    userId
                    username
                }
            }
            parent {
                serviceId
                name
                cost
                type
                autoCapture
            }
            parentId
            addOns {
                serviceId
                name
                cost
                type
                autoCapture
            }
        }
    }
`;
