import React, { useMemo, useState } from 'react';
import { OrganizationQuery } from '@elevatormedia/pitch-hooks/types/schema';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import UserChip from 'molecules/UserChip';
import useStyles from './styles';
import { Collapse, IconButton, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import PencilOff from 'mdi-material-ui/PencilOff';

const UserChipList = ({
    users,
    title,
    description,
    action,
    onRemoveToggle,
    removedUsers,
}: UserChipListProps) => {
    const classes = useStyles();

    const [expanded, setExpanded] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const canExpand = useMemo(() => users.length > 5, [users]);

    return (
        <>
            <div className={classes.headerContainer}>
                <Typography variant={'subtitle2'} className={classes.sectionHeader}>
                    {title}
                    {canExpand && (
                        <Typography
                            color={'primary'}
                            component={'span'}
                            variant={'caption'}
                            className={classes.expandToggler}
                            onClick={() => setExpanded((prev) => !prev)}
                        >
                            {expanded ? 'Collapse' : 'Expand'}
                        </Typography>
                    )}
                </Typography>

                <div>
                    {users.length > 0 && (
                        <IconButton
                            color={'primary'}
                            size={'small'}
                            onClick={() => setEditMode((prev) => !prev)}
                        >
                            {editMode ? (
                                <PencilOff fontSize={'small'} />
                            ) : (
                                <Edit fontSize={'small'} />
                            )}
                        </IconButton>
                    )}

                    {action}
                </div>
            </div>

            {description && <Typography variant={'caption'}>{description}</Typography>}

            {users.length === 0 ? (
                <div className={classes.emptyMessage}>
                    <Typography variant={'body2'} color={'textSecondary'}>
                        No users yet!
                    </Typography>
                </div>
            ) : (
                <div className={classes.chipList}>
                    <Collapse in={expanded} collapsedSize={'80px'}>
                        {(expanded ? users : users.slice(0, 5)).map((user) => {
                            const deleted =
                                removedUsers &&
                                removedUsers.find(
                                    (removedUser) => removedUser.userId === user.userId,
                                )
                                    ? true
                                    : false;

                            return (
                                <UserChip
                                    key={user.userId}
                                    username={user.preferredUsername}
                                    avatar={
                                        user.avatar && {
                                            sourceUrl: user.avatar.sourceUrl,
                                            sourceSet: user.avatar.sourceSet,
                                        }
                                    }
                                    onRemoveToggle={
                                        onRemoveToggle && editMode
                                            ? () => onRemoveToggle(user)
                                            : undefined
                                    }
                                    deleted={deleted}
                                />
                            );
                        })}

                        {canExpand && !expanded && (
                            <Tooltip title={'Show all'} placement={'top'}>
                                <div className={classes.expandListAvatarContainer}>
                                    <Avatar
                                        className={classes.expandListAvatar}
                                        onClick={() => {
                                            setExpanded(true);
                                        }}
                                    >
                                        +{users.length - 5}
                                    </Avatar>
                                </div>
                            </Tooltip>
                        )}
                    </Collapse>
                </div>
            )}
        </>
    );
};

export type UserChipListProps = {
    users: OrganizationQuery['organization']['members'];
    title: string;
    description?: string;
    action?: React.ReactNode;
    onRemoveToggle?: (user: OrganizationQuery['organization']['members'][0]) => void;
    removedUsers?: OrganizationQuery['organization']['members'];
};

export default UserChipList;
