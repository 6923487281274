import axios from 'axios';

axios.defaults.withCredentials = true;

export const authEndpoint =
    process.env.cookieDomain === 'localhost'
        ? 'http://localhost:3002/auth'
        : new URL(process.env.apiUri).origin + '/auth';

/**
 * Utility function which either returns a boolean as to whether a use currently authenticated.
 */
export const isAuthenticated = async () => {
    try {
        await axios.post(`${authEndpoint}/me`);
        return true;
    } catch (err) {
        return false;
    }
};

export const changePassword = async (currentPassword: string, newPassword: string) => {
    try {
        await axios.post(`${authEndpoint}/manage-password`, {
            action: 'CHANGE',
            currentPassword,
            newPassword,
        });
        return true;
    } catch (err) {
        throw err.response.data;
    }
};
