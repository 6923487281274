import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        cardContent: {
            margin: theme.spacing(2),
        },
        copyIcon: {
            fontSize: theme.typography.pxToRem(18),
        },
        cardActionsContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        submissionsDetailCardRoot: {
            width: '100%',
            maxWidth: '400px',
            paddingTop: theme.spacing(2),
            [theme.breakpoints.down(1100)]: {
                maxWidth: 'unset',
            },
        },
        detailRowContainer: {
            minHeight: '30px',
        },
        titleCase: {
            textTransform: 'capitalize',
        },
    });

export default makeStyles(styles);
