/**
 * Default prop configuration for entire application. This JS object allows us to provide default
 * props to MUI components.
 *
 * More Info: https://material-ui.com/customization/globals/#default-props
 */
const defaultPropsConfig = {
    props: {
        MuiPaper: {
            elevation: 0,
        },
    },
};

export default defaultPropsConfig;
