import { Media, ImageCropType, User } from '@elevatormedia/duffel-bag';
import Axios from 'axios';
import { mixed } from 'yup';
import { reportError } from './errors';

export const cropImage = async (
    image: Media,
    cropType: ImageCropType,
    currentUser: User,
) => {
    try {
        const mediaEndPoint =
            process.env.cookieDomain === 'localhost'
                ? 'http://localhost:3002/media'
                : new URL(process.env.apiUri).origin + '/media';

        const res = await Axios.get(mediaEndPoint, {
            params: {
                path: image.path,
                crop: cropType,
            },
        });

        const newImage: Media = {
            ...image,
            sourceUrl: res.data.sourceUrl,
            sourceSet: res.data.sourceSet,
        };

        return newImage;
    } catch (error) {
        reportError(error, {
            metaData: {
                operation: 'GET /media',
                cropOption: cropType,
                imagePath: image.path,
            },
            user: {
                username: currentUser.username,
            },
        });

        return null;
    }
};

const MAX_FILE_SIZE = 20971520; // ~ 20 Megabytes
export const SUPPORTED_IMAGE_FORMATS = ['image/jpeg', 'image/png'];

export const imageValidation = <T extends any>() =>
    mixed<T>()
        .test(
            'SupportedImageTypes',
            'This file type is not supported',
            // A func returning true means it is a valid input
            (value) => {
                // when image instances are empty, its fine as they aren't required all the time
                if (!value) return true;
                const imageType =
                    value instanceof File ? value.type : value.metadata.mimetype;
                // For when images are local and we need to check for valid formats
                return SUPPORTED_IMAGE_FORMATS.includes(imageType);
            },
        )
        .test(
            'MaxfileSize',
            'Image must be less than 20MB',
            // A func returning true means it is a valid input
            (value) => {
                if (!value) return true;
                if (!(value instanceof File)) return true;
                return value.size < MAX_FILE_SIZE;
            },
        );
