import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItemAvatar: {
            paddingRight: theme.spacing(2),
        },
        statusAvatar: {
            backgroundColor: theme.palette.divider,
            height: '50px',
            width: '50px',
        },
        listItemText: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            maxWidth: '90%',
        },
    }),
);

export default useStyles;
