import React, { MouseEvent } from 'react';
import { IconButton, InputAdornment, MenuItem, Paper, Tooltip } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import ChipInput, { Props } from 'material-ui-chip-input';
import { ContentCopy } from 'mdi-material-ui';
import { useSnackbar } from 'notistack';
import Autosuggest, {
    InputProps,
    RenderSuggestionParams,
    RenderSuggestionsContainerParams,
    SuggestionSelectedEventData,
    SuggestionsFetchRequestedParams,
} from 'react-autosuggest';
import useStyles from './styles';

const AutoSuggestChipInput = (props: AutoSuggestChipInputProps) => {
    const {
        chips, // Array of text values accepted by text input
        handleAddChip, // What should happen when a new chip is added
        handleDeleteChip, // What should happen when a chip is removed
        suggestionSrc, // Source array of suggestion from which to query from.

        label, // label for the text field input
        onTextInputChangeCb, // Cb for when the user enters any text into the input field

        renderCopyContentBtn,

        ...rest
    } = props;

    const { enqueueSnackbar } = useSnackbar();

    const classes = useStyles();

    // Keeps track of active suggestions based on the user input.
    const [suggestions, setSuggestions] = React.useState([]);

    const chipRef = React.useRef(null);

    // Keeps track of text entered into the field. This source is then used
    // to create a list of viable suggestions.
    const [textFieldInput, setTextFieldInput] = React.useState('');

    /**
     * Adpated from:
     * https://stackoverflow.com/questions/39501289/in-reactjs-how-to-copy-text-to-clipboard
     * @Nate
     */
    const onCopyToClipboard = () => {
        const placeholder = document.createElement('INPUT') as HTMLInputElement;
        document.body.appendChild(placeholder);

        placeholder.setAttribute('value', chips.join(', '));
        placeholder.select();
        document.execCommand('copy');
        document.body.removeChild(placeholder);

        onShareFinished('Copied to clipboard');
    };

    const onShareFinished = (msg: string) => {
        enqueueSnackbar(msg, {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            variant: 'info',
            preventDuplicate: true,
        });
    };

    /**
     * Utility function, retrieves the name attribute from a suggestion object.
     */
    const getSuggestionValue = (suggestion: ChipSuggestion) => {
        return suggestion.name;
    };

    /**
     * Generates a list of suggestions from user input.
     */
    const getSuggestions = (userInput: string, suggestionCountCap = 5) => {
        // Normalize the user input
        const inputValue = userInput.trim().toLowerCase();
        const inputLength = inputValue.length;

        // Keeps track of suggestions count to make sure we only return a specific amount.
        let generatedSuggestionsCount = 0;

        const suggestionRes =
            inputLength === 0
                ? []
                : suggestionSrc.filter((suggestion) => {
                      const keep =
                          generatedSuggestionsCount < suggestionCountCap &&
                          suggestion.name
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());

                      if (keep) {
                          generatedSuggestionsCount += 1;
                      }

                      return keep;
                  });

        return suggestionRes;
    };

    const handleTextFieldInputChage = (event: any) => {
        setTextFieldInput(event.target.value);
        if (onTextInputChangeCb && event.target.value) {
            onTextInputChangeCb(event.target.value);
        }
    };

    const handleSuggestionsFetchRequested = ({
        value,
    }: SuggestionsFetchRequestedParams) => {
        setSuggestions(getSuggestions(value));
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const handleSuggestionSelected = (
        e: React.FormEvent<any>,
        { suggestionValue }: SuggestionSelectedEventData<ChipSuggestion>,
    ) => {
        e.preventDefault();

        !hasChip(suggestionValue) && handleAddChip(suggestionValue);
        setTextFieldInput('');
    };

    /**
     * Utility function which checks whether the current input has already has a chip
     * with the same name.
     */
    const hasChip = (chip: string) => {
        return chips.includes(chip);
    };

    /**
     * Renders the container for the the list of suggestions.
     */
    const renderSuggestionsContainer = (params: RenderSuggestionsContainerParams) => {
        const { containerProps, children } = params;

        return (
            <Paper {...containerProps} square>
                {children}
            </Paper>
        );
    };

    /**
     * Renders a single list item based on the list of generated suggestions
     */
    const renderSuggestion = (
        suggestion: ChipSuggestion,
        params: RenderSuggestionParams,
    ) => {
        const { query, isHighlighted } = params;
        const matches = match(suggestion.name, query);
        const parts = parse(suggestion.name, matches);

        return (
            <MenuItem
                selected={isHighlighted}
                // prevent the click causing the input to be blurred
                onMouseDown={(e: MouseEvent) => e.preventDefault()}
                component={'div'}
            >
                {/**
                    Renderrs the text-editing distance in bold, and completion in 
                    default text.
                 */}
                <div>
                    {parts.map((part, index) => {
                        return part.highlight ? (
                            <span
                                key={String(index)}
                                className={classes.editDistanceText}
                            >
                                {part.text}
                            </span>
                        ) : (
                            <span key={String(index)}>{part.text}</span>
                        );
                    })}
                </div>
            </MenuItem>
        );
    };

    /**
     * Renders the input component. In our case it is a chip input
     */
    const renderChipInput = (inputProps: ChipInputProps<ChipSuggestion>) => {
        const {
            onChange,
            value,
            chips,
            onAdd,
            onDelete,
            label,
            onBlur,
            innerTextFieldProps,
            ...rest
        } = inputProps;

        return (
            <ChipInput
                {...innerTextFieldProps}
                {...(rest as any[])}
                onBlur={onBlur}
                inputRef={(ref) => (chipRef.current = ref)}
                onUpdateInput={onChange}
                value={chips}
                onAdd={(chip) => {
                    onAdd(chip);
                }}
                onDelete={(deletedChip) => onDelete(deletedChip)}
                label={label}
                variant={'outlined'}
                fullWidth
                blurBehavior={'ignore'}
                clearInputValueOnChange
                allowDuplicates={false}
                disableUnderline
                InputProps={{
                    endAdornment: renderCopyContentBtn && (
                        <InputAdornment position="end">
                            <div className={classes.iconButton}>
                                <Tooltip title={'Copy Text'}>
                                    <IconButton
                                        onClick={() => onCopyToClipboard()}
                                        color={'primary'}
                                    >
                                        <ContentCopy className={classes.copyIcon} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </InputAdornment>
                    ),
                }}
            />
        );
    };

    return (
        <Autosuggest
            theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
                input: classes.chipInput,
            }}
            renderInputComponent={renderChipInput}
            suggestions={suggestions}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            renderSuggestionsContainer={renderSuggestionsContainer}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            // What should happen when the user selects a value from the
            // list of suggestions.
            onSuggestionSelected={handleSuggestionSelected}
            focusInputOnSuggestionClick={false}
            inputProps={
                {
                    value: textFieldInput,
                    onChange: handleTextFieldInputChage,

                    // Custom arbitrary props
                    chips: chips,
                    onAdd: (chip: string) => handleAddChip(chip),
                    onDelete: (chip: string, index: number) =>
                        handleDeleteChip(chip, index),
                    innerTextFieldProps: { ...rest },
                } as ChipInputProps<ChipSuggestion>
            }
        />
    );
};

export type SuggestionSrc = {
    name: string;
};

export interface ChipInputProps<T> extends Omit<InputProps<T>, 'onChange'> {
    chips: Array<string>;
    onAdd: Function;
    onDelete: Function;
    label?: string;
    innerTextFieldProps?: Props;
    onChange: any;
}

export type AutoSuggestChipInputProps = Props & {
    chips: Array<string>;
    handleAddChip: Function;
    handleDeleteChip: Function;
    suggestionSrc: Array<SuggestionSrc>;
    label: string;
    onTextInputChangeCb: Function;
    renderCopyContentBtn: boolean;
    id?: string;
};

export type ChipSuggestion = {
    name: string;
    displayName?: string;
    tagId?: string;
};

AutoSuggestChipInput.defaultProps = {
    renderCopyContentBtn: false,
};

export default AutoSuggestChipInput;
