import React from 'react';
import {
    Dashboard,
    SupervisorAccountOutlined as AllUsersIcon,
    Settings as AccountSettingsIcon,
    BugReportOutlined as ReportABugIcon,
    Edit as DraftIcon,
    LocalOfferOutlined as ServiceIcon,
    AddShoppingCart as CreateServiceIcon,
    PhotoLibrary as MediaIcon,
    Gavel,
    HowToVote,
    RecentActors,
    PersonAdd,
    Business as OrganizationsIcon,
} from '@material-ui/icons';
import MaterialIcon from '@material/react-material-icon';
import {
    CalendarClock as ScheduledPostsIcon,
    BookOpenPageVariant,
    Lightbulb as PitchIcon,
    Shape,
    TextBoxOutline,
    TextBoxPlusOutline,
    ViewCompact,
} from 'mdi-material-ui';
import { BaseRouteConfig, RouteConfig } from 'types/nav';

export const DOMAIN_BASE = 'https://www.elevatormag.com';

const ROUTES: {
    [key: string]: BaseRouteConfig | RouteConfig;
} = {
    dashboard: {
        to: '/dashboard',
        navLinkText: 'Dashboard',
        permissions: [
            { type: 'orders', operation: 'monthlyEarnings', required: true },
            { type: 'orders', operation: 'monthlyEarningsChange', required: true },
            { type: 'analytics', operation: 'monthlySubmissionStats', required: true },
            {
                type: 'submissions',
                operation: 'submissionTotalsByStatus',
                required: true,
            },
            { type: 'analytics', operation: 'submissionsChangeStats', required: true },
            { type: 'users', operation: 'totalUsers', required: true },
            { type: 'analytics', operation: 'newUserStats', required: true },
        ],
    },
    analytics: {
        to: '/analytics',
        navLinkText: 'Analytics',
        permissions: [
            { type: 'analytics', operation: 'revenueStats' },
            { type: 'analytics', operation: 'monthlySubmissionStats' },
            { type: 'analytics', operation: 'allPageViewStats' },
            { type: 'analytics', operation: 'userStats' },
        ],
    },
    media: {
        to: '/media/library',
        navLinkText: 'Media Library',
        permissions: [{ type: 'media', operation: 'media', required: true }],
    },
    posts: {
        allPosts: {
            to: '/posts/all',
            navLinkText: 'Posts',
            permissions: [{ type: 'posts', operation: 'posts', required: true }],
        },
        scheduledPosts: {
            to: '/posts/scheduled-posts',
            navLinkText: 'Scheduled Posts',
            permissions: [{ type: 'posts', operation: 'posts', required: true }],
        },
        newPost: {
            to: '/posts/edit?asNewPost=true&startAs=draft',
            navLinkText: 'Create Post',
            permissions: [{ type: 'posts', operation: 'createPost', required: true }],
        },
        editPost: {
            to: '/posts/edit',
            navLinkText: 'Edit Post',
            permissions: [
                { type: 'posts', operation: 'post', required: true },
                { type: 'categories', operation: 'categories', required: true },
                { type: 'posts', operation: 'updatePost', required: true },
                { type: 'posts', operation: 'publishPost' },
            ],
            parent: '/posts/all-posts',
        },
        editFeatured: {
            to: '/posts/edit-featured',
            navLinkText: 'Front Page',
            permissions: [{ type: 'posts', operation: 'post', required: true }],
        },
        writeupQueue: {
            to: '/queue',
            navLinkText: 'Queue',
            permissions: [
                { type: 'submissions', operation: 'submissions', required: true },
            ],
        },
        categories: {
            to: '/posts/categories',
            navLinkText: 'Categories',
            permissions: [
                { type: 'categories', operation: 'categories', required: true },
                { type: 'categories', operation: 'createCategory' },
                { type: 'categories', operation: 'updateCategory' },
                { type: 'categories', operation: 'deleteCategory' },
            ],
        },
    },
    promotions: {
        allPromotions: {
            to: '/promotions/all',
            navLinkText: 'Promotions',
            permissions: [
                { type: 'promotions', operation: 'promotions', required: true },
                {
                    type: 'promotions',
                    operation: 'promotionTotalsByStatus',
                    required: true,
                },
                { type: 'services', operation: 'allServices', required: true },
            ],
        },
        createPromotion: {
            to: '/promotions/create',
            navLinkText: 'Create Promotion',
            permissions: [
                { type: 'promotions', operation: 'createPromotion', required: true },
            ],
        },
        editPromotion: {
            to: '/promotions/[promotionId]',
            getPath: (promotionId: string) => `/promotions/${promotionId}`,
            navLinkText: 'Edit Promotion',
            permissions: [
                { type: 'promotions', operation: 'promotion', required: true },
                { type: 'promotions', operation: 'updatePromotion', required: true },
                { type: 'promotions', operation: 'cancelPromotion' },
                { type: 'promotions', operation: 'deletePromotion' },
            ],
        } as BaseRouteConfig,
    },
    campaigns: {
        allCampaigns: {
            to: '/campaigns/all',
            navLinkText: 'Campaigns',
            permissions: [
                { type: 'campaigns', operation: 'campaigns', required: true },
                { type: 'campaignTypes', operation: 'campaignType', required: true },
            ],
        },
        campaign: {
            to: '/campaigns/[campaignId]',
            navLinkText: 'View Campaign',
            permissions: [{ type: 'campaigns', operation: 'campaign', required: true }],
        },
    },
    forge: {
        to: '/forge',
        navLinkText: 'Forge',
    },
    submissions: {
        newSubmissions: {
            to: '/submissions/new-submissions',
            navLinkText: 'Recent Submissions',
            permissions: [
                { type: 'submissions', operation: 'submissions', required: true },
                { type: 'submissions', operation: 'submissionsInbox', required: true },
                { type: 'submissions', operation: 'updateSubmissionStatuses:Approval' },
                { type: 'submissions', operation: 'updateSubmissionStatuses:Approved' },
            ],
        },
        recentActivity: {
            to: '/submissions/recent-activity',
            navLinkText: 'Activity',
            permissions: [
                { type: 'activities', operation: 'activities', required: true },
            ],
        },
        allSubmissions: {
            to: '/submissions/all',
            navLinkText: 'Submissions',
            permissions: [
                { type: 'submissions', operation: 'submissions', required: true },
            ],
        },
        submission: {
            to: '/submissions/[submissionId]',
            navLinkText: 'View Submission',
            permissions: [
                { type: 'submissions', operation: 'submission', required: true },
                { type: 'credits', operation: 'updateCredit' },
                { type: 'credits', operation: 'createCredit' },
                { type: 'credits', operation: 'deleteCredit' },
                { type: 'users', operation: 'authors' },
            ],
            parent: '/submissions/all-submissions',
        },
    },
    users: {
        all: {
            to: '/users/all',
            navLinkText: 'Users',
            permissions: [{ type: 'users', operation: 'users', required: true }],
        },
        addUser: {
            to: '/users/new-user',
            navLinkText: 'Create User',
            permissions: [
                { type: 'users', operation: 'users', required: true },
                { type: 'users', operation: 'createUser', required: true },
                { type: 'permissionGroups', operation: 'permissionGroups' },
                { type: 'users', operation: 'userExists' },
                { type: 'permissions', operation: 'permissions' },
                { type: 'users', operation: 'userPermissions' },
            ],
        },
        bannedEmails: {
            to: '/users/banned-emails',
            navLinkText: 'Banned',
            permissions: [
                { type: 'bannedEmails', operation: 'bannedEmails', required: true },
                { type: 'bannedEmails', operation: 'createBannedEmail' },
                { type: 'bannedEmails', operation: 'deleteBannedEmail' },
            ],
        },
        user: {
            to: '/users/[user]',
            navLinkText: 'View User',
            permissions: [
                { type: 'users', operation: 'user', required: true },
                { type: 'permissions', operation: 'permissions' },
                { type: 'permissions', operation: 'updateUserPermissions' },
                { type: 'users', operation: 'updateUser' },
                { type: 'users', operation: 'deleteUser' },
                { type: 'users', operation: 'disableUser' },
                { type: 'users', operation: 'enableUser' },
                { type: 'users', operation: 'masterResetUser' },
                { type: 'bannedEmails', operation: 'createBannedEmail' },
                { type: 'users', operation: 'userPermissions' },
            ],
            parent: '/users/all',
            getPath: (userId: string) => `/users/${userId}`,
        } as BaseRouteConfig,
    },
    me: {
        accountSettings: {
            to: '/me/account-settings',
            navLinkText: 'Account Settings',
        },
        verifyEmail: {
            to: '/me/verify',
            navLinkText: 'Verify Email',
        },
    },
    support: {
        reportAnIssue: {
            to: '/support/report-an-issue',
            navLinkText: 'Report Bug',
        },
    },
    services: {
        allServices: {
            to: '/services',
            navLinkText: 'Services',
            permissions: [{ type: 'services', operation: 'allServices', required: true }],
        },
        service: {
            to: '/services/[serviceId]',
            navLinkText: 'Edit Service',
            permissions: [{ type: 'services', operation: 'service', required: true }],
            getPath: (serviceId: string) => `/services/${serviceId}`,
        } as BaseRouteConfig,
        createService: {
            to: '/services/create',
            navLinkText: 'Create Service',
            // permissions: [
            //     { type: 'services', operation: 'createService', required: true },
            // ],
        },
    },
    organizations: {
        allOrganizations: {
            to: '/organizations/all',
            navLinkText: 'Organizations',
        },
        organization: {
            to: '/organizations/[organizationId]',
            navLinkText: 'Edit Organization',
            // permissions: [{ type: 'organizations', operation: 'organization', required: true }],
            getPath: (organizationId: string) => `/organizations/${organizationId}`,
        } as BaseRouteConfig,
        createOrganization: {
            to: '/organizations/create',
            navLinkText: 'Create Organization',
            // permissions: [
            //     { type: 'organizations', operation: 'createOrganization', required: true },
            // ],
        },
    },
};

const pathMapFromConfig = (
    routes: typeof ROUTES | BaseRouteConfig,
): Record<string, BaseRouteConfig> =>
    Object.values(routes).reduce((paths, route: BaseRouteConfig) => {
        if (route.to && route.navLinkText) paths[route.to] = route;
        else {
            const nestedPaths = pathMapFromConfig(route);

            paths = {
                ...paths,
                ...nestedPaths,
            };
        }

        return paths;
    }, {} as Record<string, BaseRouteConfig>);

export const PATH_TO_PAGE_NAME = pathMapFromConfig(ROUTES);

const HOST_NAME = process.env.cookieDomain;

const REDIRECTS = {
    auth: {
        signin: {
            to: '/auth/signin',
            uri: process.env.authRedirect,
            navLinkText: 'Sign In',
        },
        activate: {
            to: '/auth/activate',
            uri: `https://auth.${HOST_NAME}/activate`,
            navLinkText: 'Activate Account',
        },
        resetPassword: {
            to: '/auth/reset-password',
            uri: `https://auth.${HOST_NAME}/reset-password`,
            navLinkText: 'Reset Password',
        },
    },
    docsite: {
        to: '',
        uri: `https://docs.${HOST_NAME}`,
        navLinkText: 'Developer Docs',
    },
    submissions: {
        to: '',
        uri: process.env.submissionsRedirect,
        navLinkText: 'Go To Submissions Platform',
    },
    website: {
        to: '',
        uri: process.env.websiteRedirect,
        navLinkText: 'Go To Website',
    },
    preview: {
        to: '',
        uri: process.env.websiteRedirect + '/preview/',
        navLinkText: 'Preview Post',
    },
};

export interface DrawerItemRoute {
    isExternal: boolean;
    to?: string;
    uri?: string;
    navLinkText: string;
    permissions?: {
        type: string;
        operation: string;
    }[];
    navLinkIcon: JSX.Element;
    subMenu?: Array<{
        subMenuText: string;
        subMenuIcon: JSX.Element;
        to: string;
    }>;
}

interface DrawerItem {
    sectionTitle: string;
    routes: DrawerItemRoute[];
    useBottomDivider: boolean;
}

const DRAWER_CONFIG: DrawerItem[] = [
    {
        sectionTitle: '',
        routes: [
            {
                navLinkIcon: <Dashboard />,
                ...(ROUTES.dashboard as BaseRouteConfig),
                isExternal: false,
            },
            {
                navLinkIcon: <MaterialIcon icon="analytics" />,
                ...(ROUTES.analytics as BaseRouteConfig),
                isExternal: false,
            },
            {
                navLinkIcon: <MediaIcon />,
                ...(ROUTES.media as BaseRouteConfig),
                isExternal: false,
            },
        ],
        useBottomDivider: true,
    },
    {
        sectionTitle: '',
        routes: [
            {
                navLinkIcon: <MaterialIcon icon="dynamic_feed" />,
                ...(ROUTES.posts.writeupQueue as BaseRouteConfig),
                isExternal: false,
            },
            {
                navLinkIcon: <MaterialIcon icon="upcoming" />,
                ...(ROUTES.submissions.allSubmissions as BaseRouteConfig),
                isExternal: false,
            },
            {
                navLinkIcon: <HowToVote />,
                ...(ROUTES.submissions.newSubmissions as BaseRouteConfig),
                isExternal: false,
            },
            {
                ...(ROUTES.services.createService as BaseRouteConfig),
                navLinkIcon: <CreateServiceIcon />,
                isExternal: false,
            },
            {
                ...(ROUTES.services.allServices as BaseRouteConfig),
                navLinkIcon: <ServiceIcon />,
                isExternal: false,
            },
            {
                navLinkIcon: <OrganizationsIcon />,
                ...(ROUTES.organizations.allOrganizations as BaseRouteConfig),
                isExternal: false,
            },
        ],
        useBottomDivider: false,
    },
    {
        sectionTitle: 'Posts',
        routes: [
            {
                navLinkIcon: <TextBoxPlusOutline />,
                ...(ROUTES.posts.newPost as BaseRouteConfig),
                isExternal: false,
                subMenu: [
                    {
                        subMenuText: 'Pitch',
                        subMenuIcon: <PitchIcon />,
                        to: '/posts/edit?asNewPost=true&startAs=pitch',
                    },
                    {
                        subMenuText: 'Draft',
                        subMenuIcon: <DraftIcon />,
                        to: '/posts/edit?asNewPost=true&startAs=draft',
                    },
                ],
            },
            {
                navLinkIcon: <TextBoxOutline />,
                ...(ROUTES.posts.allPosts as BaseRouteConfig),
                isExternal: false,
            },
            {
                navLinkIcon: <ScheduledPostsIcon />,
                ...(ROUTES.posts.scheduledPosts as BaseRouteConfig),
                isExternal: false,
            },
        ],
        useBottomDivider: false,
    },
    {
        sectionTitle: 'Promo',
        routes: [
            {
                navLinkIcon: <MaterialIcon icon="note_add" />,
                ...(ROUTES.promotions.createPromotion as BaseRouteConfig),
                isExternal: false,
            },
            {
                navLinkIcon: <MaterialIcon icon="request_quote" />,
                ...(ROUTES.promotions.allPromotions as BaseRouteConfig),
                isExternal: false,
            },
        ],
        useBottomDivider: false,
    },
    {
        sectionTitle: 'Advertisements',
        routes: [
            {
                navLinkIcon: <MaterialIcon icon="ad_units" />,
                ...(ROUTES.campaigns.allCampaigns as BaseRouteConfig),
                isExternal: false,
            },
        ],
        useBottomDivider: false,
    },
    {
        sectionTitle: 'Admin',
        routes: [
            {
                navLinkIcon: <ViewCompact />,
                ...(ROUTES.posts.editFeatured as BaseRouteConfig),
                isExternal: false,
            },
            {
                navLinkIcon: <Shape />,
                ...(ROUTES.posts.categories as BaseRouteConfig),
                isExternal: false,
            },
            {
                navLinkIcon: <MediaIcon />,
                ...(ROUTES.media as BaseRouteConfig),
                isExternal: false,
            },
            {
                navLinkIcon: <AllUsersIcon />,
                ...(ROUTES.users.all as BaseRouteConfig),
                isExternal: false,
            },
            {
                navLinkIcon: <RecentActors />,
                ...(ROUTES.submissions.recentActivity as BaseRouteConfig),
                isExternal: false,
            },
        ],
        useBottomDivider: false,
    },
    {
        sectionTitle: 'Users',
        routes: [
            {
                navLinkIcon: <PersonAdd />,
                ...(ROUTES.users.addUser as BaseRouteConfig),
                isExternal: false,
            },
            {
                navLinkIcon: <Gavel />,
                ...(ROUTES.users.bannedEmails as BaseRouteConfig),
                isExternal: false,
            },
        ],
        useBottomDivider: false,
    },
    {
        sectionTitle: 'Account',
        routes: [
            {
                navLinkIcon: <AccountSettingsIcon />,
                ...(ROUTES.me.accountSettings as BaseRouteConfig),
                isExternal: false,
            },
        ],
        useBottomDivider: false,
    },
    {
        sectionTitle: 'Support',
        routes: [
            {
                navLinkIcon: <ReportABugIcon />,
                ...(ROUTES.support.reportAnIssue as BaseRouteConfig),
                isExternal: false,
            },
            {
                navLinkIcon: <BookOpenPageVariant />,
                ...REDIRECTS.docsite,
                isExternal: true,
            },
        ],
        useBottomDivider: false,
    },
];

export { DRAWER_CONFIG, REDIRECTS, ROUTES };
