import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            paddingLeft: 0,
            paddingRight: 0,
            /**
             * Allows submission list to maintain padding when used in
             * Desktop Quick Review
             */
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(2),
            },
        },
        listItemAvatar: {
            paddingRight: theme.spacing(2),
        },
        submissionStatusAvatar: {
            backgroundColor: theme.palette.divider,
            height: '50px',
            width: '50px',
            [theme.breakpoints.down('xs')]: {
                height: '40px',
                width: '40px',
            },
        },
        listItemText: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            maxWidth: '90%',
            [theme.breakpoints.down('xs')]: {
                maxWidth: '85%',
            },
        },
        listItemRight: {
            height: '60px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-end',
        },
        flaggedSub: {
            color: theme.palette.custom.orange.huntingVest,
            paddingTop: '2px',
            fontSize: theme.typography.pxToRem(16),
        },
    }),
);

export default useStyles;
