import React from 'react';
import useStyles from './styles';
import { Media, SearchUserResult } from '@elevatormedia/duffel-bag';
import {
    Avatar,
    Chip,
    DialogContentText,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { PROMOTION_AUTHORS } from 'lib/graphql/queries/promotions';
import { PromotionAuthor } from 'types/promotion';

const PromotionDialog = (props: PromotionDialogProps) => {
    const classes = useStyles();

    const { author } = props;

    const { data, error } = useQuery(PROMOTION_AUTHORS, {
        notifyOnNetworkStatusChange: true,
    });

    const authorOptions = data && data.promotionAuthors ? data.promotionAuthors : [];

    return (
        <>
            <div className={classes.currentAuthorsContainer}>
                <DialogContentText>Current</DialogContentText>
                <div>
                    {author ? (
                        <Chip
                            label={author.username}
                            avatar={
                                <Avatar
                                    src={author.avatar && author.avatar.sourceUrl}
                                    srcSet={author.avatar && author.avatar.sourceSet}
                                >
                                    {author.username[0].toUpperCase()}
                                </Avatar>
                            }
                            className={classes.currentAuthorChip}
                        />
                    ) : (
                        <Typography className={classes.noAuthorsText}>
                            No author
                        </Typography>
                    )}
                </div>
            </div>
            <div>
                <DialogContentText>Select</DialogContentText>
                {authorOptions.length > 0 ? (
                    <Select variant={'outlined'} fullWidth value={author?.userId || ''}>
                        {authorOptions.map((author: SearchUserResult) => (
                            <MenuItem key={author.userId} value={author.userId}>
                                {author.preferredUsername}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    <Typography>No options available</Typography>
                )}
            </div>
        </>
    );
};

export interface PromotionDialogProps {
    author: PromotionAuthor & { avatar?: Media };
}

export default PromotionDialog;
