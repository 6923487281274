import React from 'react';
import useStyles from './styles';
import {
    BorderNoneVariant,
    Cancel,
    CheckCircle,
    ChevronDown,
    ChevronUp,
    DotsHorizontalCircle,
} from 'mdi-material-ui';
import { GridContainer, GridItem, SubmissionStatus } from '@elevatormedia/duffel-bag';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

const DashboardCardAdornment = (props: DashboardCardAdornmentProps) => {
    const classes = useStyles();

    const { adornment, text } = props;

    const renderAdornment = (icon: any, adornmentText: string) => {
        return (
            <GridContainer direction={'row'} spacing={1} alignItems={'center'}>
                <GridItem>{icon}</GridItem>
                <GridItem>
                    <Typography className={classes.text} variant={'overline'}>
                        {text ? text : adornmentText}
                    </Typography>
                </GridItem>
            </GridContainer>
        );
    };

    switch (adornment) {
        case 'Review':
        case 'Approval':
            return renderAdornment(
                <DotsHorizontalCircle
                    className={clsx(classes.icon, classes.dotsHorizontal)}
                />,
                'Pending',
            );
        case 'Rejected':
            return renderAdornment(
                <Cancel className={clsx(classes.icon, classes.red)} />,
                '',
            );
        case 'Approved':
        case 'Completed':
            return renderAdornment(
                <CheckCircle className={clsx(classes.icon, classes.green)} />,
                adornment,
            );
        case 'increase':
            return renderAdornment(<ChevronUp className={clsx(classes.green)} />, text);
        case 'decrease':
            return renderAdornment(<ChevronDown className={clsx(classes.red)} />, text);

        default:
            return <BorderNoneVariant />;
    }
};

interface DashboardCardAdornmentProps {
    adornment: SubmissionStatus | 'increase' | 'decrease';
    text?: string;
}

export default DashboardCardAdornment;
