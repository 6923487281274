import React from 'react';
import { CircularProgress, Typography, Button } from '@material-ui/core';
import ImageResDownloader from '../ImagResDownloader';
import ImageCropOption from './ImageCropOption';
import clsx from 'clsx';
import useStyles from './styles';
import {
    ImageResDownloaderProps,
    ImageResDownloaderVariant,
} from 'molecules/ImagResDownloader/ImageResDownloader';
import { ImageCropType, Media } from '@elevatormedia/duffel-bag';

const ImageOptions: React.FC<ImageOptionsProps> = (props) => {
    const classes = useStyles();
    const {
        src,
        srcSet,
        crop,

        onCropImageOptionChange,
        isTransformingImage, // processing indicator
        disableSaveButton,
        onSaveButtonClick,

        postImage,
    } = props;

    const [activeCropOption, setActiveCropOption] = React.useState(crop);

    const isCropImageOptionSelected = (key: ImageCropType) => {
        return key === activeCropOption;
    };

    // Set new state and call prop callback function with new value
    const handleCardOnClick = (newState: ImageCropType) => {
        setActiveCropOption(newState);
        onCropImageOptionChange(newState);
    };

    // If the image is being transformed, render loading indicator, otherwise render image
    const renderImagePreview = () => {
        if (isTransformingImage) {
            return (
                <div className={clsx(classes.imagePreview, classes.loadingContainer)}>
                    <CircularProgress color={'primary'} />
                </div>
            );
        } else {
            return (
                <img
                    src={src}
                    srcSet={srcSet}
                    className={classes.imagePreview}
                    alt={postImage.path}
                />
            );
        }
    };

    const renderImageCropHandler = () => {
        return (
            <>
                <div className={classes.imageOptionsContainer}>
                    <ImageCropOption
                        variant={null}
                        selected={isCropImageOptionSelected(null)}
                        onClick={() => handleCardOnClick(null)}
                    />
                    <ImageCropOption
                        variant={'faces'}
                        selected={isCropImageOptionSelected('faces')}
                        onClick={() => handleCardOnClick('faces')}
                    />

                    <ImageCropOption
                        variant={'top'}
                        selected={isCropImageOptionSelected('top')}
                        onClick={() => handleCardOnClick('top')}
                    />

                    <ImageCropOption
                        variant={'bottom'}
                        selected={isCropImageOptionSelected('bottom')}
                        onClick={() => handleCardOnClick('bottom')}
                    />
                </div>
                <div className={classes.saveButtonContainer}>
                    <Button
                        disabled={
                            isTransformingImage ||
                            activeCropOption === postImage.metadata.crop ||
                            disableSaveButton
                        }
                        onClick={() => onSaveButtonClick(activeCropOption)}
                        variant={'contained'}
                        color={'primary'}
                    >
                        Refresh images on site
                    </Button>
                </div>
            </>
        );
    };

    return (
        <div className={classes.root}>
            <div className={classes.imageContainer}>{renderImagePreview()}</div>
            <div>
                {renderImageCropHandler()}
                <div className={classes.imageResDownloaderContainer}>
                    <Typography variant={'subtitle2'}>Export</Typography>
                    <ImageResDownloader source={postImage.sourceUrl} />
                </div>
            </div>
        </div>
    );
};

interface ImageOptionsProps
    extends Partial<ImageResDownloaderProps<ImageResDownloaderVariant>> {
    src: string;
    srcSet?: string;
    crop?: ImageCropType;
    isTransformingImage?: boolean;
    disableSaveButton?: boolean;
    onCropImageOptionChange?: (cropType: ImageCropType) => void;
    onSaveButtonClick?: Function;
    postImage?: Media;
}

export default ImageOptions;
