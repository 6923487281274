import { responsiveFontSizes, Theme, createTheme } from '@material-ui/core';
import customPalette from './palette';
import overridesConfig from './overrides';
import defaultPropsConfig from './props';
import staticComponentSizesConfig from './staticComponentSizes';

const theme = createTheme({
    palette: customPalette,
    ...overridesConfig,
    ...defaultPropsConfig,
    static_component_sizes: staticComponentSizesConfig,
});

export default responsiveFontSizes(theme);
