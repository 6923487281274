import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            minWidth: '165px',
        },
        dropdownArrow: {
            color: theme.palette.text.secondary,
        },
    }),
);

export default useStyles;
