import React from 'react';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { GridContainer, GridItem } from '@elevatormedia/duffel-bag';
import { FEATURED_POSTS } from '../../lib/graphql/queries/posts';
import { reportError } from '../../lib/errors';
import useStyles from './styles';
import Link from '../../atoms/Link';
import { ROUTES } from '../../config/Nav';
import { useApolloClient } from '@apollo/client';
import { Post } from 'types/post';
import { BaseRouteConfig } from 'types/nav';

const smallBoxHeight = '100px';
const largeBoxHeight = '300px';

const FeaturedPositionSelector: React.FC<FeaturedPositionSelectorProps> = (props) => {
    const classes = useStyles();
    const { activePosition, onActivePositionChange, currentPostId, alreadyFeatured } =
        props;

    const [featuredPosts, setFeaturedPosts] = React.useState<Record<string, Post>>(null);
    const client = useApolloClient();
    /**
     * Prevents dangling setState call if the component unmounts
     * before the getFeaturedPosts() call is resolved
     */
    React.useEffect(() => {
        let canceled = false;
        async function fetchData() {
            const response = await getFeaturedPosts();
            if (!canceled) {
                setFeaturedPosts(response);
            }
        }
        fetchData();

        return () => {
            canceled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Retrieve featured posts existing in current slots
     */
    const getFeaturedPosts = async () => {
        const { data, errors } = await client.query({
            query: FEATURED_POSTS,
        });

        if (errors && errors.length) {
            reportError(errors[0], {
                metaData: {
                    operation: 'query featuredPosts',
                },
            });

            return null;
        }

        const { featuredPosts } = data;

        return featuredPosts;
    };

    /**
     * retrieves a the featured post from the object based off of index
     */
    const getSlotPost = () => {
        // generate array of integer values representing slots
        const post = featuredPosts[`_${activePosition}`];

        if (!post) {
            return null;
        }

        return post;
    };

    const isSelected = (boxPosition: number) => {
        return boxPosition === activePosition;
    };

    const getBoxProps = (boxPosition: number) => {
        const boxIsActive = isSelected(boxPosition);

        return {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
            width: '100%',
            className: clsx(classes.defaultBox, {
                [classes.deselectedBox]: !boxIsActive,
                [classes.selectedBox]: boxIsActive,
            }),
        };
    };

    const getDisclaimerBoxBody = () => {
        const featuredPostIds = Object.values(featuredPosts).map(
            (featuredPost) => featuredPost.postId,
        );

        // If the current post is already featured,
        // display a disclaimer that the two posts will be swapped
        // rather than replaced
        if (featuredPostIds.includes(currentPostId)) {
            return (
                <Typography
                    variant={'body2'}
                    component={'blockquote'}
                    className={classes.warningBox}
                >
                    Your post will swap slots with "
                    <Link
                        href={
                            (ROUTES.posts.editPost as BaseRouteConfig).to +
                            occupiedSlotPost.postId
                        }
                    >
                        {occupiedSlotPost.title}
                    </Link>
                    ."
                </Typography>
            );
        }

        // Otherwise state that the occupied slot will be replaced with the
        // current post
        return (
            <Typography
                variant={'body2'}
                component={'blockquote'}
                className={classes.warningBox}
            >
                Slot <b>{activePosition}</b> is currently occupied by "
                <Link
                    href={
                        (ROUTES.posts.editPost as BaseRouteConfig).to +
                        occupiedSlotPost.postId
                    }
                >
                    {occupiedSlotPost.title}
                </Link>
                ." Publishing or saving your current work will replace this slot with this
                post.
            </Typography>
        );
    };

    if (!featuredPosts) {
        return (
            <Typography gutterBottom variant={'body2'} color={'error'}>
                Error loading existing featured posts. Please reload the page and try
                again
            </Typography>
        );
    }

    const occupiedSlotPost = getSlotPost();

    return (
        <Box width={'80vw'} maxWidth={'400px'} paddingY={2}>
            <Box paddingBottom={2}>
                <Typography variant={'body2'}>
                    Please select a featured slot for this post.{' '}
                    {alreadyFeatured &&
                        'To remove this post from the featured grid, assign a different post to the same featured position.'}
                </Typography>
            </Box>

            <GridContainer direction={'row'} spacing={2}>
                <GridItem xs={12}>
                    <Box
                        height={smallBoxHeight}
                        {...getBoxProps(0)}
                        onClick={() => onActivePositionChange(0)}
                    >
                        0
                    </Box>
                </GridItem>
                <GridItem xs={6}>
                    <Box paddingBottom={2} height={largeBoxHeight}>
                        <Box
                            height={'100%'}
                            {...getBoxProps(1)}
                            onClick={() => onActivePositionChange(1)}
                        >
                            1
                        </Box>
                    </Box>

                    <Box
                        height={smallBoxHeight}
                        {...getBoxProps(2)}
                        onClick={() => onActivePositionChange(2)}
                    >
                        2
                    </Box>
                </GridItem>
                <GridItem xs={6}>
                    <Box height={smallBoxHeight} paddingBottom={2}>
                        <Box
                            height={'100%'}
                            {...getBoxProps(3)}
                            onClick={() => onActivePositionChange(3)}
                        >
                            3
                        </Box>
                    </Box>
                    <Box
                        height={largeBoxHeight}
                        {...getBoxProps(4)}
                        onClick={() => onActivePositionChange(4)}
                    >
                        4
                    </Box>
                </GridItem>
            </GridContainer>
            {occupiedSlotPost && occupiedSlotPost.postId !== currentPostId && (
                <Box paddingTop={2}>{getDisclaimerBoxBody()}</Box>
            )}
        </Box>
    );
};

interface FeaturedPositionSelectorProps {
    activePosition: number;
    currentPostId: string;
    onActivePositionChange: (position: number) => void;
    alreadyFeatured: boolean;
}

export default FeaturedPositionSelector;
