import React, { MouseEvent, ComponentType, ForwardedRef } from 'react';
import clsx from 'clsx';
import { NextRouter, withRouter } from 'next/router';
import NextLink from 'next/link';
import MuiLink from '@material-ui/core/Link';

type NextComposedPropTypes = {
    classes?: any;
    className?: string;
    as?: string;
    href: string;
    prefetch?: boolean;
    [key: string]: any;
};

const NextComposed = React.forwardRef(function NextComposed(
    props: NextComposedPropTypes,
    ref: ForwardedRef<any>,
) {
    const { as, href, prefetch, ...other } = props;

    return (
        <NextLink href={href} prefetch={prefetch} as={as}>
            <a ref={ref} {...other} />
        </NextLink>
    );
});

export type BaseLinkPropTypes = {
    activeClassName?: string;
    as?: string;
    className?: string;
    href: string;
    innerRef?: ForwardedRef<any>;
    naked?: boolean;
    onClick?: (e: MouseEvent) => void;
    prefetch?: boolean;
    children?: React.ReactNode;
    [key: string]: any;
};

function LinkComponent(props: BaseLinkPropTypes & { router: NextRouter }) {
    const {
        activeClassName = 'active',
        router,
        className: classNameProps,
        innerRef,
        naked,
        ...other
    } = props;

    const className = clsx(classNameProps, {
        [activeClassName]: router.pathname === props.href && activeClassName,
    });

    if (naked) {
        return <NextComposed className={className} ref={innerRef} {...other} />;
    }

    return (
        <MuiLink
            component={NextComposed}
            className={className}
            ref={innerRef}
            {...other}
        />
    );
}

const RouterLink = withRouter(LinkComponent) as ComponentType<
    Omit<BaseLinkPropTypes, 'router'>
>;

const ForwardedRouterLink = React.forwardRef(
    (props: BaseLinkPropTypes, ref: ForwardedRef<any>) => (
        <RouterLink {...props} innerRef={ref} />
    ),
);

// Add display name to the forwarded component
ForwardedRouterLink.displayName = 'ForwardedRouterLink';

export default ForwardedRouterLink;
