import React from 'react';
import { Instagram, Soundcloud, Twitter, Youtube, Facebook } from 'mdi-material-ui';
import { SocialMediaLink } from '@elevatormedia/duffel-bag';
import useStyles from './styles';

const SocialMediaAccountLink: React.FC<SocialMediaLink> = (props) => {
    const classes = useStyles();
    const { type, link } = props;

    if (link.length === 0) return null;

    const renderLink = () => {
        let href = link;

        if (!link.startsWith('https://')) href = 'https://' + link;

        const commonLinkProps = {
            className: classes.userNameText,
            href,
            target: '_blank',
        };

        switch (type.toLowerCase()) {
            case 'instagram':
                return (
                    <div className={classes.root}>
                        <Instagram fontSize={'small'} className={classes.icon} />
                        <a {...commonLinkProps}>{link}</a>
                    </div>
                );
            case 'youtube':
                return (
                    <div className={classes.root}>
                        <Youtube fontSize={'small'} className={classes.icon} />
                        <a {...commonLinkProps}>{link}</a>
                    </div>
                );
            case 'soundcloud':
                return (
                    <div className={classes.root}>
                        <Soundcloud fontSize={'small'} className={classes.icon} />
                        <a {...commonLinkProps}>{link}</a>
                    </div>
                );
            case 'twitter':
                return (
                    <div className={classes.root}>
                        <Twitter fontSize={'small'} className={classes.icon} />
                        <a {...commonLinkProps}>{link}</a>
                    </div>
                );
            case 'facebook':
                return (
                    <div className={classes.root}>
                        <Facebook fontSize={'small'} className={classes.icon} />
                        <a {...commonLinkProps}>{link}</a>
                    </div>
                );
            default:
                console.error(`Unrecognized Social Media Type: ${type}`);
                return null;
        }
    };

    return renderLink();
};

// SocialMediaAccountLink.propTypes = {
//     classes: PropTypes.object.isRequired,
//     type: PropTypes.oneOf(Object.values(SOCIAL_MEDIA_TYPES)).isRequired,
//     link: PropTypes.string.isRequired,
// };

export default SocialMediaAccountLink;
