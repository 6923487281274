import React, { useCallback, useState } from 'react';
import { Button, Grow, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { Close, HandFrontRight, Image as ImageIcon, Upload } from 'mdi-material-ui';
import { useDropzone } from 'react-dropzone';
import { GridContainer, GridItem, ImageCropType, Media } from '@elevatormedia/duffel-bag';
import ImageOptions from '../../molecules/ImageOptions/ImageOptions';
import clsx from 'clsx';
import useStyles from './styles';
import { SaveButtonClick } from 'types/editor';
import { FormikErrors } from 'formik';
import MediaModal from 'organisms/MediaLibrary/MediaModal';

const FileDropzone: React.FC<FileDropzoneProps> = (props) => {
    const classes = useStyles();

    const {
        file,
        onFileChange,
        helperText,
        hasError,
        isTransformingImage = false,
        onCropImageOptionChange,
        onSaveButtonClick,
        disableSaveButton,
        postImage,
        hideImageOptions = false,
        supportedFileTypes,
        enableLibrary,
    } = props;

    const onDrop = useCallback((acceptedFiles: any[]) => {
        // Add a preview attribute to the files
        acceptedFiles.map((file) => {
            file.sourceUrl = URL.createObjectURL(file);
        });

        onFileChange(acceptedFiles[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const [isMediaModalOpen, setMediaModalOpen] = useState(false);

    // Removes files uploaded resetting state
    const removeFile = () => {
        onFileChange(null);
    };

    // Renders helper text as error or default text below the image uploader
    const renderHelperText = () => {
        return (
            <Typography variant={'caption'} color={hasError ? 'error' : 'textSecondary'}>
                {helperText}
            </Typography>
        );
    };

    const renderImageContent = () => {
        if (postImage && !file.size && !hideImageOptions) {
            return (
                <ImageOptions
                    src={file.sourceUrl}
                    srcSet={file.sourceSet}
                    crop={file.metadata.crop}
                    isTransformingImage={isTransformingImage}
                    onCropImageOptionChange={onCropImageOptionChange}
                    onSaveButtonClick={onSaveButtonClick}
                    disableSaveButton={disableSaveButton}
                    postImage={postImage}
                />
            );
        } else {
            return (
                <img
                    src={file.sourceUrl}
                    srcSet={file.sourceSet}
                    height={'auto'}
                    width={'300px'}
                    className={classes.image}
                    alt={'Default Image'}
                />
            );
        }
    };

    // Only rendered when a user is actively dragging a file.
    const renderDragActiveState = () => {
        return (
            <div className={classes.instructionContainer}>
                <HandFrontRight
                    color={'primary'}
                    fontSize={'large'}
                    className={classes.icon}
                />
                <Typography
                    variant={'body1'}
                    align={'center'}
                    color={'primary'}
                    className={classes.uploadInstr}
                >
                    Drop your file here
                </Typography>
            </div>
        );
    };

    // Rendered by default
    const renderDefaultState = () => {
        return (
            <div className={classes.instructionContainer}>
                <Upload color={'disabled'} fontSize={'large'} className={classes.icon} />
                <Typography
                    variant={'body1'}
                    align={'center'}
                    color={'textSecondary'}
                    className={classes.uploadInstr}
                >
                    Drag & drop image or click to select manually
                </Typography>

                {enableLibrary && (
                    <Button
                        size={'small'}
                        style={{ margin: '8px' }}
                        onClick={(event) => {
                            event.stopPropagation();
                            setMediaModalOpen(true);
                        }}
                    >
                        Choose From Library
                    </Button>
                )}
            </div>
        );
    };

    // Renders an image preview with the option to remove the uploaded file
    const renderFileCard = () => {
        if (!file) {
            return null;
        }

        return (
            <Grow key={file.path} in>
                <div>
                    <Paper className={clsx(hasError && classes.errorBorder)}>
                        <GridContainer
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            className={classes.paperHeader}
                        >
                            <GridItem className={classes.fileNameContainer}>
                                <ImageIcon className={classes.iconInactive} />
                                <Typography
                                    variant={'body2'}
                                    className={classes.fileName}
                                    color={'textSecondary'}
                                >
                                    {file.path || file.metadata.filename}{' '}
                                    {file.size && `- ${file.size} bytes`}
                                </Typography>
                            </GridItem>
                            <GridItem>
                                <Tooltip title={'remove file'}>
                                    <IconButton onClick={removeFile} color={'default'}>
                                        <Close />
                                    </IconButton>
                                </Tooltip>
                            </GridItem>
                        </GridContainer>
                        {renderImageContent()}
                    </Paper>
                    {renderHelperText()}
                </div>
            </Grow>
        );
    };

    // Styled dropzone
    const style = React.useMemo(
        () => ({
            className: clsx(classes.borderedBox, classes.addDefaultBorder, {
                [classes.addBlueBorder]: isDragActive,
                [classes.errorBorder]: hasError,
            }),
        }),
        [isDragActive, hasError, classes],
    );

    const renderFileDropzone = () => {
        return (
            <>
                <div {...getRootProps(style)}>
                    <input
                        {...getInputProps()}
                        accept={
                            supportedFileTypes ? supportedFileTypes.join(', ') : undefined
                        }
                    />
                    {isDragActive ? renderDragActiveState() : renderDefaultState()}
                </div>
                {renderHelperText()}
                {enableLibrary && (
                    <MediaModal
                        open={isMediaModalOpen}
                        handleClose={() => setMediaModalOpen(false)}
                        onClickMedia={(media) => onFileChange(media)}
                    />
                )}
            </>
        );
    };

    return <div>{file ? renderFileCard() : renderFileDropzone()}</div>;
};

interface FileDropzoneProps<T = any> {
    file?: T;
    onFileChange: (file: T) => void;
    hasError: boolean;

    helperText?: string | FormikErrors<Media>;
    isTransformingImage?: boolean;
    disableSaveButton?: boolean;
    // TODO: Check this type
    postImage?: T;
    hideImageOptions?: boolean;
    supportedFileTypes?: string[];
    onCropImageOptionChange?: (cropType: ImageCropType) => Promise<void>;
    onSaveButtonClick?: SaveButtonClick;

    enableLibrary?: boolean;
}

FileDropzone.defaultProps = {
    isTransformingImage: false,
    hideImageOptions: false,
};

export default FileDropzone;
