import { createStyles, makeStyles, Theme } from '@material-ui/core';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        // Shared
        statusContainer: {
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            width: '100%',
        },

        // Submission Status Circle
        alignCenter: {
            justifyContent: 'center',
            alignItems: 'center',
        },

        highContrast: {
            color: theme.palette.common.white,
        },

        // Submission Status Chip
        chipLayout: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        reviewColor: {
            backgroundColor: theme.palette.submissionStatuses.review,
        },
        approvalColor: {
            backgroundColor: theme.palette.submissionStatuses.approval,
        },
        approvedColor: {
            backgroundColor: theme.palette.submissionStatuses.approved,
        },
        completedColor: {
            backgroundColor: theme.palette.submissionStatuses.completed,
        },
        rejectedColor: {
            backgroundColor: theme.palette.submissionStatuses.rejected,
        },
        backgroundContainer: {
            height: '24px',
            width: '24px',
            borderRadius: '3px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        chipIcon: {
            fontSize: theme.typography.pxToRem(14),

            color: 'white',
        },
        statusText: {
            paddingLeft: theme.spacing(1),
            fontSize: theme.typography.pxToRem(13),
        },
        regularStatusIcon: {
            fontSize: theme.typography.pxToRem(24),
        },
        smallStatusIcon: {
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(22),
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: theme.typography.pxToRem(18),
            },
        },
    }),
);

export default styles;
