import { reduceOpacity } from '@elevatormedia/duffel-bag';

import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textFieldGroupContainer: {
            paddingBottom: theme.spacing(5),
        },
        buttonContainer: {
            paddingTop: theme.spacing(1),
        },
        groupedButton: {
            '&:not(:last-child)': {
                borderRight: `1px solid rgba(0, 0, 0, 0.2)`,
                '&$disabled': {
                    borderRight: `1px solid rgba(0, 0, 0, 0.5)`,
                },
            },
            backgroundColor: '#7986CB',
            '&:hover': {
                backgroundColor: '#9ea7d9',
            },
        },
        leftButton: {
            width: 'max-content',
        },
        errorButton: {
            color: theme.palette.submissionStatuses.rejected,
            '&:hover': {
                backgroundColor: reduceOpacity(
                    theme.palette.submissionStatuses.rejected,
                    0.08,
                ),
            },
        },
        progressContainer: {
            paddingLeft: theme.spacing(1),
        },
        disabled: {
            // Define any styles for the disabled state if needed
        },
    }),
);

export default useStyles;
