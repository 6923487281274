import gql from 'graphql-tag';

const PROMOTION_TOTALS_BY_STATUS = gql`
    query {
        promotionTotalsByStatus
    }
`;

const PROMOTION_AUTHORS = gql`
    query promotionAuthors {
        promotionAuthors {
            userId
            preferredUsername
        }
    }
`;

export { PROMOTION_TOTALS_BY_STATUS, PROMOTION_AUTHORS };
