import { createStyles, makeStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        /*****************
         * AUTO COMPLETE *
         *****************/
        chipInput: {
            outline: 'none',
            borderBottom: 'none',
            border: '0px solid red',
        },
        editDistanceText: {
            fontWeight: 'bold',
        },
        container: {
            flexGrow: 1,
            position: 'relative',
        },
        suggestionsContainerOpen: {
            position: 'absolute',
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(3),
            left: 0,
            right: 0,
            zIndex: 2,
            boxShadow: theme.shadows[2],
            borderRadius: '3px',
        },
        suggestion: {
            display: 'block',
        },
        suggestionsList: {
            margin: 0,
            padding: 0,
            borderTopWidth: 0,
            border: 'none',
        },
        copyIcon: {
            fontSize: theme.typography.pxToRem(18),
        },
        iconButton: {
            paddingBottom: theme.spacing(2),
        },
    });

export default makeStyles(styles);
