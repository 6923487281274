import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        circle: {
            display: 'inline-block',
            height: '25px',
            width: '25px',
            borderRadius: '50%',
            textAlign: 'center',
            marginLeft: theme.spacing(2),
        },
        icon: {
            marginTop: '1px',
            verticalAlign: 'middle',
            color: 'white',
        },
        calendar: {
            marginTop: '3px',
        },
    }),
);

export default useStyles;
