import React, { useEffect } from 'react';
import { GridContainer, GridItem, TextField } from '@elevatormedia/duffel-bag';
import { Box, Typography } from '@material-ui/core';
import {
    Instagram,
    Soundcloud,
    Twitter,
    Youtube,
    Facebook,
    Spotify,
} from 'mdi-material-ui';
import useStyles from './styles';
import { CreditFormValues } from 'types/credits';
import { useFormikContext } from 'formik';
import { isSocialMediaType, SocialMediaType } from 'types/socialMedia';
import { CurrentUser } from 'types/user';
import { buildUrl, extractUser, isValidDomain } from '@elevatormedia/socialite';
import { getDefaultSocialMediaPrefixes, normalizeLink } from 'utils/socials';

const CreatorInfo: React.FC<CreatorInfoProps> = ({ currentUser, disableUnderline }) => {
    const { values, errors, handleChange, setFieldTouched, touched } =
        useFormikContext<CreditFormValues>();
    const classes = useStyles();

    useEffect(() => {
        // Make form read-only when autoLinked
        const { autoLinked } = values;
        setManualEntry(!autoLinked);
    }, [values]);

    // Keeps track of whether the modal is being loaded in a read only state
    // or writable. Here, readonly state applies to where a user searched for an existing
    // Elevator user and links it to their submission
    const [manualEntry, setManualEntry] = React.useState(true);

    const getStartAdornment = (social: SocialMediaType) => {
        switch (social) {
            case 'twitter':
                return <Twitter className={classes.textFieldIcon} />;
            case 'instagram':
                return <Instagram className={classes.textFieldIcon} />;
            case 'soundcloud':
                return <Soundcloud className={classes.textFieldIcon} />;
            case 'facebook':
                return <Facebook className={classes.textFieldIcon} />;
            case 'spotify':
                return <Spotify className={classes.textFieldIcon} />;
            case 'youtube':
                return <Youtube className={classes.textFieldIcon} />;

            default:
                return null;
        }
    };

    const handleNameChange = (e: React.ChangeEvent) => {
        setFieldTouched('name');
        handleChange(e);
    };

    return (
        <GridContainer>
            <GridItem className={classes.creatorInfoRoot}>
                {currentUser && values.isSubmitter && (
                    <Box paddingBottom={3}>
                        <Typography variant={'caption'} color={'error'}>
                            Note: Editing your social media links here will update your
                            Elevator profile.
                        </Typography>
                    </Box>
                )}
                <Box paddingBottom={2}>
                    <TextField
                        id={'name'}
                        label={'Display Name'}
                        required
                        placeholder={'Young Jasper'}
                        value={values.name}
                        fullWidth
                        disableUnderline={disableUnderline}
                        onChange={handleNameChange}
                        error={touched.name && Boolean(errors.name)}
                        disabled={!manualEntry}
                        helperText={
                            touched.name && errors.name ? (
                                <Typography variant="caption" color="error">
                                    {errors.name}
                                </Typography>
                            ) : (
                                ''
                            )
                        }
                    />
                </Box>
                <GridItem className={classes.textFieldContainer}>
                    <TextField
                        id={'email'}
                        label={'Email'}
                        placeholder={'artist@gmail.com'}
                        value={values.email}
                        fullWidth
                        disableUnderline={disableUnderline}
                        onChange={handleChange}
                        error={Boolean(errors.email)}
                        disabled={!manualEntry}
                        hidden={values.autoLinked}
                        helperText={errors.email || ''}
                    />
                </GridItem>
                {Object.keys(values).map((key) => {
                    if (isSocialMediaType(key) && key !== 'appleMusic') {
                        const startAdornment = getStartAdornment(key);
                        const socialValue = values[key];
                        const emptyValue = socialValue === '';
                        const socialUrl = isValidDomain(socialValue)
                            ? socialValue
                            : key !== 'spotify'
                            ? buildUrl(socialValue, key)
                            : buildUrl(socialValue, key, 'artist');

                        const helperUrl = (
                            <Typography
                                href={emptyValue ? null : normalizeLink(socialUrl)}
                                component={emptyValue ? 'span' : 'a'}
                                variant={'caption'}
                                color={emptyValue ? 'textSecondary' : 'primary'}
                                target={'_blank'}
                            >
                                {emptyValue
                                    ? getDefaultSocialMediaPrefixes(key)
                                    : socialUrl}
                            </Typography>
                        );
                        return (
                            <GridItem key={key} className={classes.textFieldContainer}>
                                <TextField
                                    id={key}
                                    disabled={!manualEntry}
                                    label={key.replace(/^\w/, (char) =>
                                        char.toUpperCase(),
                                    )}
                                    value={socialValue}
                                    fullWidth
                                    startAdornment={startAdornment}
                                    disableUnderline={disableUnderline}
                                    placeholder={
                                        !manualEntry
                                            ? '[none]'
                                            : `${
                                                  key === 'youtube'
                                                      ? 'Channel'
                                                      : 'Username'
                                              } or Url`
                                    }
                                    onChange={handleChange}
                                    error={Boolean(errors[key])}
                                    helperText={errors[key] || helperUrl}
                                />
                            </GridItem>
                        );
                    }
                })}
            </GridItem>
        </GridContainer>
    );
};

export interface CreatorInfoProps {
    currentUser: CurrentUser;
    disableUnderline?: boolean;
}

CreatorInfo.defaultProps = {
    disableUnderline: true,
};

export default CreatorInfo;
