import React from 'react';
import { Tooltip, Button, Typography, Fab } from '@material-ui/core';
import useStyles from './styles';
import { Flash } from 'mdi-material-ui';
import { ROUTES } from 'config/Nav';
import { useRouter } from 'next/router';
import { BaseRouteConfig } from 'types/nav';

const QuickReviewButton: React.FC<QuickReviewButtonProps> = ({
    mobile,
    disabled,
    handleClick,
}) => {
    const classes = useStyles();
    const router = useRouter();

    const quickReviewDescription =
        'Quick Review allows you to quickly cycle through submissions without distractions.';

    const onClick = () => {
        if (handleClick) handleClick();
        else
            router.push({
                pathname: (ROUTES.submissions.newSubmissions as BaseRouteConfig).to,
                query: {
                    quickReviewOnInit: true,
                },
            });
    };

    return !mobile ? (
        <Tooltip title={quickReviewDescription}>
            <div className={classes.quickReviewButtonContainer}>
                <Button
                    className={classes.quickReviewButton}
                    onClick={onClick}
                    disabled={disabled}
                    variant={'outlined'}
                    color={'primary'}
                    size={'small'}
                >
                    <Flash />
                    <Typography variant={'button'} noWrap>
                        Quick Review
                    </Typography>
                </Button>
            </div>
        </Tooltip>
    ) : (
        <Fab
            onClick={onClick}
            disabled={disabled}
            color="primary"
            aria-label="Quick Review"
            className={classes.fab}
        >
            <Flash />
        </Fab>
    );
};

interface QuickReviewButtonProps {
    mobile: boolean;
    disabled: boolean;
    handleClick?: () => void;
}

export default QuickReviewButton;
