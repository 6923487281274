import React from 'react';
import {
    Button,
    List,
    ListItem,
    ListItemText,
    Typography,
    ListItemAvatar,
    Avatar,
    Box,
} from '@material-ui/core';
import { ChevronRight } from 'mdi-material-ui';
import PostStatus from '../../atoms/PostStatus';
import useStyles from './styles';
import { formatDate, PostAuthor } from '@elevatormedia/duffel-bag';

const PostsList: React.FC<PostsListProps> = (props) => {
    const classes = useStyles();
    const { handleRowOnClick, rows, page, handleChangePage } = props;

    /**
     * Handles loading more data from the database and appending them to the end of
     * the list
     * NOTE scrolls to top
     */
    const handleLoadMore = () => {
        handleChangePage(page + 1);
    };

    const formatArrayAsList = (list: PostAuthor[], attribute: keyof PostAuthor) => {
        let resultString = list[0][attribute];
        for (let index = 1; index < list.length; index++)
            resultString += `, ${list[index][attribute]}`;
        return resultString;
    };

    const renderUserRows = () => {
        return rows.map((row, index) => {
            return (
                <ListItem
                    onClick={(event) => handleRowOnClick(event, row, index)}
                    key={index}
                    button
                >
                    <ListItemAvatar className={classes.listItemAvatar}>
                        <Avatar className={classes.statusAvatar}>
                            <PostStatus status={row.status} iconOnly />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    variant="body1"
                                    color="textPrimary"
                                    className={classes.listItemText}
                                >
                                    {row.title}
                                </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                                <Typography
                                    variant="body2"
                                    color="textPrimary"
                                    className={classes.listItemText}
                                >
                                    {'Written by: ' +
                                        formatArrayAsList(
                                            row.authors,
                                            'preferredUsername',
                                        )}
                                </Typography>

                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    className={classes.listItemText}
                                >
                                    {row.status === 'scheduled'
                                        ? 'Scheduled for ' +
                                          formatDate(new Date(parseInt(row.publishedAt)))
                                        : 'Added ' +
                                          formatDate(new Date(parseInt(row.createdAt)))}
                                </Typography>
                            </React.Fragment>
                        }
                    />

                    <ChevronRight />
                </ListItem>
            );
        });
    };

    return (
        <div>
            <List>{renderUserRows()}</List>
            {rows.length && rows[0].count > rows.length ? (
                <Button
                    onClick={handleLoadMore}
                    fullWidth
                    color={'primary'}
                    variant={'outlined'}
                >
                    Load More
                </Button>
            ) : (
                <Box display="flex" justifyContent="center">
                    <Typography variant="body2" color="textSecondary">
                        End of List
                    </Typography>
                </Box>
            )}
        </div>
    );
};

interface PostsListProps<T = any> {
    handleRowOnClick: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        row: T,
        index: number,
    ) => void;
    // TODO: data return type
    // rows: Post[]; // Missing authors and count
    rows: T[];
    page: number;
    handleChangePage: (page: number) => void;
}

export default PostsList;
