import { EditorState } from 'draft-js';
import { Media } from '@elevatormedia/duffel-bag';
import { SubmissionSummary } from './submission';
import { User } from './user';
import { Credit } from './credits';

export type Post = {
    postId?: string;
    title: string;
    slug: string;
    shortLink?: string;
    status: PostStatus;
    type: PostType;
    description: string;
    content: string;
    image: Media;
    featured?: FeaturedMeta;
    authors?: PostAuthors;
    categories: PostCategories;
    tags: PostTags;
    createdAt?: string;
    updatedAt?: string;
    publishedAt?: string;
    modifiedAt?: string;
    views?: number;
    submission?: SubmissionSummary;
    credits?: Credit[];
    isAutoSaving?: boolean;
};

// Formik version of Post
export interface EditablePost
    extends Pick<
        Post,
        'title' | 'description' | 'slug' | 'image' | 'type' | 'isAutoSaving'
    > {
    tags: Array<string>;
    content: EditorState;
    selectedCategory: string;
    featuredPos: FeaturedPostPosition;
    status: PostStatus;
    submission?: SubmissionSummary;
}

export enum PostStatus {
    Published = 'published',
    Scheduled = 'scheduled',
    Private = 'private',
    Draft = 'draft',
    Pitch = 'pitch',
    Pending = 'pending',
}

export const isPostStatus = (candidate: string): candidate is PostStatus => {
    return Object.values(PostStatus).some((value) => value === candidate);
};

export type PostType =
    | 'Standard'
    | 'Featured'
    | 'Premiere'
    | 'Sponsored'
    | 'Submitted'
    | 'Paid'
    | 'Promoted';

/**
 * 0: Cover on Homepage
 * 1 - 4: Represents features position on home page
 * | 1 | 3 |
 * | 2 | 4 |
 */
export type FeaturedPostPosition = -1 | 0 | 1 | 2 | 3 | 4 | null;
export type FeaturedMeta = {
    position: FeaturedPostPosition;
};

export type PostAuthors = Array<PostAuthor>;
export interface PostAuthor
    extends Pick<User, 'userId' | 'preferredUsername' | 'avatar'> {
    avatar: Media;
}

export type PostCategories = Array<PostCategory>;
export type PostCategory = {
    categoryId: string;
    name: string;
};

export type PostTags = Array<PostTag>;
export type PostTag = {
    name: string;
    displayName: string;
};

export type PostAndCategories = {
    post: Post;
    categories: PostCategories;
};
