import { useQuery } from '@apollo/client';
import { CREDIT_TYPES } from 'lib/graphql/queries/creditTypes';
import CreditTypeSelector, { CreditTypeSelectorProps } from './CreditTypeSelector';

const CreditTypeSelectWithData = (
    props: Pick<CreditTypeSelectorProps, 'currentType' | 'handleChange'>,
) => {
    const { loading, data, error } = useQuery(CREDIT_TYPES, {
        notifyOnNetworkStatusChange: true,
    });

    if (loading || error) return null;

    const creditTypes = data && data.creditTypes ? data.creditTypes : [];

    return <CreditTypeSelector creditTypes={creditTypes} {...props} />;
};

export default CreditTypeSelectWithData;
