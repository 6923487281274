import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            minHeight: '25px',
            paddingBottom: theme.spacing(1),
        },
        icon: {
            color: theme.palette.text.secondary,
        },
        userNameText: {
            paddingLeft: theme.spacing(1),
            color: theme.palette.primary.main,
            maxWidth: '80vw',
            overflowWrap: 'break-word',
            wordWrap: 'break-word', // for older IE and Edge
            '-ms-word-break': 'break-all',
            /* Instead use this non-standard one: */
            wordBreak: 'break-word',
        },
    }),
);

export default useStyles;
