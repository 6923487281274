import parseURL from 'url-parse';
import cheerio from 'cheerio';
import CONTENT_URL_REGEXES from '../config/ContentURLTypes';

/**
 * Retrieves a lowercase instance of a domain name from a content URL.
 * for example, a link like https://open.spotify.com/track/fsdfg235Sd%3
 * would return the string 'spotify'
 */
const getDomainName = (name: string, nullableIfFailed = false) => {
    // Account for Spotify URIs of form: spotify:track:{} or spotify:playlist:{} spotify:album:{} etc.
    if (name.startsWith('spotify:')) return CONTENT_URL_REGEXES.SPOTIFY[0];

    const hostName = parseURL(name, true).hostname.toUpperCase();

    const urlTypes = Object.keys(CONTENT_URL_REGEXES) as Array<
        keyof typeof CONTENT_URL_REGEXES
    >;
    /**
     * Matching url type proceeds as follows:
     * 1. Iterate through url types [Youtube, Soundcloud, ..., ]
     * 2. For each url type, retrieve collection of regular expressions associated with that domain name
     * 3. Iterate through expressions and accumulate a collection of boolean results for each expression match
     * 4. Only return a filtered result if the boolean collection is all true.
     */
    const matches = urlTypes.filter((urlType) => {
        const expressionMatches = [...CONTENT_URL_REGEXES[urlType]].map((expression) => {
            return hostName.search(new RegExp(expression, 'i')) !== -1;
        });

        return expressionMatches.some((boolean) => boolean);
    });

    if (matches && matches.length === 1) {
        // Match Found, retrieve the key from CONTENT URL TYPES
        return matches[0];
    }

    // If flag was specified that we want to return null if no domain was matached
    if (nullableIfFailed) {
        return null;
    } else {
        // Signifies an unsuccessful match attempt for this domain name.
        return hostName;
    }
};

/**
 * Handler function to retrieve all embeds based on the host URL.
 */
const retrieveEmbed = (host: string, contentURL: string) => {
    const [YOUTUBE, SOUNDCLOUD, VIMEO, APPLE_MUSIC, GOOGLE_DRIVE, SPOTIFY] =
        Object.keys(CONTENT_URL_REGEXES);

    switch (host) {
        case YOUTUBE:
            return getYoutubeEmbed(contentURL);
        case SOUNDCLOUD:
            return getSoundcloudEmbed(contentURL);
        case VIMEO:
            return getVimeoEmbed(contentURL);
        case APPLE_MUSIC:
            return getAppleMusicEmbed(contentURL);
        case SPOTIFY:
            return getSpotifyEmbed(contentURL);
        case GOOGLE_DRIVE:
        default:
            // return network request
            // If null on client, render raw links
            return null;
    }
};

/**
 * Retrieves the Vimeo Embed using the ombed standard via a GET request. Note that we
 * are retrieving the full src property when available instead of the raw html.
 */
const getSoundcloudEmbed = async (url: string) => {
    const formattedUrl = `https://soundcloud.com/oembed?format=json&url=${url}`;
    const embedData = await fetch(formattedUrl)
        .then((response) => response.json())
        .then((res) => {
            const $ = cheerio.load(res.html);
            const embedUrl = $('iframe').attr('src');

            return embedUrl;
        })
        .catch((error) => {
            logError(url, error);
            return null; // Return null if unable to retrieve embed video id
        });

    return embedData;
};

/**
 * Retrieves the URI Embed link for the Spotify React Player. Note that here we account for
 * either the standard url link, as well as the potential case where the user
 * provides a uri as well.
 */
const getSpotifyEmbed = (uri: string) => {
    const spotifyRoot = 'https://open.spotify.com/';
    const uriSongRoot = 'spotify:track:';
    const uriAlbumRoot = 'spotify:album:';

    if (uri.startsWith(spotifyRoot)) {
        if (uri.includes('?')) {
            const paramsIndex = uri.indexOf('?');
            uri = uri.substr(0, paramsIndex);
        }

        let baseUri = spotifyRoot;

        if (uri.includes('embed/')) baseUri += 'embed/';

        uri = uri.replace(baseUri, '');

        const [contentType, contentId] = uri.split('/');

        return `spotify:${contentType}:${contentId}`;
    } else if (uri.startsWith(uriSongRoot) || uri.startsWith(uriAlbumRoot)) {
        // Signifies that content URL is really a spotify URI already. return.
        return uri;
    } else {
        logError(uri, 'Invalid URI');
        return null;
    }
};

/**
 * Retrieves the proper Apple Music embed src attribute to be used in an iframe based on
 * the content URL
 */
const getAppleMusicEmbed = (uri: string) => {
    const appleMusicRoot = 'https://music.apple.com/';

    if (uri.startsWith(appleMusicRoot)) {
        if (uri.includes('?')) {
            const paramsIndex = uri.indexOf('?');
            uri = uri.substr(0, paramsIndex);
        }

        return uri.replace('music.apple.com', 'embed.music.apple.com');
    } else {
        return null;
    }
};

/**
 * Retrieves the proper Youtube embed src attribute to be used in an iframe based on
 * a various amounts of youtube domain types.
 */
const getYoutubeEmbed = (url: string) => {
    // Aggregate root domains possible when retrieving embeds
    const youtubeRoot = 'https://www.youtube.com/';
    const youtubeRootNonWWW = 'https://youtube.com/';
    const youtubeRootShort = 'https://youtu.be/';

    // source string to use in creating a new embed link.
    const youtubeEmbed = 'https://www.youtube.com/embed/';

    if (url.startsWith(youtubeRoot) || url.startsWith(youtubeRootNonWWW)) {
        if (url.includes('?')) {
            const paramsIndex = url.indexOf('?') + 3; // up to 'v='
            const urlPortion = url.substr(0, paramsIndex);

            const embedURL = url.replace(urlPortion, youtubeEmbed);

            // parse out the appended "&feature=youtube.com" that causes embeds to fry
            if (embedURL.includes('&feature=')) {
                const featureQueryParamIndex = embedURL.indexOf('&feature=');
                return embedURL.substr(0, featureQueryParamIndex);
            }

            return embedURL;
        }
    } else if (url.startsWith(youtubeRootShort)) {
        return url.replace(youtubeRootShort, youtubeEmbed);
    } else {
        logError(url, 'Unrecognized root domain.');
        return null; // returns null to be handled by default.
    }
};

/**
 * Retrieves the Vimeo Embed using the ombed standard via a GET request. Note that we
 * are retrieving the video_id property when available instead of the raw html.
 *
 * Docs: https://developer.vimeo.com/api/oembed/videos#embedding-a-video-with-oembed-step-1
 */
const getVimeoEmbed = async (url: string) => {
    const embed = await fetch(`https://vimeo.com/api/oembed.json?url=${url}`)
        .then((response) => response.json())
        .then((res) => {
            return `https://player.vimeo.com/video/${res.video_id}`;
        })
        .catch((error) => {
            logError(url, error);
            return null; // Return null if unable to retrieve embed video id
        });

    return embed;
};

const logError = (url: string, error: any) => {
    console.error(`Unable to retrieve embed for ${url}. More Info: \n ${error}`);
};

export { retrieveEmbed, getDomainName };
