import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: '450px',
        },

        //Typography
        heavyFont: {
            fontWeight: 'bold',
        },

        // Accordion
        accordion: {
            '&:before': {
                display: 'none',
            },
            '&:first-child': {
                borderRadius: '5px, 5px, 0px, 0px',
            },
            boxShadow: 'none',
        },

        // Credit Text
        creditTextContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        creditName: {
            fontSize: theme.spacing(2),
        },
        typeName: {
            fontSize: theme.spacing(1.25),
            opacity: '65%',
        },

        addCreditHeader: {
            marginTop: theme.spacing(2),
        },
        addCreditDivider: {
            width: '70%',
        },
    });

export default makeStyles(styles);
