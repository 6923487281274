import React from 'react';
import { ListItemIcon, MenuItem } from '@material-ui/core';
import Menu from 'material-ui-popup-state/HoverMenu';
import { SubMenuItem } from 'types/nav';
import { bindMenu, PopupState } from 'material-ui-popup-state/hooks';
import { useRouter } from 'next/router';
import useStyles from './styles';

const SubMenuPopover = (props: SubMenuPopoverProps) => {
    const classes = useStyles();
    const { subMenu, popupState } = props;

    const router = useRouter();

    const handleSubMenuItemClick = (destination: string, cb: Function) => {
        cb();
        router.push(destination);
    };

    return (
        <div>
            <Menu
                {...bindMenu(popupState)}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{ className: classes.menu }}
            >
                {subMenu.map(({ subMenuText, subMenuIcon, to }) => {
                    return (
                        <MenuItem
                            key={subMenuText.replace(' ', '')}
                            onClick={() => {
                                handleSubMenuItemClick(to, popupState.close);
                            }}
                            className={classes.menuItem}
                        >
                            <ListItemIcon className={classes.menuIcon}>
                                {subMenuIcon}
                            </ListItemIcon>
                            {subMenuText}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

export type SubMenuPopoverProps = {
    subMenu: Array<SubMenuItem>;
    popupState: PopupState;
};

export default SubMenuPopover;
