import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        quickReviewButtonContainer: {
            paddingRight: theme.spacing(2),
        },
        quickReviewButton: {
            height: 41, // Size of inputs in SubmissionsToolbar
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            zIndex: 1,
        },
    }),
);

export default useStyles;
